import { defineStore } from 'pinia'
import * as WatchlistApi from '@/api/watchlists'
import { logger } from '@moatmetrics/vue-logger'
import { useAuth } from '@/auth/authPlugin'

export const useWatchlistStore = defineStore('watchlist', {
    state: () => ({
        watchlistPageData: {
            results: [],
            currentPage: 1,
            rowCount: 0,
            pageSize: 10,
        },
        fetchingWatchlists: false,
        watchlistToEdit: null,
    }),
    getters: {},
    actions: {
        async getWatchlists(params, userId) {
            if (!this.fetchingWatchlists) {
                this.fetchingWatchlists = true
                try {
                    const { data } = await WatchlistApi.getWatchlists(params, userId)

                    this.watchlistPageData = data

                    return data
                } catch (err) {
                    logger.error(err)
                    throw err
                } finally {
                    this.fetchingWatchlists = false
                }
            }
            logger.warn('Already fetching watchlists')
        },
        async updateWatchlist(payload) {
            try {
                await WatchlistApi.updateWatchlist(payload.id, payload)

                const watchlists = { ...this.watchlistPageData }
                const index = watchlists.results.findIndex((w) => w.id === payload.id)

                watchlists.results[index] = payload

                this.watchlistPageData = watchlists
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async createWatchlist(payload) {
            try {
                const { user } = useAuth()

                await WatchlistApi.createWatchlist(payload)
                await this.getWatchlists(null, user.value.id)
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async deleteWatchlist(watchlistId) {
            try {
                const { status } = await WatchlistApi.deleteWatchlist(watchlistId)

                if (status === 204) {
                    return
                }

                throw this.$t('watchlist.list.deleteError')
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        resetWatchLists() {
            this.watchlistPageData = {
                results: [],
                currentPage: 1,
                rowCount: 0,
                pageSize: 10,
            }
        },
        setWatchlistToEdit(watchlist) {
            this.watchlistToEdit = watchlist
        },
    },
})
