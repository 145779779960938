<template>
    <div class="insights">
        <AonCard class="insights-card pa-2" removePadding="true">
            <AonRow class="insight-row" v-for="insight in insights" :key="insight.id">
                <div class="insight-body">
                    <font-awesome-icon icon="fas fa-lightbulb-on" class="mr-2" />
                    <p class="insight-type mr-2">
                        {{ t(`insights.types.${insight.insightType}`) }}
                    </p>
                    <p class="insight-text">{{ insight.insightText }}</p>
                    <p class="learn-more-link link small" @click="gotoProductTab()">Learn More</p>
                </div>
                <hr v-if="insight !== insights[insights.length - 1]" />
            </AonRow>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useEntityStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import AonCard from '@moatmetrics/armory/src/components/base/AonCard.vue'

const entityStore = useEntityStore()
const insights = ref([])
const { t } = useI18n()
const router = useRouter()

onMounted(async () => {
    const response = await entityStore.getInsights(entityStore.entity.aon_entity_pk)
    insights.value = response.results
})

const gotoProductTab = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: 'moat2Product' },
    })
}
</script>

<style scoped>
.insights {
    width: 100%;
}
.insights-card {
    &.a-card {
        background: rgb(252, 252, 218);
    }
}

.insight-row {
    display: block;
}

.insight-body {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.insight-type {
    flex: 0 0 auto;
    font-weight: bold;
}

.insight-text {
    flex: 1 1 auto;
}

.learn-more-link {
    flex: 0 0 auto;
}
</style>
