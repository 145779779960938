import axios from 'axios'
import { config } from '@/config'

const baseUrl = `${config.external.reportSvcUrl}/reports`

export function getReports(params, userId) {
    let endpoint = `${baseUrl}`
    params.requested_user = userId
    params.requested_application = 'ip_alpha'
    return axios.get(endpoint, { params })
}

export function getReport(reportPk) {
    let endpoint = `${baseUrl}/${reportPk}`
    return axios.get(endpoint, { paramsSerializer: { indexes: null } })
}

export function createReport(params) {
    return axios.post(baseUrl, params)
}

export function patchReport(reportPk, params) {
    return axios.patch(`${baseUrl}/${reportPk}`, params)
}
