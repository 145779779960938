<template>
    <div class="damages-incurred-card grey04">
        <AonCard style="padding: 0 !important; position: relative; min-height: 200px">
            <div class="card-content">
                <AonCoverLoading
                    :loading="loadingDamagesIncurred"
                    title="Loading Damages..."
                ></AonCoverLoading>
                <div v-if="!noData && !loadingDamagesIncurred" class="card-content-innner">
                    <div class="heading pa-5 heading-background">
                        <p class="extra-small grey01--text bold uppercase mb-1">Damages Incurred</p>
                        <p class="large grey01--text" style="">Quantifying the</p>
                        <h6 class="brown-highlight--text bold mb-1">Financial Impact</h6>
                        <p class="grey01--text">
                            Understanding the financial toll of litigation in an area is crucial for
                            assessing its risk exposure.
                        </p>
                    </div>
                    <div class="case-holder pa-5">
                        <CaseDetails
                            v-for="caseItem in caseData"
                            :case-data="caseItem"
                            class="mb-3"
                        />
                    </div>
                </div>
                <AonCoverLoading
                    v-if="noData"
                    :loading="noData ? true : false"
                    :show-spinner="false"
                    title="No Discernible Damages"
                >
                    <template #header>
                        <div class="icon">
                            <font-awesome-icon
                                icon="fas fa-circle-exclamation"
                                class="grey01--text"
                                size="2xl"
                            />
                        </div>
                    </template>
                </AonCoverLoading>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { useEntityStore } from '@/stores'
import CaseDetails from './shared/CaseDetails.vue'

import { getLitigationCases } from '@/api/ipNode'

const entityStore = useEntityStore()
const filters = inject('filters')

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})

const logger = inject('logger')
const loadingDamagesIncurred = ref(false)
const caseData = ref(null)
const noData = ref(false)

onMounted(() => {
    onGetLitigationCases()
})

const onGetLitigationCases = async () => {
    loadingDamagesIncurred.value = true
    try {
        const params = {
            SC: ['totalDamages'],
            SD: [0],
            FC: ['totalDamages', 'caseStatus'],
            FT: ['gt', 'eq'],
            FV: [0, 'Closed'],
            page_size: 10,
            page: 1,
        }
        const { data } = await getLitigationCases(props.nodeData.id, params)
        if (!data.results.length) {
            noData.value = true
            return
        }
        caseData.value = data.results
    } catch (error) {
        logger.error('Error fetching litigation cases', error)
    } finally {
        loadingDamagesIncurred.value = false
    }
}
</script>

<style lang="scss" scoped>
.damages-incurred-card {
    width: 100%;

    .heading-background {
        background: rgba($warningBackground, 0.5);
        border-radius: 8px 8px 0 0;
    }

    .case-holder {
        max-height: 500px;
        overflow-y: auto;
    }
}
</style>
