import axios from 'axios'

const baseUrl = 'api/watchlists'

export function getWatchlists(params, userId) {
    let endpoint = `${baseUrl}/user/${userId}/query`
    return axios.get(endpoint, { params, paramsSerializer: { indexes: null } })
}

export function updateWatchlist(watchlistId, watchlist) {
    return axios.put(`${baseUrl}/${watchlistId}`, watchlist)
}

export function createWatchlist(watchlist) {
    return axios.post(baseUrl, watchlist)
}

export function deleteWatchlist(watchlistId) {
    return axios.delete(`${baseUrl}/${watchlistId}`)
}
