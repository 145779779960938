<template>
    <div class="arena-board">
        <div class="board-holder">
            <ArenaBoardCanvas v-if="finalArenaData" :board-data="finalArenaData" />
        </div>
        <ArenaBoardSlideOut v-if="arenaStore.selectedNode" />
    </div>
</template>

<script setup>
import { ref, watch, onMounted, inject } from 'vue'
import { useArenaStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import ArenaBoardSlideOut from './ArenaBoardSlideOut.vue'
import ArenaBoardCanvas from './arenaCanvas/ArenaBoardCanvas.vue'

const arenaStore = useArenaStore()
const { t } = useI18n()
const eventBus = inject('eventBus')

const props = defineProps({
    startingBoardData: {
        type: Array,
        default: () => [],
    },
})

const finalArenaData = ref(null)
const graph = ref(null)

onMounted(() => {
    if (props.startingBoardData) {
        aggregateData(true)
    }
})

watch(
    () => props.startingBoardData,
    (newVal, oldData) => {
        if (newVal !== oldData) {
            setTimeout(() => {
                aggregateData(false)
            }, 200)
        }
    }
)

const aggregateData = (init = false) => {
    arenaStore.boardData = props.startingBoardData.filter((item) => item.include !== false)

    let nodes = arenaStore.boardData.map((item) => {
        return {
            id: item.nodeId,
            parent: item.parent,
            type: 'custom',
            position: { x: 0, y: 0 },
            data: {
                nodeType: item.type,
                name: item.displayName,
                included: item.include,
                width: item.displayName.length,
                entityPk: item.internalIdentifier,
                id: item.id,
            },
        }
    })

    let edges = arenaStore.boardData
        .map((item) => {
            if (item.parent && item.parent !== item.rootNodeId) {
                return {
                    id: `${item.parent}-${item.nodeId}`,
                    source: item.parent,
                    target: item.nodeId,
                    animated: true,
                }
            }
        })
        .filter((item) => item)

    const companiesWithoutProducts = arenaStore.boardData
        .filter((item) => item.type === 'company')
        .some((company) => {
            const hasProductEdge = edges.some(
                (edge) => edge.source === company.nodeId || edge.target === company.nodeId
            )
            return !hasProductEdge
        })

    const hasEdges = edges.length >= 1
    const hasSingleConcept =
        arenaStore.boardData.length === 1 && arenaStore.boardData[0].type === 'concept'
    const hasCompany = arenaStore.boardData.find((item) => item.type === 'company')
    const hasConcept = arenaStore.boardData.find((item) => item.type === 'concept')
    const anyData = arenaStore.boardData.length > 0
    const hasNoCompanies = !arenaStore.boardData.some((item) => item.type === 'company')

    switch (true) {
        case !anyData:
            arenaStore.allowGenerate = false
            break
        case companiesWithoutProducts:
            arenaStore.allowGenerate = false
            eventBus.emit('snacktime', {
                type: 'caution',
                message: 'All companies must be connected to at least one product.',
            })
            break
        case hasSingleConcept:
            arenaStore.allowGenerate = true
            break
        case hasCompany && hasEdges:
            arenaStore.allowGenerate = true
            break
        case hasConcept && hasEdges:
            arenaStore.allowGenerate = true
            break
        case anyData && hasNoCompanies:
            arenaStore.allowGenerate = true
            break
        default:
            arenaStore.allowGenerate = false
            break
    }
    const graphData = {
        nodes: nodes,
        edges: edges,
    }
    finalArenaData.value = graphData
}
</script>

<style lang="scss" scoped>
.arena-board {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .board-holder {
        width: 100%;
        height: 100%;

        position: relative;
    }

    .arena-steps {
        position: absolute;
        top: 0;
        left: 0;

        width: 350px;
    }

    #arena-container {
        width: 100%;
        height: 100%;
    }

    :deep(.minimap) {
        position: absolute;
        top: 10px;
        left: 10px;
        background: white;
        box-shadow:
            0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 3px 1px -2px rgba(0, 0, 0, 0.12),
            0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }
}
</style>
