import { reactive, inject, onMounted } from 'vue'

export default {
    template: `
        <div class="link-wrapper h-full d-flex align-items-center" style="width: fit-content">
            <p class="small overflow-ellipsis" :class="{ 'link': checkForValidType(params.data.type) }">{{params.value}} </p>
        </div>
    `,

    emits: ['cell-clicked'],
    setup(props, { emit }) {
        const state = reactive({
            context: {},
        })

        const checkForValidType = (type) => {
            // const validTypes = ['concept', 'product', 'product_category', 'problem_statement', 'cluster']
            const validTypes = ['concept', 'problem_statement']

            if (validTypes.includes(type)) {
                return true
            }
            return false
        }

        return {
            state,
            checkForValidType,
        }
    },
}
