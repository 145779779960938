<template>
    <div class="link-wrapper h-full d-flex align-items-center" style="width: fit-content">
        <p
            v-if="params.data.report_status === 'complete'"
            class="overflow-ellipsis link small"
            @click="downloadReportEmit()"
        >
            {{ params.data.report_name }}
        </p>
        <p v-else class="overflow-ellipsis small">
            {{ params.data.report_name }}
        </p>
        <AonSpinner v-if="loading" class="ml-n13" :scale="0.15" />
    </div>
</template>

<script setup>
import { inject, onMounted, onBeforeUnmount, ref } from 'vue'
import AonSpinner from '@moatmetrics/armory/src/components/base/AonSpinner'

const eventBus = inject('eventBus')

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
})

const loading = ref(false)

onMounted(() => {
    eventBus.on('report-loading', (report) => {
        if (report.reportPk === props.params.data.report_pk) {
            loading.value = report.loading
        }
    })
})

onBeforeUnmount(() => {
    eventBus.off('report-loading')
})

const downloadReportEmit = () => {
    loading.value = loading
    props.params.reportClicked(props.params)
}
</script>
