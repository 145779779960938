<template>
    <AonModal
        class="screeners-modal"
        :title="$t('screeners.title')"
        @close="close()"
        @click:outside="close()"
    >
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" />
        <div v-else class="modal-content">
            <AonContainer>
                <AonRow>
                    <AonCol class="aon-col-12 mt-n2 pa-0" justify-content="space-between">
                        <AonTabs
                            class="light"
                            :tabs="tabData"
                            :current-tab="currentTab"
                            @tab-change="tabChange"
                        />
                    </AonCol>
                </AonRow>
                <AonRow v-if="currentTab === 1">
                    <AonCol class="aon-col-12 mt-4 pa-0" justify-content="space-between">
                        <template v-if="showCompany">
                            <p class="bold w-full mb-4">
                                {{ $t('screeners.screener.types.company') }}
                            </p>
                            <div class="list-holder w-full">
                                <NoData v-if="!popularCompanyScreeners.length" />
                                <ScreenerListItem
                                    v-for="screener in popularCompanyScreeners"
                                    :key="`${screener.id}`"
                                    class="mb-4"
                                    :screener="screener"
                                    @link="close()"
                                    @action="actionTaken()"
                                />
                            </div>
                        </template>
                        <template v-if="showMarket">
                            <p class="bold w-full mb-4">
                                {{ $t('screeners.screener.types.market') }}
                            </p>
                            <div class="list-holder w-full">
                                <NoData v-if="!popularMarketScreeners.length" />
                                <ScreenerListItem
                                    v-for="screener in popularMarketScreeners"
                                    :key="`${screener.id}`"
                                    class="mb-4"
                                    :screener="screener"
                                    @link="close()"
                                    @action="actionTaken()"
                                />
                            </div>
                        </template>
                    </AonCol>
                </AonRow>

                <AonRow v-if="currentTab === 2">
                    <AonCol class="aon-col-12 mt-4 pa-0" justify-content="space-between">
                        <template v-if="showCompany">
                            <p class="bold w-full mb-4">
                                {{ $t('screeners.screener.types.company') }}
                            </p>
                            <div class="list-holder w-full">
                                <NoData v-if="!customCompanyScreeners.length" />
                                <ScreenerListItem
                                    v-for="screener in customCompanyScreeners"
                                    :key="`${screener.id}`"
                                    class="mb-4"
                                    :screener="screener"
                                    @link="close()"
                                    @action="actionTaken()"
                                />
                            </div>
                        </template>
                        <template v-if="showMarket">
                            <p class="bold w-full mb-4">
                                {{ $t('screeners.screener.types.market') }}
                            </p>
                            <div class="list-holder w-full">
                                <NoData v-if="!customMarketScreeners.length" />
                                <ScreenerListItem
                                    v-for="screener in customMarketScreeners"
                                    :key="`${screener.id}`"
                                    class="mb-4"
                                    :screener="screener"
                                    @link="close()"
                                    @action="actionTaken()"
                                />
                            </div>
                        </template>
                        <AonButton
                            :label="$t('screeners.screener.actions.buttons.create')"
                            @clicked="screenerCreateDialogOpen = !screenerCreateDialogOpen"
                        />
                    </AonCol>
                </AonRow>
            </AonContainer>
        </div>
    </AonModal>
    <ScreenerCreateCard
        v-if="screenerCreateDialogOpen"
        type="main"
        :dialog-open="screenerCreateDialogOpen"
        @cancel="screenerCreateDialogOpen = false"
        @submit="close()"
    />
</template>

<script>
import { useScreenerStore } from '@/stores'

import { MARKET, COMPANY } from '@/lib/constants/screeners'

import ScreenerCreateCard from '@/components/screeners/ScreenerCreateCard'
import ScreenerListItem from '@/components/screeners/ScreenerListItem'
import NoData from '@/components/NoData'
import { COMPANY_SCREENER } from '@/lib/constants/route-names'

export default {
    name: 'ScreenersModal',
    components: {
        NoData,
        ScreenerListItem,
        ScreenerCreateCard,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            screenerCreateDialogOpen: false,
            currentTab: 1,
            tabData: [
                {
                    text: this.$t('screeners.popularScreeners.name'),
                    id: 1,
                },
                {
                    text: this.$t('screeners.customScreeners.name'),
                    id: 2,
                },
            ],
            modalUpdater: 0,
            loading: false,
        }
    },
    computed: {
        screeners() {
            const screenerStore = useScreenerStore()

            return screenerStore.screeners
        },
        customCompanyScreeners() {
            return this.screeners.filter((s) => s.customScreener && s.type === COMPANY)
        },
        customMarketScreeners() {
            return this.screeners.filter((s) => s.customScreener && s.type === MARKET)
        },
        popularCompanyScreeners() {
            return this.screeners.filter((s) => !s.customScreener && s.type === COMPANY)
        },
        popularMarketScreeners() {
            return this.screeners.filter((s) => !s.customScreener && s.type === MARKET)
        },
        defaultCompanyScreeners() {
            return this.screeners.filter((s) => !s.customScreener && s.type === COMPANY)
        },
        defaultMarketScreeners() {
            return this.screeners.filter((s) => !s.customScreener && s.type === MARKET)
        },
        showMarket() {
            return this.$router.currentRoute.value.name === 'Markets'
        },
        showCompany() {
            return this.$router.currentRoute.value.name === COMPANY_SCREENER
        },
    },
    async created() {
        this.loading = true
        const screenerStore = useScreenerStore()

        try {
            await screenerStore.getScreeners()
        } catch (err) {
            this.$logger.error(err)
            this.eventBus.emit('snacktime', {
                type: 'error',
                message: this.$t('screener.errors.update'),
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        close() {
            const screenerStore = useScreenerStore()
            screenerStore.toggleScreenerModal()
        },
        async getScreenerRoute(screener) {
            const queryObject = screener.query

            await this.$router.push({
                name: screener.type === COMPANY ? COMPANY_SCREENER : 'Market Screener',
                params: { screenerId: screener.id },
                query: { filter: queryObject ?? null },
            })
            this.close()
        },
        tabChange(val) {
            this.currentTab = val.id
        },
        actionTaken() {
            this.modalUpdater++
            this.currentTab = 2
        },
    },
}
</script>

<style lang="scss" scoped>
.screeners-modal {
    :deep(.container) {
        overflow: initial !important;
    }
}

.list-holder {
    height: 400px;
    overflow: auto;
    margin-bottom: 20px;
}
</style>
