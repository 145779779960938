<template>
    <div class="overview-tab pa-5 grey04">
        <InnovationFocusCard
            v-if="
                props.type === 'pa' ||
                (arenaOutputStore.targetCompany.length > 0 && props.type !== 'pa')
            "
            :nodeData="props.nodeData"
            :type="props.type"
        />
        <CompetitorInnovationFocusCard :nodeData="props.nodeData" :type="props.type" />
    </div>
</template>

<script setup>
import InnovationFocusCard from './InnovationFocusCard.vue'
import CompetitorInnovationFocusCard from './CompetitorInnovationFocusCard.vue'
import { useArenaOutputStore } from '@/stores'
import { type } from '@amcharts/amcharts5'

const arenaOutputStore = useArenaOutputStore()

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})
</script>

<style lang="scss" scoped>
.overview-tab {
    width: 100%;
}
</style>
