<template>
    <div class="new-watchlist-modal">
        <AonModal
            :title="modalTitle"
            :message="modalMsg"
            @close="cancelDialog()"
            @click:outside="close()"
        >
            <template #titleIcon>
                <font-awesome-icon icon="fas fa-circle-plus" class="icon mr-3" />
            </template>
            <AonContainer>
                <AonRow>
                    <AonCol class="mx-0">
                        <AonRadioGroup
                            v-if="!datasetStore.showOnlyCreateWatchlists"
                            class="mb-5"
                            :options="radioButtonOptions"
                            init-selection="existing"
                            @clicked="radioClicked"
                        />
                        <template v-if="curRadioPick === 'new'">
                            <AonInput
                                ref="watchlistNameInput"
                                v-model="watchlistName"
                                class="create-name mb-5"
                                label="Watchlist Name"
                            />
                            <AonTextarea
                                v-model="watchlistDescription"
                                class="create-description mb-5"
                                label="Watchlist Description"
                            />
                            <AonButton
                                class="button btn-create mr-5"
                                :label="createBtnLabel"
                                :loading="creatingWatchlist"
                                :disabled="watchlistName === null || watchlistName.trim() === ''"
                                @clicked="createWatchlist"
                            />
                            <AonButton label="Cancel" type="ghost" @clicked="cancelDialog()" />
                        </template>
                        <template v-else>
                            <AonInput
                                v-model="watchlistSearch"
                                class="mb-5"
                                search-input
                                show-clear
                                placeholder="Search"
                                @clear="searchInputChanged"
                                @input="searchInputChanged"
                            />

                            <TableAips
                                ref="watchlistTable"
                                class="watchlist-table"
                                table-style="fixed-scrolling"
                                table-name="My watchlists"
                                :all-checked="false"
                                :columns="watchlistColumns"
                                :data="shownWatchlists"
                                sticky-header
                                :use-page-data="false"
                                :loading-data="loadingWatchlists"
                                :default-sort="defaultSort"
                                :no-paging-or-scroll="false"
                                :force-clear-table="forceClearTable"
                                :page-size="20"
                                disable-filters
                                table-height="280px"
                                @loadData="getWatchlists"
                                @tableRowChecked="selectWatchlist"
                            >
                                <template #dataset_name="{ data }">
                                    <p class="grey01--text overflow-ellipsis">
                                        {{ data.dataset_name }}
                                    </p>
                                    <p class="small overflow-ellipsis">
                                        {{ data.dataset_description }}
                                    </p>
                                </template>
                                <template #modified_dt="{ data }">
                                    <p class="grey01--text">
                                        {{ $filters.toUTCString(data.modified_dt) }}
                                    </p>
                                </template>
                            </TableAips>

                            <AonButton
                                class="button btn-add mr-5 mt-4"
                                :label="addButtonText"
                                :loading="modifyingWatchlist"
                                :disabled="selectedWatchlists.size <= 0"
                                @clicked="addToWatchlist"
                            />
                            <AonButton
                                class="mt-4"
                                label="Cancel"
                                type="ghost"
                                @clicked="cancelDialog()"
                            />
                        </template>
                    </AonCol>
                </AonRow>
            </AonContainer>
        </AonModal>
    </div>
</template>

<script setup>
import { computed, inject, ref, watchEffect, onBeforeMount } from 'vue'
import {
    AonButton,
    AonInput,
    AonModal,
    AonRadioGroup,
    AonTextarea,
} from '@moatmetrics/armory/src/components'
import { useDatasetStore } from '@/stores'
import * as DatasetApi from '@/api/datasets'
import { useI18n } from 'vue-i18n'
import { useAuth } from '@/auth/authPlugin'

const { t } = useI18n()
const eventBus = inject('eventBus')

const defaultSort = [
    {
        sortColumn: 'modified_dt',
        sortDirection: -1,
        sortPriority: 1,
    },
]

const watchlistColumns = ref([
    {
        value: 'selected',
        checkboxValue: 'selected',
        showColumn: true,
        hasCheckbox: true,
        headerCheckbox: false,
        hideFromFilters: true,
        disableSort: true,
        width: '70px',
    },
    {
        value: 'dataset_name',
        locale: t('watchlists.list.name'),
        useSlot: true,
        showColumn: true,
        width: '250px',
    },
    {
        value: 'sector_count',
        locale: t('watchlists.list.competencyCount'),
        showColumn: true,
        width: '120px',
    },
    {
        value: 'entity_count',
        locale: t('watchlists.list.companyCount'),
        showColumn: true,
        width: '120px',
    },
    {
        value: 'modified_dt',
        locale: t('watchlists.list.lastModified'),
        useSlot: true,
        showColumn: true,
        width: '120px',
    },
])
const datasetStore = useDatasetStore()
const { user } = useAuth()

const forceClearTable = ref(false)
const curRadioPick = ref('existing')
const modalMsg = ref(t('watchlists.add.instructions'))
const modalTitle = ref(t('watchlists.add.header'))
const createBtnLabel = ref('Create and add items')
const radioButtonOptions = ref([
    { label: t('watchlists.add.existingWatchlist'), value: 'existing' },
    { label: t('watchlists.add.newWatchlist'), value: 'new' },
])
const watchlistName = ref(null)
const watchlistNameInput = ref(null)
const watchlistDescription = ref(null)
const creatingWatchlist = ref(false)
const loadingWatchlists = ref(false)
const modifyingWatchlist = ref(false)
const selectedWatchlists = ref(new Set())
const shownWatchlists = ref(null)
const watchlists = ref(null)
const watchlistSearch = ref(null)
const watchlistTable = ref(null)

const addButtonText = computed(() => {
    return (
        'Add to ' +
        (selectedWatchlists.value.size > 1 ? selectedWatchlists.value.size + ' ' : '') +
        'watchlist' +
        (selectedWatchlists.value.size > 1 ? 's' : '')
    )
})

onBeforeMount(() => {
    if (datasetStore.showOnlyCreateWatchlists) {
        curRadioPick.value = 'new'
        modalTitle.value = t('watchlists.add.create')
        modalMsg.value = ''
        createBtnLabel.value = 'Create empty watchlist'
        radioButtonOptions.value = [{ label: t('watchlists.add.newWatchlist'), value: 'new' }]
    }
})

const formatIdsToAdd = (idsToAdd) => {
    return idsToAdd.map((id) => {
        return { id_value: id.toString() }
    })
}

const addToWatchlist = async () => {
    modifyingWatchlist.value = true
    let idsToAdd = formatIdsToAdd(datasetStore.idsToAdd.id_values)

    try {
        await Promise.all(
            Array.from(selectedWatchlists.value).map(async (datasetPk) => {
                await datasetStore.addToDataset(datasetPk, datasetStore.idsToAdd.id_type, idsToAdd)
            })
        )
        eventBus.emit('watchlistChanged')
        eventBus.emit('snacktime', {
            type: 'success',
            message: t('watchlists.add.addSuccess'),
        })
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('watchlists.add.addError'),
        })
    }

    modifyingWatchlist.value = false
    cancelDialog()
}

const getWatchlists = async ({ params }) => {
    loadingWatchlists.value = true
    const { data } = await DatasetApi.getDatasets(params, user.value.id)

    data.results.forEach((element) => {
        element['selected'] = selectedWatchlists.value.has(element['dataset_pk'])
    })
    watchlists.value = [...data.results]
    shownWatchlists.value = [...data.results]
    applySearchFilter()
    loadingWatchlists.value = false
}

const createWatchlist = async () => {
    creatingWatchlist.value = true

    try {
        await datasetStore.createDataset({
            user_id: user.value.id,
            dataset_name: watchlistName.value,
            dataset_description: watchlistDescription.value,
            dataset_set_type: datasetStore.idsToAdd ? datasetStore.idsToAdd.id_type : 'Entity',
            dataset_values: datasetStore.idsToAdd
                ? formatIdsToAdd(datasetStore.idsToAdd.id_values)
                : [],
        })
        eventBus.emit('snacktime', {
            type: 'success',
            message: t('watchlists.create.createSuccess', { watchlistName: watchlistName.value }),
        })
        eventBus.emit('updateWatchlistPage')
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('watchlists.create.createError'),
        })
    }

    creatingWatchlist.value = false
    cancelDialog()
}

const selectWatchlist = (watchlist) => {
    if (selectedWatchlists.value.has(watchlist.dataset_pk)) {
        selectedWatchlists.value.delete(watchlist.dataset_pk)
    } else {
        selectedWatchlists.value.add(watchlist.dataset_pk)
    }
}

const radioClicked = (pick) => {
    curRadioPick.value = pick
    if (pick === 'new') {
        watchEffect(() => {
            if (watchlistNameInput.value) {
                watchlistNameInput.value.setFocus()
            }
        })
    }
    selectedWatchlists.value = new Set()
}

const applySearchFilter = () => {
    if (watchlistSearch.value) {
        shownWatchlists.value = watchlists.value.filter((watchlist) =>
            watchlist.dataset_name.toLowerCase().includes(watchlistSearch.value.toLowerCase())
        )
    } else {
        shownWatchlists.value = watchlists.value
    }
}

const searchInputChanged = () => {
    if (watchlistTable.value) {
        watchlistTable.value.resetTable()
    }
    applySearchFilter()
}

const cancelDialog = () => {
    const datasetStore = useDatasetStore()
    datasetStore.setIdsToAdd(null)
    datasetStore.toggleShowOnlyCreateWatchLists(false)
}
</script>

<style lang="scss" scoped>
:deep(.a-modal .container) {
    max-height: 820px;
}

.new-watchlist-modal {
    position: relative;
    // fix this in armory....
    z-index: 1000000;
}

:deep(.table-aips-latest) {
    .aips-table {
        // table-layout: auto !important;
        th {
            box-shadow: none !important;
        }
        .checkbox-td {
            .v-popper--has-tooltip {
                display: none !important;
            }
        }
    }

    .aips-table-wrap {
        border: none !important;
    }

    .table-header {
        display: none !important;
    }
}
</style>
