<template>
    <div class="watchlists">
        <AonRow>
            <AonCol class="aon-col-12 pa-0" justify-content="space-between">
                <TableAips
                    ref="watchlistsRef"
                    class="table"
                    :class="{ 'create-report-page': isCreatePage }"
                    sticky-header
                    table-name="My Watchlists"
                    table-height="78vh"
                    table-style="fixed-scrolling"
                    table-density="compact"
                    :use-page-data="false"
                    :table-collapse="false"
                    :page-size="20"
                    :columns="columns"
                    :data="data"
                    :loading-data="loadingWatchlists"
                    :all-checked="false"
                    :default-sort="defaultSort"
                    :table-bulk-actions="isCreatePage ? [] : tableBulkActions"
                    :show-bulk-actions="watchlistsSelected.length > 0"
                    :selected-row="isCreatePage ? selectedRow : ''"
                    enable-header-height-adjustment
                    header-height-adjustment-offset="82px"
                    @loadData="loadTableData"
                    @table-row-checked="selectRow"
                    @bulk-action-selected="selectAction"
                >
                    <template #bulkActionBarPrepend>
                        <div class="holder mr-10 d-flex">
                            <p class="white--text link mr-2" @click="clearSelectedData">Clear</p>
                            <p class="white--text mr-2">•</p>
                            <p class="selections bold white--text">
                                {{ $filters.toLocaleString(watchlistsSelected.length) }}
                                {{ $t('actions.selected') }}
                            </p>
                        </div>
                    </template>
                    <template #tableMessage>
                        <div class="height-holder w-full d-flex align-items-center">
                            <AonSkeleton
                                class="w-full d-flex align-items-center"
                                :loading="loadingWatchlists && allData === 0"
                                :data="allData"
                                light
                            >
                                <div
                                    class="info d-flex align-items-center flex-wrap nowrap"
                                    style="width: fit-content"
                                >
                                    {{
                                        $t('actions.showingCountOf', {
                                            count: $filters.toLocaleString(data.length),
                                            total: $filters.toLocaleString(allData),
                                            units: 'watchlists',
                                        })
                                    }}
                                </div>
                                <AonAlertBox
                                    v-if="watchlistCreatePageWarning"
                                    class="ml-4"
                                    is-contextual
                                    :title="watchlistCreatePageWarning.warning"
                                    type="caution"
                                    style="width: auto"
                                ></AonAlertBox>
                            </AonSkeleton>
                        </div>
                    </template>
                    <template #dataset_name="{ data }">
                        <RouterLink
                            v-if="!isCreatePage"
                            :to="{
                                name: 'Watchlist',
                                params: {
                                    datasetPk: data.dataset_pk,
                                },
                            }"
                        >
                            <p class="link overflow-ellipsis">
                                {{ data.dataset_name }}
                            </p>
                        </RouterLink>
                        <p v-else class="overflow-ellipsis">
                            {{ data.dataset_name }}
                        </p>
                    </template>
                    <template #modified_dt="{ data }">
                        <p class="grey01--text">
                            {{ $filters.toUTCString(data.modified_dt) }}
                        </p>
                    </template>
                    <template #sector_count="{ data }">
                        <p class="grey01--text">
                            {{ $filters.toLocaleString(data.sector_count) }}
                        </p>
                    </template>
                    <template #entity_count="{ data }">
                        <p class="grey01--text">
                            {{ $filters.toLocaleString(data.entity_count) }}
                        </p>
                    </template>
                </TableAips>
            </AonCol>
        </AonRow>
    </div>
    <!-- // TODO why not use the Global SnackBar here? This is displayed underneath the table?? -->
    <AonAlertBox
        v-show="showAlert"
        class="watchlist-alert-box"
        type="error"
        :title="errorTitle"
        :message="errorMessage"
        :can-close="true"
        :width-auto="true"
        @close="showAlert = false"
    />
    <!-- Swap modal content based off of different actions for other tables -->
    <AonModal
        v-if="showModal"
        :title="`Are you sure you want to delete the selected watchlists (${watchlistsSelected.length})?`"
        col-width="4"
        @close="closeModal()"
        @click:outside="closeModal()"
    >
        <form @submit.prevent="deleteWatchlists">
            <AonButton
                class="mr-4"
                label="Confirm"
                :is-form-submit="true"
                :loading="deleteLoading"
            />
            <AonButton label="Cancel" type="ghost" @clicked="closeModal()" />
        </form>
    </AonModal>
</template>

<script setup>
import { ref, computed, inject, onBeforeMount } from 'vue'
import { useDatasetStore } from '@/stores'
import { cloneDeep } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import AonAlertBox from '@moatmetrics/armory/src/components/base/AonAlertBox'
import { useAuth } from '@/auth/authPlugin'

const { t } = useI18n()
const eventBus = inject('eventBus')
const router = useRouter()
const { user } = useAuth()

const props = defineProps({
    isCreatePage: {
        type: Boolean,
        default: false,
    },
})

const columns = ref([
    {
        checkboxValue: 'checked',
        showColumn: true,
        headerCheckbox: false,
        hideFromColToggle: true,
        hideFromFilters: true,
        hasCheckbox: true,
        width: '70px',
    },
    {
        value: 'dataset_name',
        locale: t('watchlists.list.name'),
        showColumn: true,
        useSlot: true,
        filterType: 'string',
    },
    {
        value: 'dataset_description',
        locale: t('watchlists.list.description'),
        showColumn: true,
        useSlot: false,
        filterType: 'string',
    },
    {
        value: 'sector_count',
        locale: t('watchlists.list.competencyCount'),
        disableSort: true,
        showColumn: true,
        useSlot: true,
        filterType: 'number',
    },
    {
        value: 'entity_count',
        locale: t('watchlists.list.companyCount'),
        disableSort: true,
        showColumn: true,
        filterType: 'number',
    },
    {
        value: 'modified_dt',
        locale: t('watchlists.list.lastModified'),
        showColumn: true,
        useSlot: true,
        filterType: 'date-time',
        width: '200px',
    },
])
const watchlistsRef = ref(null)
const allData = ref(0)
const data = ref([])
const showAlert = ref(false)
const errorTitle = ref('Something went wrong')
const errorMessage = ref('')
const loadingWatchlists = ref(false)
const watchlistsSelected = ref([])
const defaultSort = ref([
    {
        sortColumn: 'dataset_name',
        sortDirection: 1,
        sortPriority: 1,
    },
])
const showModal = ref(false)
const deleteLoading = ref(false)
const watchlistCreatePageWarning = ref(null)

const tableBulkActions = computed(() => {
    const isDisabled =
        watchlistsSelected.value[0] &&
        (watchlistsSelected.value.length <= 25 || watchlistsSelected.value.length === 0)
    const canCreateReport =
        watchlistsSelected.value[0] &&
        watchlistsSelected.value.length === 1 &&
        watchlistsSelected.value[0].entity_count > 1
    // TODO use translations
    return [
        {
            itemText: 'Delete Selected Watchlists (Limit 25)',
            disabledRule: isDisabled,
            disabledMsg: 'Delete action must have no more than 25 selected rows',
            faIconStyle: 'fas',
            faIcon: 'trash-can',
        },
        {
            itemText: 'Create Report',
            disabledRule: canCreateReport,
            disabledMsg:
                'Create Report action has a limit of 1 referenced Watchlist, and must have Companies associated to it.',
            faIconStyle: 'fas',
            faIcon: 'circle-plus',
        },
    ]
})

const selectedRow = computed(() => {
    let selectedIndex = data.value.findIndex(
        (item) => item.dataset_pk === watchlistsSelected.value[0]?.dataset_pk
    )
    let selectedWatchlist = data.value.find(
        (item) => item.dataset_pk === watchlistsSelected.value[0]?.dataset_pk
    )

    return `row-${selectedIndex}`
})

const loadTableData = ({ params, paramsChanged }) => {
    if (paramsChanged) {
        allData.value = 0
        data.value = []
    }
    if (params === null) {
        allData.value = 0
        data.value = []
        params = watchlistsRef.value.getParams()
        params.page = 0
        params.last_row_num = 0
    }

    getWatchlists(params)
}

const getWatchlists = async (params) => {
    loadingWatchlists.value = true

    try {
        const datasetStore = useDatasetStore()
        let callData = await datasetStore.getDatasets(params, user.value.id)

        if (callData && callData.results) {
            let results = callData.results.map((item) => {
                item.actions = ' '
                item.checked = watchlistsSelected.value
                    .map((ws) => ws.dataset_pk)
                    .includes(item.dataset_pk)
                return item
            })
            data.value.push(...results)
            allData.value = callData.rowCount
        }
    } catch (err) {
        // TODO why not use the Global SnackBar here? The AonAlertBox is displayed underneath the table??
        errorMessage.value = err.message
        showAlert.value = true
    } finally {
        loadingWatchlists.value = false
    }
}

const deleteWatchlists = async () => {
    loadingWatchlists.value = true
    deleteLoading.value = true

    let selectedClone = cloneDeep(watchlistsSelected.value)

    try {
        const datasetStore = useDatasetStore()

        await datasetStore.deleteDataset(watchlistsSelected.value.map((ds) => ds.dataset_pk))
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('watchlists.delete.deleteError'),
        })
    } finally {
        closeModal()
        removePksFromSelected(selectedClone)
        deleteLoading.value = false
    }

    eventBus.emit('updateWatchlistPage')
}

const selectAction = (selected) => {
    if (selected.itemText === 'Delete Selected Watchlists (Limit 25)') {
        showModal.value = true
    }
    if (selected.itemText === 'Create Report') {
        const targetWatchlist = data.value.find(
            (watchlist) => watchlist.dataset_pk === watchlistsSelected.value[0].dataset_pk
        )

        router.push({ name: 'Create Report', query: { watchlistPk: targetWatchlist.dataset_pk } })
    }
}

const selectRow = async (selected) => {
    if (!selected.checked) {
        watchlistsSelected.value.push({
            dataset_pk: selected.dataset_pk,
            entity_count: selected.entity_count,
        })
    } else {
        watchlistsSelected.value = watchlistsSelected.value.filter(
            (item) => item.dataset_pk !== selected.dataset_pk
        )
    }

    data.value.map((val) => {
        if (val.dataset_pk === selected.dataset_pk) {
            val.checked = !val.checked
        }
        return val
    })

    if (props.isCreatePage) {
        let selectedWatchlist = data.value.find(
            (item) => item.dataset_pk === watchlistsSelected.value[0]?.dataset_pk
        )
        eventBus.emit('createPageWatchlistSelected')
    }
}

const clearSelectedData = () => {
    watchlistsSelected.value = []
    data.value.map((val) => {
        val.checked = false
        return val
    })
}

const removePksFromSelected = (pksToRemove) => {
    watchlistsSelected.value = watchlistsSelected.value.filter(
        (item) => !pksToRemove.includes(item.dataset_pk)
    )
}

const closeModal = () => {
    showModal.value = false
}
</script>

<style lang="scss" scoped>
.watchlists {
    overflow: hidden;
    .watchlist-alert-box {
        max-height: 10vh;
        overflow: hidden;
        overflow-y: auto;
    }

    :deep(.create-report-page) {
        .table-header {
            flex-wrap: unset !important;

            .left-side {
                width: 100% !important;
            }

            .right-side {
                width: 20% !important;
                align-self: end;
            }
        }
    }

    :deep(.table-aips-latest) {
        .aips-table-wrap {
            padding-bottom: 100px;
        }
        .table-filters {
            .full-row {
                &:first-of-type {
                    display: none;
                }
            }
        }
        .table-message {
            width: 100%;
        }

        .table-aips-headers {
            th {
                &:first-of-type {
                    pointer-events: none;
                    cursor: default !important;
                }
            }
        }
    }
}
</style>
