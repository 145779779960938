<template>
    <div class="arena-board-view">
        <AonCoverLoading
            :loading="arenaOutputStore.arenasOutputLoading"
            :show-spinner="jobIdPresent"
            :title="computedLoadingMessage"
            :message="jobIdPresent ? t('arenas.loading.message') : ''"
        >
            <template #footer>
                <progress
                    v-if="percentComplete !== 0"
                    :value="percentComplete"
                    max="100"
                    class="mt-4"
                />
            </template>
        </AonCoverLoading>
        <ArenaBoardOutput
            v-if="showOutput && !arenaOutputStore.arenasOutputLoading"
            :prompt="promptFound"
        />
        <AonCoverLoading
            :loading="error"
            :show-spinner="false"
            :title="t('arenas.error.title')"
            :message="t('arenas.error.msg')"
        >
            <template #header>
                <font-awesome-icon
                    :icon="`fas fa-xmark`"
                    style="border-radius: 100%"
                    class="icon error--text error-background pa-4"
                    size="6x"
                />
            </template>
            <template #footer>
                <div class="button-holder mt-5">
                    <AonButton :label="t('arenas.error.backToPrompt')" @clicked="goToHome()" />
                </div>
            </template>
        </AonCoverLoading>
    </div>
    <TechnologyDrillInModal v-if="arenaOutputStore.showDrillIn" type="arena" />
</template>

<script setup>
import { onBeforeMount, inject, ref, onMounted, onBeforeUnmount, computed, onUnmounted } from 'vue'
import { useAppStore, useArenaOutputStore, useArenaStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { pollForJobData } from '@/api/productAlignment.js'

import ArenaBoardOutput from '@/components/arenaOutput/ArenaBoardOutput.vue'
import TechnologyDrillInModal from '@/components/company/drillIn/TechnologyDrillInModal.vue'

const eventBus = inject('eventBus')
const { t } = useI18n()
const logger = inject('logger')
const route = useRoute()
const router = useRouter()

const arenaOutputStore = useArenaOutputStore()
const appStore = useAppStore()
const arenaStore = useArenaStore()
const jobIdPresent = ref(true)
const showOutput = ref(false)
const promptFound = ref(null)

const interval = ref(null)
const error = ref(null)
const percentComplete = ref(0)

onBeforeMount(() => {
    arenaOutputStore.resetState()
    appStore.pushFooter = true
    arenaOutputStore.arenaOutputActiveTab = 1
})

onMounted(() => {
    route.params.jobPk ? getProductAlignmentData(route.params.jobPk) : getProductAlignmentData(null)
})

onBeforeUnmount(() => {
    clearInterval(interval.data)
})

onUnmounted(() => {})

const computedLoadingMessage = computed(() => {
    return jobIdPresent.value ? t('arenas.loading.title') : 'Coming Soon'
})

const getProductAlignmentData = async (jobId) => {
    if (!jobId) {
        jobIdPresent.value = false
        arenaOutputStore.arenasOutputLoading = true
        return
    }

    arenaOutputStore.alignmentPk = jobId
    arenaOutputStore.arenasOutputLoading = true
    error.value = false

    try {
        getPAStatus(jobId)
        interval.data = setInterval(async () => {
            getPAStatus(jobId)
        }, 5000)
    } catch (err) {
        handleError(t('productAlignment.error.title'))
        error.value = true
    }
}

const getPAStatus = async (jobId, baseQuery = true) => {
    await pollForJobData(
        jobId,
        baseQuery,
        (data) => {
            // succes
            clearInterval(interval.data)
            let nodeData = data.nodes.filter((root) => root.parent !== null && root.include)
            arenaOutputStore.arenaOutputAllTechAreaNodes = nodeData
            arenaOutputStore.arenaOutputTechAreaSelections = nodeData
            // remove later - BB
            promptFound.value = data.nodes.find((item) => item.type === 'concept').displayName

            if (data.productAlignmentType === 'arena') {
                arenaStore.boardGlobalDetails = data
            }

            if (data.entityPk) {
                arenaOutputStore.targetCompany.push({
                    aonEntityPk: data.entityPk,
                    aonEntityName: '',
                    patentCount: 0,
                    powerScore: 0,
                })
                arenaOutputStore.targetCompanyLoading = true
            }

            generateReport()
        },
        (err) => {
            // failure
            error.value = true
            clearInterval(interval.data)
            arenaOutputStore.arenasOutputLoading = false
        },
        (progress) => {
            // progress
            percentComplete.value = progress
        }
    )
}

const generateReport = () => {
    showOutput.value = true
    arenaOutputStore.arenasOutputLoading = false
}

const handleError = (err) => {
    logger.error(err)
    percentComplete.value = 0
    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}

const goToHome = () => {
    router.push({ name: 'Home' })
}
</script>

<style lang="scss" scoped>
.arena-board-view {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
}
</style>
