<template>
    <div class="search-screen-tabs knights-cloak-80">
        <AonContainer add-page-padding>
            <AonRow>
                <AonCol class="aon-col-12 pa-0">
                    <AonTabs :current-tab="currentTab" :tabs="tabData" @tab-change="tabChange" />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
    <router-view />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { COMPANY_SCREENER, COMPANY_SEARCH } from '@/lib/constants/route-names'

const route = useRoute()
const { t } = useI18n()

const currentTab = computed(() => {
    return tabData.value.find((tab) => tab.routeName === route.name)?.id || 1
})

const tabData = ref([
    {
        text: t('searchScreen.tabs.search'),
        id: 1,
        routeName: COMPANY_SEARCH,
    },
    {
        text: t('searchScreen.tabs.screen'),
        id: 2,
        routeName: COMPANY_SCREENER,
    },
])

const tabChange = (val) => {
    router.push({ name: val.routeName })
}
</script>

<style scoped lang="scss"></style>
