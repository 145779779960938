<template>
    <div class="investment-activity-tab pa-5 grey04">
        <p class="extra-small uppercase castle-moat--text bold">
            Understanding Investment Activity
        </p>
        <h5 class="grey01--text">
            Investor Confidence: A Deep Dive into Investment Types and Trends
        </h5>
        <AonCard
            class="mt-5"
            title="Types of Investments"
            :desc="`Understanding the types of investments in ${props.nodeData.displayName} reveals the market's maturity and future potential.`"
        >
            <InvestmentsByYear :nodeData="props.nodeData" :type="props.type" />
            <InvestmentAndValuation :nodeData="props.nodeData" :type="props.type" />
            <TopInvestors :nodeData="props.nodeData" :type="props.type" />
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import { useEntityStore } from '@/stores'

import InvestmentsByYear from './InvestmentsByYear.vue'
import InvestmentAndValuation from './InvestmentAndValuation.vue'
import TopInvestors from './TopInvestors.vue'

const entityStore = useEntityStore()

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})
</script>

<style lang="scss" scoped>
.investment-activity-tab {
    width: 100%;
}
</style>
