<template>
    <div class="moat-table-custom-cell-checkbox d-flex align-items-center h-full">
        <font-awesome-icon
            icon="fas fa-circle-minus"
            class="grey02--text d-inline cursor-pointer"
            size="xl"
            @click="removeSelect"
        />
        <div
            v-if="params.allowNewTab"
            class="new-tab-icon"
            style="cursor: pointer"
            v-tooltip="{ content: 'Open company in a new tab' }"
        >
            <a :href="entityRouteLink(props.params.data.aonEntityPk)" target="_blank">
                <font-awesome-icon
                    class="grey01--text mx-2"
                    icon="fas fa-arrow-up-right-from-square"
                />
            </a>
        </div>
        <p class="small overflow-ellipsis">{{ params.value }}</p>
    </div>
</template>

<script setup>
import { reactive, inject, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { entityRouteLink } from '../../router/utils'
const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
})

const state = reactive({
    cellLabel: '',
    context: {},
})

const eventBus = inject('eventBus')
const router = useRouter()

onBeforeMount(() => {
    state.cellLabel = props.params.value
})

const removeSelect = () => {
    eventBus.emit('remove-selection', props.params)
}
</script>
