<template>
    <div class="research">
        <CompanyDetails
            v-if="routeName && routeName === 'Research Company'"
            :key="`company_details_${router.currentRoute.value.params.targetPk}`"
            :entity-id="router.currentRoute.value.params.targetPk"
        />
        <MarketDetails
            v-if="routeName && routeName === 'Research Market'"
            :key="`market_details_${router.currentRoute.value.params.targetPk}`"
            :market-id="router.currentRoute.value.params.targetPk"
        />
    </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import {} from '@/stores'

import CompanyDetails from '@/components/company/CompanyDetails'
import MarketDetails from '@/components/market/MarketDetails'

const router = useRouter()
const routeName = computed(() => router.currentRoute.value.name)
const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()

watch(
    () => router.currentRoute.value.params.targetPk,
    (newVal, oldData) => {
        entityStore.resetState()
        moat2ProductStore.resetState()
    }
)

onMounted(async () => {
    entityStore.resetState()
    moat2ProductStore.resetState()
})
</script>

<style lang="scss" scoped>
.research {
    width: 100%;
    height: 100%; // height of header nav
    position: relative;
    z-index: 0;
}
</style>
