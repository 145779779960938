<template>
    <div class="wrap">
        <VDropdown
            class="ml-2"
            placement="right"
            :distance="15"
            popper-class="hide-arrow"
            :auto-hide="false"
            :shown="showAddCompany"
        >
            <div
                class="button-holder d-flex align-items-center cursor"
                @click="showAddCompany = true"
            >
                <font-awesome-icon icon="fas fa-circle-plus" class="icon castle-moat--text mr-2" />
                <p class="uppercase castle-moat--text bold small">Add Company</p>
            </div>
            <template #popper>
                <div
                    class="company-search"
                    style="height: 305px; border-radius: 4px; border: solid 1px grey"
                >
                    <GlobalSearch
                        style="min-width: 500px; border-radius: 4px"
                        ignore-enter
                        context="companies"
                        :watchlist-pk="props.watchlistPk"
                        :enable-click-outside="false"
                    />
                </div>
            </template>
        </VDropdown>
        <div v-if="showAddCompany" class="click-catcher" @click="showAddCompany = false"></div>
    </div>
</template>

<script setup>
import { inject, onMounted, onBeforeUnmount, ref } from 'vue'
import GlobalSearch from '@/components/GlobalSearch'
import { recomputeAllPoppers } from 'floating-vue'

const eventBus = inject('eventBus')

const loading = ref(false)
const showAddCompany = ref(false)

const props = defineProps({
    watchlistPk: {
        type: String,
        default: null,
    },
})

onMounted(() => {
    eventBus.on('update-popper-position', () => {
        recomputeAllPoppers()
    })
})

onBeforeUnmount(() => {
    eventBus.off('update-popper-position')
})
</script>

<style lang="scss" scoped>
.add-company {
    position: relative;

    .search-contain {
        position: absolute;
        width: 300px;
        height: 50px;
    }

    :deep(.v-popper__arrow-container) {
        display: none;
    }

    :deep(.company-search) {
        // position: absolute;
        // left: 0;
        // top: 30px;
        border-radius: 4px;
        border: solid 1px $grey01;
    }
}
</style>

<style lang="scss">
.click-catcher {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
}
.hide-arrow {
    border-radius: 8px !important;
    .v-popper__arrow-container {
        display: none;
    }
}
</style>
