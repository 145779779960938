<template>
    <div class="innovation-tab h-full">
        <AonCoverLoading
            :loading="innovationAlignmentLoading"
            :title="`Please hang tight!`"
            :message="loadingMessage"
        >
            <template #footer>
                <progress
                    v-if="percentComplete !== 0"
                    :value="percentComplete"
                    max="100"
                    class="mt-4"
                />
            </template>
        </AonCoverLoading>
        <InnovationCard v-if="!innovationAlignmentLoading" :table-data="nodes" />
        <AonCoverLoading
            class="error-cover"
            :loading="typeof error === 'string' ? true : false"
            :show-spinner="false"
            :title="t('productAlignment.error.title')"
            :message="errorMsg"
        >
            <template #header>
                <font-awesome-icon
                    :icon="`fas fa-xmark`"
                    style="border-radius: 100%"
                    class="icon error--text error-background pa-4"
                    size="6x"
                />
            </template>
            <template #footer>
                <div class="button-holder mt-5 w-full d-flex justify-content-center">
                    <AonButton
                        class="mr-5"
                        :label="t('productAlignment.error.rerun')"
                        type="subtle"
                        @clicked="getProductSegmentationData()"
                    />
                    <AonButton
                        :label="t('productAlignment.error.findLatestRun')"
                        @clicked="getPreviousRunOrRequestNew(true)"
                    />
                </div>
            </template>
        </AonCoverLoading>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, inject, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import {
    requestInnovationAlignment,
    getProductAlignmentRuns,
    pollForJobData,
    getJobData,
} from '@/api/productAlignment.js'

import { useEntityStore } from '@/stores'
import InnovationCard from './InnovationCard.vue'
const { t } = useI18n()
const logger = inject('logger')
const entityStore = useEntityStore()

// Refs/Props
const props = defineProps({
    entityId: {
        type: Number,
        default: undefined,
    },
})

const error = ref(null)
const errorMsg = ref(null)
const innovationAlignmentLoading = ref(true)
const innovationAlignmentId = ref(null)
const percentComplete = ref(0)
const needsNewAlignment = ref(false)
const rootNode = ref(null)
const nodes = ref([])
const allChartData = ref([])
let interval = ref(0)
const loadingMessage = computed(() => {
    if (needsNewAlignment.value) {
        return `We are aligning patents to the product and technology areas for ${entityStore.entity?.name}. This process will take 5-10 minutes if not cached in our system. Please feel free to check out the other tabs and this process will continue in the background.`
    } else {
        return `Loading Innovation Landscape for ${entityStore.entity?.name}`
    }
})
const productAlignmentSearchParams = computed(() => ({
    FC: ['product_alignment_type', 'entity_pk'],
    FT: ['eq', 'eq'],
    FV: ['entity_innovation', props.entityId],
}))

onBeforeUnmount(() => {
    clearInterval(interval)
})

onMounted(async () => {})

const getPreviousRunOrRequestNew = async (findLatestSuccess = false) => {
    innovationAlignmentLoading.value = true
    error.value = null

    try {
        const { data } = await getProductAlignmentRuns(null, productAlignmentSearchParams.value)
        if (data.results.length > 0) {
            const targetData = findLatestSuccess
                ? data.results.find((run) => run.status === 'nodesComplete')
                : data.results[0]
            innovationAlignmentId.value = targetData.productAlignmentId
            if (targetData.isStale) {
                needsNewAlignment.value = true
                innovationAlignmentId.value = null
            } else {
                needsNewAlignment.value = false
            }
        } else {
            needsNewAlignment.value = true
            innovationAlignmentId.value = null
        }
    } catch (err) {
        handleError(err.status, err.statusReason)
    }
    await getProductSegmentationData(innovationAlignmentId.value)
}

const getProductSegmentationData = async (alignmentPk = null) => {
    innovationAlignmentLoading.value = true
    error.value = null
    try {
        if (!alignmentPk) {
            const { data } = await requestInnovationAlignment(props.entityId)
            innovationAlignmentId.value = data.productAlignmentId
        }
    } catch (err) {
        handleError(err.status, err.statusReason)
    }

    interval = setInterval(
        (function paHandler() {
            getPAStatus(innovationAlignmentId.value)
            return paHandler
        })(),
        5000
    )
}

const getPAStatus = async (alignmentPk) => {
    await pollForJobData(
        alignmentPk,
        true,
        async (data) => {
            // success
            clearInterval(interval)
            let paResponse = await getJobData(alignmentPk, false)

            rootNode.value = paResponse.data.rootNodeId
            console.log(paResponse.data.nodes)
            nodes.value = paResponse.data.nodes

            innovationAlignmentLoading.value = false

            // Continue polling for processing nodes
            if (paResponse.data.nodes.find((node) => node.dataStatus === 'processing')) {
                interval = setInterval(
                    (function paHandler() {
                        getProcessingNodes(alignmentPk)
                        return paHandler
                    })(),
                    5000
                )
            }
        },
        (err) => {
            // failure
            handleError(err.status, err.statusReason)
            clearInterval(interval)
        },
        (progress) => {
            percentComplete.value = progress
        },
        'nodesComplete'
    )
}

const getProcessingNodes = async (alignmentPk) => {
    await pollForJobData(
        alignmentPk,
        true,
        async (data) => {
            if (!data.nodes.find((node) => node.dataStatus === 'processing')) {
                clearInterval(interval)
                let paResponse = await getJobData(alignmentPk, true)
                allChartData.value = paResponse.data.nodes
            }
        },
        (err) => {
            logger.error(err)
            clearInterval(interval)
        }
    )
}

const handleError = (err, reason) => {
    logger.error(err)
    percentComplete.value = 0

    switch (reason) {
        case 'noPatents':
            errorMsg.value = t('productAlignment.error.noPatents', {
                entity: entityStore.entity.name,
            })
            break

        case 'unknownCompany':
        case 'noProducts':
            errorMsg.value = t('productAlignment.error.noProducts', {
                entity: entityStore.entity.name,
            })
            break

        case 'systemError':
        default:
            errorMsg.value = t('productAlignment.error.msg')
    }

    innovationAlignmentLoading.value = false
    error.value = reason
}

watch(
    () => props.entityId,
    async (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
            entityStore.getCompetitors(props.entityId)
            await getPreviousRunOrRequestNew()
        }
    },
    { immediate: true }
)
</script>

<style lang="scss" scoped>
@import '@moatmetrics/armory/src/sass/responsive.scss';
.innovation-tab {
    width: 100%;
    position: relative;
    height: 950px;
    .error-cover {
        z-index: 11;
        :deep(p.msg) {
            width: 600px;
            font-weight: bold;
        }
    }

    .header-holder {
        border-bottom: 1px solid $grey05;
    }
    :deep(.button-tabs) {
        .tab {
            border-bottom-width: 1px !important;
            background: $grey06;
            &.selected {
                border-bottom-color: transparent !important;
                background: $knightsCloak;
            }

            &:hover {
                background: $knightsCloak80;
            }

            p {
                font-size: 16px;
            }
        }
    }

    :deep(.icon-button) {
        display: flex;
        align-items: center !important;

        .a-button-label {
            margin-left: 8px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    :deep(.a-carousel) {
        .slide {
            border: solid $grey04 1px;

            .image-holder {
                background-repeat: no-repeat;
            }
        }
        @media screen and (max-width: 1200px) {
            .text-holder {
                padding: 20px !important;
            }

            h2 {
                font-size: 20px;
                line-height: 24px;
            }
            p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
</style>
