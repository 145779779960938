<template>
    <div class="tpe-card">
        <AonCard
            class="h-full"
            title="Innovation Implied P/E"
            desc="Innovation Implied P/E is a predicted estimate for how a company would be valued or recognized, based on the valuation of comparable publicly traded entities participating in like arenas of innovation."
        >
            <template #header-append>
                <div class="stat-focus pa-2 mx-1 mt-16">
                    <div
                        class="high-low-target d-flex align-items-center flex-wrap justify-content-center"
                    >
                        <div class="top-stat d-flex align-items-center">
                            <h4 class="castle-moat--text">{{ tpe.toFixed(2) }}</h4>
                            <div v-if="pe" class="difference-holder d-flex align-items-center ml-2">
                                <font-awesome-icon
                                    v-if="!peDifferenceIsNegative"
                                    icon="fas fa-circle-arrow-up"
                                    class="success--text mr-1"
                                />
                                <font-awesome-icon
                                    v-else
                                    icon="fas fa-circle-arrow-down"
                                    class="error--text mr-1"
                                />
                                <h6
                                    :class="[
                                        { 'error--text': peDifferenceIsNegative },
                                        { 'success--text': !peDifferenceIsNegative },
                                    ]"
                                >
                                    {{ peDifference.toFixed(2) }}
                                </h6>
                            </div>
                        </div>
                        <div
                            class="stat-banner pa-1 w-full mt-2"
                            :class="[{ 'castle-moat': !pe }, { gold: pe }]"
                        >
                            <p class="text-center white--text bold">
                                {{ pe ? 'Market P/E' : 'II P/E' }}
                                <span style="text-decoration: underline">{{
                                    pe ? pe.toFixed(2) : ''
                                }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <div class="card-content d-flex flex-wrap">
                <p v-if="!onValueTab" class="view-more-link link small" @click="gotoVulTab()">
                    View More
                </p>
                <AonCoverLoading
                    :loading="loadingFinancialOverview"
                    title="Loading P/E Data..."
                ></AonCoverLoading>
                <div
                    id="amChartTPE"
                    ref="amChartTPE"
                    v-if="loadingFinancialOverview || hasFinancialOverview"
                ></div>
                <div class="financial-overview-chart" v-if="hasFinancialOverview">
                    <p class="definition-blurb" v-html="tpeBlurb"></p>
                </div>
                <div v-else-if="!loadingFinancialOverview">
                    <p class="no-data italic">No financial data available</p>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed, inject, watch } from 'vue'
import { useEntityStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const router = useRouter()
const route = useRoute()
const entityId = route.params.targetPk
const entityStore = useEntityStore()

const chartData = ref([])
const amChartTPE = ref(null)
const loadingFinancialOverview = ref(false)
const hasFinancialOverview = ref(false)
const tpeMedianColor = '#2A79D2'
const tpeRangeColor = '#BCC9DF'
const peColor = '#F2AF3A'
const pe = ref(0)
const tpe = ref(0)
const onValueTab = ref(false)
let root
let yAxis
let xAxis

const tpeBlurb = computed(() => {
    const targetPlusRange = tpe.value + 5
    const targetMinusRange = tpe.value - 5 < 0 ? 0 : tpe.value - 5

    pe.value = entityStore.financialOverview?.actualEarnings ?? NaN
    tpe.value = entityStore.financialOverview?.predictedEarnings ?? NaN

    if (pe.value) {
        if (pe.value >= targetMinusRange && pe.value <= targetPlusRange) {
            return `This company's current <span style="font-weight: bold;color: ${peColor};">P/E ratio (${pe.value.toFixed(2)})</span> is near our <span style="font-weight: bold;color: ${tpeMedianColor};">Innovation Implied P/E (${tpe.value.toFixed(2)})</span>, indicating alignment with market expectations and balanced valuation.`
        } else if (pe.value < tpe.value) {
            return `This company's current <span style="font-weight: bold;color: ${peColor};">P/E ratio (${pe.value.toFixed(2)})</span> is trailing our <span style="font-weight: bold;color:${tpeMedianColor};">Innovation Implied P/E (${tpe.value.toFixed(2)})</span>, which may indicate untapped potential or inefficiencies in market execution.`
        } else {
            return `This company's current <span style="font-weight: bold;color: ${peColor};">P/E ratio (${pe.value.toFixed(2)})</span> is ahead our <span style="font-weight: bold;color: ${tpeMedianColor};">Innovation Implied P/E (${tpe.value.toFixed(2)})</span>, suggesting strong market performance or overvaluation.`
        }
    }
    return ``
})

const peDifference = computed(() => {
    return pe.value - tpe.value
})

const peDifferenceIsNegative = computed(() => {
    return pe.value - tpe.value > 0 ? true : false
})

onMounted(async () => {
    loadingFinancialOverview.value = true
    await entityStore.getFinancialOverview(entityId)
    hasFinancialOverview.value = entityStore.financialOverview
    if (hasFinancialOverview.value) {
        am5.addLicense(config.license.AMChartsLicense)
        root = am5.Root.new(amChartTPE.value)
        mapData()
        configureChart()
    }
    loadingFinancialOverview.value = false
})

onBeforeUnmount(() => {
    root.dispose()
})

watch(
    () => route.query.tabName,
    (newVal) => {
        if (newVal === 'value') {
            onValueTab.value = true
        } else {
            onValueTab.value = false
        }
    },
    { immediate: true }
)

const mapData = () => {
    chartData.value = [
        {
            company: entityStore.entity.name,
            pe: Math.ceil(entityStore.financialOverview.actualEarnings * 100) / 100,
            tpeLb: Math.ceil(entityStore.financialOverview.predictedEarningsLb * 100) / 100,
            tpe: Math.ceil(entityStore.financialOverview.predictedEarnings * 100) / 100,
            tpeUb: Math.ceil(entityStore.financialOverview.predictedEarningsUb * 100) / 100,
        },
    ]
}

const configureChart = (removeZeros = true) => {
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingLeft: 5,
            paddingRight: 15,
            paddingTop: 20,
            layout: root.verticalLayout,
            maxTooltipDistance: -1,
        })
    )

    generateAxes(chart)
    generateSeries(chart)

    setTimeout(() => {
        let legend = chart.children.push(
            am5.Legend.new(root, {
                nameField: 'name',
                fillField: 'color',
                strokeField: 'color',
                centerX: am5.percent(50),
                x: am5.percent(50),
                paddingTop: 20,
                paddingBottom: 10,
            })
        )
        if (pe.value) {
            legend.data.setAll([
                {
                    name: 'Innovation Implied P/E Confidence Range',
                    color: am5.color(tpeRangeColor),
                },
                { name: 'Innovation Implied P/E Median', color: am5.color(tpeMedianColor) },
                { name: 'Market P/E', color: am5.color(peColor) },
            ])
        } else {
            legend.data.setAll([
                {
                    name: 'Innovation Implied P/E Confidence Range',
                    color: am5.color(tpeRangeColor),
                },
                { name: 'Innovation Implied P/E Median', color: am5.color(tpeMedianColor) },
            ])
        }
    }, 100)
}

const generateAxes = (chart) => {
    let yRenderer = am5xy.AxisRendererY.new(root, {})
    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'company',
            renderer: yRenderer,
            visible: false,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    yRenderer.grid.template.setAll({
        location: 1,
    })

    yAxis.data.setAll(chartData.value)

    let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 50,
        strokeOpacity: 0.1,
        minorGridEnabled: true,
    })
    xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            max: Math.max(
                entityStore.financialOverview.predictedEarningsUb,
                entityStore.financialOverview.actualEarnings,
                100
            ),
            maxPrecision: 0,
            renderer: xRenderer,
        })
    )
}

const generateSeries = (chart) => {
    let tpeRangeSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: 'Innovation Implied P/E Confidence Range',
            xAxis: xAxis,
            yAxis: yAxis,
            baseAxis: yAxis,
            clustered: false,
            openValueXField: 'tpeLb',
            valueXField: 'tpeUb',
            categoryYField: 'company',
            fill: am5.color(tpeRangeColor),
        })
    )

    // Set corner radius for the columns to create rounded edges
    tpeRangeSeries.columns.template.setAll({
        cornerRadiusTL: 10, // Top left corner
        cornerRadiusTR: 10, // Top right corner
        cornerRadiusBL: 10, // Bottom left corner
        cornerRadiusBR: 10, // Bottom right corner
    })

    tpeRangeSeries.columns.template.setAll({
        tooltipText: '[bold]{name}:[/] {categoryY}: {tpe}',
        tooltipY: am5.percent(100),
    })

    tpeRangeSeries.columns.template.setAll({
        height: am5.percent(50),
        strokeOpacity: 0,
    })

    let tpeMedianSeries = chart.series.push(
        am5xy.StepLineSeries.new(root, {
            name: 'Innovation Implied P/E',
            xAxis: xAxis,
            yAxis: yAxis,
            baseAxis: yAxis,
            valueXField: 'tpe',
            categoryYField: 'company',
            stroke: am5.color(tpeMedianColor),
            fill: am5.color(tpeMedianColor),
            stepWidth: am5.percent(75),
            noRisers: true,
            tooltipText: '[bold]Innovation Implied P/E:[/] {categoryY}: {tpe}',
            tooltipPosition: 'pointer',
        })
    )

    tpeMedianSeries.strokes.template.setAll({
        strokeWidth: 3,
    })

    tpeRangeSeries.data.setAll(chartData.value)
    tpeMedianSeries.data.setAll(chartData.value)

    tpeRangeSeries.appear()
    tpeMedianSeries.appear()

    if (entityStore.financialOverview.actualEarnings) {
        let peSeries = chart.series.push(
            am5xy.StepLineSeries.new(root, {
                name: 'Market P/E',
                xAxis: xAxis,
                yAxis: yAxis,
                baseAxis: yAxis,
                valueXField: 'pe',
                categoryYField: 'company',
                clustered: false,
                stroke: am5.color('#F2AF3A'),
                fill: am5.color('#F2AF3A'),
                stepWidth: am5.percent(75),
                noRisers: true,
                tooltipText: '[bold]Market P/E:[/] {categoryY}: {pe}',
                tooltipPosition: 'pointer',
            })
        )

        peSeries.strokes.template.setAll({
            strokeWidth: 3,
        })

        peSeries.data.setAll(chartData.value)
        peSeries.appear()
    }
}

const gotoVulTab = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: 'value' },
    })
}
</script>

<style lang="scss" scoped>
.tpe-card {
    width: 100%;
    position: relative;
    min-height: 400px;

    .stat-focus {
        border: solid 1px $grey01;
        border-radius: 4px;
        min-width: 180px;
    }

    .view-more-link {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    :deep(.a-card) {
        .top {
            margin-top: -32px;
        }
    }

    #amChartTPE {
        width: 100%;
        height: 200px;
        div {
            height: 100%;
        }
    }
}
</style>
