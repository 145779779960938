import { defineStore } from 'pinia'
import * as Api from '@/api/opensearch'
import { logger } from '@moatmetrics/vue-logger'

export const useOpensearchStore = defineStore('opensearch', {
    state: () => ({
        loading: false,
        entityHeaders: {},
        sectorHeaders: {},
        entitiesPageData: {
            results: [],
            currentPage: 1,
            rowCount: 0,
            pageSize: 10,
        },
        sectorsPageData: {
            results: [],
            currentPage: 1,
            rowCount: 0,
            pageSize: 10,
        },
    }),
    getters: {},
    actions: {
        async resetState() {
            this.$reset()
        },
        async searchEntities(dslQuery) {
            try {
                this.loading = true
                const { data } = await Api.searchEntities(dslQuery)
                this.entitiesPageData.results = data.hits.hits.map((h) => h._source)
                this.entitiesPageData.rowCount = data.hits.total.value
            } catch (err) {
                logger.error(err)
                throw err
            } finally {
                this.loading = false
            }
        },
        async searchSectors(dslQuery) {
            try {
                this.loading = true
                const { data } = await Api.searchSectors(dslQuery)
                this.sectorsPageData.results = data.hits.hits.map((h) => h._source)
                this.sectorsPageData.rowCount = data.hits.total.value
            } catch (err) {
                logger.error(err)
                throw err
            } finally {
                this.loading = false
            }
        },
        setEntitiesSearchPaging(
            paging = { results: [], currentPage: 1, pageSize: 10, rowCount: 0 }
        ) {
            const searchPaging = {
                ...paging,
            }
            searchPaging.results = []
            this.entitiesPageData = searchPaging
        },
        setSectorsSearchPaging(
            paging = { results: [], currentPage: 1, pageSize: 10, rowCount: 0 }
        ) {
            const searchPaging = {
                ...paging,
            }
            searchPaging.results = []
            this.sectorsPageData = searchPaging
        },
    },
})
