<template>
    <div class="breadcrumb">
        <p
            v-if="type !== 'large'"
            v-tooltip="{
                content: hoverText,
            }"
            class="small overflow-ellipsis"
            :class="color"
        >
            {{ breadcrumb }}
        </p>
        <h6
            v-else
            v-tooltip="{
                content: hoverText,
            }"
            class="small overflow-ellipsis"
            :class="color"
        >
            {{ breadcrumb }}
        </h6>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumb',
    props: {
        market: {
            type: Object,
            required: true,
        },
        text: {
            type: String,
            default: null,
        },
        hover: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: 'grey02--text',
        },
    },
    computed: {
        breadcrumb() {
            if (this.text) {
                return this.text
            }

            if (!this.market.sectorPath?.length) {
                return ''
            }
            const parents = this.market.sectorPath.map((p) => p.sectorName)
            parents.pop()

            if (parents.length === 0) {
                return ''
            }

            if (parents.length > 2) {
                return `${parents[0]} > ... > ${parents[parents.length - 1]} > `
            }

            if (parents.length === 2) {
                return `${parents[0]} > ${parents[1]} > `
            }

            return `${parents[0]} > `
        },
        hoverText() {
            if (this.hover) {
                return this.hover
            }

            if (!this.market.sectorPath?.length) {
                return ''
            }
            return this.market.sectorPath.map((p) => p.sectorName).join(' > ')
        },
    },
}
</script>

<style lang="scss" scoped>
.breadcrumb {
    width: 100%;
    display: contents;
}

p {
    max-width: 400px;
}

h6 {
    width: 100%;
}
</style>
