<template>
    <div class="match-up-report-output d-flex flex-column">
        <div class="output-header knights-cloak pa-4">
            <h5 class="gold--text">Competitive Landscape</h5>
            <p class="white--text">
                Provides a comprehensive matrix view of selected target and competitor portfolios
                across key products and technologies. Utilize the Analyze By tool to strategically
                compare, analyze risks, identify opportunities, and explore various scenarios.
                Change the categories to compare companies across new technology areas.
            </p>
        </div>
        <div class="action-holder d-flex justify-content-between align-items-center my-5">
            <div class="copy">
                <h6>Hey there</h6>
                <p>Need something to put here</p>
            </div>
            <div class="buttons d-flex">
                <AonButton
                    class="mr-5"
                    label="Change Target Company"
                    type="subtle"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-bullseye' }"
                    @clicked="toggleTargetCompanyModal()"
                />
                <AonButton
                    class="mr-5"
                    label="Change Competitors"
                    :text-with-icon="true"
                    type="subtle"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-people-group' }"
                    @clicked="toggleCompetitorModal()"
                />
                <AonButton
                    label="Export Patent Data"
                    :text-with-icon="true"
                    type="subtle"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                    @clicked="exportTable()"
                />
            </div>
        </div>
        <MoatTable
            class="w-full flex-grow-1"
            :key="updater"
            :class="themeClass"
            :column-defs="colDefs"
            :row-data="tableData"
            :tooltip-show-delay="500"
            :treeData="true"
            :autoGroupColumnDef="autoGroupColumnDef"
            :groupRemoveLowestSingleChildren="true"
            :groupDisplayType="groupDisplayType"
            :getDataPath="getDataPath"
            @grid-ready="gridInit"
        />
    </div>
</template>

<script setup>
import { ref, inject, onMounted, nextTick, computed, onBeforeMount } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { formatTpeRange } from '@/lib/financial.js'

const playbookGenerationStore = usePlaybookGenerationStore()

const eventBus = inject('eventBus')
const logger = inject('logger')
const { t } = useI18n()
const router = useRouter()

const updater = ref(0)
const loading = ref(false)
const gridApi = ref(null)
const getDataPath = ref(null)
const groupDisplayType = ref(null)
const autoGroupColumnDef = ref(null)
const themeClass = ref('ag-theme-quartz')
const target = ref('QUALCOMM, Inc.')
const tableData = ref([
    {
        nodeId: '51eee03d-57a4-4818-8841-5ccd5d25bf73',
        displayName: '3D Printing For Custom Parts',
        parentNodeId: 'd4119a10-ca43-4e7c-ae6b-b6965fb188a0',
        parentDisplayName: 'Automotive Parts And Accessories',
        internalIdentifier: null,
        nodeHierarchy: [
            {
                nodeId: 'd4119a10-ca43-4e7c-ae6b-b6965fb188a0',
                type: 'product',
                displayName: 'Automotive Parts And Accessories',
            },
            {
                nodeId: '51eee03d-57a4-4818-8841-5ccd5d25bf73',
                type: 'concept',
                displayName: '3D Printing For Custom Parts',
            },
        ],
        nodeType: 'concept',
        totalPatentCount: 1451,
    },
    {
        nodeId: '20003d18-ae80-4051-bc8e-1aabdf972a7c',
        displayName: 'High-Efficiency Filtration Materials',
        parentNodeId: 'd4119a10-ca43-4e7c-ae6b-b6965fb188a0',
        parentDisplayName: 'Automotive Parts And Accessories',
        internalIdentifier: null,
        nodeHierarchy: [
            {
                nodeId: 'd4119a10-ca43-4e7c-ae6b-b6965fb188a0',
                type: 'product',
                displayName: 'Automotive Parts And Accessories',
            },
            {
                nodeId: '20003d18-ae80-4051-bc8e-1aabdf972a7c',
                type: 'concept',
                displayName: 'High-Efficiency Filtration Materials',
            },
        ],
        nodeType: 'concept',
        totalPatentCount: 1624,
    },
    {
        nodeId: 'd4119a10-ca43-4e7c-ae6b-b6965fb188a0',
        displayName: 'Automotive Parts And Accessories',
        parentNodeId: 'd4119a10-ca43-4e7c-ae6b-b6965fb188a0',
        parentDisplayName: 'Automotive Parts And Accessories',
        internalIdentifier: null,
        nodeHierarchy: [
            {
                nodeId: 'd4119a10-ca43-4e7c-ae6b-b6965fb188a0',
                type: 'product',
                displayName: 'Automotive Parts And Accessories',
            },
        ],
        nodeType: 'product',
        totalPatentCount: 0,
    },
])
const companiesData = ref([
    {
        totalPatentCount: 1451,
    },
    {
        totalPatentCount: 1624,
    },
    {
        totalPatentCount: 12543,
    },
])

const colDefs = ref([
    {
        headerName: 'QUALCOMM, Inc.',
        suppressMenu: true,
        valueGetter: (params) => {
            if (params.data.nodeType !== 'concept') {
                return '--'
            } else {
                return companiesData.value[0].totalPatentCount.toLocaleString()
            }
        },
        headerClass: (params) => {
            return params.colDef.headerName === target.value ? 'target-header' : 'comp-header'
        },
    },
    {
        headerName: 'Intel Corporation',
        suppressMenu: true,
        valueGetter: (params) => {
            if (params.data.nodeType !== 'concept') {
                return '--'
            } else {
                return companiesData.value[1].totalPatentCount.toLocaleString()
            }
        },
        headerClass: (params) => {
            return params.colDef.headerName === target.value ? 'target-header' : 'comp-header'
        },
    },
    {
        headerName: 'Apple Inc.',
        suppressMenu: true,
        valueGetter: (params) => {
            if (params.data.nodeType !== 'concept') {
                return '--'
            } else {
                return companiesData.value[2].totalPatentCount.toLocaleString()
            }
        },
        headerClass: (params) => {
            return params.colDef.headerName === target.value ? 'target-header' : 'comp-header'
        },
    },
])

onBeforeMount(() => {
    defineTableGroups()
    getDataPath.value = (data) => {
        return data.nodeHierarchy.map((item) => item.displayName)
    }
    groupDisplayType.value = 'singleColumn'
})

onMounted(async () => {
    onGetPlaybookOutput()
})

const gridInit = (params) => {
    gridApi.value = params.api
    gridApi.value.expandAll()
}

const onGetPlaybookOutput = async () => {
    loading.value = true

    // tableData.value = [{ metric: 'totalPatentCount' }]
}

const defineTableGroups = () => {
    autoGroupColumnDef.value = {
        minWidth: 450,
        suppressMenu: true,
        headerName: 'Products & Technologies',
        field: 'displayName',
        valueFormatter: (p) => {
            if (p.value) {
                if (p.value.displayName) {
                    return p.value.displayName
                } else {
                    return p.value
                }
            }
        },
        suppressMovable: true,
        lockPosition: 'left',
        sortingOrder: ['asc', 'desc', null],
    }
}

const toggleTargetCompanyModal = () => {}
const toggleCompetitorModal = () => {}

const exportTable = () => {
    gridApi.value.exportDataAsExcel()
}
</script>

<style lang="scss" scoped>
.match-up-report-output {
    height: 100%;
    width: 100%;

    :deep(.target-header) {
        background-color: rgba($gold, 0.8) !important;
        // span, p {
        //     color: $white !important;
        // }
    }

    :deep(.comp-header) {
        background-color: $grey05 !important;
    }
}
</style>
