import { createAuth0, useAuth0 } from '@auth0/auth0-vue'
import { computed, ref } from 'vue'

const USER_INJECT_TOKEN = 'user'
const AUTH_CONTEXT_INJECT_TOKEN = 'authContext'

const auth0Client = {
    user: ref(null),
    isAuthenticated: ref(false),
    tosAccepted: ref(true),
    getAccessTokenSilently: () => {},
    logout: async () => {},
    handleRedirectCallback: async () => {},
}

export const useAuth = () => {
    return auth0Client
}

const plugin = {
    install(app, config) {
        const urlParams = new URLSearchParams(window.location.search)

        let authParams = {
            redirect_uri: config.redirectUri,
            audience: config.audience,
        }

        if (urlParams.has('invitation')) {
            authParams.invitation = urlParams.get('invitation')
            authParams.organization = urlParams.get('organization')
        }

        const auth0Plugin = createAuth0(
            {
                domain: config.domain,
                clientId: config.clientId,
                authorizationParams: authParams,
                useCookiesForTransactions: false,
                cacheLocation: 'localstorage',
            },
            {
                skipRedirectCallback: true,
            }
        )

        app.use(auth0Plugin)

        auth0Client.user = computed(() => ({
            ...auth0Plugin.user.value,
            id: auth0Plugin.user?.value?.['https://moatmetrics.com/uuid'],
        }))

        auth0Client.isAuthenticated = auth0Plugin.isAuthenticated
        auth0Client.getAccessTokenSilently = auth0Plugin.getAccessTokenSilently
        auth0Client.logout = auth0Plugin.logout
        auth0Client.handleRedirectCallback = auth0Plugin.handleRedirectCallback
    },
}

export default plugin
