<template>
    <AonModal
        class="delete-modal"
        title="Delete Screener"
        col-width="8"
        @close="cancelDialog()"
        @click:outside="cancelDialog()"
    >
        <p>{{ $t('screeners.screener.screenerDelete.confirmationText') }}</p>
        <p class="my-6 italic">
            <strong>{{ screener.name }}</strong>
        </p>
        <AonButton
            :label="$t('screeners.screener.actions.buttons.delete')"
            :loading="loading"
            @click="deleteScreener()"
        />
    </AonModal>
</template>

<script>
import { useScreenerStore } from '@/stores'
import { deleteScreener as httpDeleteScreener } from '@/api/screeners'

export default {
    name: 'ScreenerDeleteCard',
    props: {
        screener: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        cancelDialog() {
            this.$emit('cancel')
        },
        async deleteScreener() {
            try {
                const screenerStore = useScreenerStore()
                const screenerId = this.screener.id

                this.loading = true

                await httpDeleteScreener(this.screener.id)
                await screenerStore.removeScreener(this.screener)
                this.$emit('submit')
                if (screenerId === this.$route.params.screenerId) {
                    await this.$router.replace({ name: this.$route.name })
                }
            } catch (err) {
                this.$logger.error(err)
                this.eventBus.emit('snacktime', {
                    type: 'error',
                    message: this.$t('screeners.screener.errors.delete'),
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.delete-modal {
    :deep(.aon-col) {
        width: 100% !important;
        height: auto !important;
    }
}
</style>
