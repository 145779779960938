import axios from 'axios'

const baseUrl = 'api/entities'

export async function getExecutiveTeam(entityPk) {
    return axios.get(`${baseUrl}/${entityPk}/executive_team`)
}

export async function getTopInventors(entityPk, column = 'rank', direction = -1) {
    let params = {
        SC: [column],
        SD: [direction],
        page_size: 10,
        last_row_num: 0,
        page: 1,
    }
    return axios.get(`${baseUrl}/${entityPk}/inventors`, {
        params,
        paramsSerializer: { indexes: null },
    })
}

export async function getInnovationTrend(entityPk) {
    return axios.get(`${baseUrl}/${entityPk}/innovation_trend`)
}

export async function getPatentFlow(entityPk) {
    let params = {
        rankCutoff: 10,
    }
    return axios.get(`${baseUrl}/${entityPk}/inventors_patent_flow`, {
        params,
        paramsSerializer: { indexes: null },
    })
}
