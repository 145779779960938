<template>
    <div class="investment-and-valuation-card mt-5">
        <AonCard title="Investment Trends & Market Confidence: Tracking Capital Inflows">
            <p class="mt-n1">
                This chart highlights capital investments into
                <span style="font-weight: bold">{{ props.nodeData.displayName }}</span> companies,
                offering a visual representation of how much funding they’ve secured over time. Each
                data point represents an investment event, providing insights into market
                confidence, growth potential, and trends in company scaling. By tracking the size
                and timing of these investments, we can better understand how the industry is
                evolving and where investor interest is focused.
            </p>
            <div class="card-content">
                <AonSpinner
                    v-if="loadingInvestmentAndVal"
                    class="ml-auto mr-auto mt-16"
                    :scale="0.3"
                />
                <div id="amChartInvestmentAndVal"></div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, inject } from 'vue'
import { useEntityStore } from '@/stores'
import { getInvestmentDetails } from '@/api/ipNode.js'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

let root
let chart
let yAxis
let xAxis

const entityStore = useEntityStore()
const logger = inject('logger')

const loadingInvestmentAndVal = ref(true)
const chartData = ref(null)
const earliestDate = ref(null)
const latestDate = ref(null)

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})

onMounted(async () => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartInvestmentAndVal')
    configureData()
})

onUnmounted(() => {
    root.dispose()
})

const configureData = async () => {
    loadingInvestmentAndVal.value = true
    try {
        const { data } = await getInvestmentDetails(props.nodeData.id)
        const dateFormatter = am5.DateFormatter.new(root, {
            dateFormat: 'MM-dd-yyyy', // Define the format for displaying the date
        })
        chartData.value = data

        chartData.value.forEach((item) => {
            const investmentDate = new Date(item.investmentDate)
            const quarter = Math.floor(investmentDate.getMonth() / 3)
            const year = investmentDate.getFullYear()
            const quarterStartDate = new Date(year, quarter * 3, 1) // Start of the quarter

            item.investmentDate = quarterStartDate.getTime() // Convert to milliseconds for the chart
            item.formattedInvestmentDate = dateFormatter.format(quarterStartDate)

            if (item.investors) {
                item.investors = item.investors.map((inv) => inv.entityName).join('\n')
            }
        })
        // Find earliest and latest dates from data
        earliestDate.value = Math.min(...chartData.value.map((item) => item.investmentDate))
        latestDate.value = Math.max(...chartData.value.map((item) => item.investmentDate))

        configureChart()
    } catch (error) {
        logger.error(error)
    } finally {
        loadingInvestmentAndVal.value = false
    }
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            interactive: false,
            wheelY: 'zoomXY',
            layout: root.verticalLayout,
            paddingTop: 40,
        })
    )

    generateAxes()
}

const generateAxes = () => {
    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
            numberFormat: '#.##a', // More precise format
            extraTooltipPrecision: 2,
            logarithmic: true,
            numberFormatter: am5.NumberFormatter.new(root, {
                numberFormat: '#.##a',
                numericFields: ['investmentAmount', 'postMoneyValuation'],
                bigNumberPrefixes: [
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' },
                    { number: 1e12, suffix: 'T' },
                ],
            }),
        })
    )

    yAxis.children.push(
        am5.Label.new(root, {
            text: 'Value (USD)',
            fontWeight: 600,
            rotation: -90,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            x: am5.percent(-25),
        })
    )

    xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: 'month', count: 3 },
            gridIntervals: [{ timeUnit: 'year', count: 1 }],
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
            }),
            extraMin: 0.05,
            extraMax: 0.05,
            min: earliestDate.value,
            max: latestDate.value,
        })
    )

    xAxis.children.push(
        am5.Label.new(root, {
            text: 'Quarter (Year)',
            fontWeight: 600,
            centerX: am5.p50,
            x: am5.percent(50),
            y: am5.percent(65),
        })
    )

    generateSeries()
}

const generateSeries = () => {
    let series = chart.series.push(
        am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'investmentAmount',
            valueXField: 'investmentDate',
            stroke: null,
            clustered: false,
        })
    )

    series.bullets.push(() => {
        let circle = am5.Circle.new(root, {
            radius: 5,
            fill: am5.color('#2A79D2'),
        })

        // Tooltip for bullets
        circle.set(
            'tooltipText',
            `[bold]Entity Name:[/] {entityName}\n[bold]Investment Amount:[/] \${investmentAmount}\n[bold]Post-Money Valuation:[/] \${postMoneyValuation}\n[bold]Investment Date:[/] {formattedInvestmentDate}\n[bold]Investors:[/]\n{investors}`
        )

        return am5.Bullet.new(root, {
            sprite: circle,
        })
    })

    series.data.setAll(chartData.value)

    series.appear(1000)
}
</script>

<style lang="scss" scoped>
.investment-and-valuation-card {
    width: 100%;
    position: relative;

    #amChartInvestmentAndVal {
        width: 100%;
        height: 350px;

        div {
            height: 100%;
        }
    }
}
</style>
