export default {
    template: `
        <div class="moat-table-custom-status-bar-sum d-flex align-items-center py-2 ml-4">
            <p class="extra-small overflow-ellipsis">{{ params.sumTitle }}: </p>
            <p class="bold extra-small overflow-ellipsis ml-1">{{ params.value }}</p>
            <div v-if="params.sectionBreak" class="mr-1 ml-5 grey01" style="width: 2px; height: 15px;"></div>
        </div>
    `,
    setup(props) {
        return {}
    },
}
