<template>
    <Companies v-if="showNewSearch" />
</template>

<script setup>
import Companies from '@/components/Companies'

import { useFlag } from '@unleash/proxy-client-vue'

const showNewSearch = useFlag('ipAlpha.searchCompHome')
</script>
