<template>
    <AonMenu
        :ref="`menu_${screener.id}`"
        :key="`${screener.id}-watchlist-choice`"
        align-left
        offset-x="-90px"
        :show="showMenu"
        animation="fade"
        @hide="showMenu = false"
    >
        <template #activator>
            <AonButton
                :icon-options="{ iconStyle: 'fas', iconName: 'fa-ellipsis-h' }"
                type="icon"
                @clicked="toggleMenu(screener.id)"
            />
        </template>
        <AonCard class="menu-card pa-2">
            <p
                v-for="action in screenerDropdownActions"
                :key="`screener_action_${action.display}`"
                class="link pa-3"
                style="text-align: right"
                :disabled="disableAction(action.display)"
                @click="performAction(action.display)"
            >
                {{ $t(`screeners.screener.actions.${action.display}`) }}
            </p>
        </AonCard>
    </AonMenu>
</template>
<script>
export default {
    name: 'ScreenerDropdownActions',
    props: {
        screener: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            showMenu: false,
        }
    },
    computed: {
        screenerDropdownActions() {
            let actions = [{ display: 'saveAs' }]
            if (this.screener.customScreener) {
                actions.push({ display: 'rename' }, { display: 'delete' })
            }
            return actions
        },
        noScreener() {
            return Object.keys(this.screener).length === 0
        },
    },
    methods: {
        performAction(action) {
            this.$emit('screenerAction', action)
            this.showMenu = false
        },
        disableAction(action) {
            switch (action) {
                case 'rename':
                    return this.noScreener ? true : !this.screener.customScreener
                case 'delete':
                    return this.noScreener ? true : !this.screener.customScreener
                default:
                    return false
            }
        },
        toggleMenu() {
            this.showMenu = !this.showMenu
        },
    },
}
</script>

<style lang="scss" scoped>
.menu-card {
    width: 130px;
    position: fixed;
}
.actionBorder {
    border: 0.1rem solid white;
    border-radius: 0.25rem;

    .mainActionButton {
        border-right: 0.1rem solid white;
        border-radius: 0px;
        width: 6rem;
    }
}
</style>
