<template>
    <div class="reports">
        <AonContainer add-page-padding class="knights-cloak">
            <AonRow>
                <AonCol class="a-col-12 pt-6 pb-16">
                    <div
                        class="header-holder d-flex w-full justify-content-between align-items-center"
                    >
                        <h4 class="grey08--text">
                            {{ $t('reports.title') }}
                        </h4>
                        <AonButton
                            v-if="reportsTabVisible"
                            :label="$t('reports.action.create')"
                            @clicked="createReport"
                        />
                    </div>
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonContainer add-page-padding class="mt-n12">
            <AonRow>
                <AonCol class="a-col-12">
                    <ReportsList />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import ReportsList from '@/components/reports/ReportsList.vue'
import { useRouter } from 'vue-router'
import { useFlag } from '@unleash/proxy-client-vue'

const router = useRouter()
const reportsTabVisible = useFlag('ipAlpha.reportCreation')

const createReport = () => {
    router.push({ name: 'Create Report' })
}
</script>

<style lang="scss" scoped>
.reports {
    min-height: 100vh;
    height: 100%;
}
</style>
