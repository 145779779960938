<template>
    <div class="treemap-chart d-flex align-items-center pb-12">
        <div id="amChartsTreemap"></div>
        <div
            class="legend-holder"
            v-tooltip="{
                content: $t('productAlignment.definitionTooltips.innImpliedPE'),
            }"
        >
            <div class="legend-labels d-flex justify-content-between w-full mb-1">
                <p>Low Innovation Implied P/E</p>
                <p>High Innovation Implied P/E</p>
            </div>
            <div class="legend"></div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, shallowRef } from 'vue'
import { useMoat2ProductStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

let root
let techAreaSeries

const customTheme = am5.Theme.new(root)

const moat2ProductStore = useMoat2ProductStore()

const treeData = shallowRef()
const productLabelConfig = ref({
    centerX: am5.percent(0),
    height: am5.percent(100),
    maxWidth: 100,
    dx: 15,
    dy: 15,
    oversizedBehavior: 'truncate',
    layer: 3,
    fill: am5.color('#FFFFFF'),
    fontSize: 16,
    fontWeight: 'bold',
    fontVariant: 'small-caps',
    oversizedBehavior: 'truncate',
    shadowOffsetY: 3,
    shadowColor: am5.color('#555'),
    shadowBlur: 10,
})

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartsTreemap')
    configureChart()
})

onBeforeUnmount(() => {
    root.dispose()
})

const configureChart = (removeZeros = true) => {
    buildThemes()

    let container = root.container.children.push(
        am5.Container.new(root, {
            width: am5.percent(100),
            height: am5.percent(100),
            layout: root.verticalLayout,
            maskContent: false,
            marginTop: 10,
        })
    )

    configureData()
    generateSeries(container)
}

const generateSeries = (container) => {
    //Dumbbell Series
    techAreaSeries = container.children.push(
        am5hierarchy.Treemap.new(root, {
            maskContent: false, //!important with zoomable containers
            sort: 'descending',
            layoutAlgorithm: 'binary',
            singleBranchOnly: false,
            downDepth: 2,
            upDepth: 0,
            initialDepth: 2,
            valueField: 'value',
            categoryField: 'name',
            childDataField: 'children',
            customValueField: 'heat',
            nodePaddingOuter: 0,
            nodePaddingInner: 0,
            tooltipPosition: 'fixed',
        })
    )

    techAreaSeries.labels.template.setAll({
        fill: am5.color('#FFFFFF'),
        fontSize: 14,
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        oversizedBehavior: 'truncate',
        tooltipPosition: 'fixed',
    })

    techAreaSeries.set('heatRules', [
        {
            target: techAreaSeries.rectangles.template,
            dataField: 'customValue',
            min: am5.color('#BCC9DF'),
            // max: am5.color('#102B47'),
            max: am5.color('#143862'),
            minValue: 0,
            maxValue: 150,
            key: 'fill',
        },
    ])

    techAreaSeries.nodes.template.events.on('click', function (ev) {
        var depth = ev.target.dataItem.get('depth')
        if (depth == 2) {
            techAreaSeries.selectDataItem(ev.target.dataItem.get('parent'))
            techAreaSeries.bullets.showBullets = false
        }
    })
    techAreaSeries.nodes.template.set(
        'tooltipText',
        '{category}\n\nInnovation Score: [bold]{sum}[/]\nInnovation Implied P/E: [bold]{customValue}[/]'
    )
    techAreaSeries.nodes.template.set('isMeasured', true)
    techAreaSeries.nodes.template.set('tooltipPosition', 'pointer')

    techAreaSeries.data.setAll(treeData.value)

    techAreaSeries.bullets.push(function (root, series, dataItem) {
        let depth = dataItem.get('depth')
        if (depth == 1) {
            let myLabel = am5.Label.new(root, {
                ...productLabelConfig.value,
                text: dataItem.dataContext.name,
                tooltip: am5.Tooltip.new(root, {
                    active: true,
                    labelText: '[bold]Things and stuff[/]\n',
                    layer: 4,
                }),
            })

            myLabel.set('maxWidth', dataItem.get('rectangle').width() - 20)
            dataItem.get('rectangle').set('userData', myLabel)
            dataItem.get('rectangle').on('width', function (width, target) {
                target.get('userData').set('maxWidth', width - 20)
            })

            return am5.Bullet.new(root, {
                locationX: 0,
                sprite: myLabel,
            })
        }
    })
}

const configureData = () => {
    let techAreas = [...moat2ProductStore.productAlignmentAllData].filter(
        (item) => item.targetEntityPatentCount > 0
    )
    let products = moat2ProductStore.productAlignmentProducts.map((prod) => {
        return {
            name: prod.to,
            productId: prod.nodeId,
            heat: 0,
            children: [],
        }
    })

    techAreas.forEach((item) => {
        let targetProd = products.find((prod) => prod.productId === item.parentNodeId)

        targetProd.children.push({
            name: item.displayName,
            value: parseInt(item.targetEntityPowerScore.toFixed(0)),
            heat: parseInt(item.targetTpe?.toFixed(0)),
        })
    })

    treeData.value = [
        {
            name: '',
            children: products.filter((item) => item.children.length > 0),
        },
    ]
}

const buildThemes = () => {
    customTheme.rule('RoundedRectangle', ['hierarchy', 'node', 'shape', 'depth1']).setAll({
        strokeWidth: 10,
        stroke: am5.color('#000000'),
    })

    root.setThemes([customTheme, am5themes_Animated.new(root)])
}
</script>

<style lang="scss">
.treemap-chart {
    width: 100%;
    position: relative;

    #amChartsTreemap {
        width: 100%;
        height: 500px;

        div {
            height: 100%;
        }
    }

    .legend-holder {
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;

        .legend {
            width: 100%;
            height: 10px;
            background: linear-gradient(90deg, rgba(188, 201, 223, 1) 0%, rgba(20, 56, 98, 1) 100%);
        }
    }

    .back-button {
        position: absolute;
        left: 15px;
        top: 50px;

        background: white !important;
    }
}
</style>
