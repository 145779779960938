<template>
    <div class="company-focus mt-5">
        <div class="company-focus-header knights-cloak pa-5">
            <h5 class="white--text">{{ props.headerName }}</h5>
        </div>
        <div class="areas pa-5 white w-full">
            <AonSpinner v-if="loadingCompanyDescription" class="ml-auto mr-auto" :scale="0.3" />
            <AonCoverLoading
                v-if="noPatents"
                :loading="noPatents ? true : false"
                :show-spinner="false"
                title="No Patents in Selected Area"
                :message="`${headerName} currently has no patent activity within the selected area. We recommend exploring other areas to better understand their focus.`"
            >
                <template #header>
                    <div class="icon">
                        <font-awesome-icon
                            icon="fas fa-circle-exclamation"
                            class="grey01--text"
                            size="2xl"
                        />
                    </div>
                </template>
            </AonCoverLoading>
            <AonCoverLoading
                v-if="systemError"
                :loading="systemError ? true : false"
                :show-spinner="false"
                title="Unable to Determine Focus Area"
                :message="`We were unable to identify the specific focus of this company’s patent activity. If you believe this is an error, please contact support for further assistance.`"
            >
                <template #header>
                    <div class="icon">
                        <font-awesome-icon
                            icon="fas fa-circle-exclamation"
                            class="grey01--text"
                            size="2xl"
                        />
                    </div>
                </template>
            </AonCoverLoading>
            <div
                v-if="!systemError && !noPatents && !loadingCompanyDescription"
                class="focus-area-holder"
            >
                <h6 class="mb-2 mt-n2 grey02--text">Focus Areas:</h6>
                <div class="focus-area-list">
                    <div v-for="item in focusAreas" class="focus-area my-1">
                        <p>
                            <span style="font-weight: 700">{{ item.focusArea }}</span> -
                            {{ item.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="supporting-visuals pa-5 white">
            <div class="expand-cta d-flex align-items-center link" @click="onToggleExpand">
                <font-awesome-icon
                    class="expand-caret castle-moat--text"
                    :class="{ open: showContent }"
                    :icon="`fas fa-caret-right`"
                />
                <p class="ml-2">See Supporting Data Visualizations</p>
            </div>
            <transition name="expand">
                <div class="expand-content" v-if="showContent">
                    <CompanyWordCloud />
                </div>
            </transition>
        </div> -->
    </div>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { useEntityStore, useArenaOutputStore } from '@/stores'
import { getCompanyFocusAreas } from '@/api/ipNode.js'
import { type } from '@amcharts/amcharts5'

// import CompanyWordCloud from './CompanyWordCloud.vue'

const entityStore = useEntityStore()
const arenaOutputStore = useArenaOutputStore()

const logger = inject('logger')

const props = defineProps({
    headerName: {
        type: String,
        default: '',
    },
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
    entityPk: {
        type: [Number, String],
        default: 'pa',
    },
})

const loadingCompanyDescription = ref(true)
const noPatents = ref(false)
const systemError = ref(false)
const focusAreas = ref(null)
const showContent = ref(false)

onMounted(() => {
    getCompanyDescription()
})

const onToggleExpand = () => {
    showContent.value = !showContent.value
}

const getCompanyDescription = async () => {
    loadingCompanyDescription.value = true

    try {
        const { data } = await getCompanyFocusAreas(props.entityPk, props.nodeData.id)

        if (data.status === 'noPatents') {
            noPatents.value = true
            return
        }
        if (data.status === 'systemError') {
            systemError.value = true
            return
        }

        focusAreas.value = data.patentFocus
    } catch (error) {
        logger.error(error)
    } finally {
        loadingCompanyDescription.value = false
    }
}
</script>

<style lang="scss" scoped>
.company-focus {
    width: 100%;

    .company-focus-header {
        border-radius: 4px 4px 0 0;
    }

    .areas {
        min-height: 100px;

        position: relative;

        :deep(.a-cover-loading) {
            position: relative;
            .holder {
                position: relative;
                transform: none;
                top: 0;
                left: 0;
            }
        }
    }

    .expand-content {
        position: relative;
        transform-origin: top;
        overflow: hidden;
    }

    .expand-caret {
        transition: all 0.3s ease-in-out;

        &.open {
            transform: rotate(90deg);
        }
    }

    .supporting-visuals {
        border-radius: 0 0 4px 4px;
    }
}
</style>
