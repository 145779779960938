import { defineStore } from 'pinia'
import * as Api from '@/api/litigation'
import { logger } from '@moatmetrics/vue-logger'

export const useLitigationStore = defineStore('litigation', {
    state: () => ({
        entitySummary: {},
        sectorRisk: [],
        sectorCases: [],
        sectorCaseHighestDamages: [],
        sectorCaseAverageDuration: [],
        sectorNpeCases: [],
    }),
    getters: {},
    actions: {
        async resetState() {
            this.$reset()
        },
        async getEntitySummary(entityId) {
            try {
                const { data } = await Api.getEntitySummary(entityId)

                this.entitySummary = data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getRiskByTargetSector(sectorId) {
            try {
                const { data } = await Api.getRiskByTargetSector(sectorId)

                this.sectorRisk = data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getSectorCases({ sectorId, years }) {
            try {
                const { data } = await Api.getSectorCases(sectorId, years)

                this.sectorCases = data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getSectorCaseAverageDuration({ sectorId, years }) {
            try {
                const { data } = await Api.getSectorAverageCaseDuration(sectorId, years)

                this.sectorCaseAverageDuration = data.slice(0, years)
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getSectorCaseHighestDamages({ sectorId, years }) {
            try {
                const { data } = await Api.getSectorCaseHighestDamages(sectorId, years)

                this.sectorCaseHighestDamages = data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getSectorNpeCases({ sectorId, years }) {
            try {
                const { data } = await Api.getSectorNpeCases(sectorId, years)

                this.sectorNpeCases = data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
    },
})
