<template>
    <div class="entity-title">
        <h4 v-if="isResearch" :class="dark ? 'white--text' : 'teal--text'">
            {{ entity.name }}
        </h4>
        <h5 v-else-if="small" :class="dark ? 'white--text' : 'teal--text'">
            {{ entity.name }}
        </h5>
        <h4 v-else :class="dark ? 'white--text' : 'teal--text'">
            {{ entity.name }}
        </h4>
        <span class="caption" :class="dark ? 'white--text' : 'grey03--text'">
            {{ entity.stock_ticker }}
            <span v-if="entity.stock_ticker" class="country-flag mx-1" :class="countryFlag" />
            {{ entity.stock_exchange }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'EntityTitle',
    props: {
        entity: {
            type: Object,
            required: true,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        isResearch: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        // https://gitlab.com/catamphetamine/country-flag-icons/-/issues/26 ?
        countryFlag() {
            if (!this.entity.stock_ticker || !this.entity.stock_region) {
                return ''
            }
            return `flag:${this.entity.stock_region}`
        },
    },
}
</script>

<style lang="scss" scoped>
.entity-title {
    width: 100%;
    .country-flag {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-block;
        overflow: hidden;
        position: relative;
        top: 3px;

        img {
            height: 100%;
        }
    }
}
</style>
