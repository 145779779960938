import { EXCHANGE_MAP } from './constants/financial'

export const formatTicker = (exchange, ticker) => {
    if (EXCHANGE_MAP[exchange]) return `${EXCHANGE_MAP[exchange]}:${ticker}`
    return `${exchange}:${ticker}`
}

export const formatTpeRange = (tpe, tpe_lb, tpe_ub) => {
    if (!tpe) {
        return ''
    } else {
        return tpe.toFixed(0)
    }
}
