<template>
    <div class="not-found align-center justify-content-center">
        <AonCard>
            <img class="mt-1 mb-2" src="@/assets/moat-logo.png" alt="Aon (Logo)" />
            <hr />
            <p class="mt-3 extra-large">
                {{ t('notFound.headerPrimary') }}
            </p>
            <p class="my-3 knights-cloak--text large">
                {{ t('notFound.headerSecondary') }}
            </p>
            <p>{{ t('notFound.bodyText') }}</p>
            <p>{{ t('error.emailText') }}</p>
            <a :href="`${emailLink}`" target="_blank"> support@moatmetrics.com </a>
            <div class="mt-6">
                <router-link :to="{ name: 'Home' }">
                    <AonButton :label="`${t('navigationText.backButtonText')}`" />
                </router-link>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
// TODO refactor the 404, error and permissionRedirect views to use a contextable component instead
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import logoImport from '@/assets/moat_alpha.png'

const { t } = useI18n()

const emailLink = computed(() => {
    return `mailto:support@moatmetrics.com`
})
</script>

<style lang="scss">
.not-found {
    display: flex;
    height: 100%;

    .a-card {
        width: 30vw;
        margin: auto;
        text-align: center;

        hr {
            color: $grey06;
        }
    }
}
</style>
