import axios from 'axios'

let searchCancelToken

export async function searchWrapper(url, params) {
    if (searchCancelToken) {
        searchCancelToken.cancel('Operation canceled due to new request.')
    }

    searchCancelToken = axios.CancelToken.source()

    try {
        return await axios.get(url, {
            cancelToken: searchCancelToken.token,
            params,
        })
    } catch (err) {
        if (!axios.isCancel(err)) {
            throw err
        }
    }
}
