import { ref, computed } from 'vue'
import * as EntityApi from '@/api/entities'
import { logger } from '@moatmetrics/vue-logger'
import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash-es'

// TODO DRY/Refactor the duplication with the participatingMarkets store
export const useCompetitorsStore = defineStore('competitors', () => {
    // state
    const competitorsApexScoreType = ref({ itemValue: 'Apex', itemText: 'Apex' })
    const competitorsCorScoreType = ref({ itemValue: 'Cor', itemText: 'COR' })
    const competitorsApexScores = ref([])
    const competitorsCorScores = ref([])
    const competitorsHistoryCache = ref({})
    const competitorsHistoryLoading = ref(false)
    const competitorsMap = ref([])
    const competitorCompetencies = ref([])
    const competitorsSelected = ref(new Set())
    const competitorsTotalIds = ref([])
    const lastCorScoresSort = ref(null)
    const lastApexScoresSort = ref(null)

    // getters
    const getCompetitorsSelectedIds = computed(() => {
        const selectedIds = [...competitorsSelected.value.values()]

        return selectedIds
    })
    const getCompetitorsCorScores = computed(() => {
        return competitorsApexScores.value
    })
    const getCompetitorsApexScores = computed(() => {
        return competitorsApexScores.value
    })
    const getCompetitorsCorScoreType = computed(() => {
        return competitorsCorScoreType.value
    })
    const getCompetitorsApexScoreType = computed(() => {
        return competitorsApexScoreType.value
    })
    const isHistoryLoading = computed(() => {
        return competitorsHistoryLoading.value
    })
    const getSelectedIdAndName = computed(() => {
        const entityIds = getCompetitorsSelectedIds.value
        const competitorsIdAndName = entityIds.map((id) => {
            const competitor = competitorsMap.value.find((comp) => comp.itemValue === id)

            return {
                id,
                name: competitor.itemText,
            }
        })
        return competitorsIdAndName
    })
    const getCompetitorsMap = computed(() => {
        return competitorsMap.value
    })
    const getCompetitorCompetencies = computed(() => {
        return competitorCompetencies.value
    })
    const getCompetitorsIds = computed(() => {
        return competitorCompetencies.value.map((cm) => cm.aon_entity_pk)
    })
    const getCompetitorsCount = computed(() => {
        // TODO temp value? should never be null/undefined!?
        return competitorsMap.value?.length
    })
    const getCompetitorsTotalCount = computed(() => {
        return competitorsTotalIds.value?.length
    })

    // actions
    const resetState = () => {
        competitorsApexScoreType.value = { itemValue: 'Apex', itemText: 'Apex' }
        competitorsCorScoreType.value = { itemValue: 'Cor', itemText: 'COR' }
        competitorsApexScores.value = []
        competitorsCorScores.value = []
        competitorsHistoryCache.value = {}
        competitorsHistoryLoading.value = false
        competitorsMap.value = []
        competitorCompetencies.value = []
        competitorsSelected.value = new Set()
        competitorsTotalIds.value = []
        lastCorScoresSort.value = null
        lastApexScoresSort.value = null
    }
    const anyCompetitors = () => {
        return !!competitorsMap.value.length
    }
    const anyCompetitorsSelected = () => {
        return !!competitorsSelected.value.size
    }
    const isCompetitorSelected = (competitorId) => {
        if (!competitorId) {
            return false
        }
        return competitorsSelected.value.has(competitorId)
    }
    const isCompetitorHistoryCached = (competitorId) => {
        return !!competitorsHistoryCache.value[competitorId]
    }
    const toggleCompetitorSelected = (competitorId) => {
        if (isCompetitorSelected(competitorId)) {
            removeCompetitorSelected(competitorId)
            buildCompetitorsSelectedScores()
            return
        }
        addCompetitorsSelected(competitorId)
    }
    const setCompetitorsHistoryLoading = (isLoading) => {
        competitorsHistoryLoading.value = isLoading
    }
    const setCompetitorsHistoryCache = (competitorsHistoryObj) => {
        competitorsHistoryCache.value = competitorsHistoryObj
    }
    const setCompetitorsCorScores = (scores) => {
        competitorsCorScores.value = scores || []
    }
    const setCompetitorsApexScores = (scores) => {
        competitorsApexScores.value = scores || []
    }
    const setCompetitorsCorScoreType = (scoreType) => {
        // TODO check if type is valid
        // const validTypes = [
        //     { itemValue: 'Cor', itemText: 'COR' },
        //     { itemValue: 'Coverage', itemText: 'Coverage' },
        //     { itemValue: 'Opportunity', itemText: 'Opportunity' },
        //     { itemValue: 'Resilience', itemText: 'Resilience' },
        // ]
        competitorsCorScoreType.value = scoreType
    }
    const setCompetitorsApexScoreType = (scoreType) => {
        // TODO check if type is valid
        // const validTypes = [
        //     { itemValue: 'Apex', itemText: 'Apex' },
        // ]

        competitorsApexScoreType.value = scoreType
    }
    const setCompetitorsTotalIds = (allCompetitorIds) => {
        if (!allCompetitorIds) {
            clearCompetitorsTotalIds()
            return
        }
        competitorsTotalIds.value = allCompetitorIds
    }
    const clearCompetitorsTotalIds = () => {
        competitorsTotalIds.value = []
    }

    const setCompetitorCompetencies = (competitors) => {
        if (!competitors || competitors.length == 0) {
            clearCompetitorCompetencies()
            return
        }

        competitorCompetencies.value = competitors
        setCompetitorsMap(competitors)
    }
    const setCompetitorsMap = (competitors) => {
        if (!competitors || competitors.length == 0) {
            clearCompetitorsMap()
            return
        }
        competitorsMap.value = competitors.map((comp) => {
            return {
                itemText: comp.aon_entity_name,
                itemValue: comp.aon_entity_pk,
            }
        })
    }
    const clearCompetitorCompetencies = () => {
        competitorCompetencies.value = []
    }
    const clearCompetitorsMap = () => {
        competitorsMap.value = []
        clearCompetitorsSelected()
        clearCompetitorsTotalIds()
    }
    const clearCompetitorsHistoryCache = () => {
        competitorsHistoryCache.value = {}
    }
    const clearCompetitorsScores = () => {
        competitorsCorScores.value = []
        competitorsApexScores.value = []
    }
    const clearCompetitorsSelected = () => {
        competitorsSelected.value = new Set()
    }
    const addCompetitorHistoryCache = (history, competitorName) => {
        if (!history || history.length === 0) {
            return
        }

        const competitorId = history[0].aonEntityPk
        const competitorHistory = {
            entityId: competitorId,
            sectorId: history[0].aonSectorPk,
            name: competitorName,
        }
        // TODO move these custom view model object transformations to a separate model helper file to avoid duplication in the future
        history.forEach((hist, i) => {
            const yearKey = `year${i + 1}`

            competitorHistory[yearKey] = new Date(hist.effectiveDate).getFullYear()
            competitorHistory[`${yearKey}Cor`] = hist.corScore
            competitorHistory[`${yearKey}Coverage`] = hist.coverageScore
            competitorHistory[`${yearKey}Opportunity`] = hist.opportunityScore
            competitorHistory[`${yearKey}Resilience`] = hist.ipRiskScore
            competitorHistory[`${yearKey}Apex`] = hist.apexScore

            competitorHistory[`${yearKey}CorChange`] = calcScoreChange(
                history[i + 1]?.corScore,
                hist.corScore
            )
            competitorHistory[`${yearKey}CoverageChange`] = calcScoreChange(
                history[i + 1]?.coverageScore,
                hist.coverageScore
            )
            competitorHistory[`${yearKey}OpportunityChange`] = calcScoreChange(
                history[i + 1]?.opportunityScore,
                hist.opportunityScore
            )
            competitorHistory[`${yearKey}ResilienceChange`] = calcScoreChange(
                history[i + 1]?.ipRiskScore,
                hist.ipRiskScore
            )
            competitorHistory[`${yearKey}ApexChange`] = calcScoreChange(
                history[i + 1]?.apexScore,
                hist.apexScore
            )
        })

        const competitorsHistory = cloneDeep(competitorsHistoryCache.value)

        competitorsHistory[competitorId] = competitorHistory
        setCompetitorsHistoryCache(competitorsHistory)
    }
    const addCompetitorsSelected = (competitorId) => {
        competitorsSelected.value.add(competitorId)
    }
    const getCompetitorsSelectedHistory = () => {
        if (competitorsSelected.value.size === 0) {
            clearCompetitorsScores()
            return []
        }

        const selectedIds = [...competitorsSelected.value.values()]
        const competitorsHistory = cloneDeep(competitorsHistoryCache.value)
        const selectedHistory = selectedIds
            .filter((sid) => competitorsHistory[sid])
            .map((cid) => competitorsHistory[cid])

        return selectedHistory
    }
    const buildCompetitorsSelectedCorScores = (useLastSort = false) => {
        const selectedHistory = getCompetitorsSelectedHistory()
        const corScoreType = competitorsCorScoreType.value.itemValue
        let corScores = selectedHistory.map((sh) => {
            return {
                entityId: sh.entityId,
                name: sh.name,
                scoreType: corScoreType,
                year1: sh['year1'],
                year1Score: sh[`year1${corScoreType}`],
                year1Change: sh[`year1${corScoreType}Change`],
                year2: sh['year2'],
                year2Score: sh[`year2${corScoreType}`],
                year2Change: sh[`year2${corScoreType}Change`],
                year3: sh['year3'],
                year3Score: sh[`year3${corScoreType}`],
                year3Change: sh[`year3${corScoreType}Change`],
                year4: sh['year4'],
                year4Score: sh[`year4${corScoreType}`],
                year4Change: sh[`year4${corScoreType}Change`],
                year5: sh['year5'],
                year5Score: sh[`year5${corScoreType}`],
                year5Change: sh[`year5${corScoreType}Change`],
            }
        })

        if (!useLastSort || !lastCorScoresSort.value || !lastCorScoresSort.value?.sortParams.SP) {
            setCompetitorsCorScores(corScores)
            return
        }

        const { sortKey, sortParams } = lastCorScoresSort.value

        setCompetitorsCorScores(sortScores(corScores, sortKey, sortParams.SD[0]))
    }
    const buildCompetitorsSelectedApexScores = (useLastSort = false) => {
        const selectedHistory = getCompetitorsSelectedHistory()
        const apexScoreType = competitorsApexScoreType.value.itemValue
        const apexScores = selectedHistory.map((sh) => {
            return {
                entityId: sh.entityId,
                name: sh.name,
                scoreType: apexScoreType,
                year1: sh['year1'],
                year1Score: sh[`year1Apex`],
                year1Change: sh[`year1${apexScoreType}Change`],
                year2: sh['year2'],
                year2Score: sh['year2Apex'],
                year2Change: sh[`year2${apexScoreType}Change`],
                year3: sh['year3'],
                year3Score: sh['year3Apex'],
                year3Change: sh[`year3${apexScoreType}Change`],
                year4: sh['year4'],
                year4Score: sh['year4Apex'],
                year4Change: sh[`year4${apexScoreType}Change`],
                year5: sh['year5'],
                year5Score: sh['year5Apex'],
                year5Change: sh[`year5${apexScoreType}Change`],
            }
        })

        if (!useLastSort || !lastApexScoresSort.value || !lastApexScoresSort.value?.sortParams.SP) {
            setCompetitorsApexScores(apexScores)
            return
        }

        const { sortKey, sortParams } = lastApexScoresSort.value

        setCompetitorsApexScores(sortScores(apexScores, sortKey, sortParams.SD[0]))
    }
    const buildCompetitorsSelectedScores = () => {
        clearCompetitorsScores()
        buildCompetitorsSelectedCorScores()
        buildCompetitorsSelectedApexScores()
    }
    const calcScoreChange = (priorYearScore, score) => {
        if (!priorYearScore) {
            return
        }

        const change = (score - priorYearScore) / Math.abs(priorYearScore)

        return change
    }
    const removeCompetitorSelected = (competitorId) => {
        competitorsSelected.value.delete(competitorId)
    }
    const removeCompetitorHistoryCache = (competitorId) => {
        competitorsHistoryCache.value.delete(competitorId)
    }
    const sortScores = (competitorsScores, sortKey, sortDirection) => {
        let sortedScores = []
        // TODO write a compare method or DRY/refactor this up inline
        if (sortDirection === 1) {
            sortedScores = competitorsScores.sort((a, b) => {
                if (a[sortKey] < b[sortKey]) {
                    return -1
                }
                if (a[sortKey] > b[sortKey]) {
                    return 1
                }
                return 0
            })
        } else {
            sortedScores = competitorsScores.sort((a, b) => {
                if (a[sortKey] > b[sortKey]) {
                    return -1
                }
                if (a[sortKey] < b[sortKey]) {
                    return 1
                }
                return 0
            })
        }

        return sortedScores
    }
    const sortCompetitorsScores = (sortParams, isCorContext) => {
        const sortKey = sortParams.SC[0] === 'name' ? 'name' : `${sortParams.SC[0]}Score`
        // TODO turn this nested if/else mess inside out or something ICK

        if (isCorContext) {
            if (competitorsCorScores.value.length <= 1) {
                return
            }
            setLastCorScoresSort({ sortKey, sortParams })
            buildCompetitorsSelectedCorScores(true)
        } else {
            if (competitorsApexScores.value.length <= 1) {
                return
            }
            setLastApexScoresSort({ sortKey, sortParams })
            buildCompetitorsSelectedApexScores(true)
        }
    }
    const setLastCorScoresSort = ({ sortKey, sortParams }) => {
        lastCorScoresSort.value = { sortKey, sortParams }
    }
    const clearLastCorScoresSort = () => {
        lastCorScoresSort.value = null
    }
    const setLastApexScoresSort = ({ sortKey, sortParams }) => {
        lastApexScoresSort.value = { sortKey, sortParams }
    }
    const clearLastApexScoresSort = () => {
        lastApexScoresSort.value = null
    }

    return {
        competitorsApexScoreType,
        competitorsCorScoreType,
        competitorsApexScores,
        competitorsCorScores,
        competitorsHistoryCache,
        competitorsHistoryLoading,
        competitorsMap,
        competitorCompetencies,
        competitorsSelected,
        getCompetitorCompetencies,
        getCompetitorsMap,
        getCompetitorsIds,
        getCompetitorsCount,
        getCompetitorsSelectedIds,
        getCompetitorsCorScores,
        getCompetitorsApexScores,
        getCompetitorsCorScoreType,
        getCompetitorsApexScoreType,
        isHistoryLoading,
        getSelectedIdAndName,
        getCompetitorsTotalCount,
        lastCorScoresSort,
        anyCompetitors,
        anyCompetitorsSelected,
        isCompetitorSelected,
        isCompetitorHistoryCached,
        toggleCompetitorSelected,
        setCompetitorsMap,
        setCompetitorCompetencies,
        setCompetitorsHistoryLoading,
        setCompetitorsHistoryCache,
        setCompetitorsCorScores,
        setCompetitorsApexScores,
        setCompetitorsCorScoreType,
        setCompetitorsApexScoreType,
        clearCompetitorCompetencies,
        clearCompetitorsMap,
        clearCompetitorsTotalIds,
        setCompetitorsTotalIds,
        clearCompetitorsHistoryCache,
        clearCompetitorsScores,
        clearCompetitorsSelected,
        addCompetitorHistoryCache,
        addCompetitorsSelected,
        buildCompetitorsSelectedScores,
        calcScoreChange,
        removeCompetitorSelected,
        removeCompetitorHistoryCache,
        sortCompetitorsScores,
        clearLastApexScoresSort,
        setLastApexScoresSort,
        clearLastCorScoresSort,
        setLastCorScoresSort,
        sortScores,
        buildCompetitorsSelectedCorScores,
        buildCompetitorsSelectedApexScores,
        getCompetitorsSelectedHistory,
        resetState,
    }
})
