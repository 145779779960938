<template>
    <div class="claim-breath-card">
        <AonCard
            :title="`Validity by Claim Breadth`"
            desc="This analysis helps you understand the enforceability and potential market impact of patents."
        >
            <template #header-append>
                <p v-if="!loading" class="grey02--text italic mt-n8 ml-4">
                    Patent totals shown are US only
                </p>
            </template>
            <div class="card-content d-flex w-full h-full">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="100%">
                        <line
                            x1="30"
                            y1="78%"
                            x2="30"
                            y2="23%"
                            stroke="#e5eff0"
                            stroke-width="20"
                            marker-end="url(#endarrow)"
                            marker-start="url(#startarrow)"
                        />
                        <text
                            font-size="1em"
                            fill="grey"
                            transform="translate(35, 335) rotate(-90)"
                        >
                            Breadth (broader = better)
                        </text>
                    </svg>
                </div>
                <div class="w-100">
                    <div class="flex justify-content-end">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="60px"
                            width="calc(100% - 150px)"
                        >
                            <defs>
                                <marker
                                    id="startarrow"
                                    markerWidth="1"
                                    markerHeight="2"
                                    refX="1"
                                    refY="1"
                                    orient="auto"
                                >
                                    <polygon points="1 0, 1 2, 0 1" fill="#e5eff0" />
                                </marker>
                                <marker
                                    id="endarrow"
                                    markerWidth="1"
                                    markerHeight="2"
                                    refX="0.1"
                                    refY="1"
                                    orient="auto"
                                    markerUnits="strokeWidth"
                                >
                                    <polygon points="0 0, 1 1, 0 2" fill="#e5eff0" />
                                </marker>
                            </defs>
                            <line
                                x1="150"
                                y1="50%"
                                x2="calc(100% - 20px)"
                                y2="50%"
                                stroke="#e5eff0"
                                stroke-width="20"
                                marker-end="url(#endarrow)"
                                marker-start="url(#startarrow)"
                            />
                            <text x="60%" y="58%" font-size="1em" fill="grey" text-anchor="middle">
                                Validity Rank (higher = better)
                            </text>
                        </svg>
                    </div>
                    <table>
                        <tr>
                            <td></td>
                            <td v-for="(rank, i) in validityRank" :key="`rank_header_${i}`">
                                {{ $filters.toLocaleString(rank) }}
                            </td>
                        </tr>
                        <tr v-for="(breadth, i) in claimBreadth" :key="`breadth_row_${i}`">
                            <td>{{ breadth }}</td>
                            <td
                                v-for="(v, i) in getPatentsByBreadth(breadth)"
                                :key="`very_broad_${i}`"
                                :style="`background: ${background(v)}`"
                            >
                                {{ $filters.toLocaleString(v) }}
                            </td>
                        </tr>
                        <tr style="border-top: 2px solid #46535e">
                            <td>Total</td>
                            <td v-for="(rank, i) in validityRank" :key="`total_${i}`">
                                {{ $filters.toLocaleString(getTotalByRank(rank)) }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useEntityStore, useParticipatingMarketsStore } from '@/stores'
import * as entityApi from '@/api/entities'
import { useI18n } from 'vue-i18n'

const entityStore = useEntityStore()
const { t } = useI18n()

const loading = ref(true)
const portfolioValidity = ref([])
const totalPatentCount = ref(null)
const claimBreadth = ['Very Broad', 'Broad', 'Average', 'Narrow']
const validityRank = ['Low', 'Medium', 'High']

onMounted(async () => {
    const response = await entityApi.getEntityPortfolioValidity(
        entityStore.entity.aon_entity_pk,
        'US'
    )
    portfolioValidity.value = response.data
    totalPatentCount.value = response.data.map((p) => p.total).reduce((a, b) => a + b, 0)
    loading.value = false
})

function getPatentsByBreadth(breadth) {
    const ranksForBreadth = portfolioValidity.value.filter((p) => p.claimBreadth === breadth)
    let totals = []
    validityRank.forEach((rank) => {
        const rankTotal = ranksForBreadth.find((r) => r.validityRank === rank)?.total || '--'
        totals.push(rankTotal)
    })
    return totals
}
function getTotalByRank(rank) {
    return portfolioValidity.value
        .filter((p) => p.validityRank === rank && p.claimBreadth !== 'Not Scored')
        .map((p) => p.total)
        .reduce((a, b) => a + b, 0)
}

function background(total) {
    return `rgba(0, 132, 187, ${total / totalPatentCount.value})`
}
</script>

<style lang="scss" scoped>
.claim-breath-card {
    width: 100%;
    position: relative;

    .card-content {
        height: 464px;
        min-height: 464px;
        table {
            min-width: 100%;
            height: calc(100% - 70px);
            tr:first-child {
                max-height: 50px;
                font-weight: 700;
                td {
                    padding: 1em;
                    height: 10%;
                    width: 25%;
                }
            }
            tr {
                td:first-child {
                    font-weight: 700;
                    width: 150px;
                    max-width: 150px;
                }
            }
            td {
                vertical-align: middle;
                text-align: center;
                background: white;
                border: 1px solid $grey05;
                height: 18%;
            }
        }
    }
}
</style>
