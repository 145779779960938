<template>
    <div class="rate-change-indicator d-flex align-items-center h-full">
        <font-awesome-icon
            v-if="bin.icon"
            :icon="bin.icon"
            :class="bin.color + '--text d-inline metric-icon mr-1'"
            size="lg"
        />
        <p :class="props.params.colorValue ? bin.color + '--text bold ' : '' + 'small'">
            {{ props.params.valueFormatted }}
        </p>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'

const props = defineProps({
    params: {
        type: Object,
        default: () => ({}),
    },
})

const bin = computed(() => {
    if (props.params.value > (props.params.upperBound ?? 0)) {
        return {
            color: 'success',
            icon: 'fas fa-arrow-up-right',
            tooltip: props.params.tooltip?.increasing,
        }
    } else if (props.params.value < (props.params.lowerBound ?? 0)) {
        return {
            color: 'error',
            icon: 'fas fa-arrow-down-right',
            tooltip: props.params.tooltip?.decreasing,
        }
    }
    return {
        color: 'grey01',
        icon: 'fas fa-arrow-right',
        tooltip: props.params.tooltip?.steady,
    }
})

onMounted(() => {
    props.params.setTooltip(bin.value.tooltip)
})
</script>
