<template>
    <div class="company-summary-tab mb-5">
        <AonContainer add-page-padding>
            <AonRow v-if="!showDashboard" class="mt-5">
                <AonSpinner
                    v-if="entityStore.loadingEntity || !entityStore.entity"
                    class="ma-auto"
                    :scale="0.5"
                    color="castleMoat"
                />
                <div v-else class="gird-holder w-full">
                    <AonCol class="aon-col-12 pl-0 py-0">
                        <SummaryCard class="mb-5" />
                        <ExecutiveTeamCard class="mb-5" />
                        <FundingCard v-if="entityStore.entity.stock_ticker" class="mb-5" />
                        <FundingRoundsCard
                            v-if="entityStore.entity.entity_type !== 'Public Company'"
                        />
                    </AonCol>
                </div>
            </AonRow>

            <AonRow v-else class="mt-5">
                <AonSpinner
                    v-if="entityStore.loadingEntity || !entityStore.entity"
                    class="ma-auto"
                    :scale="0.5"
                    color="castleMoat"
                />
                <template v-else>
                    <AonCol class="aon-col-12 px-0" v-if="showInsights">
                        <Insights class="px-0" />
                    </AonCol>
                    <AonCol class="aon-col-12 px-0">
                        <SummaryCard class="px-0" />
                    </AonCol>
                    <AonCol class="aon-col-6 pl-0">
                        <TPECard />
                    </AonCol>
                    <AonCol class="aon-col-6 pr-0">
                        <ProductStrategy />
                    </AonCol>
                    <AonCol class="aon-col-12 px-0">
                        <ProductClassPeers />
                    </AonCol>
                    <AonCol class="aon-col-12 px-0">
                        <FundingRoundsCard
                            v-if="entityStore.entity.entity_type !== 'Public Company'"
                        />
                    </AonCol>
                </template>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { useEntityStore } from '@/stores'
import SummaryCard from './SummaryCard.vue'

import { useFlag } from '@unleash/proxy-client-vue'

import TPECard from '@/components/company/tabs/valuations/TPECard.vue'
import ProductStrategy from './ProductStrategy.vue'
import ProductClassPeers from './productClassPeers/ProductClassPeers.vue'

//remove after flag is removed
import ExecutiveTeamCard from '@/components/company/tabs/execution/ExecutiveTeamCard.vue'
import FundingCard from '@/components/company/tabs/valuations/FundingCard.vue'
import FundingRoundsCard from '@/components/company/tabs/valuations/FundingRoundsCard.vue'
import Insights from '@/components/insights/Insights.vue'

const entityStore = useEntityStore()
const showDashboard = useFlag('ipAlpha.companyDashboard')
const showInsightsFlag = useFlag('ipAlpha.companyInsights')

const showInsights = ref(false)

watchEffect(async () => {
    if (entityStore.entity) {
        const insights = await entityStore.getInsights(entityStore.entity.aon_entity_pk)
        showInsights.value =
            showInsightsFlag.value && insights.results && insights.results.length > 0
    }
})
</script>

<style lang="scss" scoped>
.company-summary-tab {
    width: 100%;
    position: relative;
}
</style>

<!-- <template>
    <div class="company-summary-tab mb-5">
        <AonSpinner
            v-if="entityStore.loadingEntity || !entityStore.entity"
            class="ma-auto"
            :scale="0.5"
            color="castleMoat"
        />
        <div v-else class="card-holder">
            <SummaryCard />
            <ExecutiveTeamCard />
            <FundingCard v-if="entityStore.entity.stock_ticker" />
            <FundingRoundsCard />
        </div>
    </div>
</template>

<script setup>
import { useEntityStore } from '@/stores'
import SummaryCard from './SummaryCard.vue'
import FundingCard from './FundingCard.vue'

import ExecutiveTeamCard from '@/components/company/tabs/execution/ExecutiveTeamCard.vue'
import FundingRoundsCard from '@/components/company/tabs/valuations/FundingRoundsCard.vue'

const entityStore = useEntityStore()
</script>

<style lang="scss" scoped>
.company-summary-tab {
    width: 100%;
    position: relative;
}
</style> -->
