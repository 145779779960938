<template>
    <div class="competitor-litigation-card grey04">
        <AonCard style="padding: 0 !important; position: relative">
            <div class="card-content">
                <AonCoverLoading
                    :loading="loadingCompetitorLitigation"
                    title="Loading Competitor Litigation..."
                ></AonCoverLoading>
                <div class="card-content-innner">
                    <div class="top d-flex">
                        <div
                            class="section d-flex align-items-center w-half mr-3 pt-5 h-full flex-wrap justify-content-center"
                            style="width: 75%"
                        >
                            <AonTabs :tabs="toggleOptions" button @tab-change="setLimitToComps" />
                            <div id="amChartCompetitorLitigation"></div>
                        </div>
                        <div
                            class="text-background section d-flex align-items-center align-content-center w-half flex-wrap ml-3 h-full pa-5"
                        >
                            <p class="extra-small bold uppercase tavern-ale-60--text">
                                Competitor Litigation Overview
                            </p>
                            <p class="tavern-ale-60--text large mt-2">
                                Compare recent litigation activity against
                                <span style="font-weight: 700"
                                    >{{ entityStore.entity.name }}'s</span
                                >
                                compared to it's top competitors or the most litigated against in
                                this space.
                            </p>
                        </div>
                    </div>
                </div>
                <AonCoverLoading
                    v-if="noData"
                    :loading="noData ? true : false"
                    :show-spinner="false"
                    title="No Reported Litigation"
                >
                    <template #header>
                        <div class="icon">
                            <font-awesome-icon
                                icon="fas fa-circle-exclamation"
                                class="grey01--text"
                                size="2xl"
                            />
                        </div>
                    </template>
                </AonCoverLoading>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted, inject } from 'vue'
import { getCompetitorLitigation } from '@/api/ipNode'
import { useEntityStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const entityStore = useEntityStore()

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})

const logger = inject('logger')
let root
let chart
let xAxis
let yAxis
let series
let legend

const toggleOptions = ref([
    {
        text: 'Most Litigated',
        id: 'most',
    },
    {
        text: 'Competitors',
        id: 'comps',
    },
])
const loadingCompetitorLitigation = ref(true)
const chartData = ref([])
const shouldLimitToComps = ref(false)
const colorMap = ref(['#90CAF9', '#5C6BC0', '#101E7F', '#6e027f', '#a70070', '#29b0c3'])
const noData = ref(false)

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartCompetitorLitigation')
    onGetCompetitorLitigation()
})

onBeforeUnmount(() => {
    root.dispose()
})

const onGetCompetitorLitigation = async () => {
    loadingCompetitorLitigation.value = true

    if (root && root.container && root.container.children) {
        root.container.children.clear()
    }

    try {
        const params = {
            limitToComps: shouldLimitToComps.value,
            targetEntityPk: entityStore.entity.aon_entity_pk,
            numEntities: 5,
        }
        const { data } = await getCompetitorLitigation(props.nodeData.id, params)
        if (data.length === 0) {
            noData.value = true
            return
        }
        noData.value = false
        chartData.value = [] // Reset chart data

        data.forEach((yearData) => {
            yearData.entitySummaries.forEach((entity, entityIndex) => {
                if (entity.entityName) {
                    chartData.value.push({
                        year: yearData.year.toString(),
                        entityName: entity.entityName,
                        casesCount: entity.casesDefendantCount,
                        fill: am5.color(colorMap.value[entityIndex]),
                    })
                }
            })
        })
        chartData.value = chartData.value.sort((a, b) => a.entityName.localeCompare(b.entityName))
        configureChart()
    } catch (error) {
        logger.error(error)
    } finally {
        loadingCompetitorLitigation.value = false
    }
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingLeft: 10,
            layout: root.verticalLayout,
        })
    )

    generateAxes()
}

const generateAxes = () => {
    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'year', // Change to entityName
            renderer: am5xy.AxisRendererY.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                inversed: true, // earliest year is at the top
            }),
            minGridDistance: 20,
        })
    )

    let years = [...new Set(chartData.value.map((data) => data.year))]
        .map((year) => ({ year: year }))
        .sort((a, b) => a.year - b.year)

    yAxis.data.setAll(years) // Only year data

    xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            maxPrecision: 0,
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 40,
                strokeOpacity: 0.1,
            }),
        })
    )

    // Labels and Legend
    yAxis.children.push(
        am5.Label.new(root, {
            text: 'Year',
            fontWeight: 600,
            fontSize: 12,
            rotation: -90,
            y: am5.percent(50),
            x: am5.percent(-30),
            centerX: am5.p50,
            centerY: am5.p50,
        })
    )

    xAxis.children.push(
        am5.Label.new(root, {
            text: 'Litigation Activity',
            fontSize: 12,
            fontWeight: 600,
            centerX: am5.p50,
            x: am5.percent(50),
            y: am5.percent(65),
        })
    )

    legend = chart.children.push(
        am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.percent(55),
            paddingTop: 20,
            layout: am5.GridLayout.new(root, {
                maxColumns: 3,
                fixedWidthGrid: true,
            }),
        })
    )

    legend.labels.template.setAll({
        fontSize: 13,
        fontWeight: '300',
    })

    legend.valueLabels.template.setAll({
        fontSize: 13,
        fontWeight: '600',
    })

    legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 16,
        height: 16,
    })

    generateSeries()

    legend.data.setAll(chart.series.values)
    chart.appear(1000, 100)
}

const generateSeries = () => {
    // Get unique entity names from chartData
    const entities = [...new Set(chartData.value.map((data) => data.entityName))]

    entities.forEach((entityName, index) => {
        series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: 'casesCount',
                categoryYField: 'year',
                name: entityName, // Set name for each series
            })
        )

        series.columns.template.setAll({
            tooltipText: '{name}, {categoryY}: {valueX}', // Show name and year
            tooltipY: am5.percent(90),
            height: am5.p100,
            fill: colorMap.value[index % colorMap.value.length], // Use color from map
            stroke: am5.color(colorMap.value[index % colorMap.value.length]),
            strokeWidth: 0,
        })

        // Filter chartData for the current entity and set data
        const entityData = chartData.value.filter((data) => data.entityName === entityName)
        series.data.setAll(entityData)
    })
}

let setLimitToComps = (selection) => {
    if (selection.id === 'comps') {
        shouldLimitToComps.value = true
    } else {
        shouldLimitToComps.value = false
    }
    onGetCompetitorLitigation()
}
</script>

<style lang="scss" scoped>
.competitor-litigation-card {
    width: 100%;

    .option-dropdown {
        max-width: 300px;
    }

    :deep(.a-card .a-tabs) {
        .selected {
            border-bottom: none !important;
        }

        p {
            font-size: 14px;
        }

        li {
            &:hover {
                p {
                    color: white !important;
                }
            }
        }
    }

    .section {
        .no-break {
            white-space: nowrap;
        }
        .spacer {
            height: 10px;
            background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 8px 1px;
            background-repeat: repeat-x;
        }
    }

    .top {
        height: 550px;
        #amChartCompetitorLitigation {
            width: 100%;
            height: 90%;

            div {
                height: 100%;
            }
        }
    }

    .text-background {
        background: rgba($warningBackground, 0.5);
        border-radius: 8px 0 0 8px;
    }
}
</style>
