export const EXCHANGE_MAP = {
    ASE: 'AMEX',
    NAS: 'NASDAQ',
    NYS: 'NYSE',
    PSE: 'AMEX',
    TKS: 'TSE',
    SHE: 'SZSE',
    HKG: 'HKEX',
    TSX: 'TSXV',
    SHG: 'SSE',
    LON: 'LSE',
    ROCO: 'TPEX',
    TAI: 'TWSE',
    PAR: 'EURONEXT',
    TSE: 'TSX',
}
