import { ref, computed } from 'vue'
import * as EntityApi from '@/api/entities'
import { logger } from '@moatmetrics/vue-logger'
import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash-es'

// TODO DRY/Refactor the duplication with the competitorCompetencies store
export const useParticipatingMarketsStore = defineStore('participatingMarkets', () => {
    // state
    const competenciesApexScoreType = ref({ itemValue: 'Apex', itemText: 'Apex' })
    const competenciesCorScoreType = ref({ itemValue: 'Cor', itemText: 'COR' })
    const competenciesApexScores = ref([])
    const competenciesCorScores = ref([])
    const competenciesHistoryCache = ref({})
    const competenciesHistoryLoading = ref(false)
    const competenciesMap = ref([])
    const competencies = ref([])
    const competenciesSelected = ref(new Set())
    const competenciesTotalIds = ref([])
    const lastCorScoresSort = ref(null)
    const lastApexScoresSort = ref(null)

    // getters
    const getCompetenciesSelectedIds = computed(() => {
        const selectedIds = [...competenciesSelected.value.values()]

        return selectedIds
    })
    const getCompetenciesCorScores = computed(() => {
        return competenciesCorScores.value
    })
    const getCompetenciesApexScores = computed(() => {
        return competenciesApexScores.value
    })
    const getCompetenciesCorScoreType = computed(() => {
        return competenciesCorScoreType.value
    })
    const getCompetenciesApexScoreType = computed(() => {
        return competenciesApexScoreType.value
    })
    const isHistoryLoading = computed(() => {
        return competenciesHistoryLoading.value
    })
    const getSelectedIdAndName = computed(() => {
        const sectorIds = getCompetenciesSelectedIds.value
        const competenciesIdAndName = sectorIds.map((id) => {
            const competency = competenciesMap.value.find((comp) => comp.itemValue === id)

            return {
                id,
                name: competency.itemText,
            }
        })
        return competenciesIdAndName
    })
    const getCompetenciesMap = computed(() => {
        return competenciesMap.value
    })
    const getCompetencies = computed(() => {
        return competencies.value
    })
    const getCompetenciesIds = computed(() => {
        return competencies.value.map((cm) => cm.aon_sector_pk)
    })
    const getCompetenciesCount = computed(() => {
        // TODO temp value? should never be null/undefined!?
        return competenciesMap.value?.length
    })
    const getCompetenciesTotalCount = computed(() => {
        return competenciesTotalIds.value?.length
    })

    // actions
    const resetState = () => {
        competenciesApexScoreType.value = { itemValue: 'Apex', itemText: 'Apex' }
        competenciesCorScoreType.value = { itemValue: 'Cor', itemText: 'COR' }
        competenciesApexScores.value = []
        competenciesCorScores.value = []
        competenciesHistoryCache.value = {}
        competenciesHistoryLoading.value = false
        competenciesMap.value = []
        competencies.value = []
        competenciesSelected.value = new Set()
        competenciesTotalIds.value = []
        lastCorScoresSort.value = null
        lastApexScoresSort.value = null
    }
    const anyCompetencies = () => {
        return !!competenciesMap.value.length
    }
    const anyCompetenciesSelected = () => {
        return !!competenciesSelected.value.size
    }
    const isCompetencySelected = (sectorId) => {
        if (!sectorId) {
            return false
        }
        return competenciesSelected.value.has(sectorId)
    }
    const isCompetencyHistoryCached = (sectorId) => {
        return !!competenciesHistoryCache.value[sectorId]
    }
    const toggleCompetencySelected = (sectorId) => {
        if (isCompetencySelected(sectorId)) {
            removeCompetencySelected(sectorId)
            buildCompetenciesSelectedScores()
            return
        }
        addCompetenciesSelected(sectorId)
    }
    const setCompetenciesHistoryLoading = (isLoading) => {
        competenciesHistoryLoading.value = isLoading
    }
    const setCompetenciesHistoryCache = (competenciesHistoryObj) => {
        competenciesHistoryCache.value = competenciesHistoryObj
    }
    const setCompetenciesCorScores = (scores) => {
        competenciesCorScores.value = scores || []
    }
    const setCompetenciesApexScores = (scores) => {
        competenciesApexScores.value = scores || []
    }
    const setCompetenciesCorScoreType = (scoreType) => {
        // TODO check if type is valid
        // const validTypes = [
        //     { itemValue: 'Cor', itemText: 'COR' },
        //     { itemValue: 'Coverage', itemText: 'Coverage' },
        //     { itemValue: 'Opportunity', itemText: 'Opportunity' },
        //     { itemValue: 'Resilience', itemText: 'Resilience' },
        // ]
        competenciesCorScoreType.value = scoreType
    }
    const setCompetenciesApexScoreType = (scoreType) => {
        // TODO check if type is valid
        // const validTypes = [
        //     { itemValue: 'Apex', itemText: 'Apex' },
        // ]

        competenciesApexScoreType.value = scoreType
    }
    const setCompetenciesTotalIds = (allSectorIds) => {
        if (!allSectorIds) {
            clearCompetenciesTotalIds()
            return
        }
        competenciesTotalIds.value = allSectorIds
    }
    const clearCompetenciesTotalIds = () => {
        competenciesTotalIds.value = []
    }

    const setCompetencies = (competencies) => {
        if (!competencies || competencies.length == 0) {
            clearCompetencies()
            return
        }

        competencies.value = competencies
        setCompetenciesMap(competencies)
    }
    const setCompetenciesMap = (competencies) => {
        if (!competencies || competencies.length == 0) {
            clearCompetenciesMap()
            return
        }
        competenciesMap.value = competencies.map((comp) => {
            return {
                itemText: comp.aon_sector_name,
                itemValue: comp.aon_sector_pk,
            }
        })
    }
    const clearCompetencies = () => {
        competencies.value = []
    }
    const clearCompetenciesMap = () => {
        competenciesMap.value = []
        clearCompetenciesSelected()
        clearCompetenciesTotalIds()
    }
    const clearCompetenciesHistoryCache = () => {
        competenciesHistoryCache.value = {}
    }
    const clearCompetenciesScores = () => {
        competenciesCorScores.value = []
        competenciesApexScores.value = []
    }
    const clearCompetenciesSelected = () => {
        competenciesSelected.value = new Set()
    }
    const addCompetencyHistoryCache = (history, competencyName, sectorPathText) => {
        if (!history || history.length === 0) {
            return
        }

        const sectorId = history[0].aonSectorPk
        const competencyHistory = {
            entityId: history[0].aonEntityPk,
            sectorId,
            sectorPath: sectorPathText,
            name: competencyName,
        }

        // TODO move these custom view model object transformations to a separate model helper file to avoid duplication in the future
        history.forEach((hist, i) => {
            const yearKey = `year${i + 1}`

            competencyHistory[yearKey] = new Date(hist.effectiveDate).getFullYear()
            competencyHistory[`${yearKey}Cor`] = hist.corScore
            competencyHistory[`${yearKey}Coverage`] = hist.coverageScore
            competencyHistory[`${yearKey}Opportunity`] = hist.opportunityScore
            competencyHistory[`${yearKey}Resilience`] = hist.ipRiskScore
            competencyHistory[`${yearKey}Apex`] = hist.apexScore

            competencyHistory[`${yearKey}CorChange`] = calcScoreChange(
                history[i + 1]?.corScore,
                hist.corScore
            )
            competencyHistory[`${yearKey}CoverageChange`] = calcScoreChange(
                history[i + 1]?.coverageScore,
                hist.coverageScore
            )
            competencyHistory[`${yearKey}OpportunityChange`] = calcScoreChange(
                history[i + 1]?.opportunityScore,
                hist.opportunityScore
            )
            competencyHistory[`${yearKey}ResilienceChange`] = calcScoreChange(
                history[i + 1]?.ipRiskScore,
                hist.ipRiskScore
            )
            competencyHistory[`${yearKey}ApexChange`] = calcScoreChange(
                history[i + 1]?.apexScore,
                hist.apexScore
            )
        })

        const competenciesHistory = cloneDeep(competenciesHistoryCache.value)

        competenciesHistory[sectorId] = competencyHistory
        setCompetenciesHistoryCache(competenciesHistory)
    }
    const addCompetenciesSelected = (sectorId) => {
        competenciesSelected.value.add(sectorId)
    }
    const getCompetenciesSelectedHistory = () => {
        if (competenciesSelected.value.size === 0) {
            clearCompetenciesScores()
            return []
        }

        const selectedIds = [...competenciesSelected.value.values()]
        const competenciesHistory = cloneDeep(competenciesHistoryCache.value)
        const selectedHistory = selectedIds
            .filter((sid) => competenciesHistory[sid])
            .map((cid) => competenciesHistory[cid])

        return selectedHistory
    }
    const buildCompetenciesSelectedCorScores = (useLastSort = false) => {
        const selectedHistory = getCompetenciesSelectedHistory()
        const corScoreType = competenciesCorScoreType.value.itemValue
        let corScores = selectedHistory.map((sh) => {
            return {
                entityId: sh.entityId,
                sectorId: sh.sectorId,
                sectorPath: sh.sectorPath,
                name: sh.name,
                scoreType: corScoreType,
                year1: sh['year1'],
                year1Score: sh[`year1${corScoreType}`],
                year1Change: sh[`year1${corScoreType}Change`],
                year2: sh['year2'],
                year2Score: sh[`year2${corScoreType}`],
                year2Change: sh[`year2${corScoreType}Change`],
                year3: sh['year3'],
                year3Score: sh[`year3${corScoreType}`],
                year3Change: sh[`year3${corScoreType}Change`],
                year4: sh['year4'],
                year4Score: sh[`year4${corScoreType}`],
                year4Change: sh[`year4${corScoreType}Change`],
                year5: sh['year5'],
                year5Score: sh[`year5${corScoreType}`],
                year5Change: sh[`year5${corScoreType}Change`],
            }
        })

        if (!useLastSort || !lastCorScoresSort.value || !lastCorScoresSort.value?.sortParams.SP) {
            setCompetenciesCorScores(corScores)
            return
        }

        const { sortKey, sortParams } = lastCorScoresSort.value

        setCompetenciesCorScores(sortScores(corScores, sortKey, sortParams.SD[0]))
    }
    const buildCompetenciesSelectedApexScores = (useLastSort = false) => {
        const selectedHistory = getCompetenciesSelectedHistory()
        const apexScoreType = competenciesApexScoreType.value.itemValue
        const apexScores = selectedHistory.map((sh) => {
            return {
                entityId: sh.entityId,
                sectorId: sh.sectorId,
                sectorPath: sh.sectorPath,
                name: sh.name,
                scoreType: apexScoreType,
                year1: sh['year1'],
                year1Score: sh[`year1Apex`],
                year1Change: sh[`year1${apexScoreType}Change`],
                year2: sh['year2'],
                year2Score: sh['year2Apex'],
                year2Change: sh[`year2${apexScoreType}Change`],
                year3: sh['year3'],
                year3Score: sh['year3Apex'],
                year3Change: sh[`year3${apexScoreType}Change`],
                year4: sh['year4'],
                year4Score: sh['year4Apex'],
                year4Change: sh[`year4${apexScoreType}Change`],
                year5: sh['year5'],
                year5Score: sh['year5Apex'],
                year5Change: sh[`year5${apexScoreType}Change`],
            }
        })

        if (!useLastSort || !lastApexScoresSort.value || !lastApexScoresSort.value?.sortParams.SP) {
            setCompetenciesApexScores(apexScores)
            return
        }

        const { sortKey, sortParams } = lastApexScoresSort.value

        setCompetenciesApexScores(sortScores(apexScores, sortKey, sortParams.SD[0]))
    }
    const buildCompetenciesSelectedScores = () => {
        clearCompetenciesScores()
        buildCompetenciesSelectedCorScores()
        buildCompetenciesSelectedApexScores()
    }
    const calcScoreChange = (priorYearScore, score) => {
        if (!priorYearScore) {
            return
        }

        const change = (score - priorYearScore) / Math.abs(priorYearScore)

        return change
    }
    const removeCompetencySelected = (sectorId) => {
        competenciesSelected.value.delete(sectorId)
    }
    const removeCompetencyHistoryCache = (sectorId) => {
        competenciesHistoryCache.value.delete(sectorId)
    }
    const sortScores = (competenciesScores, sortKey, sortDirection) => {
        let sortedScores = []
        // TODO write a compare method or DRY/refactor this up inline
        if (sortDirection === 1) {
            sortedScores = competenciesScores.sort((a, b) => {
                if (a[sortKey] < b[sortKey]) {
                    return -1
                }
                if (a[sortKey] > b[sortKey]) {
                    return 1
                }
                return 0
            })
        } else {
            sortedScores = competenciesScores.sort((a, b) => {
                if (a[sortKey] > b[sortKey]) {
                    return -1
                }
                if (a[sortKey] < b[sortKey]) {
                    return 1
                }
                return 0
            })
        }

        return sortedScores
    }
    const sortCompetenciesScores = (sortParams, isCorContext) => {
        const sortKey = sortParams.SC[0] === 'name' ? 'name' : `${sortParams.SC[0]}Score`
        // TODO turn this nested if/else mess inside out or something ICK

        if (isCorContext) {
            if (competenciesCorScores.value.length <= 1) {
                return
            }
            setLastCorScoresSort({ sortKey, sortParams })
            buildCompetenciesSelectedCorScores(true)
        } else {
            if (competenciesApexScores.value.length <= 1) {
                return
            }
            setLastApexScoresSort({ sortKey, sortParams })
            buildCompetenciesSelectedApexScores(true)
        }
    }
    const setLastCorScoresSort = ({ sortKey, sortParams }) => {
        lastCorScoresSort.value = { sortKey, sortParams }
    }
    const clearLastCorScoresSort = () => {
        lastCorScoresSort.value = null
    }
    const setLastApexScoresSort = ({ sortKey, sortParams }) => {
        lastApexScoresSort.value = { sortKey, sortParams }
    }
    const clearLastApexScoresSort = () => {
        lastApexScoresSort.value = null
    }

    return {
        competenciesApexScoreType,
        competenciesCorScoreType,
        competenciesApexScores,
        competenciesCorScores,
        competenciesHistoryCache,
        competenciesHistoryLoading,
        competenciesMap,
        competencies,
        competenciesSelected,
        getCompetencies,
        getCompetenciesMap,
        getCompetenciesIds,
        getCompetenciesCount,
        getCompetenciesSelectedIds,
        getCompetenciesCorScores,
        getCompetenciesApexScores,
        getCompetenciesCorScoreType,
        getCompetenciesApexScoreType,
        isHistoryLoading,
        getSelectedIdAndName,
        getCompetenciesTotalCount,
        lastCorScoresSort,
        anyCompetencies,
        anyCompetenciesSelected,
        isCompetencySelected,
        isCompetencyHistoryCached,
        toggleCompetencySelected,
        setCompetenciesMap,
        setCompetencies,
        setCompetenciesHistoryLoading,
        setCompetenciesHistoryCache,
        setCompetenciesCorScores,
        setCompetenciesApexScores,
        setCompetenciesCorScoreType,
        setCompetenciesApexScoreType,
        clearCompetencies,
        clearCompetenciesMap,
        clearCompetenciesTotalIds,
        setCompetenciesTotalIds,
        clearCompetenciesHistoryCache,
        clearCompetenciesScores,
        clearCompetenciesSelected,
        addCompetencyHistoryCache,
        addCompetenciesSelected,
        buildCompetenciesSelectedScores,
        calcScoreChange,
        removeCompetencySelected,
        removeCompetencyHistoryCache,
        sortCompetenciesScores,
        clearLastApexScoresSort,
        setLastApexScoresSort,
        clearLastCorScoresSort,
        setLastCorScoresSort,
        sortScores,
        buildCompetenciesSelectedCorScores,
        buildCompetenciesSelectedApexScores,
        getCompetenciesSelectedHistory,
        resetState,
    }
})
