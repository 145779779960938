<template>
    <div class="screener-table mb-6">
        <TableAips
            ref="companiesTable"
            class="table"
            :class="{ 'dense-filters': filterCount > 8, 'extra-dense-filters': filterCount > 15 }"
            :table-name="tableTitle"
            table-height="75vh"
            table-style="fixed-scrolling"
            sticky-columns="first"
            filter-transition-name="none"
            toggle-columns
            :use-page-data="false"
            :table-collapse="false"
            :page-size="20"
            :columns="columns"
            :data="data"
            :loading-data="loading"
            :default-sort="defaultSort"
            :table-bulk-actions="isCreatePage || props.playbook ? [] : tableBulkActions"
            :show-bulk-actions="entitiesSelected.length > 0"
            :filtered-data-length="totalDataLength"
            enable-header-height-adjustment
            header-height-adjustment-offset="82px"
            @loadData="loadTableData"
            @bulk-action-selected="selectAction"
            @table-row-checked="selectRow"
            @add-filter="filterAdded"
            @remove-filter="filterRemoved"
            table-density="compact"
        >
            <template #bulkActionBarPrepend>
                <div class="holder mr-10 d-flex">
                    <p class="white--text link mr-2" @click="clearSelectedData">Clear</p>
                    <p class="white--text mr-2">•</p>
                    <p class="selections bold white--text">
                        {{ $filters.toLocaleString(entitiesSelected.length) }}
                        {{ $t('actions.selected') }}
                    </p>
                </div>
            </template>
            <template #tableMessage>
                <div class="height-holder w-full d-flex align-items-center">
                    <AonSkeleton
                        class="w-full d-flex align-items-center"
                        :loading="loading && totalDataLength === 0"
                        :data="totalDataLength"
                        light
                    >
                        <div
                            class="info d-flex align-items-center flex-wrap nowrap"
                            style="width: fit-content"
                        >
                            {{
                                $t('actions.showingCountOf', {
                                    count: $filters.toLocaleString(data.length),
                                    total: $filters.toLocaleString(totalDataLength),
                                    units: 'items',
                                })
                            }}
                        </div>
                    </AonSkeleton>
                </div>
            </template>
            <template v-if="showtooltips" #overall_scoreHeader>
                <VDropdown
                    class="ml-2"
                    placement="top"
                    :distance="5"
                    :triggers="['hover']"
                    :popper-triggers="['hover']"
                >
                    <font-awesome-icon
                        icon="fas fa-circle-info"
                        class="castle-moat--text d-inline metric-icon"
                    />
                    <template #popper>
                        <PopperMarketMetrics
                            :title="$t('definitionTooltips.cor.title')"
                            :definition="$t('definitionTooltips.cor.definition')"
                            :more-info="$t('definitionTooltips.cor.more')"
                        />
                    </template>
                </VDropdown>
            </template>
            <template v-if="showtooltips" #coverage_scoreHeader>
                <VDropdown
                    class="ml-2"
                    placement="top"
                    :distance="5"
                    :triggers="['hover']"
                    :popper-triggers="['hover']"
                >
                    <font-awesome-icon
                        icon="fas fa-circle-info"
                        class="castle-moat--text d-inline metric-icon"
                    />
                    <template #popper>
                        <PopperMarketMetrics
                            :title="$t('definitionTooltips.coverage.title')"
                            :definition="$t('definitionTooltips.coverage.definition')"
                            :more-info="$t('definitionTooltips.coverage.more')"
                        />
                    </template>
                </VDropdown>
            </template>
            <template v-if="showtooltips" #opportunity_scoreHeader>
                <VDropdown
                    class="ml-2"
                    placement="top"
                    :distance="5"
                    :triggers="['hover']"
                    :popper-triggers="['hover']"
                >
                    <font-awesome-icon
                        icon="fas fa-circle-info"
                        class="castle-moat--text d-inline metric-icon"
                    />
                    <template #popper>
                        <PopperMarketMetrics
                            :title="$t('definitionTooltips.opportunity.title')"
                            :definition="$t('definitionTooltips.opportunity.definition')"
                            :more-info="$t('definitionTooltips.opportunity.more')"
                        />
                    </template>
                </VDropdown>
            </template>
            <template v-if="showtooltips" #risk_scoreHeader>
                <VDropdown
                    class="ml-2"
                    placement="top"
                    :distance="5"
                    :triggers="['hover']"
                    :popper-triggers="['hover']"
                >
                    <font-awesome-icon
                        icon="fas fa-circle-info"
                        class="castle-moat--text d-inline metric-icon"
                    />
                    <template #popper>
                        <PopperMarketMetrics
                            :title="$t('definitionTooltips.risk.title')"
                            :definition="$t('definitionTooltips.risk.definition')"
                            :more-info="$t('definitionTooltips.risk.more')"
                        />
                    </template>
                </VDropdown>
            </template>
            <template #name="{ data }">
                <CompanySearchResult
                    :company="data"
                    :search-term="searchTerm"
                    @set-hierarchy-company="setCompanyHierarchyEntity(data)"
                    @show-company-summary="showCompanySummary(data)"
                />
            </template>
            <template #hq_country_name="{ data }">
                <div class="wrap d-flex align-items-center">
                    <span class="overflow-ellipsis" v-tooltip="{ content: data.hq_country_name }">
                        {{ data.hq_country_name }}
                    </span>
                </div>
            </template>
            <template #target_price_to_earnings="{ data }">
                <div class="wrap d-flex align-items-center">
                    <span class="overflow-ellipsis">
                        {{
                            data.target_price_to_earnings
                                ? roundToHundredth(data.target_price_to_earnings)
                                : '--'
                        }}
                    </span>
                </div>
            </template>
            <template #price_to_earnings="{ data }">
                <div class="wrap d-flex align-items-center">
                    <span class="overflow-ellipsis">
                        {{
                            data.price_to_earnings ? roundToHundredth(data.price_to_earnings) : '--'
                        }}
                    </span>
                </div>
            </template>
            <template #price_to_earnings_delta="{ data }">
                <div class="wrap d-flex align-items-center">
                    <span class="overflow-ellipsis">
                        {{
                            data.price_to_earnings_delta
                                ? roundToHundredth(data.price_to_earnings_delta)
                                : '--'
                        }}
                    </span>
                </div>
            </template>
            <template #org_status="{ data }">
                {{ t(`screeners.company.org_status.${data.org_status}`) }}
            </template>
            <template #ownership_status="{ data }">
                {{ t(`screeners.company.ownership_status.${data.ownership_status}`) }}
            </template>
        </TableAips>
        <CompanyHierarchy
            v-if="showCompanyHierarchy"
            :parent-entity-id="companyHierarchyEntityParentId"
            :selected-entity-id="companyHierarchyEntityId"
            @cancel="showCompanyHierarchy = false"
        />
    </div>
</template>

<script setup>
import { computed, getCurrentInstance, inject, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDatasetStore, useEntityStore, useScreenerStore } from '@/stores'
import { useI18n } from 'vue-i18n'

import * as osApi from '@/api/opensearch'
import { getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import { getDataset, getDatasetValues, removeFromDataset } from '@/api/datasets'
import { globalFilterToScreenerFilterString } from '@/lib/helpers'
import { useScreenerPresignedUrlDownloader } from '@/composables/presignedUrlDownloader'
import { useScreenerTableComposable } from '@/composables/screenerTable'

import CompanyHierarchy from '@/components/company/CompanyHierarchy'
import CompanySearchResult from '@/components/company/CompanySearchResult'
import PopperMarketMetrics from '@/components/PopperMarketMetrics'

import { useFlag } from '@unleash/proxy-client-vue'
import { useAuth } from '@/auth/authPlugin'

const showtooltips = useFlag('ipAlpha.legalTooltips')

const emit = defineEmits(['playbook-target-entity'])
const props = defineProps({
    tableTitle: {
        type: String,
        default: 'Companies',
    },
    isCreatePage: {
        type: Boolean,
        default: false,
    },
    maxSelection: {
        type: Number,
        default: 25,
    },
    screenerContextId: {
        type: String,
        default: 'screener',
    },
    playbook: {
        type: Boolean,
        default: false,
    },
})

const $filters = inject('filters')
const eventBus = inject('eventBus')
const logger = inject('logger')

const { t } = useI18n()
const { user } = useAuth()
const datasetStore = useDatasetStore()
const entityStore = useEntityStore()
const screenerStore = useScreenerStore(props.screenerContextId)
const route = useRoute()
const router = useRouter()

const initDefaultSort = [
    {
        sortColumn: 'overall_score',
        sortDirection: -1,
        sortPriority: 1,
    },
]
const { defaultFilter, defaultSort, isDefaultFilter } = useScreenerTableComposable(
    'COMPANY',
    initDefaultSort,
    props.screenerContextId.includes('Watchlist')
)

const companiesTable = ref(null)
const data = ref([])
const datasetPk = ref(router.currentRoute.value.params.datasetPk)
const filterCount = ref(0)
const companyHierarchyEntityId = ref(null)
const companyHierarchyEntityParentId = ref(null)
const showCompanyHierarchy = ref(false)
const loading = ref(true)
const totalDataLength = ref(0)
const watchlistUpdater = ref(0)
let entityPks = ref(null)

const columns = computed(() => {
    return [
        {
            checkboxValue: 'checked',
            showColumn: true,
            hasCheckbox: true,
            headerCheckbox: false,
            hideFromColToggle: true,
            hideFromFilters: true,
            width: '70px',
        },
        {
            value: 'name',
            locale: t('screeners.company.headers.name'),
            disableTooltip: true,
            showColumn: true,
            filterType: 'string',
            useSlot: true,
            width: '400px',
            stickyColumn: true,
        },
        {
            value: 'overall_score',
            locale: t('definitionTooltips.cor.title'),
            showColumn: true,
            width: '170px',
            headerWithSlot: true,
            disableHeaderTooltip: true,
            filterType: 'number',
            useItemTextForDisplay: true,
            filterActive: isDefaultFilter('overall_score'),
        },
        {
            value: 'coverage_score',
            locale: t('definitionTooltips.coverage.title'),
            showColumn: true,
            width: '170px',
            headerWithSlot: true,
            disableHeaderTooltip: true,
            filterType: 'number',
            useItemTextForDisplay: true,
            filterActive: isDefaultFilter('coverage_score'),
        },
        {
            value: 'opportunity_score',
            locale: t('definitionTooltips.opportunity.title'),
            showColumn: true,
            width: '170px',
            headerWithSlot: true,
            disableHeaderTooltip: true,
            filterType: 'number',
            useItemTextForDisplay: true,
            filterActive: isDefaultFilter('opportunity_score'),
        },
        {
            value: 'risk_score',
            locale: t('definitionTooltips.risk.title'),
            showColumn: true,
            width: '170px',
            headerWithSlot: true,
            disableHeaderTooltip: true,
            filterType: 'number',
            useItemTextForDisplay: true,
            filterActive: isDefaultFilter('risk_score'),
        },
        {
            value: 'total_revenue',
            locale: t('screeners.company.headers.total_revenue'),
            showColumn: true,
            filterType: 'number',
            width: '150px',
            filterActive: isDefaultFilter('total_revenue'),
        },
        {
            value: 'target_price_to_earnings',
            locale: t('screeners.company.headers.target_price_to_earnings'),
            showColumn: true,
            filterType: 'number',
            width: '150px',
            filterActive: isDefaultFilter('target_price_to_earnings'),
            useSlot: true,
        },
        {
            value: 'price_to_earnings',
            locale: t('screeners.company.headers.price_to_earnings'),
            showColumn: true,
            filterType: 'number',
            width: '150px',
            filterActive: isDefaultFilter('price_to_earnings'),
            useSlot: true,
        },
        {
            value: 'price_to_earnings_delta',
            locale: t('screeners.company.headers.price_to_earnings_delta'),
            showColumn: true,
            filterType: 'number',
            width: '150px',
            filterActive: isDefaultFilter('price_to_earnings_delta'),
            useSlot: true,
        },
        {
            value: 'hq_country_name',
            locale: t('screeners.company.headers.hq_country_name'),
            showColumn: true,
            filterType: 'string',
            width: '175px',
            filterActive: isDefaultFilter('hq_country_name'),
            useSlot: true,
            disableTooltip: true,
        },
        {
            value: 'total_funding_usd',
            locale: t('screeners.company.headers.total_funding_usd'),
            showColumn: true,
            filterType: 'number',
            width: '150px',
            filterActive: isDefaultFilter('total_funding_usd'),
        },
        {
            value: 'last_funding_on',
            locale: t('screeners.company.headers.last_funding_on'),
            showColumn: true,
            filterType: 'date',
            width: '170px',
            filterActive: isDefaultFilter('last_funding_on'),
        },
        {
            value: 'num_funding_rounds',
            locale: t('screeners.company.headers.num_funding_rounds'),
            showColumn: true,
            filterType: 'number',
            width: '170px',
            filterActive: isDefaultFilter('num_funding_rounds'),
        },
        {
            value: 'org_status',
            locale: t('screeners.company.headers.org_status'),
            showColumn: true,
            filterType: 'dropdown',
            filterItems: [
                { itemText: t(`screeners.company.org_status.operating`), itemValue: 'operating' },
                { itemText: t(`screeners.company.org_status.ipo`), itemValue: 'ipo' },
                { itemText: t(`screeners.company.org_status.closed`), itemValue: 'closed' },
                { itemText: t(`screeners.company.org_status.acquired`), itemValue: 'acquired' },
            ],
            width: '150px',
            useSlot: true,
            filterActive: isDefaultFilter('org_status'),
        },
        {
            value: 'employee_count',
            locale: t('screeners.company.headers.employee_count'),
            showColumn: true,
            filterType: 'dropdown',
            filterItems: [
                { itemText: t(`screeners.company.employee_counts.1-10`), itemValue: '1-10' },
                { itemText: t(`screeners.company.employee_counts.11-50`), itemValue: '11-50' },
                { itemText: t(`screeners.company.employee_counts.51-100`), itemValue: '51-100' },
                { itemText: t(`screeners.company.employee_counts.101-250`), itemValue: '101-250' },
                { itemText: t(`screeners.company.employee_counts.251-500`), itemValue: '251-500' },
                {
                    itemText: t(`screeners.company.employee_counts.501-1000`),
                    itemValue: '501-1000',
                },
                {
                    itemText: t(`screeners.company.employee_counts.1001-5000`),
                    itemValue: '1001-5000',
                },
                {
                    itemText: t(`screeners.company.employee_counts.5001-10000`),
                    itemValue: '5001-10000',
                },
                { itemText: t(`screeners.company.employee_counts.10000+`), itemValue: '10000+' },
            ],
            width: '170px',
            filterActive: isDefaultFilter('employee_count'),
        },
        {
            value: 'patent_count_us',
            locale: t('screeners.company.headers.active_assets'),
            showColumn: true,
            filterType: 'number',
            width: '170px',
            filterActive: isDefaultFilter('patent_count_us'),
        },
        {
            value: 'ownership_status',
            locale: t('screeners.company.headers.ownership_status'),
            showColumn: true,
            filterType: 'dropdown',
            filterItems: [
                {
                    itemText: t(`screeners.company.ownership_status.public`),
                    itemValue: 'public',
                },
                {
                    itemText: t(`screeners.company.ownership_status.private`),
                    itemValue: 'private',
                },
            ],
            width: '170px',
            useSlot: true,
            filterActive: isDefaultFilter('ownership_status'),
        },
    ]
})
const entitiesSelected = computed(() => screenerStore.entitiesSelected)
const searchTerm = computed(() => {
    if (!route.query.filter) {
        return null
    }

    const json = atob(route.query.filter)
    const screener = JSON.parse(json)
    const nameFilter = screener.filters.find((sf) => sf.filterColumn === 'name')

    return nameFilter ? nameFilter.filterValue : null
})
const tableBulkActions = computed(() => {
    const selectedCount = screenerStore.entitiesSelected.length
    const isDisabled = !screenerStore.anyEntitiesSelected || selectedCount > 25
    let tableActions = [
        {
            itemText: t('actions.addToWatchlist.label'),
            disabledRule: !isDisabled,
            disabledMsg: t('actions.addToWatchlist.disabled'),
            faIconStyle: 'fas',
            faIcon: 'list-dropdown',
        },
        {
            itemText: t('actions.exportLimit.label'),
            disabledRule: !isDisabled,
            disabledMsg: t('actions.exportLimit.disabled'),
            faIconStyle: 'fas',
            faIcon: 'download',
        },
    ]

    if (datasetPk.value) {
        tableActions = tableActions.toSpliced(2, 0, {
            itemText: t('actions.removeFromWatchlist.label'),
            disabledRule: !isDisabled,
            disabledMsg: t('actions.removeFromWatchlist.disabled'),
            faIconStyle: 'fas',
            faIcon: 'xmark',
        })
    }

    return tableActions
})

onMounted(() => {
    filterCount.value = defaultFilter.value.length

    if (props.playbook) {
    }
})

watch(
    () => screenerStore.entitiesSelected,
    () => {
        setCheckboxSelections()
    }
)

const setCompanyHierarchyEntity = (rowEntity) => {
    companyHierarchyEntityId.value = rowEntity.aon_entity_pk
    companyHierarchyEntityParentId.value = rowEntity.ultimate_aon_entity_pk
        ? parseInt(rowEntity.ultimate_aon_entity_pk)
        : parseInt(rowEntity.aon_entity_pk)
    showCompanyHierarchy.value = true
}

// =========================
// SCREENER TABLE COMPOSABLE
// =========================
const loadTableData = async ({ params }) => {
    // TODO loadTableData: check the paramsfilters for a new/removed name contains filter
    const base = globalFilterToScreenerFilterString(params, initDefaultSort)

    if (!route.query.filter || route.query.filter !== base) {
        totalDataLength.value = 0
        data.value = []
        await router.replace({
            query: {
                filter: base,
            },
        })
    }

    if (datasetPk.value) {
        params = companiesTable.value.getParams()

        if (!entityPks.value) {
            let dataset = await getDataset(datasetPk.value)
            let datasetValues = await getDatasetValues(
                datasetPk.value,
                'Entity',
                0,
                dataset.data.entity_count
            )
            entityPks.value = datasetValues.data.results
        }

        if (props.isCreatePage && props.screenerContextId == 'competitorSelection') {
            //remove the target entity from the table
            const targetEntityPk =
                reportCreationStore.form.reportCreationSelection.targetEntity.entityPk
            entityPks.value = entityPks.value.filter((pk) => pk.id_value != targetEntityPk)
        }
        if (entityPks.value && entityPks.value.length > 0) {
            if (!params.FC) {
                params.FC = []
            }
            if (!params.FT) {
                params.FT = []
            }
            if (!params.FV) {
                params.FV = []
            }
            params.FC.push('aon_entity_pk')
            params.FT.push('termsParam')
            params.FV.push(
                entityPks.value.flatMap((val) => {
                    return val.id_value
                })
            )
        } else if (!entityPks.value || entityPks.value.length == 0) {
            loading.value = false
            return
        }
    }

    const osParams = getOpenSearchQuery(params)

    await getTableData(osParams)
}
const getTableData = async (params) => {
    loading.value = true

    let response = await osApi.searchEntities(params)

    totalDataLength.value = response.data.hits.total.value

    let scrubbedData = response.data.hits.hits.map((src) => {
        const item = src._source

        return {
            ...item,
            overall_score: $filters.zScore(item.overall_score),
            coverage_score: $filters.zScore(item.coverage_score),
            opportunity_score: $filters.zScore(item.opportunity_score),
            risk_score: $filters.zScore(item.risk_score),
            power_score: $filters.zScore(item.power_score),
            velocity_score: $filters.zScore(item.velocity_score),
            final_diversity_score: $filters.zScore(item.final_diversity_score),
            apex_score: $filters.zScore(item.apex_score),
            total_revenue: $filters.abbreviate(item.total_revenue),
            hq_country: $filters.country(item.hq_country),
            valuation_price_usd: $filters.abbreviate(item.valuation_price_usd),
            money_raised_usd: $filters.abbreviate(item.money_raised_usd),
            total_funding_usd: $filters.abbreviate(item.total_funding_usd),
            last_funding_on: $filters.longDate(item.last_funding_on),
            num_funding_rounds: $filters.na(item.num_funding_rounds),
            checked: screenerStore.isEntitySelected(item.aon_entity_pk),
            patent_count_us: $filters.toLocaleString(item.patent_count_us),
        }
    })

    if (params.from === 0) {
        data.value = [...scrubbedData]
    } else {
        data.value.push(...scrubbedData)
    }

    loading.value = false
    watchlistUpdater.value++
}
const showCompanySummary = async (slotData) => {
    try {
        const dslQuery = getOpenSearchQuery({
            FC: ['aon_entity_pk'],
            FT: ['eq'],
            FV: [slotData.aon_entity_pk],
        })
        const { data } = await osApi.searchEntities(dslQuery)

        let company = data.hits.hits.map((h) => h._source)[0]
        entityStore.setEntity(company)
        entityStore.setSummaryEntity(company)
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('market.errors.quickSummary'),
        })
    }
}
const selectAction = async (action) => {
    const [watchlist, removeFromWatchlist, exportAction] = [
        t('actions.addToWatchlist.label'),
        t('actions.removeFromWatchlist.label'),
        t('actions.exportLimit.label'),
    ]

    switch (action.itemText) {
        case watchlist:
            addSelectedToWatchlist()
            break
        case removeFromWatchlist:
            await removeSelectedFromWatchlist()
            break
        case exportAction:
            exportSelected()
            break
    }
}
// TODO dry these common table action methods up and move them to a helper/lib
const addSelectedToWatchlist = () => {
    datasetStore.setIdsToAdd({
        id_type: 'Entity',
        id_values: screenerStore.getEntitiesSelectedIds,
    })
}
const removeSelectedFromWatchlist = async () => {
    try {
        await removeFromDataset(
            datasetPk.value,
            'Entity',
            screenerStore.getEntitiesSelectedIds.map((id) => {
                return { id_value: id.toString() }
            })
        )
        clearSelectedData()
        eventBus.emit('watchlistChanged', 'Entity')
        eventBus.emit('snacktime', {
            type: 'success',
            message: t('watchlists.remove.removeSuccess'),
        })
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('watchlists.remove.addError'),
        })
    }
}
const exportSelected = () => {
    const selectedIds = screenerStore.getEntitiesSelectedIds
    const tableParams = companiesTable.value.getParams()

    tableParams.FC.push('aon_entity_pk')
    tableParams.FT.push('termsParam')
    tableParams.FV.push(selectedIds)

    const searchQuery = getOpenSearchQuery(tableParams)

    useScreenerPresignedUrlDownloader('entities', searchQuery)
}
const clearSelectedData = () => {
    screenerStore.setSelectedEntities([])
}
const selectRow = async (row) => {
    if (props.playbook) {
        emit('playbook-target-entity', row)
        return
    }

    row.checked
        ? screenerStore.removeSelectedEntity(row.aon_entity_pk)
        : screenerStore.addSelectedEntity({ name: row.name, pk: row.aon_entity_pk })

    if (props.isCreatePage) {
        eventBus.emit(`${props.screenerContextId}_entitiesSelected`, screenerStore.entitiesSelected)
    }
}
const setCheckboxSelections = () => {
    data.value.map((val) => {
        if (screenerStore.entitiesSelected.map((e) => e.pk).includes(val.aon_entity_pk)) {
            val.checked = true
        } else {
            val.checked = false
        }
        return val
    })
}
const filterAdded = (e) => {
    filterCount.value = e
}
const filterRemoved = (e) => {
    filterCount.value = e
}

const roundToHundredth = (num) => {
    return parseInt(num).toFixed(2)
}
</script>

<style scoped lang="scss">
.screener-table {
    width: 100%;
    overflow: hidden;
}
.dense-filters {
    :deep(.table-filters) {
        .half-row {
            width: 33%;
        }
    }
}
.extra-dense-filters {
    :deep(.table-filters) {
        .half-row {
            width: 25%;
        }
    }
}
:deep(.table-aips-latest) {
    height: 100%;

    .aips-table-wrap {
        padding-bottom: 100px;
    }
    .no-value {
        display: block !important;
    }

    .table-filters {
        .full-row {
            &:first-of-type {
                display: none;
            }
        }
    }

    .aips-table {
        th {
            box-shadow: none !important;
        }
    }

    .table-aips-headers {
        th {
            &:first-of-type {
                pointer-events: none;
                cursor: default !important;
            }
        }
    }

    .bulk-action-bar {
        margin-bottom: 20px;
    }
}

:deep(.a-modal) {
    .aon-container {
        .container {
            overflow-x: hidden;
        }
    }
}
</style>
