<template>
    <div class="screener-header knights-cloak-active mb-5">
        <AonContainer add-page-padding>
            <AonRow>
                <AonCol class="aon-col-12 screener-tabs__aon-col justify-content-between">
                    <div class="d-flex align-center">
                        <h5 class="grey08--text ma-0">
                            {{
                                screener.name === 'Aon Apex Competencies'
                                    ? 'Aon Apex Markets'
                                    : t('screeners.header.customScreener')
                            }}
                        </h5>
                        <div v-if="screenerModified" class="caption grey05--text ml-2 mt-1">
                            {{ t('screeners.header.modified') }}
                        </div>
                        <AonButton
                            class="ml-4"
                            darkMode
                            type="ghost"
                            :disabled="actionsDisabled"
                            :label="t('screeners.header.allScreenersLink')"
                            @clicked="openScreenerModal"
                        />
                    </div>
                    <div class="d-flex">
                        <AonButton
                            v-if="screenerModified && screener.customScreener"
                            class="ml-2"
                            darkMode
                            label="Save"
                            type="subtle"
                            width="50px"
                            :disabled="actionsDisabled"
                            :loading="saving"
                            @clicked="saveScreener"
                        />
                        <AonButton
                            class="ml-2"
                            darkMode
                            label="Save As"
                            type="subtle"
                            width="50px"
                            :disabled="actionsDisabled"
                            @clicked="openSaveAsModal"
                        />
                        <AonButton
                            v-if="screenerModified"
                            class="ml-2"
                            darkMode
                            label="Reset Screener"
                            type="subtle"
                            width="50px"
                            :disabled="actionsDisabled"
                            @clicked="resetScreener"
                        />
                    </div>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['openSaveAsModal', 'openScreenerModal', 'resetScreener', 'saveScreener'])

const props = defineProps({
    loading: {
        type: Boolean,
        required: true,
    },
    saving: {
        type: Boolean,
        required: true,
    },
    screener: {
        type: Object,
        required: true,
    },
    screenerModified: {
        type: Boolean,
        required: true,
    },
})

const { t } = useI18n()

const actionsDisabled = computed(() => {
    return props.loading || props.saving
})

const openSaveAsModal = (val) => {
    emit('openSaveAsModal', val)
}
const openScreenerModal = (val) => {
    emit('openScreenerModal', val)
}
const resetScreener = (val) => {
    emit('resetScreener', val)
}
const saveScreener = (val) => {
    emit('saveScreener', val)
}
</script>

<style lang="scss" scoped>
.screener-header {
    .a-button {
        &.dark-mode {
            color: $grey08;

            &:hover {
                color: $castleMoatHover;
            }
            &.subtle {
                &.disabled {
                    color: $grey06;
                    border: 1px solid $grey06;
                }
                &:hover {
                    text-decoration: none;
                    background-color: $grey08;
                }
            }
        }
    }
}
</style>
