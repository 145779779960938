export const ERROR = 'Error'
export const NOT_FOUND = 'Not Found'
export const COMPANY_SCREENER = 'Company Screener'
export const COMPANY_SEARCH = 'Company Search'

export default {
    ERROR,
    NOT_FOUND,
    COMPANY_SCREENER,
    COMPANY_SEARCH,
}
