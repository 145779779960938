import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
    state: () => ({
        showGlobalModal: false,
        showTOS: false,
        globalModalObj: null,
        pushFooter: false,
    }),
    actions: {
        async resetState() {
            this.$reset()
        },
        setShowGlobalModal(showMask) {
            this.showGlobalModal = showMask
        },
        setShowTOS(bool) {
            this.showTOS = bool
        },
        setGlobalModal(modalObj) {
            this.globalModalObj = modalObj
        },
    },
})
