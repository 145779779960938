<template>
    <VueFlow
        class="arena-canvas"
        :nodes="localNodes"
        :edges="props.boardData.edges"
        :nodes-draggable="false"
        @pane-ready="onPaneReady"
        @nodes-initialized="layoutGraph('LR')"
        @node-click="selectNode($event)"
        @pane-click="deleteSelectedNode()"
    >
        <template #node-custom="props">
            <ArenaBoardNode v-bind="props" />
        </template>

        <template #edge-custom="props">
            <ArenaBoardEdge v-bind="props" />
        </template>
        <MiniMap class="move-mini" pannable node-color="#999" />
    </VueFlow>
</template>

<script setup>
import { onMounted, ref, inject, watch } from 'vue'
import { useArenaStore } from '@/stores'

import { VueFlow } from '@vue-flow/core'
import { MiniMap } from '@vue-flow/minimap'
import '@vue-flow/minimap/dist/style.css'

import ArenaBoardNode from './ArenaBoardNode.vue'
import ArenaBoardEdge from './ArenaBoardEdge.vue'
import { dagreLayout } from './helpers/dagreLayout'

const { layout } = dagreLayout()
const arenaStore = useArenaStore()
const eventBus = inject('eventBus')

const arenaCanvasInstance = ref(null)

const props = defineProps({
    boardData: {
        type: Object,
        required: true,
    },
})

const localNodes = ref([...props.boardData.nodes])

watch(
    () => props.boardData,
    () => {
        if (!props.boardData || !props.boardData.nodes?.length) {
            localNodes.value = []
            return
        }

        localNodes.value = layout([...props.boardData.nodes], props.boardData.edges, 'LR')
    },
    { deep: true }
)

onMounted(() => {
    eventBus.on('node-added', (data) => {
        setTimeout(() => {
            panToNode(data.nodeId ? data.nodeId : data[0].id)
        }, 500)
    })
})

const onPaneReady = (vueFlowInstance) => {
    vueFlowInstance.fitView({ padding: 0.5, includeHiddenNodes: true })
    arenaCanvasInstance.value = vueFlowInstance
}

const layoutGraph = async (direction) => {
    props.boardData.nodes = layout(props.boardData.nodes, props.boardData.edges, direction)
}

const selectNode = (event) => {
    const targetNode = props.boardData.nodes.find((node) => node.id === event.node.id)
    arenaStore.selectedNode = targetNode

    let targetParent = props.boardData.nodes.find((node) => {
        return node.id === arenaStore.selectedNode.parent
    })

    if (targetParent) {
        arenaStore.selectedNodeParent = targetParent.data
    } else {
        arenaStore.selectedNodeParent = {
            id: arenaStore.boardData[0].rootNodeId,
            name: 'Arena Root',
            nodeType: 'concept',
        }
    }

    panToNode(targetNode.id)
}

const deleteSelectedNode = () => {
    if (arenaStore.selectedNode) {
        arenaStore.selectedNode = null
        arenaStore.selectedNodeParent = null
    }
}

const panToNode = (nodeId) => {
    const node = arenaCanvasInstance.value.getNode(nodeId)
    if (node) {
        arenaCanvasInstance.value.setCenter(node.position.x + 200, node.position.y, {
            zoom: 1,
            duration: 800,
        })
    }
}
</script>

<style>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';

.arena-canvas {
    width: 100%;
    height: 100%;
}

.vue-flow__edge-path {
    stroke-width: 2px;
}

.move-mini {
    position: absolute;
    left: 0;
    bottom: 10px;
    right: auto !important;
    z-index: 10;
}
</style>
