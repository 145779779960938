<template>
    <div class="related-concepts pt-5">
        <div class="header-holder px-5 d-flex justify-content-between align-items-center">
            <p class="castle-moat--text bold">Concepts</p>
            <p class="link small" @click="allSelect()">Select/Deselect All</p>
        </div>
        <p class="px-5 extra-small grey01--text my-2 micro">
            These concepts relate to {{ arenaStore.selectedNode.data.name }}
        </p>
        <div class="concept-holder pl-5">
            <div class="spinner text-align-center mt-16" v-if="requestRelatedLoading">
                <p class="grey01--text mb-10 bold">Finding Related Concepts</p>
                <AonSpinner class="ml-auto mr-auto" :scale="0.3" />
            </div>
            <div v-else class="all-concepts">
                <div class="overflow-wrap">
                    <div v-for="concept in arenaStore.selectedNodeChildren" class="concept">
                        <AonCheckbox
                            :is-checked="concept.include"
                            class="mt-4 w-full"
                            :class="{ disabled: concept.disabled }"
                            :label="`${concept.displayName}`"
                            @clicked="updateSelections(concept)"
                            @mouseover="debouncedTooltipHandler(concept)"
                            v-tooltip="{ content: getTooltipText(concept) }"
                        />
                    </div>
                </div>
            </div>
            <div class="add-background text-align-center pt-16" v-if="updateNodesLoading">
                <p class="grey01--text mb-10 bold">Assigning Node</p>
                <AonSpinner class="ml-auto mr-auto" :scale="0.3" />
            </div>
        </div>
        <div class="action-holder px-3 py-3 white">
            <AonButton
                v-if="!requestRelatedLoading"
                :class="{ disabled: nodesToUpdateList.length <= 0 }"
                :label="`Add/Remove Elements`"
                width="100%"
                @clicked="updateSelectedNodes"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, inject, computed, onBeforeUnmount } from 'vue'
import { pollForChildNodes, updateNodes } from '@/api/arena.js'
import { useArenaStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { elementaryDescription } from '@/api/llm.js'
import { debounce } from 'lodash-es'

const arenaStore = useArenaStore()
const { t } = useI18n()
const eventBus = inject('eventBus')
const logger = inject('logger')

const error = ref(null)
const requestRelatedLoading = ref(false)
const updateNodesLoading = ref(false)
const nodesToUpdateList = ref([])
const selectAll = ref(false)
const interval = ref(null)

onBeforeUnmount(() => {
    clearInterval(interval.data)
})

onMounted(() => {
    pollForRelatedConcepts()
    interval.data = setInterval(async () => {
        pollForRelatedConcepts()
    }, 5000)
})

const getTooltipText = (concept) => {
    if (concept && concept.tooltipText) {
        return concept.tooltipText
    }
    return t('llmApi.loadingDescription')
}

const tooltipHandler = function (concept) {
    if (concept.tooltipText) {
        return concept.tooltipText
    }

    let concepts = [concept.displayName]
    if (
        arenaStore.selectedNode.type !== 'company' &&
        arenaStore.selectedNode.data &&
        arenaStore.selectedNode.data.nodeType !== 'company'
    ) {
        concepts.unshift(arenaStore.selectedNode.displayName ?? arenaStore.selectedNode.data.name)
    }

    return elementaryDescription(concepts)
        .then((res) => {
            concept.tooltipText = res.data
        })
        .catch((err) => {
            concept.tooltipText = t('llmApi.noDescription')
        })
}

const debouncedTooltipHandler = debounce(tooltipHandler, 200)

const finalToUpdateCount = computed(() => {
    return nodesToUpdateList.value?.length
})

const pollForRelatedConcepts = async () => {
    requestRelatedLoading.value = true
    await pollForChildNodes(
        arenaStore.selectedNode.id,
        (data) => {
            // succes
            clearInterval(interval.data)
            arenaStore.selectedNodeChildren = data.childNodes.sort((a, b) => {
                return b.include - a.include
            })

            let allInlcuded = arenaStore.selectedNodeChildren.filter((item) => item.include)
            if (allInlcuded.length === arenaStore.selectedNodeChildren.length) {
                selectAll.value = true
            }

            requestRelatedLoading.value = false
        },
        (err) => {
            // failure
            handleError(t('productAlignment.error.title'))
            error.value = true
            clearInterval(interval.data)
        }
    )
}

const updateSelections = (concept, skipToggle = false) => {
    if (!skipToggle) {
        concept.include = !concept.include
    }
    const existingIndex = nodesToUpdateList.value.findIndex((c) => c.id === concept.id)
    if (existingIndex !== -1) {
        nodesToUpdateList.value.splice(existingIndex, 1, concept)
    } else {
        nodesToUpdateList.value.push(concept)
    }
}

const allSelect = () => {
    selectAll.value = !selectAll.value

    arenaStore.selectedNodeChildren.forEach((stateConcept) => {
        stateConcept.include = selectAll.value
        updateSelections(stateConcept, true)
    })
}

const updateSelectedNodes = async (passed) => {
    arenaStore.arenaBoardLoading = true
    updateNodesLoading.value = true

    let bodyObj = {
        nodes: nodesToUpdateList.value.map((concept) => {
            return {
                nodeId: concept.id,
                include: concept.include,
                displayName: concept.displayName,
            }
        }),
        excludeCascade: true,
    }

    try {
        await updateNodes(bodyObj)
        eventBus.emit('node-added', { nodeId: arenaStore.selectedNode.id })
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    }
    nodesToUpdateList.value = []
    updateNodesLoading.value = false
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}
</script>

<style lang="scss" scoped>
.related-concepts {
    $addOffset: 64px;
    $addOffsetTop: 58px; //offset of header
    $conceptHolderGradHeight: 10px;

    width: 100%;
    height: 100%;
    position: relative;

    .micro {
        max-height: 32px;
    }

    .concept-holder {
        position: relative;
        // height: 100%;
        max-height: calc(100% - $addOffset - $addOffsetTop);
        padding: $conceptHolderGradHeight;
        width: 100%;
        overflow: auto;

        .overflow-wrap {
            max-height: 90%;
        }

        .all-concepts {
            width: 100%;
            height: 100%;
        }

        .concept {
            &:first-of-type {
                :deep(.aon-checkbox) {
                    margin: 0 !important;
                }
            }
        }
    }

    .add-background {
        width: 100%;
        height: 110%;
        background: rgba(255, 255, 255, 0.8);

        position: absolute;
        top: 0;
        left: 0;
    }

    .action-holder {
        border-top: 1px solid $grey04;
    }
}
</style>
