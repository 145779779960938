<template>
    <div id="arena-config-modal">
        <AonModal title="Arena Configuration" message="" col-width="8" @close="$emit('close')">
            <div class="modal-content">
                <AonTabs
                    class="pt-2 pl-4 pr-4 knights-cloak-80"
                    :current-tab="currentTab"
                    :tabs="tabData"
                    @tab-change="(val) => (currentTab = val.id)"
                />
                <form @submit.prevent="updateArena()">
                    <div class="form-content d-flex w-full">
                        <template v-if="currentTab === 1">
                            <AonTextarea
                                v-model="form.includeKeywords"
                                name="includeKeywords"
                                :label="t('arenas.config.keywordFilters.includeKeywords.label')"
                                :description="
                                    t('arenas.config.keywordFilters.includeKeywords.desc')
                                "
                                id="includeKeywords"
                                :placeholder="
                                    t('arenas.config.keywordFilters.includeKeywords.placeholder')
                                "
                                focus
                            />
                            <AonTextarea
                                v-model="form.excludeKeywords"
                                name="excludeKeywords"
                                :label="t('arenas.config.keywordFilters.excludeKeywords.label')"
                                :description="
                                    t('arenas.config.keywordFilters.excludeKeywords.desc')
                                "
                                id="excludeKeywords"
                                :placeholder="
                                    t('arenas.config.keywordFilters.excludeKeywords.placeholder')
                                "
                            />
                            <div></div>
                        </template>
                        <template v-if="currentTab === 2">
                            <AonButton
                                label="Use Defaults"
                                type="subtle"
                                @clicked="applyDefaultPromptConfig"
                            />
                            <AonTextarea
                                v-model="form.instruction"
                                name="instruction"
                                label="Instruction"
                                id="instruction"
                                :placeholder="defaultTechSummaryPrompt.instruction"
                                focus
                                :error="!!v$.instruction.$errors.length"
                                :error-options="{
                                    errorMsg: v$.instruction.$errors[0]
                                        ? v$.instruction.$errors[0].$message
                                        : null,
                                }"
                            />
                            <AonTextarea
                                v-model="form.prompt"
                                name="prompt"
                                label="Prompt"
                                id="prompt"
                                :placeholder="defaultTechSummaryPrompt.prompt"
                                :error="!!v$.prompt.$errors.length"
                                :error-options="{
                                    errorMsg: v$.prompt.$errors[0]
                                        ? v$.prompt.$errors[0].$message
                                        : null,
                                }"
                            />
                            <AonInput
                                v-model="form.similarityThreshold"
                                name="similarityThreshold"
                                label="Similarity Threshold"
                                id="similarityThreshold"
                                :placeholder="defaultTechSummaryPrompt.similarityThreshold"
                                :error="!!v$.similarityThreshold.$errors.length"
                                :error-options="{
                                    errorMsg: v$.similarityThreshold.$errors[0]
                                        ? v$.similarityThreshold.$errors[0].$message
                                        : null,
                                }"
                            />
                        </template>
                    </div>
                    <div class="button-group d-flex justify-content-start">
                        <AonButton
                            class="mt-4"
                            label="Save Configuration"
                            :loading="arenaUpdateLoading"
                            is-form-submit
                        />
                        <AonButton
                            class="mt-4"
                            label="Cancel"
                            type="subtle"
                            :disabled="arenaUpdateLoading"
                            @clicked="emit('close')"
                        />
                    </div>
                </form>
            </div>
        </AonModal>
    </div>
</template>

<script setup>
import { inject, ref, reactive, computed, watch } from 'vue'
import { patchArena } from '@/api/arena.js'
import { useArenaStore } from '@/stores'
import { useVuelidate } from '@vuelidate/core'
import { AonTextarea } from '@moatmetrics/armory/src/components'
import { useFlag } from '@unleash/proxy-client-vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const emit = defineEmits(['close'])
const eventBus = inject('eventBus')
const logger = inject('logger')
const arenaStore = useArenaStore()

const unleashPromptConfig = useFlag('ipAlpha.promptConfig')
const arenaPatentKeywordsFlag = useFlag('ipAlpha.arenaPatentKeywords')

const props = defineProps({
    config: {
        type: Object,
        default: {},
    },
})

const error = ref(null)
const arenaUpdateLoading = ref(false)
const tabData = computed(() => {
    let tabs = []

    if (arenaPatentKeywordsFlag.value) {
        tabs.push({
            text: 'Patent Keywords',
            id: 1,
        })
    }

    if (unleashPromptConfig.value) {
        tabs.push({
            text: 'Tech Summary Prompt',
            id: 2,
        })
    }

    return tabs
})

const currentTab = ref(tabData.value[0].id)

watch(arenaPatentKeywordsFlag, (val) => {
    if (!val) {
        onTabRemoved(1)
    }
})

watch(unleashPromptConfig, (val) => {
    if (!val) {
        onTabRemoved(2)
    }
})

const onTabRemoved = (tabId) => {
    if (currentTab.value === tabId && tabData.value.length >= 1) {
        currentTab.value = tabData.value[0].id
    } else {
        emit('close')
    }
}

const defaultTechSummaryPrompt = ref({
    prompt: 'Patentable technology: <leafConcept>',
    instruction:
        'You will be provided with a patentable technology, create a description of the technology.\n' +
        'Please follow this list of rules for the description:\n' +
        "- In two sentences, concisely describe the technology in the context of <entityName>'s <concepts>.\n" +
        '- In another two sentences explain how the technology works in detail.\n' +
        '- Briefly describe the applications of the technology in an additional sentence.\n' +
        '- Do not use the company name or any company-specific or trademarked language.\n' +
        '- Do not use conversational language.\n' +
        "- If you aren't familiar with the technology from <entityName>, return an empty string.",
    similarityThreshold: 0.8,
})

const form = reactive({
    prompt: props.config?.promptConfig?.technicalSummary?.prompt,
    instruction: props.config?.promptConfig?.technicalSummary?.instruction,
    similarityThreshold: props.config?.promptConfig?.technicalSummary?.similarityThreshold,
    includeKeywords: props.config?.patentFilters?.includeKeywords?.join(','),
    excludeKeywords: props.config?.patentFilters?.excludeKeywords?.join(','),
})
const rules = {
    prompt: { required: false },
    instruction: { required: false },
    similarityThreshold: { required: false },
    includeKeywords: { required: false },
    excludeKeywords: { required: false },
}
const v$ = useVuelidate(rules, form)

const applyDefaultPromptConfig = () => {
    form.prompt = defaultTechSummaryPrompt.value.prompt
    form.instruction = defaultTechSummaryPrompt.value.instruction
    form.similarityThreshold = defaultTechSummaryPrompt.value.similarityThreshold
}

const updateArena = async () => {
    arenaUpdateLoading.value = true

    const result = await v$.value.$validate()

    if (!result) {
        arenaUpdateLoading.value = false
        return
    }

    const body = {
        config: {
            promptConfig: {
                technicalSummary: {
                    prompt: form.prompt || null,
                    instruction: form.instruction || null,
                    similarityThreshold: form.similarityThreshold || null,
                },
            },
            patentFilters: {
                includeKeywords: form.includeKeywords ? form.includeKeywords.split(',') : null,
                excludeKeywords: form.excludeKeywords ? form.excludeKeywords.split(',') : null,
            },
        },
    }
    try {
        const productAlignmentPk = props.productAlignmentPkOverwrite
            ? props.productAlignmentPkOverwrite
            : arenaStore.boardGlobalDetails.productAlignmentId
        const { data } = await patchArena(productAlignmentPk, body)
        if (arenaStore.boardGlobalDetails) {
            arenaStore.boardGlobalDetails.config = data.config
        }
    } catch (err) {
        handleError(err)
    } finally {
        arenaUpdateLoading.value = false
        emit('close')
    }
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}
</script>

<style lang="scss" scoped>
#arena-config-modal {
    padding: 0;

    :deep(.a-modal-close) {
        display: none;
    }

    .form-content {
        padding: 20px;
        flex-direction: column;
        border: 1px solid $knightsCloak80;
        overflow: auto;
        height: 400px;

        > div {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .button-group {
        > div {
            margin-right: 10px;
        }
    }
}
</style>
