import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useMoat2ProductStore = defineStore('moat2Product', () => {
    const initLoad = ref(false)
    const productAlignmentLoading = ref(true)
    const productAlignmentActiveTab = ref(1)
    const alignmentPk = ref(null)
    const productAlignmentProducts = ref([])
    const productAlignmentAllData = ref([])
    const productAlignmentTechAreaNodes = ref(new Set())
    const productAlignmentTechAreaSelections = ref(new Set())
    const productAlignmentTechAreaSelectionsOld = ref(new Set())
    const productAlignmentTopCompetitors = ref(null)
    const allCompetitorData = ref([])
    const showPACarousel = ref(true)
    const carouselSlides = ref([
        {
            title: 'Product Segmentation Overview',
            copy: "Enables a deep dive into a company's innovation by mapping patents to product classes and technology areas, enabling benchmarking against competitors. With customizable analysis and visual tools, it directly highlights competitive dynamics, offering strategic insights into technological advancements and market positioning.",
            imageOptions: {
                imagePath: '/src/assets/slide1.jpg',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            },
        },
        {
            title: 'Technology Areas',
            copy: "Utilize the checkboxes within the Technology Areas table to select specific Product Classes and Technology Areas, setting the stage for a tailored analysis of the competitive landscape. After making your selections, it's essential to regenerate the analytics to ensure the insights reflect the latest data and your chosen parameters. This recalibration process allows for an in-depth exploration of innovation trends and competitor positioning within targeted sectors, empowering you with refined data to inform strategic decision-making.",
            imageOptions: {
                imagePath: '/src/assets/slide2.jpg',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            },
        },
        {
            title: 'Patent Count Over Time',
            copy: "The violin plot provides a visual comparison of patent filing volumes per year for your target entity against top peers, illustrating the distribution and density of filings over time. This is one of three interactive charts available, including a streamgraph and a 100% stacked area chart, each designed to showcase the same information in unique formats. By cycling through the violin, streamgraph, and stacked area charts, users can choose their preferred visualization method to analyze trends in patent activity, gaining comprehensive insights into how a target entity's patent filings compare with those of its leading competitors over time.",
            imageOptions: {
                imagePath: '/src/assets/slide3.jpg',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            },
        },
        {
            title: 'Evolution of Competitive Landscape',
            copy: 'The Magic Quadrant chart visualizes the competitive landscape’s evolution, with the x-axis representing filing recency and the y-axis showcasing patent volume. The size of each bubble indicates a company\'s ability to execute. Press "Play" to animate the landscape through the years, observing how companies adapt and shift in response to market demands and innovation trends. Click on any Company Node to follow its specific path over time, providing a detailed view of its strategic movements and execution capability. This interactive tool is essential for understanding competitive dynamics and identifying leaders and challengers.',
            imageOptions: {
                imagePath: '/src/assets/slide4.jpg',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            },
        },
        {
            title: 'Strategic Company Focus',
            copy: 'In the analysis, companies are sorted by their patent count, with the target company and the top 7 companies by default pinned at the top for enhanced visibility. You have the flexibility to pin additional companies of interest, enabling a tailored view of the competitive landscape. After adjusting your selections, remember to regenerate the analytics to accurately reflect these changes in the charts. This functionality allows for a customized analysis, ensuring that your strategic insights are focused on entities most relevant to your investigation.',
            imageOptions: {
                imagePath: '/src/assets/slide5.jpg',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            },
        },
    ])

    const techAreaIdCollection = computed(() => {
        let filteredProductsByNodeIds = [...productAlignmentTechAreaSelections.value].filter(
            (item) =>
                !productAlignmentProducts.value.some((product) => product.nodeId === item.nodeId)
        )
        return filteredProductsByNodeIds.map((item) => item.nodeId)
    })

    const topCompetitorCollection = computed(() => {
        let mappedCompetitors = productAlignmentTopCompetitors.value?.map(
            (competitor) => competitor.aonEntityPk
        )

        return mappedCompetitors
    })

    const resetState = async () => {
        initLoad.value = false
        productAlignmentLoading.value = true
        productAlignmentActiveTab.value = 1
        alignmentPk.value = null
        productAlignmentProducts.value = []
        productAlignmentAllData.value = []
        productAlignmentTechAreaNodes.value = new Set()
        productAlignmentTechAreaSelections.value = new Set()
        productAlignmentTechAreaSelectionsOld.value = new Set()
        productAlignmentTopCompetitors.value = null
        allCompetitorData.value = []
        showPACarousel.value = true
    }

    return {
        initLoad,
        productAlignmentLoading,
        productAlignmentActiveTab,
        alignmentPk,
        productAlignmentProducts,
        productAlignmentAllData,
        productAlignmentTechAreaNodes,
        productAlignmentTechAreaSelections,
        productAlignmentTechAreaSelectionsOld,
        productAlignmentTopCompetitors,
        allCompetitorData,
        showPACarousel,
        carouselSlides,
        techAreaIdCollection,
        topCompetitorCollection,
        resetState,
    }
})
