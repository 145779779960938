<template>
    <div class="industry-litigation-table">
        <MoatTable
            v-if="axisRange.length > 0"
            class="w-full h-full"
            :key="`tech_areas_${tableUpdater}`"
            :class="themeClass"
            :column-defs="colDefs"
            :tooltip-interaction="true"
            :tooltip-mouse-track="true"
            :tooltip-show-delay="500"
            tooltip-show-mode="whenTruncated"
            :autoGroupColumnDef="autoGroupColumnDef"
            :groupRemoveLowestSingleChildren="true"
            :groupDisplayType="groupDisplayType"
            :treeData="true"
            :getDataPath="getDataPath"
            :autoSizeStrategy="sizingStrategy"
            :row-data="props.tableData"
            @grid-ready="gridInit"
            @first-data-rendered="getMaxCount"
            @cell-clicked="onCellClicked"
        />
    </div>
</template>

<script setup>
import { ref, inject, onBeforeMount, nextTick } from 'vue'
import { useEntityStore } from '@/stores'
import { useI18n } from 'vue-i18n'

import { useFlag } from '@unleash/proxy-client-vue'
const allowDrillIn = useFlag('ipAlph.drillIn')

// Moat Table specific
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'

// injectors
const filters = inject('filters')
const { t } = useI18n()

const entityStore = useEntityStore()

const props = defineProps({
    tableData: {
        type: Array,
        default: () => [],
    },
})

const gridApi = ref(null)
const tableUpdater = ref(0)
const getDataPath = ref(null)
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 2000,
})
const axisRange = ref([0, 10])
const colDefs = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.riskScore'),
            headerName: 'Risk Score',
        },
        field: 'riskScore',
        headerTooltip: 'Risk Score',
        flex: 1,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.caseCountbyYear'),
            headerName: 'Case Count By Year',
        },
        field: 'caseCountHistory',
        headerTooltip: 'Case Count By Year',
        flex: 1,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
            sparklineOptions: {
                type: 'column',
                xKey: 'year',
                yKey: 'caseCount',
                fill: '#2A79D2',
                valueAxisDomain: axisRange.value,
                axis: { type: 'number' },
                tooltip: {
                    renderer: (params) => {
                        const { x, y } = params.datum
                        return {
                            title: `${x}:`,
                            content: y,
                        }
                    },
                },
            },
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.caseCount'),
            headerName: 'Case Count',
        },
        field: 'caseCount',
        headerTooltip: 'Case Count',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.caseCount.toLocaleString()
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.avgCaseLength'),
            headerName: 'Average Case Length',
        },
        field: 'avgCaseLength',
        headerTooltip: 'Average Case Length',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.avgCaseLength.toLocaleString()
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.patentsAsserted'),
            headerName: 'Patents Asserted',
        },
        field: 'patentCount',
        headerTooltip: 'Patents Asserted',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.patentCount.toLocaleString()
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.knownDamages'),
            headerName: 'Known Damages',
        },
        field: 'totalDamages',
        headerTooltip: 'Known Damages',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.totalDamages)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.highestKnownDamages'),
            headerName: 'Highest Known Damages',
        },
        field: 'maxIndividualDamages',
        headerTooltip: 'Highest Known Damages',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.maxIndividualDamages)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.npeCases'),
            headerName: 'NPE Cases',
        },
        field: 'npeCaseCount',
        headerTooltip: 'NPE Cases',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.npeCaseCount.toLocaleString()
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('vulnerabilities.tooltips.npeEntities'),
            headerName: 'NPE Entities',
        },
        field: 'npeEntityCount',
        headerTooltip: 'NPE Entities',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.npeEntityCount.toLocaleString()
            }
        },
    },
])
const autoGroupColumnDef = ref(null)
const groupDisplayType = ref(null)
const themeClass = ref('ag-theme-quartz')

onBeforeMount(() => {
    defineTableGroups()
    getDataPath.value = (data) => {
        return data.nodeHierarchy.map((item) => item)
    }
    groupDisplayType.value = 'singleColumn'
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const getMaxCount = () => {
    const max = Math.max(...props.tableData.map((item) => item.caseCount))
    axisRange.value = [0, max]
    gridApi.value.setGridOption('columnDefs', colDefs.value)
}

const onCellClicked = async (params) => {
    if (!allowDrillIn.value || params.data.nodeType === 'company') {
        return
    }
    entityStore.drillInTargetId = params.data.nodeId
    await nextTick()
    entityStore.showDrillIn = true
}

const defineTableGroups = () => {
    autoGroupColumnDef.value = {
        width: 450,
        field: 'displayName',
        tooltipField: 'displayName',
        suppressMovable: true,
        lockPosition: 'left',
        headerName: 'Product',
        cellClass: (params) => {
            if (params.data) {
                let allowConditions =
                    allowDrillIn.value &&
                    params.value !== entityStore.entity?.name &&
                    params.data.nodeType !== 'company'

                return allowConditions ? 'cell-link' : ''
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.industry-litigation-table {
    width: 100%;
    height: 100%;
    position: relative;
}
</style>
