import axios from 'axios'

const baseUrl = 'api/entities'
const prodAlignmentUrl = 'api/productalignment'

export function getLitigationHistorySummary(entityId) {
    return axios.get(`${baseUrl}/${entityId}/litigation/summary `)
}

export function getLitigationRisk(pk) {
    return axios.get(`${prodAlignmentUrl}/${pk}/litigation_risk`)
}
