import axios from 'axios'

const baseUrl = 'api/screeners'

export async function getScreeners(userId) {
    return axios.get(`${baseUrl}/user/${userId}`)
}

export async function getScreener(screenerId) {
    return axios.get(`${baseUrl}/${screenerId}`)
}

export async function updateScreener(screener) {
    return axios.put(`${baseUrl}`, screener)
}

export async function deleteScreener(screenerId) {
    return axios.put(`${baseUrl}/delete/${screenerId}`)
}

export async function createScreener(screener) {
    return axios.post(`${baseUrl}`, screener)
}
