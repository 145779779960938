import { cloneDeep, isNull, isString, isUndefined, remove } from 'lodash-es'
import { formatPrecision, zScore } from './filters'

export function addChildrenToTree(tree, pathIds, children) {
    if (!tree || !pathIds || !children || !pathIds.length || !children.length) {
        throw new Error('Unable to add children to tree')
    }

    let node = tree

    pathIds.forEach((id) => {
        if (!node || !node.markets) {
            return
        }

        node = node.markets.find((n) => n.sectorId === id)
    })

    const lastParent = pathIds[pathIds.length - 1]

    if (node.sectorId !== lastParent) {
        throw new Error(`Parent market not found ${lastParent}`)
    }
    node['markets'] = children

    return cloneDeep(tree)
}

export function getParentPathIds(sectorPath) {
    const sectorPathIds = sectorPath.map((s) => s.sectorId)

    sectorPathIds.shift()

    return sectorPathIds
}

export function objectArrayToCSV(keys, source, date_format_overrides = []) {
    //keys are properties of each object contained within the source[] of objects that we need to include in our csv
    let csv = ''

    source.forEach((data) => {
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]

            if (key.includes('.keyword')) {
                key = key.replace('.keyword', '')
            }

            let field = data[key]

            //convert scientific notation to number
            if (isString(field) && parseInt(field) && field.includes('E+')) {
                field = Number(field)
            }

            if (isNull(field) || isUndefined(field) || field === '--' || field === '-') {
                field = ''
            }

            if (
                !isNull(field) &&
                !isUndefined(field) &&
                field !== '' &&
                date_format_overrides.some((d) => d === key)
            ) {
                // prevents accidental date formatting
                csv += '='
            }

            if (i !== keys.length - 1) {
                csv += `"${field}",`
            } else {
                csv += `"${field}"`
            }
        }

        csv += '\n'
    })

    return csv
}

export function decimalToPercent(decimalValue, numDecimalPlaces) {
    const indexOfDecimal = `${decimalValue}`.indexOf('.')

    return parseFloat(
        `${decimalValue}`.substring(
            0,
            indexOfDecimal + (numDecimalPlaces > 0 ? 1 + numDecimalPlaces : 0)
        )
    )
}

export function getLastFiveYears(includeCurrent = false) {
    const offset = includeCurrent ? 5 : 6
    const startYear = new Date().getFullYear() - offset

    return Array.from({ length: 5 }, (_, i) => startYear + i + 1)
}

export default function abbreviateNumber(number, options = {}) {
    const isNegative = number < 0
    const decimalPlaces = Math.pow(10, options.decimalPlaces || 0)
    const units = options.units || ['K', 'M', 'B', 'T']

    let abbreviatedNumber = Math.abs(number)

    for (let i = units.length - 1; i >= 0; i--) {
        const size = Math.pow(10, (i + 1) * 3)

        if (size <= abbreviatedNumber) {
            abbreviatedNumber =
                Math.round((abbreviatedNumber * decimalPlaces) / size) / decimalPlaces

            if (abbreviateNumber === 1000 && i < units.length - 1) {
                abbreviatedNumber = 1
                i++
            }

            abbreviatedNumber += units[i]

            break
        }
    }

    return isNegative ? '-' + abbreviatedNumber : abbreviatedNumber
}

export function isEmptyObject(object, ignore = []) {
    return Object.entries(object).every(([key, value]) => {
        return ignore.includes(key) || value === null || value === ''
    })
}

export function removeEmptyObjectsFromArray(array, ignore) {
    remove(array, (object) => isEmptyObject(object, ignore))
}

export function isTrueObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]'
}
export const formatFilterType = (type) => {
    const normalizedType = type.toLowerCase()
    const typeMap = {
        dt_range: 'date range',
        eq: 'equals',
        gt: 'greater than',
        lt: 'less than',
        range: 'range',
        complex_start: 'starts with',
        complex_text: 'text contains',
        complex_word: 'word match',
        empty: 'empty',
    }

    const typeString = typeMap[normalizedType]

    if (typeString) return typeString

    throw new Error(`No type mapped for '${type}'`)
}

export const globalFilterToScreenerFilterString = (params, defaultSort) => {
    let screener = { filters: [], sorting: [], showScreener: true }
    if (params.FC && params.FC.length) {
        screener.filters = params.FC.map((filter, i) => {
            const screenerFilter = {
                filterColumn: filter,
                filterType: params.FT[i],
                filterValue: String(params.FV[i]),
            }
            if (params.FD) {
                screenerFilter.filterDisplay = params.FD[i]
            }
            return screenerFilter
        })
    }
    if (params.SC && params.SC.length) {
        screener.sorting = params.SC.map((sorting, i) => {
            return {
                sortColumn: sorting,
                sortDirection: params.SD[i],
                sortPriority: params.SP[i],
            }
        })
    } else {
        screener.sorting = defaultSort
    }

    return btoa(JSON.stringify(screener))
}

export const hexToRGB = (hex) => {
    return {
        r: parseInt(hex.substring(1, 3), 16),
        g: parseInt(hex.substring(3, 5), 16),
        b: parseInt(hex.substring(5), 16),
    }
}

export const sectorPathText = (sectorPathArr, sectorNameKey = 'name') => {
    if (!JSON.parse(sectorPathArr)?.length) {
        return ''
    }
    return JSON.parse(sectorPathArr)
        .map((p) => p[sectorNameKey])
        .join(' > ')
}

export const scoreChange = (year, priorYearScore, score) => {
    if (!priorYearScore) {
        return '--'
    }
    const change = ((score - priorYearScore) / Math.abs(priorYearScore)) * 100
    const prefix = change < 0 ? '⬇' : '⬆'

    return ` ${prefix} ${formatPrecision(change)}% from ${year - 1} (${zScore(priorYearScore)})`
}

export const insertNewlines = (str, every) => {
    let result = ''
    let lineLength = 0
    const words = str.split(' ')

    words.forEach((word) => {
        if (lineLength + word.length + 1 > every) {
            result += '\n'
            lineLength = 0
        }
        result += (lineLength === 0 ? '' : ' ') + word
        lineLength += word.length + 1
    })

    return result
}

// POC FORM HOMEPAGE TEXTAREA ONLY
export var typedPlaceholder = function (options) {
    this.placeholderIdx = 0
    this.charIdx = 0
    var placeholder

    this.setPlaceholder = function () {
        placeholder = options.placeholders[this.placeholderIdx]
        var placeholderChunk = placeholder.substring(0, this.charIdx + 1)
        let focusEl = document.querySelector(options.element)

        if (options.static) {
        }

        const textarea = focusEl?.querySelector('.input-wrapper textarea')

        if (textarea) {
            if (options.static) {
                textarea.placeholder = `${options.preText} “Rocket powered backpack in personal aviation”`
            } else {
                textarea.placeholder = `${options.preText} ${placeholderChunk}`
            }
        } else {
            this.kill()
        }
    }

    this.onTickReverse = function (afterReverse) {
        if (this.charIdx === 0) {
            afterReverse.bind(this)()
            clearInterval(this.intervalId)
            this.init()
        } else {
            this.setPlaceholder()
            this.charIdx--
        }
    }

    this.goReverse = function () {
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
            this.onTickReverse.bind(this, function () {
                this.charIdx = 0
                this.placeholderIdx++
                if (this.placeholderIdx === options.placeholders.length) {
                    // end of all placeholders reached
                    this.placeholderIdx = 0
                }
            }),
            options.speed
        )
    }

    this.onTick = function () {
        placeholder = options.placeholders[this.placeholderIdx]
        if (this.charIdx === placeholder.length) {
            // end of a placeholder sentence reached
            setTimeout(this.goReverse.bind(this), options.stay)
        }

        this.setPlaceholder()

        this.charIdx++
    }

    this.init = function () {
        this.intervalId = setInterval(this.onTick.bind(this), options.speed)
    }

    this.kill = function () {
        clearInterval(this.intervalId)
        return
    }
}
