<template>
    <div class="prompt-history-table">
        <!-- <AonCoverLoading :loading="props.loading" title="Populating Competitors" /> -->
        <TableAips
            class="table-border company-space-table"
            table-height="500px"
            table-style="fixed-scrolling"
            table-density="compact"
            disable-filters
            :sort-columns="false"
            sticky-header
            table-name="hisdafjb"
            :columns="promptColumns"
            :data="tableData"
            :loading-data="loadingPrompts"
            :page-size="10"
            :table-collapse="false"
            :use-page-data="false"
            :default-filter="defaultFilter"
            :default-sort="defaultSort"
            @load-data="loadTableData"
        >
            <template #searchText="{ data }">
                <div class="company-name-holder d-flex align-center">
                    <p
                        class="small link overflow-ellipsis"
                        @click="goToOutput(data.productAlignmentId)"
                    >
                        {{ data.searchText }}
                    </p>
                </div>
            </template>
            <template #createdDt="{ data }">
                <p class="small">{{ $filters.toUTCString(data.createdDt) }}</p>
            </template>
            <template #alignmentPk="{ data }">
                <div
                    class="link-holder d-flex justify-content-center"
                    @click="goToOutput(data.productAlignmentId)"
                >
                    <p class="link mr-2">View Prompt Results</p>
                    <font-awesome-icon
                        icon="fas fa-circle-arrow-right"
                        class="knights-cloak--text align-self-center"
                        size="lg"
                    />
                </div>
            </template>
        </TableAips>
    </div>
</template>

<script setup>
// Global
import { onMounted, ref, inject, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { getJobRuns } from '@/api/productAlignment.js'
import { useRouter } from 'vue-router'
// injectors
const eventBus = inject('eventBus')
const { t } = useI18n()
const logger = inject('logger')
const router = useRouter()

const loadingPrompts = ref(false)
const tableData = ref([])
const allData = ref(0)
const promptColumns = ref([
    {
        value: 'searchText',
        locale: 'Concept Prompt',
        filterType: 'string',
        showColumn: true,
        useSlot: true,
    },
    {
        value: 'createdDt',
        locale: 'Date Generated',
        filterType: 'date',
        showColumn: true,
        width: '250px',
        disableTooltip: true,
        useSlot: true,
    },
    {
        value: 'alignmentPk',
        locale: '',
        showColumn: true,
        useSlot: true,
        alignColumn: 'center',
        width: '250px',
    },
])
const defaultFilter = ref([
    {
        filterColumn: 'product_alignment_type',
        filterValue: 'conceptSearch',
        filterType: 'Equals',
        filterLabel: 'Concepts',
    },
])
const defaultSort = ref([
    {
        sortColumn: 'created_dt',
        sortDirection: -1,
        sortPriority: 1,
    },
])

const loadTableData = ({ params, paramsChanged }) => {
    if (paramsChanged) {
        allData.value = 0
        tableData.value = []
    }

    getPrompts(params)
}

const getPrompts = async (params) => {
    loadingPrompts.value = true
    try {
        let { data } = await getJobRuns(params)
        tableData.value = [...tableData.value, ...data.results]
    } catch (err) {
        // TODO why not use the Global SnackBar here? The AonAlertBox is displayed underneath the table??
        errorMessage.value = err.message
        showAlert.value = true
    } finally {
        loadingPrompts.value = false
    }
}

const goToOutput = (id) => {
    router.push({ name: 'Tailored Output', params: { jobPk: id } })
}
</script>

<style lang="scss" scoped>
.prompt-history-table {
    width: 100%;
    min-height: 400px;
    position: relative;

    :deep(.table-aips-latest) {
        .aips-table-wrap {
            border: none !important;
            padding-bottom: 100px;
        }
        .table-header,
        .table-filters-container {
            display: none;
        }

        th {
            background: white !important;
            &:first-of-type {
                border-radius: 8px 0 0 0;
            }

            &:last-of-type {
                border-radius: 0 8px 0 0;
            }
        }
    }
}
</style>
