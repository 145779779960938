<template>
    <div class="world-view-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-5">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        title="World View"
                        desc="Explore the global footprint of the company's patent filings, including applications, grants, and asset inventories."
                    >
                        <div class="card-content">
                            <AonSpinner
                                v-if="entityStore.loadingEntity"
                                class="ma-auto"
                                :scale="0.5"
                                color="castleMoat"
                            />
                            <PatentCharacteristicsWorld v-else />
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { useEntityStore } from '@/stores'

import PatentCharacteristicsWorld from './worldView/PatentCharacteristicsWorld.vue'

const entityStore = useEntityStore()
</script>

<style lang="scss" scoped>
.world-view-card {
    width: 100%;
    position: relative;
}
</style>
