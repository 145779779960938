<template>
    <div class="company-market-summary">
        <AonDrawer
            class="a-drawer-right"
            width="500px"
            right
            :show-close="false"
            :drawer-open="drawerOpen"
            @input="drawerOpen = false"
        >
            <div class="content-holder">
                <div v-if="loading" class="my-10">
                    <AonSpinner class="mx-auto" :scale="0.5" />
                </div>
                <template v-if="!loading && (currentMarket || currentCompany)">
                    <div
                        class="header-holder d-flex justify-content-between align-items-center mb-3"
                    >
                        <p class="small">
                            {{
                                currentCompany
                                    ? t('entitySummary.companySummary')
                                    : t('marketSummary.marketSummary')
                            }}
                        </p>
                        <AonButton
                            class="close"
                            type="icon"
                            :style="{ color: '#000' }"
                            :icon-options="{ iconStyle: 'fas', iconName: 'fa-xmark' }"
                            @clicked="drawerOpen = false"
                        />
                    </div>

                    <!-- Company Summary -->
                    <div class="summary-holder-entity" v-if="currentCompany">
                        <EntityTitle class="mb-5" :entity="currentCompany" />
                        <Description
                            :title="currentCompany.name"
                            :description="currentCompany.description"
                            :dark="false"
                        />
                        <div
                            class="stats-holder-entity d-flex justify-content-between flex-wrap my-5"
                        >
                            <div v-if="currentCompany.entity_type" class="w-half mb-2">
                                <font-awesome-icon icon="fas fa-flag" class="fa-fw mr-2" />
                                <span class="caption grey01--text">
                                    {{ currentCompany.entity_type }}
                                </span>
                            </div>
                            <div v-if="currentCompany.address" class="w-half mb-2 d-flex pl-1">
                                <font-awesome-icon
                                    icon="fas fa-map-marker-alt"
                                    class="fa-fw mr-2 d-inline"
                                />
                                <span class="caption grey01--text">{{
                                    currentCompany.address.replace(/,/g, ', ')
                                }}</span>
                            </div>
                            <a
                                v-if="currentCompany.homepage_url"
                                :href="currentCompany.homepage_url"
                                target="_blank"
                                class="grey01--text w-half mb-2"
                            >
                                <font-awesome-icon icon="fas fa-external-link" class="fa-fw mr-2" />
                                <span class="caption">{{ currentCompany.homepage_url }}</span>
                            </a>
                            <div v-if="totalRevenue" class="w-half mb-2">
                                <font-awesome-icon icon="fas fa-sack-dollar" class="fa-fw mr-2" />
                                <span class="caption grey01--text">
                                    {{ totalRevenue }} {{ revenueYear }}
                                </span>
                            </div>
                        </div>
                        <div class="button-holder d-flex justify-content-between mb-5">
                            <AonButton
                                class="w-half mr-5"
                                type="prominent"
                                :route-params="{
                                    name: 'Research Company',
                                    params: { targetPk: currentCompany.aon_entity_pk },
                                }"
                                :label="$t('marketSummary.explore')"
                                @clicked="exploreCompany(currentCompany)"
                            />
                            <AddToWatchlistButton
                                class="w-half"
                                id-type="Entity"
                                :id-values="[currentCompany.aon_entity_pk]"
                                style="min-width: 0"
                            />
                        </div>
                    </div>

                    <!-- Market Summary -->
                    <div class="summary-holder-market" v-if="currentMarket && !currentCompany">
                        <h5 class="market-name mb-5 knights-cloak--text">
                            {{ currentMarket.aon_sector_name }}
                        </h5>
                        <Description
                            :title="currentMarket.name"
                            :description="currentMarket.description"
                            :dark="false"
                        />
                        <div class="stats-holder d-flex justify-content-between my-5">
                            <div class="d-flex">
                                <div class="metric-label caption">
                                    {{ t('marketSummary.metrics.companyCount') }}
                                </div>
                                <p class="metric-value caption ml-1 bold">
                                    {{
                                        currentMarket.participating_company_count?.toLocaleString() ||
                                        '-'
                                    }}
                                </p>
                            </div>
                            <div class="d-flex">
                                <div class="metric-label caption">
                                    {{ t('marketSummary.metrics.marketRevenue') }}
                                </div>
                                <p class="metric-value caption ml-1 bold">
                                    {{ filters.abbreviate(currentMarket.calculated_total_revenue) }}
                                </p>
                            </div>
                        </div>
                        <div class="button-holder d-flex justify-content-between">
                            <AonButton
                                class="w-half mr-5"
                                type="prominent"
                                :route-params="{
                                    name: 'Research Market',
                                    params: { targetPk: currentMarket.aon_sector_pk },
                                }"
                                :label="$t('marketSummary.explore')"
                                @clicked="exploreMarket(currentMarket)"
                            />
                            <AddToWatchlistButton
                                class="w-half"
                                id-type="Sector"
                                :id-values="[currentMarket.aon_sector_pk]"
                                style="min-width: 0"
                            />
                        </div>
                        <hr class="my-5" />
                    </div>

                    <!-- Market Summary - Top Companies Table -->
                    <div class="table-holder" v-if="currentMarket && !currentCompany">
                        <h6 class="px-2 mb-3 knights-cloak--text">
                            {{ t('marketSummary.topCompanies') }}
                        </h6>
                        <AonSpinner v-if="loadingTopCompanies" class="mx-auto mt-10" :scale="0.4" />
                        <TableAips
                            v-else
                            disable-filters
                            :elevation="0"
                            :sort-columns="false"
                            :use-page-data="false"
                            :table-collapse="false"
                            :columns="topCompanyColumns"
                            :data="marketsStore.marketRanks"
                            :loading-data="noTopCompanyData"
                            no-paging-or-scroll
                        >
                            <template #aon_entity_name="{ data }">
                                <div style="width: 250px" class="overflow-ellipsis">
                                    <p
                                        class="text-decoration-none extra-small"
                                        @click="exploreCompany(data)"
                                    >
                                        {{ data.aon_entity_name }}
                                    </p>
                                </div>
                            </template>
                            <template #actions="{ data }">
                                <AddToWatchlistButton
                                    v-if="data.entityId"
                                    :key="`market_${data.entityId}`"
                                    :id-values="[data.entityId]"
                                    id-type="Entity"
                                    use-icon
                                />
                            </template>
                        </TableAips>
                    </div>
                </template>
            </div>
        </AonDrawer>
    </div>
</template>

<script setup>
import { ref, computed, inject, watch } from 'vue'
import { useMarketsStore, useIpMetricsStore, useEntityStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { AonDrawer, AonSearchResult } from '@moatmetrics/armory/src/components'
import Breadcrumb from '@/components/Breadcrumb'
import Description from '@/components/Description'
import AddToWatchlistButton from '../Watchlists/WatchlistActions/AddToWatchlistButton'
import EntityTitle from '@/components/company/EntityTitle'

import NumberAbbreviate from 'number-abbreviate'
import moment from 'moment'
import { useAuth } from '@/auth/authPlugin'

const marketsStore = useMarketsStore()
const entityStore = useEntityStore()
const { user } = useAuth()
const router = useRouter()

const logger = inject('logger')
const { t } = useI18n()
const filters = inject('filters')
const eventBus = inject('eventBus')
const numberAbbreviate = new NumberAbbreviate(['K', 'M', 'B', 'T'])

// component logic
const drawerOpen = ref(false)
const loading = ref(false)

// Market
const errorMarkets = ref(false)
const loadingTopCompanies = ref(true)
const topCompanyColumns = ref([
    {
        locale: 'COMPANY',
        value: 'aon_entity_name',
        showColumn: true,
        useSlot: true,
    },
    {
        locale: 'Moat Quality',
        value: 'cor',
        showColumn: true,
        alignColumn: 'right',
    },
])

//Company
const errorCompany = ref(false)
const loadingMarkets = ref(true)

watch(
    () => drawerOpen.value,
    async (newVal, oldVal) => {
        if (!newVal) {
            // if (router.currentRoute.value.name !== 'Research') {
            marketsStore.setCurrentMarket(null, true)
            marketsStore.setCurrentMarket(null)
            entityStore.summaryEntity = null
            // }
        }
    }
)

watch(
    () => entityStore.summaryEntity,
    async (newVal, oldVal) => {
        if (!newVal) {
            drawerOpen.value = false
        }
        if (newVal && newVal !== oldVal) {
            drawerOpen.value = true
        }
    }
)

watch(
    () => marketsStore.getCurrentMarket,
    async (newVal, oldVal) => {
        if (router.currentRoute.value.name !== 'Research Market') {
            getTopCompanyData()
            drawerOpen.value = true
        }
        if (!newVal) {
            drawerOpen.value = false
        }
    }
)

watch(
    () => marketsStore.overwriteMarket,
    async (newVal, oldVal) => {
        getTopCompanyData()
        drawerOpen.value = true

        if (!newVal) {
            drawerOpen.value = false
        }
    }
)

const currentCompany = computed(() => {
    return entityStore.summaryEntity
})

const currentMarket = computed(() => {
    return marketsStore.overwriteMarket
        ? marketsStore.overwriteMarket
        : marketsStore.getCurrentMarket
})

const totalRevenue = computed(() => {
    return !currentCompany.value || !currentCompany.value.total_revenue
        ? null
        : `$${numberAbbreviate.abbreviate(currentCompany.value.total_revenue)}`
})

const revenueYear = computed(() => {
    if (!currentCompany.value) {
        return null
    }
    return `(${moment.utc(currentCompany.value.revenue_end_date).format('YYYY')})`
})

const noTopCompanyData = computed(() => {
    return !loadingTopCompanies.value && !marketsStore.marketRanks?.length
})

const getTopCompanyData = async () => {
    errorMarkets.value = false

    try {
        loadingTopCompanies.value = true
        await marketsStore.getMarketRanks({
            marketId: currentMarket.value.aon_sector_pk,
            params: {
                FC: ['is_participating'],
                FT: ['eq'],
                FV: ['True'],
                SC: ['cor'],
                SD: [-1],
                page: 1,
                page_size: 10,
                last_row_num: 0,
            },
        })
        logger.error(err)
    } catch (err) {
    } finally {
        loadingTopCompanies.value = false
    }
}

const exploreMarket = (market) => {
    drawerOpen.value = false
    router.push({ name: 'Research Market', params: { targetPk: market.aon_sector_pk } })
}

const exploreCompany = (company) => {
    drawerOpen.value = false
    if (company) {
        router.push({
            name: 'Research Company',
            params: { targetPk: company.aon_entity_pk },
            query: { tabName: 'profile' },
        })
    }
}
</script>

<style lang="scss" scoped>
.company-market-summary {
    z-index: 3;

    :deep(.table-aips-latest) {
        border: none !important;

        .overflow-x-scroll,
        .aips-table,
        .aips-table-wrap {
            transform: none !important;
            border: none !important;
            padding: 0;
        }

        .aips-table {
            table-layout: auto !important;
        }

        thead tr {
            border: none !important;

            th {
                border: none !important;
                padding: 8px !important;
            }
        }

        tbody tr td {
            padding: 8px !important;
            border: none !important;
        }

        .table-header {
            display: none;
        }
    }

    :deep(.a-drawer) {
        .a-drawer-mask {
            height: calc(100% + 111px);
        }
    }
}
</style>
