<template>
    <div class="ip-value-card">
        <AonCard title="IP Value" style="padding-top: 50px !important">
            <div class="card-content">
                <div class="metric-holder d-flex">
                    <div class="half metric d-flex mr-5">
                        <div class="copy pa-4 w-full">
                            <p class="knights-cloak--text bold mb-1">Secondary Market Estimate</p>
                            <p>Computes value using comps from secondary IP market sales</p>
                        </div>
                        <div class="number pa-4 d-flex align-items-center justify-content-center">
                            <font-awesome-icon
                                v-if="!proUser"
                                icon="fas fa-lock"
                                :class="`white--text`"
                                size="2x"
                            />
                            <p v-else-if="entityStore.ipValue" class="white--text bold">
                                {{ entityStore.ipValue }}
                            </p>
                            <AonSpinner v-else :scale="0.3" color="white" />
                        </div>
                    </div>
                    <div class="half metric d-flex">
                        <div class="copy pa-4 w-full">
                            <p class="knights-cloak--text bold mb-1">
                                Build/Buy - Cost to Re-Create
                            </p>
                            <p>
                                Estimates value based on dollar cost to reproduce the research,
                                development, and expense to build the IP portfolio.
                            </p>
                        </div>
                        <div class="number pa-4 d-flex align-items-center justify-content-center">
                            <font-awesome-icon
                                v-if="!proUser"
                                icon="fas fa-lock"
                                :class="`white--text`"
                                size="2x"
                            />
                            <p v-else-if="entityStore.ipRebuildCost" class="white--text bold">
                                {{ entityStore.ipRebuildCost }}
                            </p>
                            <AonSpinner v-else :scale="0.3" color="white" />
                        </div>
                    </div>
                </div>
                <div class="pro-banner w-full knights-cloak mt-5 d-flex pa-5">
                    <div
                        class="action-holder w-half d-flex flex-wrap align-items-center align-content-center"
                    >
                        <h5 class="gold--text w-full">IP-Aligned Enterprise Value</h5>
                        <p class="white--text w-full mt-1 mb-4 pr-5">
                            Estimated value of enterprise value as informed by the IP assets in
                            support of their product and revenue generation mix.
                        </p>
                        <AonButton
                            label="Upgrade to Analyst PRO"
                            text-with-icon
                            :icon-options="{
                                iconStyle: 'fas',
                                iconName: calcNumber ? 'fa-star' : 'fa-lock',
                            }"
                        />
                    </div>
                    <img class="banner-img" src="/pro_banner.png" alt="pro banner image" />
                </div>
            </div>
        </AonCard>
        <CardWarning
            warning-label="PREMIUM FEATURE"
            warning-msg="Preview of Investor Pro Feature. Upgrade to gain full access."
        />
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, inject, computed } from 'vue'
import * as entityApi from '@/api/entities'
import { useEntityStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

import { useRoute } from 'vue-router'
import CardWarning from '@/components/helpers/CardWarning.vue'

const entityStore = useEntityStore()
const proUser = useFlag('alpha.AnalystPro')
const route = useRoute()
const entityId = route.params.targetPk

onMounted(async () => {
    if (proUser.value) {
        entityStore.getIpValue(entityId)
        entityStore.getIpRebuildCost(entityId)
    }
})

onBeforeUnmount(() => {})

const calcNumber = computed(() => {
    return false

    // Use this after we have pro users set up - BB

    // if (proUser.value) {
    //     // return entityStore.entity.ip_value ???
    // } else {
    //     return false
    // }
})
</script>

<style lang="scss" scoped>
.ip-value-card {
    width: 100%;
    position: relative;

    .half {
        width: 50%;
        min-height: 120px;
        border: 1px solid $grey04;
    }

    .number {
        min-width: 80px;
        height: 100%;
        background: $knightsCloak;

        p {
            text-wrap: nowrap;
        }
    }

    .pro-banner {
        height: 200px;
        position: relative;
        overflow: hidden;

        .banner-img {
            width: 700px;

            position: absolute;
            top: -80px;
            right: 16px;
        }
    }
}
</style>
