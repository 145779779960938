import { defineStore } from 'pinia'
import { ref } from 'vue'
import * as Api from '@/api/ipMetrics'

import { logger } from '@moatmetrics/vue-logger'

export const useIpMetricsStore = defineStore('ipMetrics', () => {
    // state
    const ipMetricBins = ref(null)

    // actions
    const getIpMetricBins = async () => {
        if (!ipMetricBins.value) {
            try {
                const { data } = await Api.getIpMetricBins()
                ipMetricBins.value = data
            } catch (err) {
                logger.error(err)
                throw err
            }
        }
    }

    return {
        ipMetricBins,
        getIpMetricBins,
    }
})
