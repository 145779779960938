<template>
    <div class="company-tree-view">
        <div
            class="company-node"
            :class="{
                active: showChildren,
                selected: selectedCompanyId === company.aon_entity_pk,
            }"
        >
            <AonRow :flex-wrap="false">
                <AonCol class="aon-col-8 px-0 py-1">
                    <div
                        v-tooltip="{
                            content: company.name,
                            placement: 'top',
                        }"
                        class="company-name-wrap d-flex align-items-center"
                        :style="indent"
                    >
                        <div class="node-indicator" @click="toggleChildren">
                            <font-awesome-icon
                                v-if="childCompanies.length > 0"
                                icon="fas fa-angle-right"
                                class="castle-moat--text"
                                size="sm"
                            />
                        </div>
                        <p class="overflow-ellipsis">
                            {{ company.name }}
                        </p>
                        <div
                            v-tooltip="{
                                content: 'Explore Company',
                            }"
                            class="company-link"
                            @click="exploreCompany(company.aon_entity_pk)"
                        >
                            <font-awesome-icon
                                icon="fas fa-arrow-circle-right"
                                class="castle-moat--text"
                            />
                        </div>
                    </div>
                </AonCol>
                <AonCol class="patent-count aon-col-2 px-0 py-1">
                    <p
                        v-if="showCounts"
                        v-tooltip="{
                            content: $t(`entityHierarchy.patents`),
                            placement: 'top',
                        }"
                    >
                        <font-awesome-icon icon="far fa-file-lines" class="mr-1" />
                        {{ totalPatents }}
                    </p>
                </AonCol>
                <AonCol class="revenue aon-col-2 px-0 py-1">
                    <p
                        v-if="showCounts"
                        v-tooltip="{
                            content: $t(`entityHierarchy.revenue`),
                            placement: 'top',
                        }"
                    >
                        <font-awesome-icon icon="far fa-sack-dollar" class="mr-1" />
                        {{ totalRevenue }}
                    </p>
                </AonCol>
            </AonRow>
        </div>
        <!-- <v-expand-transition> -->
        <div v-show="showChildren">
            <CompanyTreeView
                v-for="(child, i) in childCompanies"
                :key="`tree-view_${i}`"
                :companies="companies"
                :company="child"
                :depth="depth + 1"
                :selected-company-id="selectedCompanyId"
                @open="open"
                @cancel="cancel"
            />
        </div>
        <!-- </v-expand-transition> -->
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
import NumberAbbreviate from 'number-abbreviate'
import { useAuth } from '@/auth/authPlugin'

const numberAbbreviate = new NumberAbbreviate(['K', 'M', 'B', 'T'])

export default {
    name: 'CompanyTreeView',
    props: {
        depth: {
            type: Number,
            default: 0,
        },
        company: {
            type: Object,
            required: true,
        },
        companies: {
            type: Array,
            default: () => [],
        },
        selectedCompanyId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            loadingChildren: false,
            showChildren: true,
        }
    },
    computed: {
        user() {
            const { user } = useAuth()

            return user
        },
        indent() {
            return { transform: `translate(${this.depth * 25}px)` }
        },
        childCompanies() {
            return this.companies.filter(
                (c) => c.parent_aon_entity_pk === this.company.aon_entity_pk
            )
        },
        totalPatents() {
            if (!this.company.patent_count_us) {
                return this.$t('entityHierarchy.na')
            }
            return this.company.patent_count_us.toLocaleString()
        },
        totalRevenue() {
            if (!this.company.total_revenue) {
                return this.$t('entityHierarchy.na')
            }
            return `$${numberAbbreviate.abbreviate(this.company.total_revenue)}`
        },
        showCounts() {
            return !!(this.company.patent_count || this.company.total_revenue)
        },
    },
    created() {
        if (this.selectedCompanyId == this.company.aon_entity_pk) {
            this.open()
        }
    },
    methods: {
        open() {
            this.showChildren = true
            this.$emit('open')
        },
        cancel() {
            this.$emit('cancel')
        },
        toggleChildren() {
            this.showChildren = !this.showChildren
        },
        exploreCompany(aon_entity_pk) {
            this.$router.push({
                name: 'Research Company',
                params: { targetPk: aon_entity_pk },
                query: { tabName: 'profile' },
            })
            this.cancel()
        },
    },
}
</script>

<style lang="scss">
.company-tree-view {
    .company-node {
        margin-bottom: 0.5em;

        &:hover {
            background-color: $grey08;
        }

        .patent-count {
            font-weight: normal;
            i {
                font-weight: normal;
            }
        }
        .revenue {
            font-weight: normal;
            i {
                font-weight: normal;
            }
        }
        &.active {
            .company-name-wrap {
                white-space: nowrap;

                .node-indicator {
                    .fa-angle-right {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &.selected {
            .company-name-wrap {
                font-weight: 700;
            }
        }

        .company-name-wrap {
            position: relative;
            padding: 0.2em 0 0.2em 1.75em;
            width: 80%;

            .node-indicator {
                font-size: 1em;
                position: absolute;
                top: 0.2em;
                left: 0;
                display: flex;
                height: 1.5em;
                width: 1.5em;
                cursor: pointer;

                .fa-angle-right {
                    margin: auto;
                    color: $castleMoatActive;
                    font-weight: 500;
                    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
                }
            }

            .company-link {
                display: inline-block;
                font-size: 0.85em;
                margin: 0 0 0 0.4em;
                color: $castleMoatActive;
                cursor: pointer;

                &:hover {
                    color: $castleMoatHover;
                }
            }
        }
    }
}
</style>
