<template>
    <div class="map-chart">
        <div id="amGlobeChart" ref="amGlobeChart"></div>
    </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted } from 'vue'
import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow'
import am5geodata_data_countries2 from '@amcharts/amcharts5-geodata/data/countries2'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { debounce } from 'lodash-es'
import { config } from '@/config'

let root
let chart
let mapDiv
let polygonSeries
let backgroundSeries
let circleSeries
const finalChartData = ref([])
const amGlobeChart = ref(null)
// eslint-disable-next-line no-unused-vars
const props = defineProps({
    chartData: {
        type: Array,
        required: true,
        default: () => [],
    },
})

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new(amGlobeChart.value)
    configureData()
})

onBeforeUnmount(() => {
    root.dispose()
})

const configureData = () => {
    for (const item of props.chartData) {
        if (am5geodata_data_countries2.hasOwnProperty(item.id)) {
            let country = am5geodata_data_countries2[item.id]
            if (country.maps.length) {
                finalChartData.value.push({
                    ...item,
                    name: country.country,
                })
            }
        }
    }
    configureChart()
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    chart = root.container.children.push(
        am5map.MapChart.new(root, {
            panX: 'none',
            panY: 'none',
            pinchZoom: false,
            projection: am5map.geoNaturalEarth1(),
        })
    )

    chart.children.push(
        am5.Container.new(root, {
            layout: root.horizontalLayout,
            x: -10,
            y: 13,
        })
    )

    configureSeries()
}

const configureSeries = () => {
    polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_worldLow,
        })
    )
    polygonSeries.mapPolygons.template.setAll({
        fill: root.interfaceColors.get('alternativeBackground'),
        fillOpacity: 0.15,
        strokeWidth: 0.5,
        stroke: root.interfaceColors.get('background'),
        interactive: true,
    })

    polygonSeries.mapPolygons.template.states.create('active', {
        fill: root.interfaceColors.get('primaryButtonHover'),
    })

    // Create polygon series for projected circles
    circleSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}))
    circleSeries.mapPolygons.template.setAll({
        templateField: 'polygonTemplate',
        tooltipText: '{name}: {value}',
    })

    plotData(circleSeries)
    chart.appear(1000, 100)
}

const plotData = (circleSeries) => {
    let valueLow
    let valueHigh

    if (finalChartData.value.length > 1) {
        valueLow = Infinity
        valueHigh = -Infinity

        for (let i = 0; i < finalChartData.value.length; i++) {
            let value = finalChartData.value[i].value
            if (value < valueLow) {
                valueLow = value
            }
            if (value > valueHigh) {
                valueHigh = value
            }
        }
    }

    // radius in degrees
    let minRadius = 2
    let maxRadius = 6

    // Create circles when data for countries is fully loaded.
    polygonSeries.events.on('datavalidated', function () {
        circleSeries.data.clear()

        for (let i = 0; i < finalChartData.value.length; i++) {
            let dataContext = finalChartData.value[i]
            let countryDataItem = polygonSeries.getDataItemById(dataContext.id)
            if (countryDataItem) {
                let countryPolygon = countryDataItem.get('mapPolygon')

                let value = dataContext.value
                let radius = 4

                if (finalChartData.value.length > 1) {
                    radius = minRadius + (maxRadius * (value - valueLow)) / (valueHigh - valueLow)
                }

                if (countryPolygon) {
                    let geometry = am5map.getGeoCircle(countryPolygon.visualCentroid(), radius)
                    circleSeries.data.push({
                        name: dataContext.name,
                        value: dataContext.value,
                        polygonTemplate: dataContext.polygonTemplate,
                        geometry: geometry,
                    })
                }
            }
        }
    })
}
</script>

<script>
export default {
    name: 'MapChart',
}
</script>

<style lang="scss" scoped>
.map-chart {
    width: 100%;

    #amGlobeChart {
        width: 100%;
        height: 350px;
        min-height: 100px;
    }
}
</style>
