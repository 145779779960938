<template>
    <div class="tree-view">
        <div
            class="market-node"
            :class="{
                active: showChildren,
                selected: selectedTreeMarketId === market.sectorId,
            }"
        >
            <div class="market-name" :style="indent">
                <div class="node-indicator" @click="toggleChildren">
                    <font-awesome-icon
                        v-if="market.numberChildren > 0"
                        icon="fas fa-angle-right"
                        class="knights-cloak--text"
                    />
                </div>
                {{ market.name }}
                <div
                    v-tooltip="{
                        content: 'Explore Market',
                    }"
                    class="market-link"
                    @click="exploreMarket(market)"
                >
                    <font-awesome-icon icon="fas fa-circle-arrow-right" class="castle-moat--text" />
                </div>
            </div>
        </div>
        <!-- <v-expand-transition> -->
        <div v-show="showChildren">
            <AonSpinner v-if="showChildrenSpinner" class="mx-auto" :scale="0.25" />
            <TreeView
                v-for="(sector, i) in markets"
                :key="`tree-view_${i}`"
                :markets="sector.markets"
                :market="sector"
                :depth="depth + 1"
                :selected-market-id="selectedMarketId"
                @open="open"
                @cancel="cancel"
            />
        </div>
        <!-- </v-expand-transition> -->
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { useMarketsStore } from '@/stores'
import { useAuth } from '@/auth/authPlugin'

export default {
    name: 'TreeView',
    props: {
        depth: {
            type: Number,
            default: 0,
        },
        market: {
            type: Object,
            required: true,
        },
        markets: {
            type: Array,
            default: () => [],
        },
        selectedMarketId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            loadingChildren: false,
            showChildren: false,
        }
    },
    computed: {
        user() {
            const { user } = useAuth()

            return user
        },
        ...mapState(useMarketsStore, ['selectedTreeMarketId']),
        indent() {
            return { transform: `translate(${this.depth * 25}px)` }
        },
        showChildrenSpinner() {
            return this.loadingChildren && !this.markets.length
        },
    },
    watch: {
        market() {
            this.checkForExpansion()
        },
    },
    mounted() {
        this.checkForExpansion()
    },
    methods: {
        open() {
            this.showChildren = true
            this.$emit('open')
        },
        cancel() {
            this.$emit('cancel')
        },
        async loadChildren(marketId) {
            try {
                const marketsStore = useMarketsStore()

                await marketsStore.loadChildren(marketId)
            } catch (err) {
                this.error = true
            }
        },
        toggleChildren() {
            const marketsStore = useMarketsStore()

            marketsStore.setSelectedTreeMarketId(this.market.sectorId)
            this.showChildren = !this.showChildren

            if (this.showChildren && this.market.numberChildren > 0 && this.markets.length === 0) {
                this.loadingChildren = true
                this.loadChildren(this.market.sectorId)

                return
            }
            this.loadingChildren = false
        },
        exploreMarket(market) {
            this.$router.push({ name: 'Research Market', params: { targetPk: market.sectorId } })
            clearSearch()
        },
        checkForExpansion() {
            if (
                this.selectedTreeMarketId === this.selectedMarketId &&
                this.markets.some((m) => m.sectorId === this.selectedMarketId)
            ) {
                this.open()
            }
        },
    },
}
</script>

<style lang="scss">
.tree-view {
    .market-node {
        margin-bottom: 0.5em;
        padding-bottom: 0.2em;

        &.active {
            .market-name {
                .node-indicator {
                    .fa-angle-right {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &.selected {
            .market-name {
                font-weight: 700;
            }
        }

        .market-name {
            position: relative;
            padding: 0.2em 0 0.2em 1.75em;

            .node-indicator {
                font-size: 1em;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                height: 1.5em;
                width: 1.5em;
                cursor: pointer;

                .fa-angle-right {
                    margin: auto;
                    color: $knightsCloak;
                    font-weight: 500;
                    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
                }
            }

            .market-link {
                display: inline-block;
                font-size: 0.85em;
                margin: 0 0 0 0.4em;
                color: $knightsCloak;
                cursor: pointer;

                &:hover {
                    color: $castleMoatHover;
                }
            }
        }
    }
}
</style>
