<template>
    <div class="moat-quality-card">
        <AonCard title="" desc="" style="position: relative; padding: 0 !important">
            <div class="card-content d-flex justify-content-center">
                <AonSpinner v-if="loading" :scale="0.5" class="ml-auto mr-auto mt-16"></AonSpinner>
                <div v-show="!loading" class="metric-holder w-full d-flex pl-5 pb-5">
                    <div
                        class="banner-score knights-cloak d-flex flex-column justify-content-center align-items-center"
                    >
                        <h1
                            class="white--text mb-10 mt-n5"
                            style="font-size: 140px; font-weight: 900"
                        >
                            {{ Math.floor(entityStore.moatQualityMetrics?.cor) || '-' }}
                        </h1>
                        <p class="white--text bold">Moat Quality Score</p>
                        <p class="white--text mt-5 px-5 text-center">
                            A comprehensive metric evaluating the scope, momentum, and resilience of
                            a company's patent portfolio. Investors and analysts can use this score
                            to gauge the competitive strength and innovation potential of a company.
                        </p>
                    </div>
                    <div v-if="!hasMoatQualityMetrics" class="no-data">
                        <p class="no-data mt-5 px-5 text-center">No data available</p>
                    </div>
                    <div
                        v-show="hasMoatQualityMetrics"
                        id="amChartMoatQuality"
                        ref="amChartMoatQuality"
                    ></div>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import {
    onMounted,
    ref,
    shallowRef,
    watch,
    onActivated,
    onDeactivated,
    computed,
    onUnmounted,
} from 'vue'
import { useEntityStore } from '@/stores'
import { useRoute } from 'vue-router'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5radar from '@amcharts/amcharts5/radar'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const route = useRoute()
const entityId = route.params.targetPk
const entityStore = useEntityStore()

const loading = ref(true)
const hasMoatQualityMetrics = ref(true)
const chartData = shallowRef([])
const amChartMoatQuality = ref(null)
let root
let xAxis
let yAxis

watch(
    () => entityStore.moatQualityMetrics,
    (newValue, oldValue) => {
        if (newValue) {
            chartData.value = [
                {
                    category: 'Resilience',
                    value: Math.floor(newValue?.resilience),
                    full: 100,
                    columnSettings: {
                        fill: am5.color('#d10058'),
                    },
                },
                {
                    category: 'Momentum',
                    value: Math.floor(newValue?.opportunity),
                    full: 100,
                    columnSettings: {
                        fill: am5.color('#8abd45'),
                    },
                },
                {
                    category: 'Scope',
                    value: Math.floor(newValue?.coverage),
                    full: 100,
                    columnSettings: {
                        fill: am5.color('#101e7f'),
                    },
                },
            ]

            configureChart()
        }
    },
    { immediate: true }
)

onMounted(async () => {
    root = am5.Root.new(amChartMoatQuality.value)
    am5.addLicense(config.license.AMChartsLicense)
    if (!(await entityStore.getMoatQualityMetrics(entityId))) {
        loading.value = false
        hasMoatQualityMetrics.value = false
    }
})

onUnmounted(() => {
    root.dispose()
})

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
            panX: false,
            panY: false,
            innerRadius: am5.percent(20),
            startAngle: -90,
            endAngle: 180,
        })
    )
    configureAxes(chart)
    loading.value = false
}

const configureAxes = (chart) => {
    var xRenderer = am5radar.AxisRendererCircular.new(root, {
        //minGridDistance: 50
    })

    xRenderer.labels.template.setAll({
        radius: 10,
    })

    xRenderer.grid.template.setAll({
        forceHidden: true,
    })

    xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: xRenderer,
            min: 0,
            max: 100,
            strictMinMax: true,
            numberFormat: '#',
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    var yRenderer = am5radar.AxisRendererRadial.new(root, {
        minGridDistance: 20,
    })

    yRenderer.labels.template.setAll({
        centerX: am5.p100,
        fontWeight: '500',
        fontSize: 18,
        templateField: 'columnSettings',
    })

    yRenderer.grid.template.setAll({
        forceHidden: true,
    })

    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'category',
            renderer: yRenderer,
        })
    )

    yAxis.data.setAll(chartData.value)

    configureSeries(chart)
}

const configureSeries = (chart) => {
    // Grey series
    let series1 = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            clustered: false,
            valueXField: 'full',
            categoryYField: 'category',
            fill: root.interfaceColors.get('alternativeBackground'),
        })
    )

    series1.columns.template.setAll({
        width: am5.p100,
        fillOpacity: 0.08,
        strokeOpacity: 0,
        cornerRadius: 20,
    })

    series1.data.setAll(chartData.value)

    var series2 = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            clustered: false,
            valueXField: 'value',
            categoryYField: 'category',
        })
    )

    series2.columns.template.setAll({
        width: am5.p100,
        strokeOpacity: 0,
        tooltipText: '{category}: {valueX}',
        cornerRadius: 20,
        templateField: 'columnSettings',
    })

    series2.data.setAll(chartData.value)

    series1.appear(1000)
    series2.appear(1000)
    chart.appear(1000, 100)
}
</script>

<style lang="scss" scoped>
.moat-quality-card {
    width: 100%;
    position: relative;

    .card-content {
        height: 530px;
    }

    #amChartMoatQuality {
        width: 50%;
        height: 100%;
        min-height: 100px;
        div {
            height: 100%;
        }
    }

    .banner-score {
        width: 50%;
        clip-path: polygon(100% 0%, 100% 100%, 50% 85%, 0 100%, 0 0);
    }

    .no-data {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}
</style>
