<template>
    <div class="technology-drill-in-header pa-5 knights-cloak">
        <div class="tech-path d-flex align-items-center">
            <div
                v-for="step in techPath"
                v-tooltip="{
                    content: step,
                }"
                class="path-step d-flex align-items-center"
            >
                <p v-if="step" class="step-text gold--text overflow-ellipsis">{{ step }}</p>
                <font-awesome-icon
                    v-if="step"
                    icon="fas fa-chevron-right"
                    class="white--text pl-4 pr-2 mr-2"
                    size="sm"
                />
            </div>
        </div>
        <h4 class="white--text mt-2">{{ currentNodeName }}</h4>
        <p class="white--text mt-2">{{ currentNodeDescription }}</p>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useEntityStore, useArenaOutputStore } from '@/stores'

const arenaOutputStore = useArenaOutputStore()

const props = defineProps({
    loading: {
        type: Boolean,
    },
    nodeData: {
        type: Object,
        default: () => {},
    },
})

const techPath = ref([])
const currentNodeName = ref('')
const currentNodeDescription = ref('')

onMounted(() => {
    if (arenaOutputStore.targetCompany.length > 0) {
        techPath.value.push(arenaOutputStore.targetCompany[0].aonEntityName)
    }
    techPath.value = props.nodeData.nodeHierarchy.map((node) => node.displayName)
    if (arenaOutputStore.targetCompany.length > 0) {
        techPath.value.unshift(arenaOutputStore.targetCompany[0].aonEntityName)
    }
    currentNodeName.value = props.nodeData.displayName
    currentNodeDescription.value = props.nodeData.description
})
</script>

<style lang="scss" scoped>
.technology-drill-in-header {
    width: 100%;
    border-bottom: solid white 1px;

    .path-step {
        &:last-of-type {
            display: none;
        }

        .step-text {
            max-width: 250px;
        }
    }
}
</style>
