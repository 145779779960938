export const INVESTMENT_GROUPS = {
    'Pre-Seed': ['pre_seed', 'grant', 'non_equity_assistance', 'angel'],
    Seed: ['seed', 'equity_crowdfunding', 'product_crowdfunding'],
    'Series A': ['series_a'],
    'Series B': ['series_b'],
    'Series C': ['series_c'],
    'Series D': ['series_d'],
    'Series E': ['series_e'],
    'Series F': ['series_f'],
    'Series G': ['series_g'],
    'Series H': ['series_h'],
    'Series I': ['series_i'],
    'Series J': ['series_j'],
    'Private Equity': ['private_equity', 'corporate_round'],
    'Post-IPO': ['post_ipo_equity', 'post_ipo_debt', 'post_ipo_secondary'],
    'Debt Financing': ['debt_financing', 'convertible_note'],
    'Other/Unknown': ['initial_coin_offering', 'secondary_market', 'series_unknown', 'undisclosed'],
}

export const INVESTMENT_TYPE_TO_GROUP = Object.entries(INVESTMENT_GROUPS).reduce(
    (acc, [group, types]) => {
        types.forEach((type) => {
            acc[type] = group
        })
        return acc
    },
    {}
)
