<template>
    <div class="concept-input pt-3 pb-5 px-5" :class="{ closed: isCollapsed }">
        <p v-if="props.type === 'concept'" class="white--text align-center mb-2 mt-2">
            Search based on key technology area or conceptual idea
        </p>
        <form
            id="context-target-add-concept"
            class="d-flex flex-column"
            @submit.prevent="submitConcept()"
        >
            <div
                v-if="props.altLayout"
                class="top d-flex justify-content-start align-items-center"
                @click="toggleCollapsedInput()"
            >
                <font-awesome-icon class="grey03--text pr-2 mt-n1 icon" icon="fas fa-angle-down" />
                <p class="bold castle-moat--text mb-1 w-full text-left">Custom Concept</p>
            </div>
            <div class="collapsed-content mt-1">
                <p class="text-left extra-small grey01--text mb-2">
                    {{ props.instructionText }}
                </p>
                <AonTextarea
                    class="w-full concept-textarea pa-0"
                    v-model="conceptForm.concept"
                    :error="v$.concept.$error"
                    :error-options="{
                        errorMsg: v$.concept.$errors[0] ? v$.concept.$errors[0].$message : null,
                    }"
                    focus
                />
                <AonButton
                    class="mb-1"
                    :class="[
                        { 'align-self-start mt-2': props.altLayout },
                        { 'align-self-center mt-5': !props.altLayout },
                    ]"
                    :label="props.type === 'concept' ? 'Generate Results' : 'Add Concept Element'"
                    is-form-submit
                    width="100%"
                    :loading="conceptLoading || altLayoutAddLoading"
                />
            </div>
        </form>
    </div>
</template>

<script setup>
import { inject, ref, reactive, onBeforeUnmount, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useArenaStore } from '@/stores'

import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { requestConceptAlignment } from '@/api/productAlignment.js'
import { typedPlaceholder } from '@/lib/helpers'

import AonTextarea from '@moatmetrics/armory/src/components/base/forms/AonTextarea'

const eventBus = inject('eventBus')
const router = useRouter()
const arenaStore = useArenaStore()

const emit = defineEmits(['add-node-to-arena'])
const props = defineProps({
    type: {
        type: String,
        default: 'concept',
    },
    altLayout: {
        type: Boolean,
        default: false,
    },
    instructionText: {
        type: String,
        default: 'Create a custom concept and add it to the Arena.',
    },
    maxLength: {
        type: Number,
        default: 200,
    },
    ignoreHeightConstraint: {
        type: Boolean,
        default: false,
    },
})

const placeholder = ref(null)
const conceptLoading = ref(false)
const altLayoutAddLoading = ref(false)
const userForcedCollapse = ref(false)

const conceptForm = reactive({
    concept: '',
})
const rules = {
    concept: { required, maxLength: maxLength(props.maxLength) },
}
const v$ = useVuelidate(rules, conceptForm)

onMounted(() => {
    placeholder.value?.kill()
    initAnimatedPlaceholder()
})

onBeforeUnmount(() => {
    placeholder.value.kill()
})

const isCollapsed = computed(() => {
    if (props.ignoreHeightConstraint) {
        return false
    }

    if (!arenaStore.forceConceptInputClose) {
        return userForcedCollapse.value
    }

    if (arenaStore.forceConceptInputClose && userForcedCollapse.value) {
        return false
    }

    if (arenaStore.forceConceptInputClose && !userForcedCollapse.value) {
        return true
    }
})

const submitConcept = async (source = null) => {
    const validInputs = await v$.value.$validate()

    if (!validInputs) {
        return
    }
    altLayoutAddLoading.value = true

    if (props.type === 'arena') {
        emit('add-node-to-arena', { data: conceptForm.concept, source: source })
        altLayoutAddLoading.value = false
        return
    }

    conceptLoading.value = true
    let response
    try {
        response = await requestConceptAlignment(conceptForm.concept)
    } catch (err) {
        logger.error(err)

        eventBus.emit('snacktime', {
            type: 'error',
            message: err,
        })
    } finally {
        placeholder.value.kill()
        router.push({
            name: 'Tailored Output',
            params: { jobPk: response.data.productAlignmentId },
        })
        conceptLoading.value = false
        altLayoutAddLoading.value = false
    }
}

const initAnimatedPlaceholder = () => {
    placeholder.value = new typedPlaceholder({
        placeholders: [
            '“Rocket powered backpack in personal aviation”',
            '"Waterjets with Advanced Hydrodynamics in the context of Marine Propulsion Systems"',
            '"Time travel"',
        ],
        preText: 'Ex: ',
        stay: 1000,
        speed: 30,
        element: '.concept-textarea',
        static: arenaStore.selectedNode ? true : false,
    })
    placeholder.value.init()
    // "focus" param not working
    let focusEl = document.querySelector('.concept-textarea')
    focusEl
        ? focusEl.querySelector('.input-wrapper').getElementsByTagName('textarea')[0].focus()
        : ''
}

const toggleCollapsedInput = () => {
    userForcedCollapse.value = !userForcedCollapse.value
}
</script>

<style lang="scss" scoped>
@import '@moatmetrics/armory/src/sass/responsive.scss';
.concept-input {
    width: 100%;
    text-align: center;
    height: 210px;
    background: rgba($castleMoat, 0.2);

    transition: all 0.3s ease-in-out;
    transition-delay: 0.1s;

    &.closed {
        height: 46px;
        transition-delay: 0.3s;
        background: white;

        .top {
            .icon {
                transform: rotate(-90deg);
            }
        }

        .collapsed-content {
            pointer-events: none;
            opacity: 0;
            transform: translateY(-30px);

            transition-delay: 0.1s;
        }
    }

    .concept-textarea {
        font-size: 1em;
        padding: 10px;

        &::placeholder {
            color: $grey04;
        }
    }

    .top {
        cursor: pointer;

        .icon {
            transform: rotate(0);
            transform-origin: 10px;

            transition: all 0.3s ease-in-out;
        }
    }

    .collapsed-content {
        transition: all 0.3s ease-in-out;
        transition-delay: 0.3s;
    }

    :deep(.aon-textarea) {
        textarea {
            min-height: 80px;
        }
    }

    .add-button {
        cursor: pointer;
    }
}
</style>
