import axios from 'axios'

const baseUrl = 'api/opensearch'

export function searchEntities(dslQuery) {
    return axios.post(`${baseUrl}/entities/_search`, dslQuery)
}

export function searchSectors(dslQuery) {
    return axios.post(`${baseUrl}/sectors/_search`, dslQuery)
}

export function generateEntitiesReport({ query, from, size, sort, username }) {
    return axios.post(`${baseUrl}/entities/generate-report`, {
        query,
        from,
        size,
        sort,
        username,
    })
}

export function generateSectorsReport({ query, from, size, sort, username }) {
    return axios.post(`${baseUrl}/sectors/generate-report`, {
        query,
        from,
        size,
        sort,
        username,
    })
}
