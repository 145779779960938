import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { logger } from '@moatmetrics/vue-logger'
import { createReport, patchReport } from '@/api/reports'

import { useRouter } from 'vue-router'
import { useAuth } from '@/auth/authPlugin'
import { useMoat2ProductStore, useArenaOutputStore } from '@/stores'

export const useReportGenerationStore = defineStore('reportGeneration', () => {
    const moat2ProductStore = useMoat2ProductStore()
    const arenaOutputStore = useArenaOutputStore()
    const { user } = useAuth()
    const router = useRouter()
    const generateLoading = ref(false)

    const reportName = ref(null)
    const reportTypeSelected = ref(null)
    const reportSelections = ref([])
    const trackStepsInit = ref([
        {
            stepName: 'Select Report Type',
            isCurrentStep: true,
            isCompletedStep: false,
            stepNum: 1,
        },
        {
            stepName: 'Report steps appear below',
            stepDesc: 'The steps belove will be tailored based on your selected report type.',
        },
        {
            stepName: 'Placeholder Label',
            stepDesc:
                'Quis esse nostrud magna velit deserunt consectetur ullamco adipisicing velit mollit ad pariatur dolore in.',
            redacted: true,
        },
    ])
    const baseTrackSteps = [
        {
            stepName: 'Target Company',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 2,
        },
        {
            stepName: 'Select Technology Areas',
            stepDesc:
                'Choose the technology areas that matter most for your analysis. Your selections will customize the Moat2Product Report to focus on relevant IP and product alignments. Identify key areas to drive your strategic decision-making.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 3,
        },
        {
            stepName: 'Select Contenders',
            stepDesc:
                'Identify the competitors you want to compare against your Target Company. These contenders will be analyzed to highlight competitive differences and opportunities within your selected technology areas.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 4,
        },
        {
            stepName: 'Report Configuration',
            stepDesc:
                "Customize your report settings and preferences. Choose how you want your report formatted, the metrics included, and how you'd like it delivered. Tailor the report to fit your needs for maximum insight.",
            isCurrentStep: false,
            isCompletedStep: true,
            stepNum: 5,
        },
    ]
    const trackStepsCompetitiveAnalysis = ref(
        baseTrackSteps.map((step) => ({
            ...step,
            stepName: step.stepNum === 4 ? 'Select Contender Source' : step.stepName,
        }))
    )
    const trackStepsHeadToHead = ref([...baseTrackSteps])
    const trackStepsCounter = ref([...baseTrackSteps])
    const trackStepsRisk = ref([...baseTrackSteps])

    const trackStepsFinal = ref([])

    // 'compAnalysis' reportTypeSelected specific
    const contenderSource = ref(null)
    const shouldFillContenders = ref(false)
    const shouldUpdateContenderList = ref(false)
    const shouldShowContenderSelection = ref(false)
    const selectionSource = ref(null)
    const lockCompanySelection = ref(false)

    const resetState = () => {
        generateLoading.value = false
        reportSelections.value = []
        trackStepsInit.value[0].isCurrentStep = true
        trackStepsInit.value[0].isCompletedStep = false
        trackStepsFinal.value = []
        contenderSource.value = null
        shouldFillContenders.value = false
        shouldShowContenderSelection.value = false
        shouldUpdateContenderList.value = false
        selectionSource.value = null
        reportTypeSelected.value = null
        trackStepsCompetitiveAnalysis.value.forEach((step) => {
            step.isCurrentStep = false
            step.isCompletedStep = false
        })
    }

    const nextButtonDisabled = computed(() => {
        // If report type is not selected (step 1)
        if (!reportTypeSelected.value) {
            return true
        }

        // All steps are set
        if (trackStepsFinal.value.length > 0 && !isReportConfigComplete.value) {
            const currentStep = getCurrentStep()
            const correspondingReportSelection = reportSelections.value.find((selection) => {
                return selection.stepNum === currentStep.stepNum
            })

            // Special case for compAnalysis
            if (reportTypeSelected.value === 'compAnalysis' && contenderSource.value) {
                return false
            }

            // Common validation for all report types
            if (
                !correspondingReportSelection ||
                !correspondingReportSelection.value ||
                correspondingReportSelection.value.length === 0
            ) {
                return true
            }
        }

        return false
    })

    const isReportConfigComplete = computed(() => {
        let isComplete = true

        trackStepsFinal.value.forEach((step) => {
            if (!step.isCompletedStep) {
                isComplete = false
            }
        })

        if (trackStepsFinal.value[trackStepsFinal.value.length - 1]?.isCurrentStep !== true) {
            isComplete = false
        }

        return isComplete
    })

    const getSelectedReportTypeSteps = () => {
        if (reportSelections.value[0]?.value === 'compAnalysis') {
            return trackStepsCompetitiveAnalysis.value
        } else if (reportSelections.value[0]?.value === 'headToHead') {
            return trackStepsHeadToHead.value
        } else if (reportSelections.value[0]?.value === 'counter') {
            return trackStepsCounter.value
        } else if (reportSelections.value[0]?.value === 'risk') {
            return trackStepsRisk.value
        } else {
            return trackStepsCompetitiveAnalysis.value
        }
    }

    const getReportSelectionByStep = (stepNum) => {
        return reportSelections.value.find((s) => s.stepNum === stepNum)
    }

    const setReportCreationType = (type, label) => {
        reportTypeSelected.value = type

        const existingFirstStep = reportSelections.value.find((s) => s.stepNum === 1)
        if (existingFirstStep) {
            existingFirstStep.value = type
            existingFirstStep.selectionLabel = label
        } else {
            reportSelections.value.unshift({
                value: type,
                selectionLabel: label,
                stepNum: 1,
            })
        }
        if (
            reportTypeSelected.value === 'headToHead' ||
            reportTypeSelected.value === 'counter' ||
            reportTypeSelected.value === 'risk'
        ) {
            forwardTargetCompanyContenderSelections(true)
        }
    }

    const setReportCompanySelection = (value) => {
        const currentStep = getCurrentStep()
        const findSelection = getReportSelectionByStep(currentStep.stepNum)
        if (!findSelection) {
            reportSelections.value.push({
                value: value.aon_entity_pk,
                selectionLabel: value.name || value.aonEntityName,
                stepNum: currentStep.stepNum,
                allowEdit: true,
            })
        } else {
            findSelection.value = value.aon_entity_pk
            findSelection.selectionLabel = value.name || value.aonEntityName
        }
    }

    /////////////////////////////////////
    // Step navigation and Creation
    /////////////////////////////////////
    const getCurrentStep = () => {
        return trackStepsFinal.value.find((s) => s.isCurrentStep)
    }

    const setCurrentStep = (stepNum) => {
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        trackStepsFinal.value[currentStepIndex].isCurrentStep = false
        trackStepsFinal.value[stepNum - 1].isCurrentStep = true
        trackStepsFinal.value[stepNum - 1].isCompletedStep = false

        if (stepNum === 4) {
            shouldShowContenderSelection.value = false
        }
    }

    const setCompletedStep = () => {
        const currentStep = getCurrentStep()
        if (!currentStep) {
            handleInitialStep()
            return
        }

        switch (reportTypeSelected.value) {
            case 'compAnalysis':
                handleCompAnalysisStep()
                break
            case 'headToHead':
            case 'counter':
            case 'risk':
                handleHeadToHeadOrCounterStep()
                break
        }
    }

    const handleInitialStep = () => {
        trackStepsInit.value[0].isCurrentStep = false
        trackStepsInit.value[0].isCompletedStep = true
        trackStepsFinal.value = [trackStepsInit.value[0], ...getSelectedReportTypeSteps()]

        if (reportSelections.value[1]) {
            trackStepsFinal.value[2].isCurrentStep = true
            trackStepsFinal.value[1].isCompletedStep = true
        } else {
            trackStepsFinal.value[1].isCurrentStep = true
            trackStepsFinal.value[0].isCompletedStep = true
        }
    }

    const handleCompAnalysisStep = () => {
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        if (contenderSource.value !== null) {
            if (contenderSource.value !== 'auto' && !shouldShowContenderSelection.value) {
                toggleContenderSelection()
                return
            }
        }

        markStepComplete(currentStepIndex)
        trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
    }

    const handleHeadToHeadOrCounterStep = () => {
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)
        markStepComplete(currentStepIndex)
        trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
    }

    const markStepComplete = (index) => {
        trackStepsFinal.value[index].isCurrentStep = false
        trackStepsFinal.value[index].isCompletedStep = true
    }

    const gotoPreviousStep = () => {
        const currentStep = getCurrentStep()
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        if (reportTypeSelected.value === 'compAnalysis') {
            if (shouldShowContenderSelection.value && currentStepIndex === 3) {
                shouldShowContenderSelection.value = false
            } else {
                currentStep.isCurrentStep = false
                trackStepsFinal.value[currentStepIndex - 1].isCurrentStep = true
            }

            if (currentStepIndex <= 3) {
                contenderSource.value = null
            }
        } else {
            currentStep.isCurrentStep = false
            trackStepsFinal.value[currentStepIndex - 1].isCurrentStep = true
        }
    }
    /////////////////////////////////////
    // End Step navigation and Creation
    /////////////////////////////////////

    ///////////////////////////////////////////
    // Generate Report Submit
    ///////////////////////////////////////////
    const generateReport = async () => {
        generateLoading.value = true

        const params = buildBaseParams(
            reportTypeSelected.value,
            reportName.value,
            reportSelections.value,
            user.value
        )
        const stores = { moat2ProductStore, arenaOutputStore }

        if (reportTypeSelected.value === 'compAnalysis') {
            params.report_input = buildCompAnalysisInput(
                selectionSource.value,
                stores,
                reportSelections.value,
                shouldFillContenders.value
            )
        } else if (reportTypeSelected.value === 'headToHead') {
            params.report_input = buildHeadToHeadInput(
                selectionSource.value,
                stores,
                reportSelections.value
            )
        } else if (reportTypeSelected.value === 'counter') {
            params.report_input = buildCounterInput(
                selectionSource.value,
                stores,
                reportSelections.value
            )
        } else if (reportTypeSelected.value === 'risk') {
            params.report_input = buildRiskInput(
                selectionSource.value,
                stores,
                reportSelections.value
            )
        }

        try {
            const { data } = await createReport(params)
            submitReportForGeneration(data)
        } catch (err) {
            logger.error(err)
        }
    }

    const buildBaseParams = (reportTypeSelected, reportName, reportSelections, user) => {
        return {
            report_type: getReportType(reportTypeSelected),
            report_name: reportName || reportSelections[1].selectionLabel,
            report_description: '',
            requested_application: 'ip_alpha',
            report_input: {},
            requested_user: user.id,
        }
    }

    const getReportType = (reportType) => {
        const types = {
            compAnalysis: 'product_alignment',
            counter: 'counter_assertion',
            headToHead: 'head_to_head',
            risk: 'risk_analysis',
        }
        return types[reportType] || 'product_alignment'
    }

    const buildCompAnalysisInput = (
        selectionSource,
        stores,
        reportSelections,
        shouldFillContenders
    ) => {
        return {
            product_alignment_id:
                selectionSource === 'moat2Product'
                    ? stores.moat2ProductStore.alignmentPk
                    : stores.arenaOutputStore.alignmentPk,
            global_contender_list: reportSelections[3].value.map(
                (contender) => contender.aonEntityPk
            ),
            global_fill_contenders: shouldFillContenders,
            tech_area_contender_list: [
                {
                    node_ids: reportSelections[2].value.map((techArea) => techArea.nodeId),
                },
            ],
        }
    }

    const buildHeadToHeadInput = (selectionSource, stores, reportSelections) => {
        return {
            product_alignment_id:
                selectionSource === 'moat2Product'
                    ? stores.moat2ProductStore.alignmentPk
                    : stores.arenaOutputStore.alignmentPk,
            target_entity_pk: reportSelections[1].value,
            contender_ids: reportSelections[3].value.map((contender) => contender.aonEntityPk),
            node_ids: reportSelections[2].value.map((techArea) => techArea.nodeId),
        }
    }

    const buildCounterInput = (selectionSource, stores, reportSelections) => {
        return {
            product_alignment_id:
                selectionSource === 'moat2Product'
                    ? stores.moat2ProductStore.alignmentPk
                    : stores.arenaOutputStore.alignmentPk,
            target_entity_pk: reportSelections[1].value,
            contender_id: reportSelections[3].value[0].aonEntityPk,
            node_ids: reportSelections[2].value.map((techArea) => techArea.nodeId),
        }
    }

    const buildRiskInput = (selectionSource, stores, reportSelections) => {
        return {
            product_alignment_id:
                selectionSource === 'moat2Product'
                    ? stores.moat2ProductStore.alignmentPk
                    : stores.arenaOutputStore.alignmentPk,
            contender_ids: reportSelections[3].value.map((contender) => contender.aonEntityPk),
            node_ids: reportSelections[2].value.map((techArea) => techArea.nodeId),
        }
    }

    const submitReportForGeneration = async (reportData) => {
        const params = {
            report_action: 'submit',
        }

        try {
            await patchReport(reportData.report_pk, params)
        } catch (err) {
            logger.error(err)
        } finally {
            generateLoading.value = false
            router.push({ name: 'Reports' })
        }
    }
    ///////////////////////////////////////////
    // End Generate Report Submit
    ///////////////////////////////////////////

    const forwardTargetCompanySelections = (source) => {
        selectionSource.value = source
        const store = source === 'moat2Product' ? moat2ProductStore : arenaOutputStore
        const entity =
            source === 'moat2Product'
                ? store.productAlignmentTopCompetitors[0]
                : store.targetCompany[0]
        reportSelections.value.push(
            {
                value: entity.aonEntityPk,
                selectionLabel: entity.name || entity.aonEntityName,
                stepNum: 2,
            },
            {
                value:
                    source === 'moat2Product'
                        ? [...store.productAlignmentTechAreaSelections]
                        : [...store.arenaOutputTechAreaSelections],
                selectionLabel: ' Technology Areas Selected',
                stepNum: 3,
                allowEdit: true,
            }
        )
        lockCompanySelection.value = true
    }

    const forwardTargetCompanyContenderSelections = (targetOnly = false) => {
        const contenderSelection = {
            value: !targetOnly
                ? moat2ProductStore.productAlignmentTopCompetitors
                : [moat2ProductStore.productAlignmentTopCompetitors[0]],
            selectionLabel: ' Contenders Selected',
            stepNum: 4,
            allowEdit: true,
        }

        const existingIndex = reportSelections.value.findIndex((item) => item.stepNum === 4)
        existingIndex !== -1
            ? (reportSelections.value[existingIndex] = contenderSelection)
            : reportSelections.value.push(contenderSelection)
    }

    const setShouldFillContenders = () => {
        shouldFillContenders.value = !shouldFillContenders.value
    }

    const toggleContenderSelection = () => {
        shouldShowContenderSelection.value = !shouldShowContenderSelection.value
    }

    const flagForContenderUpdate = () => {
        shouldUpdateContenderList.value = true
    }

    const onTechAreaChange = () => {
        contenderSource.value = null
        trackStepsFinal.value[3].isCompletedStep = false
        trackStepsFinal.value[3].value = []
    }

    return {
        generateLoading,
        resetState,
        trackStepsInit,
        trackStepsFinal,
        contenderSource,
        lockCompanySelection,
        reportName,
        reportTypeSelected,
        reportSelections,
        setReportCreationType,
        getSelectedReportTypeSteps,
        setCurrentStep,
        getCurrentStep,
        gotoPreviousStep,
        getReportSelectionByStep,
        nextButtonDisabled,
        setCompletedStep,
        forwardTargetCompanySelections,
        forwardTargetCompanyContenderSelections,
        setShouldFillContenders,
        toggleContenderSelection,
        shouldShowContenderSelection,
        flagForContenderUpdate,
        shouldUpdateContenderList,
        selectionSource,
        isReportConfigComplete,
        generateReport,
        onTechAreaChange,
        setReportCompanySelection,
    }
})
