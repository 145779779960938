<template>
    <Markets v-if="showNewSearch" />
</template>

<script setup>
import Markets from '@/components/Markets'

import { useFlag } from '@unleash/proxy-client-vue'

const showNewSearch = useFlag('ipAlpha.searchCompHome')
</script>
