<template>
    <font-awesome-icon
        v-if="useIcon"
        icon="fas fa-circle-plus"
        class="knights-cloak--text align-self-center cursor-pointer"
        size="lg"
        @click="addIdsToWatchlist"
    />
    <AonButton
        v-else
        :label="buttonLabel"
        :text-with-icon="true"
        :icon-options="{
            iconStyle: 'fas',
            iconName: 'fa-circle-plus',
        }"
        :width="buttonWidth"
        class="btnClass"
        :disabled="idValues?.length === 0"
        @clicked="addIdsToWatchlist"
    />
</template>

<script setup>
import { useDatasetStore } from '@/stores'
import { hideAllPoppers } from 'floating-vue'

const props = defineProps({
    title: {
        type: String,
        default: 'Add to watchlist',
    },
    idValues: {
        type: Array,
        required: true,
    },
    idType: {
        type: String,
        required: true,
        validator: (value) => ['Entity', 'Sector'].includes(value),
    },
    useIcon: {
        type: Boolean,
        default: false,
    },
    buttonWidth: {
        type: String,
        default: '100%',
    },
    buttonLabel: {
        type: String,
        default: 'Add to watchlist',
    },
})

const addIdsToWatchlist = () => {
    const datasetStore = useDatasetStore()
    datasetStore.setIdsToAdd({
        id_type: props.idType,
        id_values: props.idValues,
    })
    // hideAllPoppers()
}
</script>
