<template>
    <div class="validity-card">
        <AonCard title="Validity Visualization" :desc="description">
            <template #header-append>
                <p v-if="!loading" class="grey02--text italic ml-2 mt-n10 header-template">
                    Patents shown are US only
                </p>
            </template>
            <div class="card-content d-flex justify-content-center">
                <AonSpinner
                    v-if="loading"
                    :scale="0.5"
                    class="spinner ml-auto mr-auto mt-16"
                ></AonSpinner>
                <div v-else>
                    <p class="y-axis-label-1">Broad</p>
                    <p class="y-axis-label-2">Narrow</p>
                    <div class="y-axis-label-with-arrows d-flex align-items-center w-full">
                        <font-awesome-icon icon="fas fa-arrow-left-long" class="black--text" />
                        <p class="bold mx-4">Claim Breadth</p>
                        <font-awesome-icon icon="fas fa-arrow-right-long" class="black--text" />
                    </div>
                    <div class="x-axis-label-with-arrows d-flex align-items-center w-full">
                        <font-awesome-icon icon="fas fa-arrow-left-long" class="black--text" />
                        <p class="bold mx-4">Estimated Expiration</p>
                        <font-awesome-icon icon="fas fa-arrow-right-long" class="black--text" />
                    </div>
                </div>

                <div id="amChartValidity" ref="amChartValidity"></div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, onUnmounted, computed } from 'vue'
import { useEntityStore } from '@/stores'
import { useRoute } from 'vue-router'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

let root
let yAxis
let xAxis

const route = useRoute()
const entityId = route.params.targetPk
const loading = ref(true)
const entityStore = useEntityStore()
const amChartValidity = ref(null)
const series1Data = ref(null)
const series2Data = ref(null)
const series3Data = ref(null)

onMounted(async () => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new(amChartValidity.value)
    await entityStore.getValidityByClaimScore(entityId)
})

onUnmounted(() => {
    root.dispose()
})

const description = computed(() => {
    return (
        'This scatter plot provides a unique view of the patent landscape for ' +
        entityStore.entity.name +
        '. Each dot represents a patent, positioned based on its claim breadth and expiration date. The shape (circle, square, or triangle) and color indicate validity.'
    )
})

const mapData = (validityByClaimScore) => {
    let chartData = validityByClaimScore.map((data) => {
        return {
            name: data.validityBin,
            expirationDt: new Date(data.expirationDt).getTime(),
            value: data.validityBin,
            claimScore: Math.min(data.claimScore, 100),
        }
    })

    series1Data.value = chartData.filter((data) => data.name === 'Low')
    series2Data.value = chartData.filter((data) => data.name === 'Medium')
    series3Data.value = chartData.filter((data) => data.name === 'High')
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])
    let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            interactive: false,
            wheelY: 'none',
            layout: root.verticalLayout,
            paddingLeft: 80,
        })
    )

    generateAxes(chart)

    loading.value = false
}

const generateAxes = (chart) => {
    var yRenderer = am5xy.AxisRendererY.new(root, {
        inversed: true,
    })

    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: yRenderer,
            visible: false,
            min: 0,
            max: 101,
        })
    )

    var xRenderer = am5xy.AxisRendererX.new(root, {
        // visible: false,
        // minorGridEnabled: true,
        minGridDistance: 100,
    })

    xRenderer.labels.template.setAll({
        // centerY: am5.p50,
        location: 0,
    })
    xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: 'day', count: 30 },
            gridIntervals: [{ timeUnit: 'year', count: 5 }],
            renderer: xRenderer,
            cellEndLocation: 0.5,
            extraMin: 0.05,
            extraMax: 0.05,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    configureSeries(chart)
}

const configureSeries = (chart) => {
    ///////////////////////
    // Series 0 Config
    ///////////////////////
    let tooltip0 = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false,
        labelText: '{valueX.formatDate()}, Validity Score: {value}',
    })
    tooltip0.get('background').setAll({
        fill: am5.color('#E06259'),
        fillOpacity: 1,
    })

    let series0 = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: 'Low',
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'claimScore',
            valueXField: 'expirationDt',
            valueField: 'value',
            tooltip: tooltip0,
        })
    )

    series0.bullets.push(function () {
        let graphics = am5.Triangle.new(root, {
            width: 12,
            height: 10,
            fill: am5.color('#E06259'),
            stroke: am5.color(0xffffff),
            strokeWidth: 2,
            centerY: am5.p50,
            centerX: am5.p50,
        })

        return am5.Bullet.new(root, {
            sprite: graphics,
        })
    })

    ///////////////////////
    // Series 1 Config
    ///////////////////////
    let tooltip1 = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false,
        labelText: '{valueX.formatDate()}, Validity Score: {value}',
    })
    tooltip1.get('background').setAll({
        fill: am5.color('#BCC9DF'),
        fillOpacity: 1,
    })
    let series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: 'Medium',
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'claimScore',
            valueXField: 'expirationDt',
            valueField: 'value',
            tooltip: tooltip1,
        })
    )

    series1.bullets.push(function () {
        let graphics = am5.Circle.new(root, {
            fill: am5.color('#BCC9DF'),
            radius: 4,
        })

        return am5.Bullet.new(root, {
            sprite: graphics,
        })
    })

    ///////////////////////
    // Series 2 Config
    ///////////////////////
    let tooltip2 = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false,
        labelText: '{valueX.formatDate()}, Validity Score: {value}',
    })
    tooltip2.get('background').setAll({
        fill: am5.color('#359B8E'),
        fillOpacity: 1,
    })
    let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: 'High',
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'claimScore',
            valueXField: 'expirationDt',
            valueField: 'value',
            tooltip: tooltip2,
        })
    )

    series2.bullets.push(function () {
        let graphics = am5.Rectangle.new(root, {
            width: 8,
            height: 8,
            fill: am5.color('#359B8E'),
            stroke: am5.color(0xffffff),
            strokeWidth: 2,
            centerY: am5.p50,
            centerX: am5.p50,
        })

        return am5.Bullet.new(root, {
            sprite: graphics,
        })
    })

    ///////////////////////
    // Global Series Config
    ///////////////////////
    series0.strokes.template.set('strokeOpacity', 0)
    series1.strokes.template.set('strokeOpacity', 0)
    series2.strokes.template.set('strokeOpacity', 0)

    series0.data.processor = am5.DataProcessor.new(root, {
        dateFields: ['expirationDt'],
        dateFormat: 'MM-dd-yyyy',
    })
    series1.data.processor = am5.DataProcessor.new(root, {
        dateFields: ['expirationDt'],
        dateFormat: 'MM-dd-yyyy',
    })
    series2.data.processor = am5.DataProcessor.new(root, {
        dateFields: ['expirationDt'],
        dateFormat: 'MM-dd-yyyy',
    })

    configureLegend(chart, series0, series1, series2)
}

let configureLegend = (chart, series0, series1, series2) => {
    chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            behavior: 'zoomXY',
            snapToSeries: [series1, series0, series2],
        })
    )

    var legend = chart.children.push(
        am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            paddingTop: 40,
        })
    )

    // Make series change state when legend item is hovered
    legend.itemContainers.template.states.create('hover', {})

    legend.itemContainers.template.events.on('pointerover', function (e) {
        e.target.dataItem.dataContext.hover()
    })
    legend.itemContainers.template.events.on('pointerout', function (e) {
        e.target.dataItem.dataContext.unhover()
    })

    legend.data.setAll(chart.series.values)

    series0.data.setAll(series1Data.value)
    series1.data.setAll(series2Data.value)
    series2.data.setAll(series3Data.value)
    series0.appear(1000)
    series1.appear(1000)
    series2.appear(1000)
    chart.appear(1000, 100)
}

watch(
    () => entityStore.validityByClaimScore,
    (newValue, oldValue) => {
        if (newValue != null) {
            mapData(newValue)
            configureChart()
        }
    },
    { immediate: true }
)
</script>

<style lang="scss" scoped>
.validity-card {
    width: 100%;
    position: relative;

    .card-content {
        height: 580px;
    }

    .header-template {
        flex-basis: 1;
    }

    #amChartValidity {
        width: 100%;
        height: 100%;
        min-height: 100px;
        div {
            height: 100%;
        }
    }

    .spinner {
        position: absolute;
    }

    .y-axis-label-1 {
        position: absolute;
        left: 70px;
        top: 30%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }

    .y-axis-label-2 {
        position: absolute;
        left: 70px;
        top: 77%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }

    .y-axis-label-with-arrows {
        position: absolute;
        left: 40px;
        top: 62%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }

    .x-axis-label-with-arrows {
        position: absolute;
        left: 45%;
        top: 86.5%;
    }
}
</style>
