<template>
    <div>
        <p v-if="showInvestmentStoryDisclosure" class="mt-4 bold">
            {{ t('productAlignment.definitionTooltips.investmentStoryDisclosure') }}
        </p>
        <div v-if="showLoadingHeader" class="spinner-holder d-flex align-items-center my-8 ml-n1">
            <AonSpinner :loading="showLoadingHeader" :scale="0.5" class="mx-0" />
            <h3 class="knights-cloak--text ml-n4">
                {{ t('productAlignment.loading.retrievingInvestmentStory') }}
            </h3>
        </div>
        <div v-if="showEmptyState">
            {{ emptyStateMessage }}
        </div>
        <div v-show="!showEmptyState" class="sankey-chart d-flex align-items-center">
            <div :style="`height: ${calcHeight}`" id="amChartSankey"></div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, onBeforeUnmount } from 'vue'

import * as am5 from '@amcharts/amcharts5'
import * as am5flow from '@amcharts/amcharts5/flow'
import { useEntityStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { AonSpinner } from '@moatmetrics/armory/src/components'
import { config } from '@/config'
const { t } = useI18n()
import { elementaryDescription } from '@/api/llm.js'
import { debounce } from 'lodash-es'
import { insertNewlines } from '@/lib/helpers'

let root

const emit = defineEmits(['node-selected'])
const entityStore = useEntityStore()

const props = defineProps({
    chartData: {
        type: Array,
        default: () => [],
    },
    sourceNodeData: {
        type: Object,
        default: () => {},
    },
    productData: {
        type: Array,
        default: () => [],
    },
    normalizeCounts: {
        type: Boolean,
        default: false,
    },
    activeView: {
        type: Number,
        default: 0,
    },
})

const chartHeightHelper = ref(null)
const finalChartData = ref()
const localRemoveZeros = ref(true)
const usPatentBreakdown = ref()
const investmentStoryView = 0
const innovationOpportunityView = 1
const companySizePatentThreshold = 100
const chartFinishedConfiguring = ref(false)

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartSankey')
    usPatentBreakdown.value = entityStore.patentBreakdown.find((pb) => pb.country === 'US')
    root.container.children.clear()
    configureChart(props.activeView === innovationOpportunityView ? false : true)
})

onBeforeUnmount(() => {
    root.dispose()
})

watch(
    () => props.activeView,
    (newVal) => {
        resetChart(newVal)
    }
)

watch(
    () => props.chartData,
    () => {
        resetChart(props.activeView)
    }
)

const resetChart = (activeView) => {
    chartFinishedConfiguring.value = false
    root.container.children.clear()
    if (activeView === innovationOpportunityView) {
        configureChart(false)
    }
    if (activeView === investmentStoryView) {
        configureChart()
    }
}

const calcHeight = computed(() => {
    return `${chartHeightHelper.value * 20}px`
})

const largeDataSet = computed(() => {
    return props.productData.length > 8
})

const forcedNormalizeCounts = computed(() => {
    return props.normalizeCounts || !localRemoveZeros.value
})

const showLoadingHeader = computed(() => {
    return !chartFinishedConfiguring.value
})

const emptyStateMessage = computed(() => {
    return props.activeView === investmentStoryView
        ? t('productAlignment.definitionTooltips.noInvestmentStory')
        : t('productAlignment.definitionTooltips.noInnovationOpportunity')
})
const showEmptyState = computed(() => {
    return !finalChartData.value || finalChartData.value.length === 0
})

const pathTooltipContent = computed(() => {
    return !forcedNormalizeCounts.value
        ? `From: [bold]{tooltipSettings.from}[/]\nTo: [bold]{tooltipSettings.to}[/]\nMapped Patents: [bold]{tooltipSettings.mappedPatents}[/]`
        : `From: [bold]{tooltipSettings.from}[/]\nTo: [bold]{tooltipSettings.to}[/]`
})

const showInvestmentStoryDisclosure = computed(() => {
    if (
        !finalChartData.value ||
        finalChartData.value.length === 0 ||
        !usPatentBreakdown.value ||
        props.activeView === innovationOpportunityView
    ) {
        return false
    }
    return usPatentBreakdown.value.assetCount > companySizePatentThreshold
})

const configureChart = (removeZeros = true) => {
    localRemoveZeros.value = removeZeros
    if (removeZeros) {
        finalChartData.value = props.chartData.filter((item) => item.value !== 0)
        if (
            usPatentBreakdown.value &&
            usPatentBreakdown.value.assetCount > companySizePatentThreshold
        ) {
            finalChartData.value = props.chartData.filter(
                (item) => item.recentPatentAssociationCount !== 0
            )
        } else {
            finalChartData.value = props.chartData.filter(
                (item) => item.patentAssociationCount !== 0
            )
        }
    } else {
        // filter out all zero values
        finalChartData.value = props.chartData.filter((item) => item.patentAssociationCount === 0)

        // add back in the products that have something assigned to them
        finalChartData.value = [
            ...finalChartData.value,
            ...props.productData.filter((item) => {
                return (
                    finalChartData.value.find((node) => node.from === item.to) &&
                    !finalChartData.value.some((node) => node.to === item.to)
                )
            }),
        ]

        finalChartData.value = finalChartData.value.map((item) => {
            return {
                ...item,
                value: 1,
            }
        })
    }

    finalChartData.value = finalChartData.value.sort((a, b) => {
        if (a.from === 'Other') return 1
        if (b.from === 'Other') return -1

        if (a.to === 'Other') return 1
        if (b.to === 'Other') return -1

        if (a.from < b.from) return -1
        if (a.from > b.from) return 1

        if (a.from === b.from) {
            if (a.to < b.to) return -1
            if (a.to > b.to) return 1
        }

        return 0
    })

    // Create series
    var series = root.container.children.push(
        am5flow.Sankey.new(root, {
            sourceIdField: 'parent',
            targetIdField: 'nodeId',
            valueField: 'value',
            nodeAlign: 'left',
            nodePadding: 8,
            nodeWidth: 10,
            tooltipPosition: 'pointer',
        })
    )

    chartHeightHelper.value = finalChartData.value.length

    setupNodeConfig(series, removeZeros)
    setupLinkConfig(series, removeZeros)

    // Set data
    series.data.setAll(finalChartData.value)

    chartFinishedConfiguring.value = true
}

const setupNodeConfig = (series, removeZeros) => {
    // Insures that the disabled prop is present on all nodes
    series.nodes.setAll({
        disabledField: 'disabled',
        nameField: 'displayName',
    })
    // Set node labels
    series.nodes.labels.template.setAll({
        x: am5.p50,
        paddingLeft: 20,
        paddingRight: 20,
        fontWeight: 'bold',
        fontSize: 12,
        text: '{name}',
    })

    // Flips the label to the other side for nodes with only incoming links
    series.nodes.labels.template.adapters.add('centerX', function (center, target) {
        if (target.dataItem.get('incomingLinks', []) == 0) {
            return am5.p0
        } else if (target.dataItem.get('outgoingLinks', []) == 0) {
            return am5.p100
        }
        return am5.p0
    })

    // Set node shape
    series.nodes.rectangles.template.setAll({
        cornerRadiusTL: 2,
        cornerRadiusTR: 2,
        cornerRadiusBL: 2,
        cornerRadiusBR: 2,
        stroke: am5.color(0x000000),
        strokeWidth: 2,
    })

    // Node colors
    setNodeColors(series, removeZeros)
}

const setupLinkConfig = (series) => {
    series.links.template.set('templateField', 'tooltipSettings')

    // Link coloring
    series.links.template.setAll({
        fillStyle: 'source',
    })

    series.links.template.setAll({
        tooltipPosition: 'pointer',
        tooltipText: pathTooltipContent.value,
        loadedDescription: false,
        tooltip: am5.Tooltip.new(root, {
            width: 750,
        }),
    })

    series.links.template.events.on('pointerover', debouncedPointerOverHandler)
}

const debouncedPointerOverHandler = debounce(async function (event) {
    let target = event.target
    let loadedDescription = target.get('loadedDescription')

    if (loadedDescription) {
        return
    }
    target.tooltipText = `${pathTooltipContent.value}\n${t('llmApi.loadingDescription')}`
    target.set('tooltipText', `${pathTooltipContent.value}\n${t('llmApi.loadingDescription')}`)
    let concepts = [target.dataItem.dataContext.to]
    let source = target.get('source')
    let incomingLinks = source.get('incomingLinks', [])
    if (incomingLinks && incomingLinks.length > 0) {
        concepts.unshift(target.dataItem.dataContext.from)
    }

    try {
        const { data } = await elementaryDescription(concepts)
        const formattedData = insertNewlines(data, 95)
        target.set('tooltipText', `${pathTooltipContent.value}\n${formattedData}`)
        target.set('loadedDescription', true)
    } catch (error) {
        target.set('tooltipText', `${pathTooltipContent.value}\n${t('llmApi.noDescription')}`)
    }
}, 200)

const setNodeColors = (series, removeZeros) => {
    let colorMap = finalChartData.value.map((item) => {
        return {
            id: item.nodeId,
            displayName: item.to,
            fill: getColor(item, removeZeros),
        }
    })

    // Remove duplicates
    let uniqueIds = new Set()

    colorMap = colorMap.filter((item) => {
        if (uniqueIds.has(item.id)) {
            return false
        } else {
            uniqueIds.add(item.id)
            return true
        }
    })

    // Source node
    colorMap.push({
        id: props.sourceNodeData.nodeId,
        displayName: props.sourceNodeData.displayName,
        fill: removeZeros ? am5.color('#143862') : am5.color('#46535E'),
    })

    // Apply colors
    series.nodes.data.setAll(colorMap)
}

const getColor = (node, removeZeros) => {
    if (!removeZeros) {
        if (node.from === props.sourceNodeData.displayName) {
            return am5.color('#82939A')
        } else {
            return am5.color('#CDDBDE')
        }
    } else {
        if (node.from === props.sourceNodeData.displayName) {
            return am5.color('#1F589A')
        } else {
            return am5.color('#111')
        }
    }
}
</script>

<style lang="scss">
.sankey-chart {
    width: 100%;

    #amChartSankey {
        width: 100%;
        height: 1000px;
        min-height: 100px;
        div {
            height: 100%;
        }
    }
}
</style>
