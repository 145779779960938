<template>
    <div class="litigation-history-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-5">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        :title="`${entityStore.entity.name} Litigation History`"
                        desc="Overview of patent litigation activity involving the target entity"
                    >
                        <div class="card-content">
                            <AonCoverLoading
                                :loading="loadingHistory"
                                title="Loading History..."
                            ></AonCoverLoading>
                            <AonRow class="mt-5">
                                <!-- Loop if this gets any bigger - BB -->
                                <AonCol
                                    v-for="dataPoint in litigationHistoryTabs"
                                    class="aon-col-3 py-0 pl-0 flex-column justify-content-center"
                                >
                                    <div class="mini-card pa-3 grey05">
                                        <p class="bold mb-2">{{ dataPoint.label }}</p>
                                        <div class="metric">
                                            <h2 class="grey01--text mb-n1" style="font-weight: 400">
                                                {{
                                                    valueFormatter(
                                                        dataPoint.valueType,
                                                        dataPoint.value
                                                    )
                                                }}
                                            </h2>
                                        </div>
                                    </div>
                                </AonCol>
                            </AonRow>
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonCoverLoading
            v-if="noData"
            :loading="noData ? true : false"
            :show-spinner="false"
            title="No Litigation History"
            :message="`${entityStore.entity.name} has not been identified as being involved in litigation`"
        >
            <template #header>
                <div class="icon">
                    <font-awesome-icon
                        icon="fas fa-circle-exclamation"
                        class="grey01--text"
                        size="2xl"
                    />
                </div>
            </template>
        </AonCoverLoading>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import { useEntityStore } from '@/stores'

import { getLitigationHistorySummary } from '@/api/vulnerabilities.js'

const entityStore = useEntityStore()

const filters = inject('filters')
const logger = inject('logger')

const loadingHistory = ref(false)
const litigationHistoryTabs = ref([
    {
        label: 'Cases as Plaintiff',
        valueType: 'number',
        value: null,
    },
    {
        label: 'Cases as Defendant',
        valueType: 'number',
        value: null,
    },
    {
        label: 'Court Awarded Damages',
        valueType: 'currency',
        value: null,
    },
    {
        label: 'NPE Cases',
        valueType: 'number',
        value: null,
    },
])
const noData = ref(false)

onMounted(() => {
    fetchMetrics()
})

const fetchMetrics = async () => {
    loadingHistory.value = true

    try {
        const { data } = await getLitigationHistorySummary(entityStore.entity.aon_entity_pk)
        const tabMapping = {
            'Cases as Plaintiff': 'casesPlaintiffCount',
            'Cases as Defendant': 'casesDefendantCount',
            'Court Awarded Damages': 'damagesIncurred',
            'NPE Cases': 'casesNPECount',
        }

        litigationHistoryTabs.value.forEach((tab) => {
            tab.value = data[tabMapping[tab.label]] ?? null
        })
    } catch (error) {
        logger.error(error)
    } finally {
        noData.value = litigationHistoryTabs.value.every((tab) => tab.value === 0)
        loadingHistory.value = false
    }
}

const valueFormatter = (valueType, value) => {
    if (valueType === 'currency') {
        return filters.abbreviate(value)
    } else if (valueType === 'number') {
        return filters.toLocaleString(value)
    } else {
        return value
    }
}
</script>
<style lang="scss" scoped>
.litigation-history-card {
    width: 100%;
    position: relative;

    .mini-card {
        border-radius: calc($border-radius * 2);
        border: solid 1px $grey04;
    }

    :deep(.a-cover-loading) {
        .holder {
            padding-top: 10px !important;
        }
    }
}
</style>
