<template>
    <div class="contender-selection-track">
        <SelectContenderSource v-if="!reportGenerationStore.shouldShowContenderSelection" />
        <SelectContenders
            v-else
            :key="updater"
            :all-table-data="allContenders"
            :loading="false"
            :report-step="4"
            :defaultGroupByUltimateToggle="groupByUltimateParentToggle"
            @refresh-competitor-table="refreshCompetitorTable"
        />
        <AonCoverLoading
            :loading="reportGenerationStore.shouldUpdateContenderList"
            title="Populating Potential Contenders"
        />
    </div>
</template>

<script setup>
import { ref, inject, onMounted, nextTick, computed, onActivated } from 'vue'
import { useReportGenerationStore, useMoat2ProductStore, useArenaOutputStore } from '@/stores'
import { getCompetitors } from '@/api/productAlignment.js'

import { default as SelectContenders } from '@/components/alignmentOutput/benchmarkPeers/OutputCompetitorSelection.vue'
import SelectContenderSource from './SelectContenderSource.vue'

const logger = inject('logger')

const reportGenerationStore = useReportGenerationStore()
const moat2ProductStore = useMoat2ProductStore()
const arenaOutputStore = useArenaOutputStore()
const emit = defineEmits(['update-tables'])

const updater = ref(0)
const groupByUltimateParentToggle = ref(true)
onMounted(async () => {
    await nextTick()
})

onActivated(async () => {
    await nextTick()
    if (reportGenerationStore.shouldUpdateContenderList) {
        reportGenerationStore.contenderSource = null
        try {
            await refreshCompetitorTable({
                groupByUltimateParent: reportGenerationStore.groupByUltimateParent,
            })
        } catch (err) {
            // catch if call fails
            logger.error(err)
        }
    }

    // set default group by ultimate parent toggle
    groupByUltimateParentToggle.value = arenaOutputStore.groupByUltimateParent
})

const refreshCompetitorTable = async ({ groupByUltimateParent }) => {
    try {
        reportGenerationStore.shouldUpdateContenderList = true
        groupByUltimateParentToggle.value = groupByUltimateParent
        await getCompetitors(
            moat2ProductStore.alignmentPk
                ? moat2ProductStore.alignmentPk
                : arenaOutputStore.alignmentPk,
            reportGenerationStore.reportSelections[2].value.map((techArea) => techArea.nodeId),
            null,
            groupByUltimateParent
        ).then((response) => {
            moat2ProductStore.allCompetitorData = response.data
            arenaOutputStore.allCompetitorData = response.data
            moat2ProductStore.productAlignmentTopCompetitors = response.data.slice(0, 8)
            if (
                reportGenerationStore.reportSelections[3] &&
                reportGenerationStore.reportSelections[3].value
            ) {
                reportGenerationStore.reportSelections[3].value = response.data.slice(0, 8)
            }
            reportGenerationStore.shouldUpdateContenderList = false
            updater.value++
        })
    } catch (err) {
        // catch if call fails
        logger.error(err)
    }
}

const allContenders = computed(() => {
    return moat2ProductStore.allCompetitorData
})
</script>

<style lang="scss" scoped>
.contender-selection-track {
    height: 100%;
    width: 100%;

    position: relative;
}
</style>
