import { defineStore } from 'pinia'
import { remove, uniqBy } from 'lodash-es'
import { COMPANY } from '@/lib/constants/screeners'
import * as Api from '@/api/screeners'
import { logger } from '@moatmetrics/vue-logger'
import { useAuth } from '@/auth/authPlugin'

export const useScreenerStore = (id = 'screener') =>
    defineStore(id, {
        state: () => ({
            companyScreener: {},
            companyScreenerModified: false,
            marketScreener: {},
            marketScreenerModified: false,
            screeners: [],
            recentlyVisitedScreenerPath: {},
            companyScreenerDefaultFilters: {},
            marketScreenerDefaultFilters: {},
            entityTableHeaders: [],
            sectorTableHeaders: [],
            screenersModalOpen: false,
            screenerSaveAsModalOpen: false,
            entitiesSelected: [],
            marketsSelected: [],
        }),
        getters: {
            anyEntitiesSelected: (state) => {
                return !!state.entitiesSelected.length
            },
            anyMarketsSelected: (state) => {
                return !!state.marketsSelected.length
            },
            isEntitySelected: (state) => (entityId) => {
                return state.entitiesSelected.some((e) => e.pk === entityId)
            },
            isMarketSelected: (state) => (marketId) => {
                return state.marketsSelected.some((e) => e.pk === marketId)
            },
            getEntitiesSelected: (state) => {
                return state.entitiesSelected
            },
            getMarketsSelected: (state) => {
                return state.marketsSelected
            },
            getEntitiesSelectedIds: (state) => {
                return state.entitiesSelected.map((es) => es.pk)
            },
            getMarketsSelectedIds: (state) => {
                return state.marketsSelected.map((es) => es.pk)
            },
            getEntitiesSelectedIdAndName: (state) => {
                const entitiesSelected = state.entitiesSelected
                const entitiesIdAndName = entitiesSelected.map((es) => {
                    return {
                        id: es.pk,
                        name: es.name,
                    }
                })

                return entitiesIdAndName
            },
            getMarketsSelectedIdAndName: (state) => {
                const marketsSelected = state.marketsSelected
                const marketsIdAndName = marketsSelected.map((es) => {
                    return {
                        id: es.pk,
                        name: es.name,
                    }
                })

                return marketsIdAndName
            },
        },
        actions: {
            async resetState() {
                this.$reset()
            },
            toggleScreenerModal() {
                this.screenersModalOpen = !this.screenersModalOpen
            },
            toggleScreenerSaveAsModal() {
                this.screenerSaveAsModalOpen = !this.screenerSaveAsModalOpen
            },
            async getScreener(screenerId) {
                try {
                    const screenerFromState = this.screeners.filter((s) => s.id === screenerId)

                    if (screenerFromState.length > 0) {
                        if (screenerFromState[0].type === COMPANY) {
                            this.companyScreener = screenerFromState[0]
                        } else {
                            this.marketScreener = screenerFromState[0]
                        }
                    } else {
                        const { data } = await Api.getScreener(screenerId)

                        if (data.type === COMPANY) {
                            this.companyScreener = data
                        } else {
                            this.marketScreener = data
                        }
                    }
                } catch (err) {
                    logger.error(err)
                    throw err
                }
            },
            async getScreeners() {
                try {
                    const { user } = useAuth()
                    const { data } = await Api.getScreeners(user.value.id)

                    if (!data[0]) return
                    this.screeners = data
                } catch (err) {
                    logger.error(err)
                    throw err
                }
            },
            // Mutations
            setCompanyScreener(screener) {
                this.companyScreener = { ...screener }
            },
            setMarketScreener(screener) {
                this.marketScreener = { ...screener }
            },
            updateCompanyScreener(screener) {
                if (this.companyScreener.id === screener.id) {
                    this.companyScreener = { ...screener }
                }

                const updated = this.screeners.find((s) => s.id === screener.id)

                Object.assign(updated, screener)
            },
            updateMarketScreener(screener) {
                if (this.marketScreener.id === screener.id) {
                    this.marketScreener = { ...screener }
                }
                const updated = this.screeners.find((s) => s.id === screener.id)

                Object.assign(updated, screener)
            },
            setScreeners(screeners) {
                this.screeners = screeners
            },
            addScreener(screener) {
                this.screeners.unshift(screener)
            },
            removeScreener(screener) {
                const screeners = [...this.screeners]

                remove(screeners, (s) => {
                    return s.id === screener.id
                })
                this.screeners = screeners
                if (screener.type === COMPANY && this.companyScreener.id === screener.id) {
                    this.companyScreener = {}
                } else if (this.marketScreener.id === screener.id) {
                    this.marketScreener = {}
                }
            },
            setRecentlyVisitedScreenerPath(screenerPath, context) {
                this.recentlyVisitedScreenerPath[context] = screenerPath
            },
            setCompanyScreenerDefaultFilters(filters) {
                this.companyScreenerDefaultFilters = filters
            },
            setMarketScreenerDefaultFilters(filters) {
                this.marketScreenerDefaultFilters = filters
            },
            setEntityTableHeaders(headers) {
                this.entityTableHeaders = headers
            },
            setSectorTableHeaders(headers) {
                this.sectorTableHeaders = headers
            },
            setCompanyScreenerModified(modified) {
                this.companyScreenerModified = modified
            },
            setMarketScreenerModified(modified) {
                this.marketScreenerModified = modified
            },
            addSelectedEntity(val) {
                const entitiesSelected = [...this.entitiesSelected, val]

                this.entitiesSelected = uniqBy(entitiesSelected, 'pk')
            },
            removeSelectedEntity(val) {
                const entitiesSelected = [...this.entitiesSelected]

                remove(entitiesSelected, (s) => {
                    return s.pk === val
                })
                this.entitiesSelected = entitiesSelected
            },
            setSelectedEntities(entitiesSelected) {
                this.entitiesSelected = entitiesSelected
            },
            addSelectedMarket(val) {
                const marketsSelected = [...this.marketsSelected, val]

                this.marketsSelected = uniqBy(marketsSelected, 'pk')
            },
            removeSelectedMarket(val) {
                const marketsSelected = [...this.marketsSelected]

                remove(marketsSelected, (s) => {
                    return s.pk === val
                })
                this.marketsSelected = marketsSelected
            },
            setSelectedMarkets(marketsSelected) {
                this.marketsSelected = marketsSelected
            },
        },
    })()
