<template>
    <AonModal
        class="markets-dialog"
        :title="$t('market.taxonomy')"
        @close="$emit('cancel')"
        @click:outside="$emit('cancel')"
    >
        <AonSpinner v-if="taxonomyTreeLoading" class="mx-auto" :scale="0.4" />
        <div v-else-if="error" class="error white--text pa-3">
            {{ $t('error.genericError') }}
        </div>
        <!-- TODO make custom transition and ditch vuetify -->
        <template v-else>
            <!-- <v-expand-transition> -->
            <div v-if="taxonomyTree" class="taxonomy-tree">
                <TreeView
                    :selected-market-id="selectedMarketId"
                    :market="taxonomyTree"
                    :markets="taxonomyTree.markets"
                    :depth="0"
                    @open="scrollToSelected"
                    @cancel="closeDialog"
                />
            </div>
            <!-- </v-expand-transition> -->
        </template>
    </AonModal>
</template>

<script>
import { mapState } from 'pinia'
import { useMarketsStore } from '@/stores'

import TreeView from '@/components/Taxonomy/TreeView'

export default {
    name: 'MarketsDialog',
    components: {
        TreeView,
    },
    props: {
        parentMarketId: {
            type: Number,
            required: true,
        },
        selectedMarketId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            error: false,
        }
    },
    computed: {
        ...mapState(useMarketsStore, ['taxonomyTree', 'taxonomyTreeLoading']),
    },
    async created() {
        this.error = false

        const payload = {
            parentMarketId: this.parentMarketId,
            selectedMarketId: this.selectedMarketId,
        }

        try {
            const marketsStore = useMarketsStore()

            await marketsStore.getTaxonomyTree(payload)
        } catch (err) {
            this.$logger.error(err)
            this.error = true
        }
    },
    methods: {
        scrollToSelected() {
            this.$nextTick(() =>
                document
                    .querySelector('.markets-dialog .selected')
                    .scrollIntoView({ behavior: 'smooth', block: 'center' })
            )
        },
        closeDialog() {
            this.$emit('cancel')
        },
    },
}
</script>

<style lang="scss">
.markets-dialog {
    .taxonomy-tree {
        padding: 1em;
        border: 1px solid $grey03;
        border-radius: 0.25em;
    }
}
</style>
