import { useAuth } from '@/auth/authPlugin'

export async function requestBearerHandler(config) {
    const authClient = useAuth()
    try {
        const token = await authClient.getAccessTokenSilently()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
    } catch (error) {
        await authClient.logout({
            async openUrl(url) {
                window.location.href = document.location.origin
            },
        })
    }

    return config
}

export const responseErrorHandler = async (error) => {
    const authClient = useAuth()
    if (error.response && error.response.status === 401) {
        await authClient.logout({
            async openUrl(url) {
                window.location.href = document.location.origin
            },
        })

        return Promise.reject(error)
    }

    if (error.response && !authClient.isAuthenticated.value) {
        location.reload(true)
    }

    return Promise.reject(error)
}
