<template>
    <div class="case-highlights-card grey04">
        <AonCard>
            <div class="card-content" style="min-height: 300px; position: relative">
                <AonDropdown
                    class="option-dropdown mb-4"
                    :class="{ disabled: loadingCases }"
                    :items="caseOptions"
                    label=""
                    :init-selection="caseOptions && caseOptions[0] ? caseOptions[0] : null"
                    @select-option="onSelectOption"
                />
                <AonCoverLoading
                    :loading="loadingCases"
                    title="Loading Case Data..."
                ></AonCoverLoading>
                <div v-show="!loadingCases" class="card-content-innner">
                    <div class="top d-flex align-items-start">
                        <div class="section d-flex align-items-center w-half flex-wrap mr-3">
                            <div
                                v-for="(value, key) in litCaseDetails"
                                :key="key"
                                class="line-item mb-3 w-full d-flex align-items-center"
                            >
                                <p class="no-break grey01--text">{{ formatLabel(key) }}:</p>
                                <div class="spacer w-full mx-1 mt-1"></div>
                                <p class="no-break grey01--text bold">
                                    {{ formatValue(key, value) }}
                                </p>
                            </div>
                        </div>
                        <div class="section d-flex align-items-center w-half mt-n10 ml-3">
                            <div id="amChartCaseHighlights"></div>
                        </div>
                    </div>
                    <div class="bottom d-flex align-items-start mt-5">
                        <div
                            class="top-plaintiffs section d-flex align-items-center w-half flex-wrap mr-3"
                        >
                            <h5 class="section-header w-full mb-4">Key Plaintiffs</h5>
                            <div
                                class="column-headers w-full d-flex justify-space-between align-items-center mb-4"
                            >
                                <p class="bold w-full">Name</p>
                                <p class="bold no-break">Case Count</p>
                            </div>
                            <div
                                v-for="(company, key) in topPlaintiffs"
                                :key="key"
                                class="line-item mb-3 w-full d-flex align-items-center"
                            >
                                <p class="no-break grey01--text">{{ company.entityName }}:</p>
                                <div class="spacer w-full mx-1 mt-1"></div>
                                <p class="no-break grey01--text bold">
                                    {{
                                        typeof company.caseCount === 'number'
                                            ? company.caseCount.toLocaleString()
                                            : company.caseCount
                                    }}
                                </p>
                            </div>
                        </div>
                        <div
                            class="top-defendants section d-flex align-items-center w-half flex-wrap ml-3"
                        >
                            <h5 class="section-header w-full mb-4">Key Defendants</h5>
                            <div
                                class="column-headers w-full d-flex justify-space-between align-items-center mb-4"
                            >
                                <p class="bold w-full">Name</p>
                                <p class="bold no-break">Case Count</p>
                            </div>
                            <div
                                v-for="(company, key) in topDefendants"
                                :key="key"
                                class="line-item mb-3 w-full d-flex align-items-center"
                            >
                                <p class="no-break grey01--text">{{ company.entityName }}:</p>
                                <div class="spacer w-full mx-1 mt-1"></div>
                                <p class="no-break grey01--text bold">
                                    {{
                                        typeof company.caseCount === 'number'
                                            ? company.caseCount.toLocaleString()
                                            : company.caseCount
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted, inject } from 'vue'
import { getLitigationSummary } from '@/api/ipNode'

import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})

const logger = inject('logger')
const filters = inject('filters')
let root = null
let chart = null

const loadingCases = ref(true)
const caseOptions = ref([
    {
        itemText: 'Infringement Cases',
        value: 'Infringement',
    },
    {
        itemText: 'PTAB',
        value: 'Ptab',
    },
    {
        itemText: 'ITC',
        value: 'Itc',
    },
])
const selectedCaseType = ref('Infringement')

const litCaseDetails = ref(null)
const chartData = ref(null)
const topPlaintiffs = ref([])
const topDefendants = ref([])

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartCaseHighlights')
    onGetLitigationSummary()
})

onBeforeUnmount(() => {
    root.dispose()
})

const onGetLitigationSummary = async () => {
    loadingCases.value = true

    if (root && root.container && root.container.children) {
        root.container.children.clear()
    }

    try {
        const params = { litType: selectedCaseType.value ? selectedCaseType.value : 'Infringement' }
        const { data } = await getLitigationSummary(props.nodeData.id, params)

        litCaseDetails.value = {
            avgCaseLength: data.avgCaseLength,
            caseCount: data.caseCount,
            casePatentCount: data.casePatentCount,
            npeCaseCount: data.npeCaseCount,
            npeEntityCount: data.npeEntityCount,
        }

        if (selectedCaseType.value === 'Infringement') {
            litCaseDetails.value.maxIndividualDamages = data.maxIndividualDamages
            litCaseDetails.value.totalDamages = data.totalDamages
        }

        chartData.value = data.resolutionCounts.map((item) =>
            item.resolution === 'Claimant Wins' ? { ...item, resolution: 'Plaintiff Wins' } : item
        )
        topPlaintiffs.value = data.topPlaintiffs || []
        topDefendants.value = data.topDefendants || []

        configureChart()
    } catch (error) {
        logger.error(error)
    } finally {
        loadingCases.value = false
    }
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])
    chart = root.container.children.push(
        am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
        })
    )

    generateSeries()
}

const generateSeries = () => {
    let series = chart.series.push(
        am5percent.PieSeries.new(root, {
            valueField: 'count',
            categoryField: 'resolution',
        })
    )

    series
        .get('colors')
        .set('colors', [
            am5.color('#84FFFF'),
            am5.color('#FFF59D'),
            am5.color('#69F0AE'),
            am5.color('#FF9E80'),
            am5.color('#0091EA'),
            am5.color('#7C4DFF'),
        ])

    series.labels.template.setAll({
        fontSize: 14,
        text: '{category} - [bold]{value}[/]',
    })

    series.data.setAll(chartData.value)

    let legend = chart.children.push(
        am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.percent(55),
            paddingTop: 20,
        })
    )

    legend.labels.template.setAll({
        fontSize: 13,
        fontWeight: '300',
    })

    legend.valueLabels.template.setAll({
        fontSize: 13,
        fontWeight: '600',
    })

    legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 16,
        height: 16,
    })

    legend.data.setAll(series.dataItems)
    series.appear(1000, 100)
}

const onSelectOption = (option) => {
    selectedCaseType.value = option.value
    onGetLitigationSummary()
}

const formatLabel = (key) => {
    return key
        .split(/(?=[A-Z])/)
        .join(' ')
        .replace(/\b\w/g, (l) => l.toUpperCase())
        .replace(/Npe/g, 'NPE')
}

const formatValue = (key, value) => {
    if (key === 'avgCaseLength') {
        return `${value} yrs`
    } else if (key === 'maxIndividualDamages' || key === 'totalDamages') {
        return filters.abbreviate(value)
    } else {
        return parseInt(value).toLocaleString()
    }
}
</script>

<style lang="scss" scoped>
.case-highlights-card {
    width: 100%;

    .option-dropdown {
        max-width: 300px;
    }

    .section {
        .no-break {
            white-space: nowrap;
        }
        .spacer {
            height: 10px;
            background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 8px 1px;
            background-repeat: repeat-x;
        }
    }

    #amChartCaseHighlights {
        width: 100%;
        height: 260px;

        div {
            height: 100%;
        }
    }
}
</style>
