<template>
    <div
        class="arena-control-button"
        v-tooltip="{
            content: tooltipContent,
            delay: { show: 500, hide: 100 },
            enabled: tooltipEnabled,
        }"
    >
        <div
            class="icon-holder py-3 px-6 d-flex justify-content-center cursor-pointer"
            :class="{ disabled: disabled }"
            @click="emit('clicked')"
        >
            <font-awesome-icon :icon="icon" class="white--text" size="xl" />
            <slot />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const emit = defineEmits(['clicked'])

const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    disabledMsg: {
        type: String,
        default: 'Disabled',
    },
    tooltipMsg: {
        type: String,
        default: null,
    },
})

const tooltipEnabled = computed(() => {
    return props.tooltipMsg !== null || props.disabled
})

const tooltipContent = computed(() => {
    return props.disabled ? props.disabledMsg : props.tooltipMsg
})
</script>

<style lang="scss" scoped>
.arena-control-button {
    width: 44px;
    height: 44px;

    transform: translateX(0);
    transition: all 0.3s ease-in-out;

    .icon-holder {
        border-radius: 50%;
        box-shadow: 0px 3px 13px 0px rgba(0, 17, 53, 0.6);
        background: $castleMoat;

        transition: all 0.3s ease-in-out;

        &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}
</style>
