<template>
    <div class="card-warning d-flex">
        <div class="label-holder py-1 px-5 d-flex" v-tooltip="{ content: props.warningMsg }">
            <font-awesome-icon
                icon="fas fa-circle-exclamation"
                class="black--text d-inline mr-2"
                size="sm"
            />
            <p class="extra-small bold">{{ warningLabel }}</p>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['tab-clicked'])

const props = defineProps({
    warningLabel: {
        type: String,
        default: 'Label',
    },
    warningMsg: {
        type: String,
        default: 'Msg goes here',
    },
})

const setTab = (id) => {
    emit('tab-clicked', id)
}
</script>

<style lang="scss" scoped>
.card-warning {
    width: 100%;
    height: 100%;
    border: $warning 3px solid;
    border-radius: 8px;
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .label-holder {
        min-width: 140px;
        background: $warning;
        border-radius: 0 0 12px;
        cursor: pointer;
        pointer-events: all;

        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
