<template>
    <div class="popper-patent-jurisdictions pa-4">
        <div
            v-for="(jurisdiction, i) in jurisdictions"
            :key="`jurisdiction_${i}`"
            class="jurisdiction pl-4"
        >
            <p class="title">
                {{ $t(`jurisdictions.${jurisdiction.country}`) }}
            </p>
            <p class="count">
                Count:
                {{ $filters.toLocaleString(jurisdiction.assetCount) }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
// import { useI18n } from 'vue-i18n'
// const { t } = useI18n()
const props = defineProps({
    jurisdictions: {
        type: Object,
        required: true,
    },
})

// weird linting for props var
const jurisdictions = computed(() => {
    return props.jurisdictions
})
</script>

<style scoped lang="scss">
.popper-patent-jurisdictions {
    .jurisdiction {
        color: $grey02;
        font-size: 12px;
        .title {
            font-weight: 700;
        }
        .count {
            font-weight: 400;
        }
    }
}
</style>
