<template>
    <div class="snackbar elevation-5">
        <transition name="snackbar">
            <AonAlertBox
                v-if="snackbar"
                v-model="snackbar"
                is-contextual
                :type="type"
                :title="title"
                can-close
                @close="snackbar = false"
            />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Snackbar',
    components: {},
    data() {
        return {
            snackbar: false,
            type: 'info',
            timeout: 10000,
            title: '',
            description: '',
            alertOptions: null,
        }
    },
    created() {
        this.eventBus.on('snacktime', this.updateSnackbar)
    },
    methods: {
        updateSnackbar(options) {
            const {
                color = 'info',
                type,
                timeout = 10000,
                persistent,
                title,
                message,
                description,
                ...additional
            } = options

            this.type = type || color
            this.timeout = timeout
            this.title = title || message
            this.description = description
            this.alertOptions = additional

            this.snackbar = true

            if (!persistent) {
                this.initTimeout()
            }
        },

        initTimeout() {
            setTimeout(() => {
                this.snackbar = false
            }, this.timeout)
        },
    },
}
</script>

<style lang="scss" scoped>
.snackbar {
    position: fixed;
    top: 8rem;
    right: 1rem;
    max-width: 30rem;
    width: 100%;
    z-index: 9000;

    .snackbar-enter-active,
    .snackbar-leave-active {
        transition:
            opacity 250ms,
            transform 250ms;
    }

    .snackbar-enter,
    .snackbar-leave-to {
        opacity: 0;
        transform: translateX(2rem);
    }
}
</style>
