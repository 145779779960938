<template>
    <div class="search-screen-tabs knights-cloak-80">
        <AonContainer add-page-padding>
            <AonRow>
                <AonCol class="aon-col-12 pa-0">
                    <AonTabs
                        class="dark"
                        :current-tab="currentTab"
                        :tabs="tabData"
                        @tab-change="tabChange"
                    />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
    <div v-if="currentTab === 1" class="market-search">
        <AonContainer add-page-padding>
            <AonRow>
                <AonCol class="aon-col-12 px-0">
                    <SearchTable />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
    <div v-if="currentTab === 2" class="market-screener">
        <ScreenerHeader
            :loading="loading"
            :saving="saving"
            :screener="screener"
            :screenerModified="screenerModified"
            @openSaveAsModal="openSaveAsModal"
            @openScreenerModal="openScreenerModal"
            @resetScreener="resetScreener"
            @saveScreener="saveScreener"
        />
        <AonContainer add-page-padding>
            <AonRow>
                <AonCol class="aon-col-12 px-0">
                    <ScreenerMarketTable v-if="!loading" />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>

    <ScreenerCreateCard
        v-if="showSaveAsScreeners"
        :screener="screener"
        :query-filters="queryFilters"
        type="MARKET"
        @cancel="closeSaveAsModal"
        @submit="closeSaveAsModal"
    />
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useSharedScreenerComposable } from '@/composables/screener'

import SearchTable from '@/components/markets/MarketsSearchTable'
import ScreenerHeader from '@/components/screeners/ScreenerHeader'
import ScreenerMarketTable from '@/components/screeners/ScreenerMarketTable'
import ScreenerCreateCard from '@/components/screeners/ScreenerCreateCard'

// ===================
// SCREENER COMPOSABLE
// ===================
const {
    loading,
    saving,
    screener,
    screenerModified,
    showSaveAsScreeners,
    queryFilters,
    closeSaveAsModal,
    saveScreener,
    resetScreener,
    openScreenerModal,
    openSaveAsModal,
} = useSharedScreenerComposable('MARKET')

const route = useRoute()
const { t } = useI18n()

const currentTab = ref(1)
const tabData = ref([
    {
        text: t('searchScreen.tabs.search'),
        id: 1,
    },
    {
        text: t('searchScreen.tabs.screen'),
        id: 2,
    },
])

onMounted(() => {
    if (route.query.showScreener) {
        currentTab.value = 2
    }
})

const tabChange = (val) => {
    currentTab.value = val.id
}
</script>

<style scoped lang="scss">
.market-screener,
.market-search {
    min-height: 100vh;
    height: 100%; // minus header nav and tabs
    width: 100%;
}
</style>
