<template>
    <div class="project-strategy">
        <AonCard
            title="Product Strategy"
            :desc="`These are ${entityStore.entity ? entityStore.entity.name + '\'s' : ''} top product classes based on momentum.`"
        >
            <div class="content-holder">
                <p class="view-more-link link small" @click="gotoMoat2ProductTab()">View More</p>
                <AonCoverLoading
                    :loading="loadingData"
                    title="Loading Product Strategy..."
                    message="This might take a few minutes if a company hasn't been analyzed before."
                ></AonCoverLoading>
                <AonCoverLoading
                    v-if="noData"
                    :loading="noData ? true : false"
                    :show-spinner="false"
                    title="No Product Strategy Identified"
                >
                    <template #header>
                        <div class="icon">
                            <font-awesome-icon
                                icon="fas fa-circle-exclamation"
                                class="grey01--text"
                                size="2xl"
                            />
                        </div>
                    </template>
                </AonCoverLoading>
                <div id="amChartProdStrat"></div>
                <div class="legend-container">
                    <div class="range"></div>
                    <div
                        class="label-container d-flex w-full align-items-center justify-content-between"
                    >
                        <p class="extra-small bold mt-1">Less Market Appeal</p>
                        <p class="extra-small bold mt-1">More Market Appeal</p>
                    </div>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref, inject, watch, onBeforeUnmount, nextTick } from 'vue'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import { getCompanyProductStrategy } from '@/api/companyDashboard.js'
import { useRoute, useRouter } from 'vue-router'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

import { config } from '@/config'
const logger = inject('logger')
const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()
const router = useRouter()
const route = useRoute()

const loadingData = ref(true)
const noData = ref(false)
const chartData = ref(null)

let root
let chart
let xAxis
let xData
let products

onMounted(async () => {
    await nextTick()
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartProdStrat')
})

onBeforeUnmount(() => {
    root.dispose()
})

watch(
    () => moat2ProductStore.productAlignmentLoading,
    (newVal, oldVal) => {
        if (!newVal) {
            getAndMapData()
        }
    }
)

const getAndMapData = async () => {
    if (!root) {
        return
    }
    loadingData.value = true
    try {
        let { data } = await getCompanyProductStrategy(entityStore.entity.aon_entity_pk)

        if (data.length === 0) {
            noData.value = true
            loadingData.value = false
            return
        }
        chartData.value = data
        xData = processData()
        products = [...new Set(chartData.value.map((comp) => comp.productClass))]
            .map((productClass) => {
                const productEntries = chartData.value.filter(
                    (item) => item.productClass === productClass
                )
                const entriesWithTpe = productEntries.filter((entry) => entry.tpe !== 0)

                if (entriesWithTpe.length === 0) {
                    return null
                }

                const latestYearWithTpe = Math.max(
                    ...entriesWithTpe.map((entry) => entry.publicationYear)
                )
                const latestTpe = entriesWithTpe.find(
                    (entry) => entry.publicationYear === latestYearWithTpe
                ).tpe

                return {
                    product: productClass,
                    tpe: latestTpe,
                }
            })
            .filter((product) => product !== null)

        setTimeout(() => {
            configureChart()
            loadingData.value = false
        }, 100)
    } catch (error) {
        logger.error(error)
    }
}

const configureChart = () => {
    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false, // Disable horizontal panning
            panY: false, // Disable vertical panning
            wheelX: 'none', // Disable zooming on X-axis via scroll wheel
            wheelY: 'none', // Disable zooming on Y-axis via scroll wheel
            pinchZoomX: false, // Disable pinch zoom on X-axis
            pinchZoomY: false, // Disable pinch zoom on Y-axis
            maxTooltipDistance: 0,
        })
    )
    generateXAxes()

    products.forEach((prod) => {
        createSeries(prod)
    })

    chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
            behavior: 'none',
            xAxis: xAxis,
        })
    )
    chart.appear(1000, 100)
}

const generateXAxes = () => {
    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'publicationYear',
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 20,
            }),
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    let xRenderer = xAxis.get('renderer')

    xRenderer.labels.template.setAll({
        rotation: -45,
        location: 0.5,
        multiLocation: 0.5,
        centerX: am5.p100,
        centerY: am5.p50,
        fontSize: 12,
    })

    xRenderer.grid.template.setAll({
        location: 0.5,
        multiLocation: 0.5,
    })
    xAxis.data.setAll(xData)

    chart.leftAxesContainer.setAll({
        layout: root.verticalLayout,
    })
}

const createSeries = (prod) => {
    console.log(prod)
    const globalBounds = findGlobalBounds()
    let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            maxDeviation: 0,
            strictMinMax: true,
            extraMin: 0.05,
            extraMax: 0.05,
            renderer: am5xy.AxisRendererY.new(root, {}),
            min: globalBounds.low,
            max: globalBounds.high,
        })
    )

    let yRenderer = yAxis.get('renderer')

    yRenderer.labels.template.setAll({
        forceHidden: true,
    })

    yRenderer.grid.template.setAll({
        forceHidden: true,
    })

    let series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'high',
            openValueYField: 'low',
            categoryXField: 'publicationYear',
            fill: am5.Color.interpolate(prod.tpe / 100, am5.color('#BCC9DF'), am5.color('#2A79D2')),
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    series
        .get('tooltip')
        .label.set(
            'text',
            `Product Class: [bold]${prod.product}[/]\nInnovation Score: [bold]{innovationScore}[/]\nMarket Appeal: [bold]${prod.tpe}[/]`
        )

    series.fills.template.setAll({
        fillOpacity: 1,
        visible: true,
    })

    series.data.setAll(processData(prod.product))

    yAxis.children.unshift(
        am5.Label.new(root, {
            text:
                prod.product.length > 40
                    ? prod.product.substring(0, 40) + '...'
                    : prod.product.substring(0, 40),
            y: am5.percent(16),
            x: am5.percent(100),
            centerX: am5.percent(100),
            fill: am5.color(0xffffff),
            fontWeight: '600',
            background: am5.RoundedRectangle.new(root, {
                fill: series.get('fill'),
            }),
        })
    )
}
const processData = (productName = null) => {
    const filteredData = productName
        ? chartData.value.filter((item) => item.productClass === productName)
        : chartData.value

    return filteredData
        .reduce((acc, curr) => {
            const existingYear = acc.find((item) => item.publicationYear === curr.publicationYear)
            if (existingYear) {
                existingYear.innovationScore += curr.innovationScore
            } else {
                acc.push({
                    publicationYear: curr.publicationYear,
                    innovationScore: curr.innovationScore,
                })
            }
            return acc
        }, [])
        .map((item) => ({
            publicationYear: item.publicationYear.toString(),
            innovationScore: Math.ceil(item.innovationScore),
            high: Math.ceil(item.innovationScore / 4),
            low: Math.ceil(item.innovationScore / 4) * -1,
        }))
        .sort((a, b) => a.publicationYear.localeCompare(b.publicationYear))
}

const findGlobalBounds = () => {
    const innovationScore = Math.max(...chartData.value.map((item) => item.innovationScore))

    return {
        high: Math.ceil(innovationScore),
        low: Math.ceil(innovationScore) * -1,
    }
}

const gotoMoat2ProductTab = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: 'moat2Product' },
    })
}
</script>

<style lang="scss" scoped>
.project-strategy {
    width: 100%;
    position: relative;

    #amChartProdStrat {
        width: 100%;
        height: 300px;

        div {
            height: 100%;
        }
    }

    .view-more-link {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .legend-container {
        width: 100%;

        .range {
            width: 100%;
            height: 10px;
            background: linear-gradient(
                90deg,
                rgba(188, 201, 223, 1) 0%,
                rgba(42, 121, 210, 1) 100%
            );
        }
    }
}
</style>
