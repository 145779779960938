import axios from 'axios'

const baseUrl = 'api/litigation'

export function getEntitySummary(entityId) {
    return axios.get(`${baseUrl}/entity/${entityId}?years=8`)
}

export function getRiskByTargetSector(sectorId) {
    return axios.get(`${baseUrl}/sector/${sectorId}/risk`)
}

export function getSectorCases(sectorId, years) {
    return axios.get(`${baseUrl}/sector/${sectorId}/cases?years=${years}`)
}

export function getSectorNpeCases(sectorId, years) {
    return axios.get(`${baseUrl}/sector/${sectorId}/npe_cases?years=${years}`)
}

export function getSectorCaseHighestDamages(sectorId, years) {
    return axios.get(`${baseUrl}/sector/${sectorId}/highest_damages?years=${years}`)
}

export function getSectorCaseTotalDamages(sectorId, years) {
    return axios.get(`${baseUrl}/sector/${sectorId}/total_damages?years=${years}`)
}

export function getSectorAverageCaseDuration(sectorId, years) {
    return axios.get(`${baseUrl}/sector/${sectorId}/case_duration?years=${years}`)
}
