<template>
    <div class="competitor-innovation-focus-card mt-5">
        <p class="extra-small uppercase castle-moat--text bold">Competitor Innovation Focus</p>
        <h5 class="grey01--text">What are peers doing within {{ props.nodeData.displayName }}?</h5>
        <div class="competitors-holder">
            <AonSpinner v-if="loadingCompetitors" class="ml-auto mr-auto mt-0" :scale="0.3" />
            <div v-else class="competitor">
                <CompanyFocus
                    v-for="competitor in competitorList"
                    :headerName="competitor.aonEntityName"
                    :nodeData="props.nodeData"
                    :entityPk="competitor.aonEntityPk"
                    :type="props.type"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import { useEntityStore, useArenaOutputStore } from '@/stores'
import { getNodeCompetitors } from '@/api/ipNode'

import CompanyFocus from './shared/CompanyFocus.vue'
import { type } from '@amcharts/amcharts5'

const entityStore = useEntityStore()
const arenaOutputStore = useArenaOutputStore()
const logger = inject('logger')

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})

const loadingCompetitors = ref(true)
const competitorList = ref(null)

onMounted(() => {
    onGetNodeCompetitors()
})

const onGetNodeCompetitors = async () => {
    loadingCompetitors.value = true
    try {
        const { data } = await getNodeCompetitors(props.nodeData.id)
        const filterCompetitors = (data, excludeName) =>
            data.filter((item) => item.aonEntityName !== excludeName).slice(0, 5)

        if (props.type === 'pa') {
            competitorList.value = filterCompetitors(data, entityStore.entity.name)
        } else {
            competitorList.value = arenaOutputStore.targetCompany[0]
                ? filterCompetitors(data, arenaOutputStore.targetCompany[0].aonEntityName)
                : data.slice(0, 5)
        }
    } catch (error) {
        logger.error(error)
    } finally {
        loadingCompetitors.value = false
    }
}
</script>

<style lang="scss" scoped>
.competitor-innovation-focus-card {
    width: 100%;

    .competitors-holder {
        min-height: 50px;
    }
}
</style>
