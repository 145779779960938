import { ref } from 'vue'
import { defineStore } from 'pinia'

import {
    setVisibleContextMenu,
    getVisibleContextMenu,
    removeVisibleContextMenu,
} from '../lib/contextLocalStorage'

export const useArenaStore = defineStore('arena', () => {
    // state
    const boardGlobalDetails = ref(null)
    const rootNodeId = ref(null)
    const rootPAId = ref(null)
    const boardData = ref([])
    const selectedNode = ref(null)
    const selectedNodeParent = ref(null)
    const selectedNodeChildren = ref(null)
    const focusedNode = ref(null)
    const arenaBoardLoading = ref(true)
    const allowGenerate = ref(false)
    const showEditModal = ref(false)
    const showDeleteConfirmation = ref(false)
    const showCompanyModal = ref(false)
    const showConceptGeneration = ref(false)
    const hideContextList = ref([])
    const activeContextId = ref(null)
    const forceConceptInputClose = ref(false)
    const areaBoardSteps = ref([
        {
            stepName: 'Arena Board',
            stepDesc:
                'Begin by adding companies or concepts, and then drill down to define your domain. Aim to narrow down the arena to the broadest definition of competitive advantage.',
            currentStep: true,
            completedStep: false,
            stepNum: 1,
        },
        {
            stepName: 'Patent Space',
            stepDesc:
                'Explore the material components emerging from your defined arena. View a list of companies and benchmark them against each other for the entire arena as well as for each material component. This comprehensive view offers a clear understanding of the competitive IP landscape within your selected domain.',
            currentStep: false,
            completedStep: false,
            stepNum: 2,
        },
        {
            stepName: 'Report Generation',
            stepDesc: '',
            currentStep: false,
            completedStep: false,
            stepNum: 3,
            stepLabel: '3',
        },
    ])

    // actions
    const resetState = () => {
        boardGlobalDetails.value = null
        boardData.value = []
        rootNodeId.value = null
        rootPAId.value = null
        selectedNode.value = null
        selectedNodeParent.value = null
        selectedNodeChildren.value = null
        focusedNode.value = null
        arenaBoardLoading.value = true
        showEditModal.value = false
        showDeleteConfirmation.value = false
        showCompanyModal.value = false
        showConceptGeneration.value = false
        allowGenerate.value = false
        activeContextId.value = null
        hideContextList.value = []
        forceConceptInputClose.value = false
    }

    const checkContextVibility = (contextId) => {
        let validContext = getVisibleContextMenu(contextId)

        if (validContext) {
            hideContextList.value.push(contextId)
        }
    }

    const assignContextVibility = (contextId, bool) => {
        setVisibleContextMenu(contextId, bool)
    }

    const removeContextVibility = (contextId, bool) => {
        removeVisibleContextMenu(contextId)
    }

    const setCurrentStep = (step) => {
        areaBoardSteps.value.forEach((s) => {
            s.currentStep = false
        })

        let targetCurrentStep = areaBoardSteps.value.find((s) => s.stepNum === step.stepNum)
        targetCurrentStep.currentStep = true
        targetCurrentStep.disabled = false
    }

    const setCompletedStep = (step) => {
        // Complete step
        const targetStep = areaBoardSteps.value.find((s) => s.stepNum === step)
        targetStep.completedStep = true

        if (targetStep.substeps && targetStep.substeps.length > 0) {
            targetStep.substeps.forEach((substep) => {
                substep.completedStep = true
            })
        }

        // if there is a next step, set currentStep to that one
        const newCurrentStepIndex = areaBoardSteps.value.findIndex((s) => s.stepNum === step)
        const newCurrentStep = areaBoardSteps.value[newCurrentStepIndex + 1]
        if (newCurrentStep) {
            setCurrentStep(newCurrentStep)
        }
    }

    return {
        boardGlobalDetails,
        boardData,
        hideContextList,
        checkContextVibility,
        assignContextVibility,
        areaBoardSteps,
        setCurrentStep,
        setCompletedStep,
        resetState,
        arenaBoardLoading,
        showCompanyModal,
        rootNodeId,
        rootPAId,
        showConceptGeneration,
        selectedNode,
        selectedNodeParent,
        selectedNodeChildren,
        focusedNode,
        activeContextId,
        forceConceptInputClose,
        removeContextVibility,
        allowGenerate,
        showEditModal,
        showDeleteConfirmation,
    }
})
