import { onBeforeMount, ref } from 'vue'
import { formatFilterType } from '@/lib/helpers'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export function useScreenerTableComposable(type, initDefaultSort, forceDefaultSort = false) {
    const route = useRoute()
    const defaultFilter = ref([])
    const defaultSort = ref([...initDefaultSort])
    const defaultFilterColumns = ref([])
    const { t } = useI18n()

    onBeforeMount(async () => {
        if (route.query.filter) {
            const json = atob(route.query.filter)
            const screener = JSON.parse(json)
            if (screener.filters && screener.filters.length) {
                defaultFilterColumns.value = screener.filters.map((f) => f.filterColumn)
                const tType = type === 'COMPANY' ? 'company' : 'market'
                defaultFilter.value = screener.filters.map((f) => {
                    let value = f.filterValue
                    if (f.filterType === 'dt_range') {
                        value = f.filterValue.replace('~', '|')
                    }
                    return {
                        ...f,
                        filterType: formatFilterType(f.filterType),
                        filterLabel: t(`screeners.${tType}.headers.${f.filterColumn}`),
                        filterValue: value,
                    }
                })
            }
            if (screener.sorting && screener.sorting.length && !forceDefaultSort) {
                // For now, we only support a single sort column
                defaultSort.value = screener.sorting
            }
        }
    })

    const isDefaultFilter = (filterValue) => {
        return defaultFilterColumns.value.includes(filterValue)
    }

    return {
        defaultFilter,
        defaultSort,
        isDefaultFilter,
    }
}
