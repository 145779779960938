import { defineStore } from 'pinia'
import * as DatasetApi from '@/api/datasets'
import { logger } from '@moatmetrics/vue-logger'
import { useAuth } from '@/auth/authPlugin'

export const useDatasetStore = defineStore('dataset', {
    state: () => ({
        datasetPageData: {
            results: [],
            currentPage: 1,
            rowCount: 0,
            pageSize: 10,
        },
        fetchingDatasets: false,
        datasetToEdit: null,
        idsToAdd: null,
        showOnlyCreateWatchlists: false,
    }),
    getters: {
        getDatasetByPk: (state) => (pk) => {
            pk = parseInt(pk)
            return state.datasetPageData.results.find((ds) => ds.dataset_pk === pk)
        },
    },
    actions: {
        async getDatasets(params, userId) {
            if (!this.fetchingDatasets) {
                this.fetchingDatasets = true
                try {
                    const { data } = await DatasetApi.getDatasets(params, userId)
                    this.datasetPageData = data
                } catch (err) {
                    logger.error(err)
                    throw err
                } finally {
                    this.fetchingDatasets = false
                }
            }

            return this.datasetPageData
        },
        async createDataset(dataset) {
            try {
                await DatasetApi.createDataset(dataset)
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async editDataset(datasetPk, dataset_name, dataset_description) {
            try {
                const { user } = useAuth()

                await DatasetApi.updateDataset(
                    datasetPk,
                    user.value.id,
                    dataset_name,
                    dataset_description
                )
                await this.refreshDataset(datasetPk)
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        validateTypeContext(context) {
            if (context !== 'Entity' && context !== 'Sector') {
                throw new Error(`Context must be either "Entity" or "Sector", received ${context}`)
            }
        },
        async addToDataset(datasetPk, type, values) {
            try {
                this.validateTypeContext(type)

                const { user } = useAuth()

                await DatasetApi.addToDataset(datasetPk, user.value.id, type, values)
                await this.refreshDataset(datasetPk)
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async removeFromDataset(datasetPk, type, values) {
            try {
                this.validateTypeContext(type)
                await DatasetApi.removeFromDataset(datasetPk, type, values)
                await this.refreshDataset(datasetPk)
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async deleteDataset(datasetPks) {
            for (let pk of datasetPks) {
                try {
                    await DatasetApi.deleteDataset(pk)
                    let indexToRemove = this.datasetPageData.results.find(
                        (ds) => ds.dataset_pk === pk
                    )
                    if (indexToRemove >= 0) {
                        this.datasetPagedData.results.remove(indexToRemove)
                    }
                } catch (err) {
                    logger.error(err)
                    throw err
                }
            }
        },
        async refreshDataset(datasetPk) {
            datasetPk = parseInt(datasetPk)
            let dataset = await DatasetApi.getDataset(datasetPk)
            let updateIndex = this.datasetPageData.results.findIndex(
                (ds) => ds.dataset_pk === datasetPk
            )
            if (updateIndex >= 0) {
                this.datasetPageData.results[updateIndex] = dataset.data
            } else {
                this.datasetPageData.results.push(dataset.data)
            }
            return dataset.data
        },
        setIdsToAdd(idsToAdd) {
            this.idsToAdd = idsToAdd
        },
        setDatasetToEdit(dataset) {
            this.datasetToEdit = dataset
        },
        toggleShowOnlyCreateWatchLists(bool = true) {
            this.showOnlyCreateWatchlists = bool
        },
    },
})
