import { computed, onBeforeMount, ref, watch } from 'vue'
import { useScreenerStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'
import * as screenerApi from '@/api/screeners'
import { COMPANY_SCREENER } from '@/lib/constants/route-names'

export function useSharedScreenerComposable(type) {
    const loading = ref(true)
    const saving = ref(false)
    const route = useRoute()
    const router = useRouter()
    const screenerStore = useScreenerStore()

    const routeName = type === 'COMPANY' ? COMPANY_SCREENER : 'Markets'
    const context = type === 'COMPANY' ? 'entities' : 'sectors'

    onBeforeMount(async () => {
        if (screener.value && screener.value.id !== route.params.screenerId) {
            if (type === 'COMPANY') {
                screenerStore.setCompanyScreener(null)
                screenerStore.setCompanyScreenerModified(false)
            } else {
                screenerStore.setMarketScreener(null)
                screenerStore.setMarketScreenerModified(false)
            }
        }
        await loadScreener()
        screenerStore.setRecentlyVisitedScreenerPath(
            { path: route.path, query: route.query },
            context
        )
    })

    // Reloads screener when the screenerId changes
    watch(
        () => route.params.screenerId,
        async () => {
            if (route.name === routeName) {
                await loadScreener()
            }
        }
    )

    // Checks if the screener has been modified
    watch(
        () => route.query.filter,
        async () => {
            if (route.name === routeName) {
                if (!route.query.filter) {
                    await setFilter()
                }
                if (screener.value) {
                    if (type === 'COMPANY') {
                        screenerStore.setCompanyScreenerModified(
                            screener.value.query !== route.query.filter
                        )
                    } else {
                        screenerStore.setMarketScreenerModified(
                            screener.value.query !== route.query.filter
                        )
                    }
                    screenerStore.setRecentlyVisitedScreenerPath(
                        {
                            path: route.path,
                            query: route.query,
                        },
                        context
                    )
                }
            }
        }
    )

    //computed
    const screener = computed(() => {
        if (type === 'COMPANY') {
            return screenerStore.companyScreener
        }
        return screenerStore.marketScreener
    })

    const screenerModified = computed(() => {
        if (type === 'COMPANY') {
            return screenerStore.companyScreenerModified
        }
        return screenerStore.marketScreenerModified
    })

    const showSaveAsScreeners = computed(() => {
        return screenerStore.screenerSaveAsModalOpen
    })

    const queryFilters = computed(() => {
        if (!route.query.filter) {
            return { filters: [], sorting: [] }
        }
        return route.query.filter
    })

    const closeSaveAsModal = () => {
        screenerStore.toggleScreenerSaveAsModal()
    }

    const saveScreener = async () => {
        saving.value = true
        if (type === 'COMPANY') {
            const screener = { ...screenerStore.companyScreener, query: route.query.filter }

            await screenerApi.updateScreener(screener)
            screenerStore.setCompanyScreenerModified(false)
        } else {
            const screener = { ...screenerStore.marketScreener, query: route.query.filter }

            await screenerApi.updateScreener(screener)
            screenerStore.setMarketScreenerModified(false)
        }
        saving.value = false
    }

    const resetScreener = async () => {
        loading.value = true
        await setFilter()
        loading.value = false
    }

    const openScreenerModal = () => {
        screenerStore.toggleScreenerModal()
    }

    const openSaveAsModal = () => {
        screenerStore.toggleScreenerSaveAsModal()
    }

    const loadScreener = async () => {
        loading.value = true
        if (route.params.screenerId) {
            if (screener.value.id !== route.params.screenerId) {
                await screenerStore.getScreener(route.params.screenerId)
            }
            if (!route.query.filter) {
                await setFilter()
            }
        } else {
            await loadDefaultScreener()
        }
        loading.value = false
    }

    //methods
    const loadDefaultScreener = async () => {
        if (!screener.value.id) {
            if (!screenerStore.screeners || !screenerStore.screeners.length) {
                await screenerStore.getScreeners()
            }

            const targetScreener = screenerStore.screeners.filter(
                (s) => !s.customScreener && s.type === type
            )[0]

            if (type === 'COMPANY') {
                screenerStore.setCompanyScreener(targetScreener)
            } else {
                screenerStore.setMarketScreener(targetScreener)
            }
        }
        const query = { filter: route.query.filter ?? screener.value.query }

        if (route.query.showScreener) {
            query['showScreener'] = true
        }
        await router.push({
            path: `${router.currentRoute.value.path}/${screener.value.id}`,
            query,
        })
    }

    const setFilter = async () => {
        const query = { filter: screener.value.query }
        if (route.query.showScreener) {
            query['showScreener'] = true
        }

        await router.replace({
            query,
        })
    }

    return {
        loading,
        saving,
        screener,
        screenerModified,
        showSaveAsScreeners,
        queryFilters,
        closeSaveAsModal,
        saveScreener,
        resetScreener,
        openScreenerModal,
        openSaveAsModal,
    }
}
