<template>
    <div class="patent-expiration-card grey04">
        <AonCard style="padding: 0 !important; position: relative">
            <div class="card-content">
                <AonCoverLoading
                    :loading="loadingExpiration"
                    title="Loading Expiration By Year..."
                ></AonCoverLoading>
                <div class="card-content-innner">
                    <div class="top d-flex">
                        <div
                            v-if="!noData"
                            class="text-background section d-flex align-items-center align-content-center w-half flex-wrap mr-3 h-full pa-5"
                        >
                            <p class="extra-small bold uppercase tavern-ale-60--text">
                                Patent Expiration Overview
                            </p>
                            <p class="tavern-ale-60--text large mt-2">
                                The {{ nodeData.displayName }} node accounts for
                                <span style="font-weight: 700">{{ computedTotalPercent }}%</span> of
                                <span style="font-weight: 700"
                                    >{{ entityStore.entity.name }}'s</span
                                >
                                total patent portfolio.
                                <span style="font-weight: 700"
                                    >{{ computedTotalExpiringPercent }}% of those, or
                                    {{ entityNodePatentExpiringCount }} patents</span
                                >
                                are expiring in the next 5 years.
                            </p>
                        </div>
                        <div
                            class="section d-flex align-items-center w-half pa-2 h-full"
                            style="width: 75%"
                        >
                            <div id="amChartPatentExpiration"></div>
                        </div>
                    </div>
                    <AonCoverLoading
                        v-if="noData"
                        :loading="noData ? true : false"
                        :show-spinner="false"
                        :title="`${entityStore.entity.name} has no patents expiring in this space within the next 5 years.`"
                    >
                        <template #header>
                            <div class="icon">
                                <font-awesome-icon
                                    icon="fas fa-circle-exclamation"
                                    class="grey01--text"
                                    size="2xl"
                                />
                            </div>
                        </template>
                    </AonCoverLoading>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted, inject, computed } from 'vue'
import { getPatentExpiration, getEntityNodePatentCount } from '@/api/ipNode'
import { useEntityStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const entityStore = useEntityStore()

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})

const logger = inject('logger')
let root
let chart
let xAxis
let yAxis

const loadingExpiration = ref(false)
const chartData = ref(null)
const noData = ref(false)
const entityTotalPatentCount = ref(entityStore.entity.patent_count_us)
const entityNodePatentExpiringCount = ref(0)
const entityNodePatentCount = ref(0)

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartPatentExpiration')
    onGetPatentExpiration()
})

onBeforeUnmount(() => {
    root.dispose()
})

const computedTotalPercent = computed(() => {
    return ((entityNodePatentCount.value / entityTotalPatentCount.value) * 100).toFixed(2)
})

const computedTotalExpiringPercent = computed(() => {
    return ((entityNodePatentExpiringCount.value / entityNodePatentCount.value) * 100).toFixed(2)
})

const onGetPatentExpiration = async () => {
    loadingExpiration.value = true
    try {
        const params = {
            targetEntityPk: entityStore.entity.aon_entity_pk,
            numYears: 5,
        }
        const { data } = await getPatentExpiration(props.nodeData.id, params)
        chartData.value = data.map((item) => {
            return {
                year: item.year.toString(),
                count: item.count,
            }
        })

        entityNodePatentExpiringCount.value = data.reduce((sum, item) => sum + item.count, 0)

        if (entityNodePatentExpiringCount.value === 0) {
            noData.value = true
            loadingExpiration.value = false
            return
        }

        onGetEntityNodePatentCount(props.nodeData.id, entityStore.entity.aon_entity_pk)
    } catch (error) {
        logger.error(error)
    }
}

const onGetEntityNodePatentCount = async (nodeId, targetEntityPk) => {
    try {
        const { data } = await getEntityNodePatentCount(nodeId, targetEntityPk)
        entityNodePatentCount.value = data.count
        configureChart()
    } catch (error) {
        logger.error(error)
    } finally {
        loadingExpiration.value = false
    }
}

const configureChart = () => {
    if (!root) {
        return
    }
    root.setThemes([am5themes_Animated.new(root)])

    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            focusable: true,
            paddingLeft: 0,
            paddingTop: 30,
        })
    )

    generateAxes()
}

const generateAxes = () => {
    let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30,
        minorGridEnabled: true,
    })

    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    xRenderer.grid.template.setAll({
        location: 1,
    })

    xAxis.data.setAll(chartData.value)

    xAxis.children.push(
        am5.Label.new(root, {
            text: 'Year',
            fontSize: 12,
            fontWeight: 600,
            centerX: am5.p50,
            x: am5.percent(50),
            y: am5.percent(65),
        })
    )

    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            maxDeviation: 1,
            renderer: am5xy.AxisRendererY.new(root, {}),
        })
    )

    yAxis.children.push(
        am5.Label.new(root, {
            text: 'Number of Expiring Patents',
            fontWeight: 600,
            fontSize: 12,
            rotation: -90,
            y: am5.percent(50),
            x: am5.percent(-40),
            centerX: am5.p50,
            centerY: am5.p50,
        })
    )

    generateSeries()
}

const generateSeries = () => {
    let series = chart.series.push(
        am5xy.LineSeries.new(root, {
            minBulletDistance: 10,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'count',
            categoryXField: 'year',
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
                fill: am5.color('#2A79D2'),
            }),
        })
    )

    series.data.setAll(chartData.value)

    series.bullets.push(function () {
        let circle = am5.Circle.new(root, {
            radius: 4,
            fill: am5.color('#2A79D2'),
            stroke: am5.color('#2A79D2'),
            strokeWidth: 2,
        })

        return am5.Bullet.new(root, {
            sprite: circle,
        })
    })

    let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
            xAxis: xAxis,
        })
    )
    cursor.lineY.set('visible', false)

    series.appear(1000, 100)
}
</script>

<style lang="scss" scoped>
.patent-expiration-card {
    width: 100%;

    .option-dropdown {
        max-width: 300px;
    }

    .section {
        .no-break {
            white-space: nowrap;
        }
        .spacer {
            height: 10px;
            background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 8px 1px;
            background-repeat: repeat-x;
        }
    }

    .top {
        height: 300px;
        #amChartPatentExpiration {
            width: 100%;
            height: 100%;

            div {
                height: 100%;
            }
        }
    }

    .text-background {
        background: rgba($warningBackground, 0.5);
        border-radius: 8px 0 0 8px;
    }
}
</style>
