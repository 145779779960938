import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { logger } from '@moatmetrics/vue-logger'
import { createReport, patchReport } from '@/api/reports'

import { useRouter } from 'vue-router'
import { useAuth } from '@/auth/authPlugin'

export const usePlaybookGenerationStore = defineStore('playbookGeneration', () => {
    const { user } = useAuth()
    const router = useRouter()
    const generateLoading = ref(false)

    const playbookTypeSelected = ref(null)
    const playbookSelections = ref([])
    const trackStepsInit = ref([
        {
            stepName: 'Select Playbook Type',
            isCurrentStep: true,
            isCompletedStep: false,
            stepNum: 1,
        },
        {
            stepName: 'Playbook steps appear below',
            stepDesc: 'The steps belove will be tailored based on your selected playbook type.',
        },
        {
            stepName: 'Placeholder Label',
            stepDesc:
                'Quis esse nostrud magna velit deserunt consectetur ullamco adipisicing velit mollit ad pariatur dolore in.',
            redacted: true,
        },
    ])
    const baseTrackSteps = [
        {
            stepName: 'Target Company',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 2,
        },
        {
            stepName: 'Select Contenders',
            stepDesc:
                'Identify the competitors you want to compare against your Target Company. These contenders will be analyzed to highlight competitive differences and opportunities within your selected technology areas.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 3,
        },
        {
            stepName: 'Playbook Output',
            isCurrentStep: false,
            isCompletedStep: true,
            stepNum: 4,
        },
    ]
    const trackStepsMatchUp = ref([...baseTrackSteps])
    const trackStepsFinal = ref([])
    const disableNext = ref(false)

    const resetState = () => {
        generateLoading.value = false
        playbookSelections.value = []
        trackStepsInit.value[0].isCurrentStep = true
        trackStepsInit.value[0].isCompletedStep = false
        trackStepsFinal.value = []
        playbookTypeSelected.value = null
    }

    const nextButtonDisabled = computed(() => {
        // If report type is not selected (step 1)
        if (!playbookTypeSelected.value) {
            return true
        }

        if (disableNext.value) {
            return true
        }

        // All steps are set
        if (trackStepsFinal.value.length > 0 && !isPlaybookConfigComplete.value) {
            const currentStep = getCurrentStep()
            const correspondingPlaybookSelection = playbookSelections.value.find((selection) => {
                return selection.stepNum === currentStep.stepNum
            })

            // Common validation for all playbook types
            if (
                !correspondingPlaybookSelection ||
                !correspondingPlaybookSelection.value ||
                correspondingPlaybookSelection.value.length === 0
            ) {
                return true
            }
        }

        return false
    })

    const isPlaybookConfigComplete = computed(() => {
        let isComplete = true

        trackStepsFinal.value.forEach((step) => {
            if (!step.isCompletedStep) {
                isComplete = false
            }
        })

        if (trackStepsFinal.value[trackStepsFinal.value.length - 1]?.isCurrentStep !== true) {
            isComplete = false
        }

        return isComplete
    })

    const getSelectedPlaybookTypeSteps = () => {
        if (playbookSelections.value[0]?.value === 'matchUp') {
            return trackStepsMatchUp.value
        } else {
            return trackStepsMatchUp.value
        }
    }

    const getPlaybookSelectionByStep = (stepNum) => {
        return playbookSelections.value.find((s) => s.stepNum === stepNum)
    }

    const setPlaybookType = (type, label) => {
        playbookTypeSelected.value = type

        const existingFirstStep = playbookSelections.value.find((s) => s.stepNum === 1)
        if (existingFirstStep) {
            existingFirstStep.value = type
            existingFirstStep.selectionLabel = label
        } else {
            playbookSelections.value.unshift({
                value: type,
                selectionLabel: label,
                stepNum: 1,
            })
        }
    }

    const setPlaybookCompanySelection = (value) => {
        if (!value) {
            return
        }

        const currentStep = getCurrentStep()
        const findSelection = getPlaybookSelectionByStep(currentStep.stepNum)

        if (!findSelection) {
            playbookSelections.value.push({
                value: value.aon_entity_pk,
                selectionLabel: value.name || value.aonEntityName,
                stepNum: currentStep.stepNum,
                allowEdit: true,
            })
        } else {
            findSelection.value = value.aon_entity_pk
            findSelection.selectionLabel = value.name || value.aonEntityName
        }
    }

    const setPlaybookCompetitorSelection = (value) => {
        if (!value) {
            return
        }

        const currentStep = getCurrentStep()
        const findSelection = getPlaybookSelectionByStep(currentStep.stepNum)

        if (!findSelection) {
            playbookSelections.value.push({
                value: value,
                selectionLabel: 'Contenders Selected',
                stepNum: currentStep.stepNum,
                allowEdit: true,
            })
        } else {
            findSelection.value = value
        }
    }

    /////////////////////////////////////
    // Step navigation and Creation
    /////////////////////////////////////
    const getCurrentStep = () => {
        return trackStepsFinal.value.find((s) => s.isCurrentStep)
    }

    const setCurrentStep = (stepNum) => {
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        trackStepsFinal.value[currentStepIndex].isCurrentStep = false
        trackStepsFinal.value[stepNum - 1].isCurrentStep = true
        trackStepsFinal.value[stepNum - 1].isCompletedStep = false

        // if (stepNum === 4) {
        //     shouldShowContenderSelection.value = false
        // }
    }

    const setCompletedStep = () => {
        const currentStep = getCurrentStep()
        if (!currentStep) {
            handleInitialStep()
            return
        }

        switch (playbookTypeSelected.value) {
            case 'matchUp':
                const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)
                markStepComplete(currentStepIndex)
                trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
                break
        }
    }

    const handleInitialStep = () => {
        trackStepsInit.value[0].isCurrentStep = false
        trackStepsInit.value[0].isCompletedStep = true
        trackStepsFinal.value = [trackStepsInit.value[0], ...getSelectedPlaybookTypeSteps()]

        if (playbookSelections.value[1]) {
            trackStepsFinal.value[2].isCurrentStep = true
            trackStepsFinal.value[1].isCompletedStep = true
        } else {
            trackStepsFinal.value[1].isCurrentStep = true
            trackStepsFinal.value[0].isCompletedStep = true
        }
    }

    const markStepComplete = (index) => {
        trackStepsFinal.value[index].isCurrentStep = false
        trackStepsFinal.value[index].isCompletedStep = true
    }

    const gotoPreviousStep = () => {
        const currentStep = getCurrentStep()
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        currentStep.isCurrentStep = false
        trackStepsFinal.value[currentStepIndex - 1].isCurrentStep = true
    }
    /////////////////////////////////////
    // End Step navigation and Creation
    /////////////////////////////////////

    ///////////////////////////////////////////
    // Generate Report Submit
    ///////////////////////////////////////////
    const generatePlaybook = async () => {
        generateLoading.value = true

        const params = buildBaseParams(
            playbookTypeSelected.value,
            playbookSelections.value,
            user.value
        )

        if (playbookTypeSelected.value === 'matchUp') {
            params.report_input = buildMatchUpInput(playbookSelections.value)
        }

        try {
            const { data } = await createReport(params)
            submitPlaybookForGeneration(data)
        } catch (err) {
            logger.error(err)
        }
    }

    const buildBaseParams = (playbookTypeSelected, playbookSelections, user) => {
        return {
            report_type: getPlaybookType(playbookTypeSelected),
            report_name: playbookSelections[1].selectionLabel,
            report_description: '',
            requested_application: 'ip_alpha',
            report_input: {},
            requested_user: user.id,
        }
    }

    const getPlaybookType = (reportType) => {
        const types = {
            compAnalysis: 'product_alignment',
            counter: 'counter_assertion',
            headToHead: 'head_to_head',
            risk: 'risk_analysis',
        }
        return types[reportType] || 'product_alignment'
    }

    const buildMatchUpInput = (playbookSelections) => {
        return {
            product_alignment_id: playbookSelections[0].value,
            target_entity_pk: playbookSelections[1].value,
            contender_ids: playbookSelections[3].value.map((contender) => contender.aonEntityPk),
            node_ids: playbookSelections[2].value.map((techArea) => techArea.nodeId),
        }
    }

    const submitPlaybookForGeneration = async (reportData) => {
        const params = {
            report_action: 'submit',
        }

        try {
            await patchReport(reportData.report_pk, params)
        } catch (err) {
            logger.error(err)
        } finally {
            generateLoading.value = false
            router.push({ name: 'Reports' })
        }
    }
    ///////////////////////////////////////////
    // End Generate Report Submit
    ///////////////////////////////////////////

    return {
        generateLoading,
        resetState,
        trackStepsInit,
        trackStepsFinal,
        playbookTypeSelected,
        playbookSelections,
        setPlaybookType,
        getSelectedPlaybookTypeSteps,
        setCurrentStep,
        getCurrentStep,
        gotoPreviousStep,
        getPlaybookSelectionByStep,
        nextButtonDisabled,
        setCompletedStep,
        isPlaybookConfigComplete,
        generatePlaybook,
        setPlaybookCompanySelection,
        setPlaybookCompetitorSelection,
        disableNext,
    }
})
