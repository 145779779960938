<template>
    <div class="investments-by-year-card mt-5">
        <AonCard
            title="Investment Types By Year"
            :desc="`Understand the changing investment landscape in ${props.nodeData.displayName} by exploring the distribution of private equity, venture capital, mergers & acquisitions (M&A), IPOs, and other investment types over time.`"
        >
            <div class="card-content">
                <AonCoverLoading
                    :loading="loadingInvestments"
                    title="Loading Investments By Year..."
                ></AonCoverLoading>
                <div id="amChartInvestmentsByYear"></div>
            </div>
            <AonCoverLoading
                v-if="noData"
                :loading="noData ? true : false"
                :show-spinner="false"
                :title="`There were not identified investments in ${props.nodeData.displayName}`"
            >
                <template #header>
                    <div class="icon">
                        <font-awesome-icon
                            icon="fas fa-circle-exclamation"
                            class="grey01--text"
                            size="2xl"
                        />
                    </div>
                </template>
            </AonCoverLoading>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, inject, shallowRef } from 'vue'
import { getInvestmentsByYear } from '@/api/ipNode'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})

const logger = inject('logger')
let root
let chart
let yAxis
let xAxis
let legend

const loadingInvestments = ref(true)
const noData = ref(false)
const chartData = ref(null)

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartInvestmentsByYear')
    configureData()
})

onBeforeUnmount(() => {
    root.dispose()
})

const configureData = async () => {
    loadingInvestments.value = true

    // Need to have this for unit tests
    if (root && root.container && root.container.children) {
        root.container.children.clear()
    }

    try {
        const { data } = await getInvestmentsByYear(props.nodeData.id)
        if (!data.length) {
            noData.value = true
            loadingInvestments.value = false
            return
        }
        chartData.value = transformData(data)
        configureChart()
    } catch (err) {
        logger.error(err)
    } finally {
        loadingInvestments.value = false
    }
}

const transformData = (data) => {
    const result = data.reduce((acc, item) => {
        let yearEntry = acc.find((entry) => entry.year === item.year.toString())
        if (!yearEntry) {
            yearEntry = { year: item.year.toString() }
            acc.push(yearEntry)
        }
        yearEntry[item.investmentType] = item.count
        return acc
    }, [])
    return result
}

const configureChart = () => {
    if (!root) {
        return
    }
    root.setThemes([am5themes_Animated.new(root)])

    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            interactive: false,
            wheelY: 'none',
            layout: root.verticalLayout,
            paddingLeft: 30,
        })
    )

    legend = chart.children.push(
        am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.percent(55),
            paddingTop: 20,
        })
    )

    generateAxes()
}

const generateAxes = () => {
    var xRenderer = am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
        minGridDistance: 60,
    })
    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    xRenderer.grid.template.setAll({
        location: 1,
    })

    xAxis.data.setAll(chartData.value)

    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0,
            }),
        })
    )
    yAxis.children.push(
        am5.Label.new(root, {
            text: 'Number of Investments',
            fontWeight: 600,
            rotation: -90,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            x: -25,
        })
    )

    makeSeries('IPOs', 'ipo')
    makeSeries('Mergers and Acquisitions', 'm_and_a')
    makeSeries('Other', 'other')
    makeSeries('Private Equity', 'private_equity')
    makeSeries('Venture Capital', 'venture_capital')
    chart.appear(1000, 100)
}

const makeSeries = (name, fieldName) => {
    let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: name,
            stacked: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: 'year',
        })
    )

    series.columns.template.setAll({
        tooltipText: '{name}: {valueY}',
        tooltipY: am5.percent(10),
    })
    series.data.setAll(chartData.value)
    series.appear()
    legend.data.push(series)
}
</script>

<style lang="scss" scoped>
.investments-by-year-card {
    width: 100%;
    position: relative;

    #amChartInvestmentsByYear {
        width: 100%;
        height: 300px;

        div {
            height: 100%;
        }
    }
    .card-content {
        position: relative;
    }

    .y-axis-label {
        position: absolute;
        left: 10px;
        top: 60%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }
}
</style>
