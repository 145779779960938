<template>
    <div class="app-error align-center justify-content-center">
        <AonCard>
            <img class="mt-1 mb-2" src="@/assets/svgs/aon-logo.svg" alt="Aon (Logo)" />
            <hr />
            <p class="mt-3extra-large">
                {{ t('error.headerPrimary') }}
            </p>
            <p class="my-3 knights-cloak--text large">
                {{ t('error.headerSecondary') }}
            </p>
            <p>{{ t('error.emailText') }}</p>
            <a :href="`${emailLink}`" target="_blank">
                {{ emailText }}
            </a>
            <div class="mt-6">
                <router-link class="mr-8" :to="{ name: 'Home' }">
                    <AonButton :label="`< ${t('navigationText.backButtonText')}`" />
                </router-link>
                <router-link to="/logout">
                    {{ t('navigationText.logout') }}
                </router-link>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
// TODO refactor the 404, error and permissionRedirect views to use a contextable component instead
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({})
const emailText = computed(() => t('support.emailLink'))
const emailLink = computed(() => `mailto:${emailText.value.toLowerCase()}`)
</script>

<style scoped lang="scss">
.app-error {
    display: flex;
    height: 100%;

    .a-card {
        width: 30vw;
        margin: auto;
        text-align: center;

        hr {
            color: $grey06;
        }
    }
}
</style>
