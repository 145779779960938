import { INVESTMENT_TYPE_TO_GROUP } from './constants/investments'

export const getInvestmentGroup = (investmentType) => {
    if (!investmentType) {
        return ''
    }

    if (!INVESTMENT_TYPE_TO_GROUP.hasOwnProperty(investmentType)) {
        return 'Other/Unknown'
    }

    return INVESTMENT_TYPE_TO_GROUP[investmentType]
}
