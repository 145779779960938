<template>
    <div class="target-company-selection-table d-flex flex-column">
        <!-- <AonCoverLoading
            :loading="tableLoading"
            title="Loading Company Watchlist..."
        ></AonCoverLoading> -->
        <ScreenerCompanyTable
            playbook
            @playbook-target-entity="onSelectEntity"
            style="margin-bottom: 0 !important"
        />
    </div>
</template>

<script setup>
import { ref, inject, onMounted, nextTick, computed, onActivated } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { getCompetitors } from '@/api/productAlignment.js'

import ScreenerCompanyTable from '@/components/screeners/ScreenerCompanyTable'

const playbookGenerationStore = usePlaybookGenerationStore()

const localSelection = ref([])
const maxSelections = ref(10)

onMounted(async () => {
    localSelection.value = []
    await nextTick()
})

onActivated(async () => {
    await nextTick()
})

// This is only to support the screener table
// remove when we transistion to ag grid
const onSelectEntity = (comp) => {
    const existingIndex = localSelection.value.findIndex(
        (item) => item.aon_entity_pk === comp.aon_entity_pk
    )

    if (existingIndex >= 0) {
        localSelection.value.splice(existingIndex, 1)
    } else {
        localSelection.value.push(comp)
    }

    const selectionCount = localSelection.value.length
    console.log(selectionCount)
    playbookGenerationStore.disableNext =
        selectionCount > maxSelections.value || selectionCount === 0

    if (selectionCount <= maxSelections.value) {
        playbookGenerationStore.setPlaybookCompetitorSelection(localSelection.value)
    }
}
</script>

<style lang="scss" scoped>
.target-company-selection-table {
    height: 100%;
    width: 100%;

    position: relative;

    :deep(.search-companies-table) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
</style>
