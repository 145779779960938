<template>
    <div class="app-footer-wrap">
        <AonContainer class="app-footer pb-5 pt-8" add-page-padding>
            <AonRow>
                <AonCol class="aon-col-12 justify-content-between pa-0">
                    <div class="copy-rights d-flex align-center flex-wrap">
                        <p class="small white--text">
                            {{ t('global.footer.copyright1', { year }) }}
                        </p>
                        <p class="d-flex align-center small white--text mt-4">
                            {{ t('global.footer.copyright2') }}
                        </p>
                    </div>
                </AonCol>
            </AonRow>
            <AonRow>
                <AonCol class="aon-col-12 justify-content-between pa-0 mt-6">
                    <div class="links-and-logo d-flex align-center justify-content-between w-full">
                        <div class="logo-holder d-flex align-center">
                            <img
                                class="app-footer__logo mr-2"
                                :alt="`Aon ${t('global.title')}`"
                                src="@/assets/moat-logo.svg"
                            />
                            <h6 class="white--text">© {{ year }} Moat Metrics, Inc</h6>
                        </div>

                        <div class="links-holder d-flex align-center">
                            <p class="bold">
                                <a
                                    href="https://www.aon.com/about-aon/privacy.jsp"
                                    target="_blank"
                                    class="px-2 mx-2 gold--text"
                                >
                                    {{ t('global.footer.privacy') }}
                                </a>
                            </p>
                            <p class="bold white--text">
                                {{ t('global.footer.financialDataBy') }}
                                <a
                                    href="http://www.crunchbase.com"
                                    target="_blank"
                                    class="mr-4 gold--text"
                                >
                                    Crunchbase
                                </a>
                            </p>
                            <p class="bold white--text">
                                {{ t('global.footer.rawDataBy') }}
                                <a
                                    class="gold--text"
                                    href="https://www.factset.com/data-attribution"
                                    target="_blank"
                                >
                                    Factset
                                </a>
                            </p>
                            <a
                                class="gold--text ml-2"
                                href="https://moatmetrics.notion.site/94052d1802e74047a9f9250c8cc76da5?v=eb8c674d15504eb48abd510bc4133c14"
                                target="_blank"
                            >
                                <img
                                    class="mx-2 mt-1"
                                    alt="Moat Metrics LinkedIn"
                                    src="@/assets/icon/notion-logo.svg"
                                />
                            </a>
                            <a
                                class="gold--text"
                                href="https://www.linkedin.com/company/moat-metrics/"
                                target="_blank"
                            >
                                <img
                                    class="mx-2 mt-1"
                                    alt="Moat Metrics LinkedIn"
                                    src="@/assets/icon/linkedin-logo.svg"
                                />
                            </a>
                        </div>
                    </div>
                </AonCol>
            </AonRow>
        </AonContainer>
        <div class="footer-grad"></div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const year = computed(() => {
    return new Date().getFullYear()
})
</script>

<style lang="scss" scoped>
.app-footer-wrap {
    width: 100%;
    background-color: $knightsCloak;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 0;

    .app-footer {
        &__logo {
            height: 40px;
        }

        &__legalese {
            p {
                font-size: 10px;
            }
        }
    }

    .footer-grad {
        width: 100%;
        height: 15px;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        border-top: 2px solid $grey05;
        background: linear-gradient(
            to bottom,
            rgba(124, 142, 174, 0.4) 0%,
            rgba(16, 43, 71, 0) 100%
        );
    }
}
</style>
