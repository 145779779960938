<template>
    <div class="market-details d-flex flex-column justify-content-start">
        <AonContainer add-page-padding class="header-holder py-5 ma-0">
            <AonRow>
                <AonCol class="aon-col-12" justify-content="space-between">
                    <MarketHeader :loading="!market || loading" :market="market" />
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonContainer add-page-padding class="below-header tab-holder ma-0">
            <div class="background-helper" />
            <AonRow>
                <AonCol class="aon-col-12 pa-0" justify-content="space-between">
                    <AonTabs class="pt-2" :tabs="tabData" bottom-border @tab-change="tabChange" />
                </AonCol>
            </AonRow>
        </AonContainer>

        <AonContainer add-page-padding class="mt-5 mb-0 pb-0">
            <AonRow>
                <AonCol v-show="currentTab === 1" class="aon-col-12 pa-0">
                    <MarketRanks :loading="loading" :market-id="currentMarketId" class="mb-10" />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script>
import { useMarketsStore } from '@/stores'

import MarketRanks from './MarketRanks'
import MarketHeader from './MarketHeader'
import { useAuth } from '@/auth/authPlugin'

export default {
    name: 'Market Details',
    components: {
        MarketRanks,
        MarketHeader,
    },
    props: {
        marketId: {
            type: Number,
            required: true,
        },
        marketMaturity: {
            default: null,
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            error: false,
            loading: false,
            currentTab: 1,
            tabData: [
                {
                    text: this.$t('market.tabs.topCompanies'),
                    id: 1,
                },
            ],
        }
    },
    computed: {
        user() {
            const { user } = useAuth()

            return user
        },
        currentMarketId() {
            return parseInt(this.marketId)
        },
        market() {
            const marketsStore = useMarketsStore()

            return marketsStore.cachedMarkets[this.currentMarketId] || null
        },
        marketIpData() {
            const marketsStore = useMarketsStore()
            const marketIpData = marketsStore.cachedMarketIpData[this.currentMarketId]

            return marketIpData ?? []
        },
    },
    watch: {
        async marketId(newVal, oldVal) {
            if (newVal !== oldVal) {
                await this.getMarket(this.currentMarketId)
            }
        },
    },
    async created() {
        await this.getMarket(this.currentMarketId)
    },
    methods: {
        async getMarket(id) {
            if (!id) {
                this.$logger.error('MarketDetails getMarket(id) is missing the marketId')

                return
            }

            try {
                const marketsStore = useMarketsStore()
                const marketIdChanged = this.market?.aon_sector_pk !== id

                this.error = false

                if (!this.market || marketIdChanged) {
                    this.loading = true
                    await marketsStore.getMarket(id)
                }
            } catch (err) {
                this.$logger.error(err)
                this.error = true
            } finally {
                this.loading = false
            }
        },
        buildChartMetric: (title, apex, age, colorIndex) => {
            return {
                title,
                apex,
                age,
                colorIndex,
            }
        },
        tabChange(val) {
            this.currentTab = val.id
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@moatmetrics/armory/src/sass/responsive.scss';

.market-details {
    min-height: 100vh;
    height: 100%;
    width: 100%;

    .header-holder {
        background: $knightsCloak80;
    }

    .tab-holder {
        background: $knightsCloak60;
    }

    .background-helper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
</style>
