export const LOGIN = 'Login'
export const LOGOUT = 'Logout'
export const LOGIN_CALLBACK = 'LoginCallback'
export const TOS = 'Terms Of Service'
export const GET_STARTED = 'Get Started'
export const ACCOUNT_PROFILE = 'Account Profile'
export const PERMISSION_REDIRECT = 'Permission Redirect'
export const UPDATE_SECURITY_QUESTION = 'Update Security Question'

export default {
    LOGIN: LOGIN,
    LOGIN_CALLBACK,
    LOGOUT: LOGOUT,
    TOS: TOS,
    GET_STARTED: GET_STARTED,
    ACCOUNT_PROFILE: ACCOUNT_PROFILE,
    PERMISSION_REDIRECT: PERMISSION_REDIRECT,
    UPDATE_SECURITY_QUESTION,
}
