import axios from 'axios'
import axiosRetry from 'axios-retry'
import { requestBearerHandler, responseErrorHandler } from '@/api/interceptors'
import { config } from '@/config'

axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
})

axios.defaults.baseURL = config.apiUrl
axios.interceptors.request.use(requestBearerHandler)
axios.interceptors.response.use(
    (response) => response,
    (error) => responseErrorHandler(error)
)
