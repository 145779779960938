import axios from 'axios'
import { searchWrapper } from './search'

const baseUrl = 'api/sectors'

export function getMarketClustersJobId(marketId) {
    return axios.get(`${baseUrl}/${marketId}/clusters`)
}

export function getMarketIpData(marketId) {
    return axios.get(`${baseUrl}/${marketId}/entities/ip_data`)
}

export function getMarketQuadrantCoordinates(marketId) {
    return axios.get(`${baseUrl}/${marketId}/magic-quadrant-coordinates`)
}

export function getSectorClustersJobId(marketId) {
    return axios.get(`${baseUrl}/${marketId}/clusters`)
}

export function getSectorIpData(marketId) {
    return axios.get(`${baseUrl}/${marketId}/entities/ip_data`)
}

export function getSectorQuadrantCoordinates(marketId) {
    return axios.get(`${baseUrl}/${marketId}/magic-quadrant-coordinates`)
}

export function getSector(marketId) {
    return axios.get(`${baseUrl}/${marketId}`)
}

export function getSectorMetadata(marketId) {
    return axios.get(`${baseUrl}/${marketId}/metadata`)
}

export function getSectorChildren(marketId) {
    return axios.get(`${baseUrl}/${marketId}/hierarchy/children`)
}

export function getSectorRanks(marketId, sort, page, pageSize) {
    return axios.get(
        `${baseUrl}/${marketId}/ranks?sort=${sort}&pageSize=${pageSize}&lastRow=${
            (page - 1) * pageSize
        }`
    )
}
export function getSectorRevenues(sectorId, aggregation) {
    return axios.get(
        `${baseUrl}/${sectorId}/entities/revenues/aggregate?aggregation=${aggregation}`
    )
}
export function getSectorNpeEntities(sectorId) {
    return axios.get(`${baseUrl}/${sectorId}/entities/npe`)
}

export function search(searchTerm, params = {}) {
    return searchWrapper(`${baseUrl}/search`, {
        ...params,
        sectorName: searchTerm,
    })
}

export function getSectorEntities(
    sectorId,
    sort = 'corRank',
    page = 1,
    pageSize = 10,
    orderDesc = false,
    searchText = '',
    orderByIgnoreNulls = false
) {
    return axios.get(`${baseUrl}/${sectorId}/entities`, {
        params: {
            orderBy: sort,
            orderDesc,
            page,
            pageSize,
            searchText,
            orderByIgnoreNulls,
        },
    })
}

export function getSectorYearlyRevenue(sectorId) {
    return axios.get(`${baseUrl}/${sectorId}/cagr`)
}

export function postSectorEntitiesExtract(sectorId, userName, email) {
    return axios.post(`api/data_extract/entity_sectors`, {
        userName: userName,
        email: email,
        filters: [
            {
                columnName: 'aon_sector_pk',
                type: 'Equals',
                value: String(sectorId),
            },
        ],
    })
}

export function postSectorEntitiesQuery(
    sectorId,
    sort = 'corRank',
    page = 1,
    pageSize = 10,
    orderDesc = false,
    searchText = '',
    orderByIgnoreNulls = false,
    filters = []
) {
    return axios.post(`${baseUrl}/${sectorId}/entities/query`, filters, {
        params: {
            orderBy: sort,
            orderDesc,
            page,
            pageSize,
            searchText,
            orderByIgnoreNulls,
        },
    })
}

export function querySectorEntities(sectorId, params) {
    return axios.get(`${baseUrl}/${sectorId}/entity_moat_quality_metrics`, {
        params,
        paramsSerializer: { indexes: null },
    })
}
