<template>
    <div class="moat-product-tab h-full">
        <AonCoverLoading
            :loading="moat2ProductStore.productAlignmentLoading"
            :title="`Please hang tight!`"
            :message="loadingMessage"
        >
            <template #footer>
                <progress
                    v-if="percentComplete !== 0"
                    :value="percentComplete"
                    max="100"
                    class="mt-4"
                />
            </template>
        </AonCoverLoading>
        <Moat2ProductOutput
            v-if="moat2ProductStore.alignmentPk && allChartData && entityStore.entity"
            :key="`${entityStore.entity.aon_entity_pk}`"
            :entity-id="entityStore.entity.aon_entity_pk"
            :entity-name="entityStore.entity?.name"
            :all-chart-data="allChartData"
            :patent-breakdown="usPatentBreakdown"
        />
        <AonCoverLoading
            class="error-cover"
            :loading="typeof error === 'string' ? true : false"
            :show-spinner="false"
            :title="t('productAlignment.error.title')"
            :message="errorMsg"
        >
            <template #header>
                <font-awesome-icon
                    :icon="`fas fa-xmark`"
                    style="border-radius: 100%"
                    class="icon error--text error-background pa-4"
                    size="6x"
                />
            </template>
            <template v-if="showRerunButtons" #footer>
                <div class="button-holder mt-5 w-full d-flex justify-content-center">
                    <AonButton
                        class="mr-5"
                        :label="t('productAlignment.error.rerun')"
                        type="subtle"
                        @clicked="getProductSegmentationData()"
                    />
                    <AonButton
                        :label="t('productAlignment.error.findLatestRun')"
                        @clicked="getPreviousRunOrRequestNew(true)"
                    />
                </div>
            </template>
        </AonCoverLoading>
    </div>
</template>

<script setup>
import axios from 'axios'
// Global
import { onMounted, ref, inject, computed, onBeforeUnmount, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import {
    requestProductAlignment,
    getProductAlignmentRuns,
    pollForJobData,
    getJobData,
} from '@/api/productAlignment.js'
import { useEntityStore, useArenaStore, useMoat2ProductStore } from '@/stores'

// Comps
import Moat2ProductOutput from './Moat2ProductOutput.vue'

// injectors
const eventBus = inject('eventBus')
const { t } = useI18n()
const logger = inject('logger')
const entityStore = useEntityStore()
const arenaStore = useArenaStore()
const moat2ProductStore = useMoat2ProductStore()

// Refs/Props
const props = defineProps({
    entityId: {
        type: Number,
        default: undefined,
    },
})

const needsNewAlignment = ref(true)
const allChartData = ref(null)
const error = ref(false)
const errorMsg = ref(null)
const usPatentBreakdown = ref()
const percentComplete = ref(0)
let interval = ref(0)

onBeforeUnmount(() => {
    clearInterval(interval)
    eventBus.all.clear()
})

onMounted(async () => {
    entityStore.getCompetitors(entityStore.entity.aon_entity_pk)
    entityStore.getAcquisitionTargets(entityStore.entity.aon_entity_pk)
    entityStore.getPotentialBuyers(entityStore.entity.aon_entity_pk)
    await getPreviousRunOrRequestNew()
    let checkInterval = setInterval(() => {
        if (entityStore.patentBreakdown?.length > 0) {
            clearInterval(checkInterval)
            usPatentBreakdown.value = entityStore.patentBreakdown.find((pb) => pb.country === 'US')
        }
    }, 100)
})

const loadingMessage = computed(() => {
    if (needsNewAlignment.value) {
        return `We are aligning patents to the product and technology areas for ${entityStore.entity?.name}. This process will take 5-10 minutes if not cached in our system. Please feel free to check out the other tabs and this process will continue in the background.`
    } else {
        return `Loading Moat2Product for ${entityStore.entity?.name}`
    }
})

const showRerunButtons = computed(() => {
    return !['unknownCompany', 'noProducts'].includes(error.value)
})

const getPreviousRunOrRequestNew = async (findLatestSuccess = false) => {
    moat2ProductStore.productAlignmentLoading = true
    error.value = null

    try {
        const { data } = await getProductAlignmentRuns(entityStore.entity.aon_entity_pk)
        if (data.results.length > 0) {
            const targetData = findLatestSuccess
                ? data.results.find((run) => run.status === 'reportComplete')
                : data.results[0]
            arenaStore.rootPAId = targetData.rootNodeId
            moat2ProductStore.alignmentPk = targetData.productAlignmentId
            if (targetData.isStale) {
                needsNewAlignment.value = true
                moat2ProductStore.alignmentPk = null
            } else {
                needsNewAlignment.value = false
            }
        } else {
            needsNewAlignment.value = true
            moat2ProductStore.alignmentPk = null
        }
    } catch (err) {
        handleError(err.status, err.statusReason)
    }
    await getProductSegmentationData(moat2ProductStore.alignmentPk)
}

const getProductSegmentationData = async (alignmentPk = null) => {
    moat2ProductStore.productAlignmentLoading = true
    error.value = null

    try {
        if (!alignmentPk) {
            const { data } = await requestProductAlignment(entityStore.entity.aon_entity_pk)
            moat2ProductStore.alignmentPk = data.productAlignmentId
        }
    } catch (err) {
        handleError(err.status, err.statusReason)
    }

    interval = setInterval(
        (function paHandler() {
            getPAStatus(moat2ProductStore.alignmentPk)
            return paHandler
        })(),
        5000
    )
}

const getPAStatus = async (alignmentPk) => {
    await pollForJobData(
        alignmentPk,
        true,
        async (data) => {
            // success
            clearInterval(interval)
            let paResponse = await getJobData(alignmentPk, false)

            arenaStore.rootPAId = paResponse.data.rootNodeId
            moat2ProductStore.alignmentPk = alignmentPk
            moat2ProductStore.productAlignmentTechAreaNodes = new Set(paResponse.data.nodes)
            moat2ProductStore.productAlignmentTechAreaSelections = new Set(paResponse.data.nodes)
            allChartData.value = paResponse.data.nodes

            moat2ProductStore.productAlignmentLoading = false

            // Continue polling for processing nodes
            if (paResponse.data.nodes.find((node) => node.dataStatus === 'processing')) {
                interval = setInterval(
                    (function paHandler() {
                        getProcessingNodes(moat2ProductStore.alignmentPk)
                        return paHandler
                    })(),
                    5000
                )
            }
        },
        (err) => {
            // failure
            handleError(err.status, err.statusReason)
            clearInterval(interval)
        },
        (progress) => {
            percentComplete.value = progress
        }
    )
}

const getProcessingNodes = async (alignmentPk) => {
    await pollForJobData(
        alignmentPk,
        true,
        async (data) => {
            if (!data.nodes.find((node) => node.dataStatus === 'processing')) {
                clearInterval(interval)
                let paResponse = await getJobData(alignmentPk, false)
                allChartData.value = paResponse.data.nodes
                eventBus.emit('snacktime', {
                    type: 'success',
                    message:
                        'Additional technology areas have been classified under "other". Refresh to see full results.',
                })
            }
        },
        (err) => {
            logger.error(err)
            clearInterval(interval)
        }
    )
}

const handleError = (err, reason) => {
    logger.error(err)
    percentComplete.value = 0

    switch (reason) {
        case 'noPatents':
            errorMsg.value = t('productAlignment.error.noPatents', {
                entity: entityStore.entity.name,
            })
            break

        case 'unknownCompany':
        case 'noProducts':
            errorMsg.value = t('productAlignment.error.noProducts', {
                entity: entityStore.entity.name,
            })
            break

        case 'systemError':
        default:
            errorMsg.value = t('productAlignment.error.msg')
    }

    moat2ProductStore.productAlignmentLoading = false
    error.value = reason
}
</script>

<style lang="scss" scoped>
@import '@moatmetrics/armory/src/sass/responsive.scss';
.moat-product-tab {
    width: 100%;
    position: relative;
    min-height: 600px;
    height: 100%;

    .error-cover {
        z-index: 11;
        :deep(p.msg) {
            width: 600px;
            font-weight: bold;
        }
    }

    .toggle-sankey {
        position: absolute;
        top: 15px;
        left: 30px;
        z-index: 1;
    }

    .header-holder {
        border-bottom: 1px solid $grey05;
    }

    .sankey-modal {
        position: fixed;
        overflow: auto;
        background: white;
        width: 95%;
        height: 80vh;
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .close-sankey {
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }
    .chart-background {
        width: 200%;
        height: 200%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }

    :deep(.button-tabs) {
        .tab {
            border-bottom-width: 1px !important;
            background: $grey06;
            &.selected {
                border-bottom-color: transparent !important;
                background: $knightsCloak;
            }

            &:hover {
                background: $knightsCloak80;
            }

            p {
                font-size: 16px;
            }
        }
    }

    :deep(.icon-button) {
        display: flex;
        align-items: center !important;

        .a-button-label {
            margin-left: 8px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    :deep(.a-carousel) {
        .slide {
            border: solid $grey04 1px;

            .image-holder {
                background-repeat: no-repeat;
            }
        }
        @media screen and (max-width: 1200px) {
            .text-holder {
                padding: 20px !important;
            }

            h2 {
                font-size: 20px;
                line-height: 24px;
            }
            p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
</style>
