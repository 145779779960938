<template>
    <div class="case-details white">
        <div class="case px-3 py-2 mb-3">
            <h6 class="knights-cloak--text d-flex align-items-center justify-content-between">
                {{ caseData.caseTitle ? caseData.caseTitle : '* No Case Title Found *' }}
                <p v-if="caseData.caseNumber" class="castle-moat--text small bold uppercase">
                    {{ caseData.caseNumber }}
                </p>
            </h6>
            <div class="stat-holder d-flex align-content-center justify-start mt-2">
                <div v-if="caseData.caseFiledOn" class="stat-item mr-5">
                    <p class="grey01--text extra-small bold uppercase">Filed Date</p>
                    <p class="grey01--text">
                        {{ filters.toUTCString(caseData.caseFiledOn) }}
                    </p>
                </div>
                <div v-if="caseData.caseTerminatedOn" class="stat-item mr-5">
                    <p class="grey01--text extra-small bold uppercase">Closing Date</p>
                    <p class="grey01--text">
                        {{ filters.toUTCString(caseData.caseTerminatedOn) }}
                    </p>
                </div>
                <div class="stat-item mr-5">
                    <p class="grey01--text extra-small bold uppercase">Resolution</p>
                    <p class="grey01--text">
                        {{ caseData.caseResolution ? caseData.caseResolution : 'Not Resolved' }}
                    </p>
                </div>
                <div v-if="caseData.totalDamages" class="stat-item mr-5">
                    <p class="grey01--text extra-small bold uppercase">Court-Awarded Damages</p>
                    <p class="grey01--text">
                        {{ filters.abbreviate(caseData.totalDamages) }}
                    </p>
                </div>
                <div
                    v-if="caseData.caseStatus"
                    class="stat-item"
                    style="flex-grow: 1; text-align: right"
                >
                    <p class="grey01--text extra-small bold uppercase">Status</p>
                    <p class="grey01--text">{{ caseData.caseStatus }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue'
const filters = inject('filters')

const props = defineProps({
    caseData: {
        type: Object,
        default: () => {},
    },
})
</script>

<style lang="scss" scoped>
.case-details {
    width: 100%;

    .heading-background {
        background: rgba($warningBackground, 0.5);
        border-radius: 8px 8px 0 0;
    }

    .case {
        border: solid 1px $grey04;

        &:last-of-type {
            margin-bottom: 0 !important;
        }

        .stat-item {
            min-width: 100px;
        }
    }
}
</style>
