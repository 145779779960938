<template>
    <ArenaBoardControlButton
        id="context-target-add-node"
        icon="fas fa-plus"
        :class="{ open: showAddContextMenu && !showMenu }"
        @clicked="toggleAddContext()"
    >
        <div
            id="context-target-add-node-menu"
            :class="{ open: showAddContextMenu && !showMenu }"
            class="add-context-menu pt-3"
        >
            <p class="bold grey01--text pr-3">Add Element</p>
            <div class="add-node-options d-flex flex-wrap w-full mt-3">
                <div
                    v-for="option in addOptions"
                    class="option pa-3 w-full d-flex justify-content-end align-items-center"
                    @click="showAddModal(option.type)"
                >
                    <p class="mr-2 grey01--tex">{{ option.label }}</p>
                    <font-awesome-icon
                        :icon="`fas ${option.iconOptions.name}`"
                        class="icon grey02--text"
                    />
                </div>
            </div>
        </div>
    </ArenaBoardControlButton>
    <AonModal
        v-if="arenaStore.showCompanyModal"
        class="concept-modal"
        title=""
        message=""
        col-width="8"
        @close="closeCompanySearch()"
    >
        <div class="header mb-5">
            <h5>Add Element by Company</h5>
            <AonInput
                class="mt-4"
                style="width: 500px"
                v-model="searchTerm"
                search-input
                label="Search Contender Table"
                placeholder="Search Contender Table"
                @keyup="searchTable()"
                @clear="clearSearch()"
            />
        </div>
        <MoatTable
            style="width: 100%; height: 400px"
            :defaultColDef="defaultColDef"
            :class="themeClass"
            :column-defs="colDefs"
            :row-data="tableData"
            :tooltip-interaction="true"
            :tooltip-mouse-track="true"
            :tooltip-show-delay="500"
            tooltip-show-mode="whenTruncated"
            :autoSizeStrategy="sizingStrategy"
            row-model-type="serverSide"
            :cache-block-size="50"
            :max-blocks-in-cache="10"
            @grid-ready="gridInit"
            @row-selected="setNodeToAdd"
        />
        <AonCoverLoading :loading="addNodeLoading" title="Adding Element"></AonCoverLoading>
        <AonRow>
            <AonCol class="aon-col-10 mt-5 pa-0">
                <AonButton
                    :class="{ disabled: nodesToAdd.length > 1 || nodesToAdd.length === 0 }"
                    label="Add Element to Arena"
                    @clicked="addNodeToArena()"
                />
            </AonCol>
            <AonCol class="aon-col-2 mt-5 pa-0 align-items-center">
                <p class="small text-right w-full">
                    {{ t('global.footer.rawDataBy') }}
                    <a href="https://www.factset.com/data-attribution" target="_blank"> Factset </a>
                </p>
            </AonCol>
        </AonRow>
    </AonModal>
    <AonModal
        v-if="arenaStore.showConceptGeneration"
        class="concept-modal"
        :title="addConceptModalTitle"
        message=""
        col-width="6"
        @close="closeConceptGeneration()"
    >
        <ConceptInput
            type="arena"
            :instructionText="instructionText"
            :maxLength="addNodeType === 'text_block' ? 10000 : 200"
            ignore-height-constraint
            @add-node-to-arena="addNodeToArena"
        />
        <AonCoverLoading :loading="addNodeLoading" :title="addingElementText"></AonCoverLoading>
    </AonModal>
</template>

<script setup>
import { inject, ref, watch, computed, onMounted } from 'vue'
import { addNode } from '@/api/arena.js'
import { useArenaStore } from '@/stores'
import { getEntitySearchQuery, getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'

import { useFlag } from '@unleash/proxy-client-vue'
import { useI18n } from 'vue-i18n'
import * as osApi from '@/api/opensearch'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { moatParamBuilder } from '@/components/moatTable/helpers/queryBuilder.js'

import ConceptInput from '@/components/home/ConceptInput'
import ArenaBoardControlButton from '@/components/arenaBoard/ArenaBoardControlButton.vue'

const showExtractConceptsFromText = useFlag('ipAlpha.showExtractConceptsFromText')
const eventBus = inject('eventBus')
const { t } = useI18n()
const logger = inject('logger')
const filters = inject('filters')
const arenaStore = useArenaStore()

const emit = defineEmits(['node-added'])

const error = ref(null)
const addNodeLoading = ref(false)
const showAddContextMenu = ref(false)
const nodesToAdd = ref([])
const addOptions = ref([
    {
        type: 'company',
        label: 'By Company',
        iconOptions: {
            name: 'fas fa-city',
        },
    },
    {
        type: 'concept',
        label: 'By Concept',
        iconOptions: {
            name: 'fas fa-memo',
        },
    },
])
const addNodeType = ref(null)

// Table
const gridApi = ref(null)
const searchTerm = ref('')
const tableData = ref([])
const tablePageSize = ref(50)
const tablePageNum = ref(0)
const defaultColDef = ref({
    sortable: false,
})
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 2400,
})
const colDefs = ref([
    {
        field: 'name',
        checkboxSelection: true,
        cellClass: 'radio-cell',
        headerName: 'Company',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
        headerTooltip: 'Company',
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        width: '500',
        cellClassRules: {
            'disabled-checkbox': (params) => {
                return nodesToAdd.value.length > 1 || nodesToAdd.value.length === 0
            },
        },
    },
    {
        field: 'hq_country_name',
        headerName: 'HQ Country',
    },
    {
        field: 'ultimate_aon_entity_name',
        headerName: 'Ultimate Parent',
    },
    {
        field: 'employee_count',
        headerName: 'Employee Count',
    },
    {
        field: 'total_revenue',
        headerName: 'Annual Revenue',
    },
    {
        field: 'patent_count_us',
        headerName: 'Active Assets',
    },
    {
        field: 'ownership_status',
        headerName: 'Ownership Status',
    },
    {
        field: 'homepage_url',
        headerName: 'URL',
    },
])

watch(
    () => arenaStore.arenaBoardLoading,
    (newVal, oldVal) => {
        if (!newVal) {
            setTimeout(() => {
                if (arenaStore.boardData.length === 0) {
                    showAddContextMenu.value = true
                }
            }, 500)
        }
    }
)

onMounted(() => {
    if (showExtractConceptsFromText.value) {
        addOptions.value.push({
            type: 'text_block',
            label: 'Extract From Text',
            iconOptions: {
                name: 'fas fa-book',
            },
        })
    }
})

const addConceptModalTitle = computed(() => {
    if (addNodeType.value === 'company') {
        return 'Add Element by Company'
    } else if (addNodeType.value === 'text_block') {
        return 'Extract Concepts from Text'
    } else if (addNodeType.value === 'concept') {
        return 'Add Element by Concept'
    }

    return 'Add Element'
})

const instructionText = computed(() => {
    if (addNodeType.value === 'text_block') {
        return 'Automatically extract concepts from text and add them to the Arena.'
    }

    return 'Create a custom concept and add it to the Arena.'
})

const addingElementText = computed(() => {
    if (addNodeType.value === 'text_block') {
        return 'Adding Elements'
    }

    return 'Adding Element'
})

const selectedNode = computed(() => {
    return arenaStore.selectedNode
})

const showMenu = computed(() => {
    return arenaStore.showCompanyModal || arenaStore.showConceptGeneration
})

const addNodeToArena = async (passed) => {
    let finalData = passed
    if (nodesToAdd.value.length > 0) {
        finalData = nodesToAdd.value[0]
    }
    let bodyObj
    addNodeLoading.value = true

    if (addNodeType.value === 'text_block') {
        bodyObj = {
            displayName: finalData.data,
            type: 'text_block',
            include: true,
            parent: arenaStore.rootNodeId,
        }
    } else if (typeof finalData.data === 'string') {
        bodyObj = {
            displayName: finalData.data,
            type: 'concept',
            include: true,
            parent: arenaStore.rootNodeId,
        }
    } else {
        bodyObj = {
            displayName: finalData.data.name,
            type: 'company',
            internalIdentifier: finalData.data.aon_entity_pk.toString(),
            include: true,
            parent: arenaStore.rootNodeId,
        }
    }

    try {
        let { data } = await addNode(bodyObj)
        emit('node-added')
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    }
    addNodeLoading.value = false
    nodesToAdd.value = []

    closeCompanySearch()
    closeConceptGeneration()
}

const setNodeToAdd = (row) => {
    nodesToAdd.value = [{ data: row.node.data }]
}

const gridInit = (params) => {
    gridApi.value = params.api
    setupServerSideData()
    gridApi.value.closeToolPanel()
}

const setupServerSideData = async () => {
    const datasource = createServerSideDatasource()
    gridApi.value.setGridOption('serverSideDatasource', datasource)
}

const fetchCompetitorData = async (paramsFromTable) => {
    try {
        let finalParams = moatParamBuilder(
            paramsFromTable,
            { page_size: tablePageSize.value, last_row_num: 0, page: tablePageNum.value },
            null
        )
        const osParams = getEntitySearchQuery(finalParams)
        const response = await osApi.searchEntities(osParams)

        let scrubbedData = response.data.hits.hits.map((src) => {
            const item = src._source

            return {
                ...item,
                employee_count: filters.toLocaleString(item.employee_count),
                patent_count_us: filters.toLocaleString(parseInt(item.patent_count_us)),
                total_revenue: filters.abbreviate(item.total_revenue),
            }
        })

        tablePageNum.value++
        return {
            success: true,
            rows: scrubbedData,
        }
    } catch (err) {
        logger.error(err)
        return {
            success: false,
        }
    }
}

const createServerSideDatasource = () => {
    return {
        getRows: async (params) => {
            const response = await fetchCompetitorData(params.request)

            if (response.success) {
                params.success({ rowData: response.rows })
            } else {
                params.fail()
            }
        },
    }
}

const toggleAddContext = () => {
    showAddContextMenu.value = !showAddContextMenu.value
}
const showAddModal = (type) => {
    addNodeType.value = type
    type === 'company'
        ? (arenaStore.showCompanyModal = true)
        : (arenaStore.showConceptGeneration = true)
}

const closeCompanySearch = (stepNum) => {
    arenaStore.showCompanyModal = false
    showAddContextMenu.value = false
}

const closeConceptGeneration = (stepNum) => {
    arenaStore.showConceptGeneration = false
    showAddContextMenu.value = false
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}

// search filter
const searchTable = async () => {
    await gridApi.value.setColumnFilterModel('name', {
        filterType: 'text',
        type: 'startsWith',
        filter: searchTerm.value,
    })

    // Tell grid to run filter operation again
    gridApi.value.onFilterChanged()
}

const clearSearch = () => {
    searchTerm.value = ''
    gridApi.value.setFilterModel(null)
}
</script>

<style lang="scss" scoped>
#context-target-add-node {
    :deep(.disabled-checkbox) {
        opacity: 0.2;
        pointer-events: none;
    }

    &.open {
        :deep(.icon-holder) {
            background: $castleMoatActive;

            > svg {
                transform: rotate(45deg);
            }
        }
    }

    .add-context-menu {
        width: 200px;
        text-align: right;
        background: white;
        box-shadow: 0px 6px 15px 0px rgba(0, 17, 53, 0.2);

        position: absolute;
        top: calc(100% + 15px);
        right: 2px;
        z-index: 2;

        transform: translateY(30px);
        opacity: 0;
        pointer-events: none;

        transition: all 0.3s ease-in-out;

        &.open {
            transform: translateY(0);
            opacity: 1;
            pointer-events: all;
        }

        .option {
            cursor: pointer;

            transition: background 0.3s ease-in-out;

            .icon {
                width: 20px;
            }

            &:hover {
                background: $grey05;
            }
        }
    }
}

// .concept-modal {
.a-modal {
    &.concept-modal {
        z-index: 3 !important;
        :deep(.container) {
            overflow: visible;
            transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px));
        }
    }
}
// }
</style>
