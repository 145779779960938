<template>
    <div class="search-markets-table my-5">
        <TableAips
            ref="searchMarketsTable"
            disable-filters
            table-style="fixed-scrolling"
            sticky-header
            table-height="80vh"
            table-density="compact"
            toggle-columns
            :columns="columns"
            :data="data"
            :default-filter="defaultFilter"
            :loading-data="loading"
            :page-size="20"
            :show-bulk-actions="selectedMarketIds.length > 0"
            :table-bulk-actions="tableBulkActions"
            :table-collapse="false"
            :table-name="tableTitle"
            :use-page-data="false"
            @loadData="loadTableData"
            @bulk-action-selected="selectAction"
            @table-row-checked="selectRow"
        >
            <template #bulkActionBarPrepend>
                <div class="holder mr-10 d-flex">
                    <p class="white--text link mr-2" @click="clearSelectedData">Clear</p>
                    <p class="white--text mr-2">•</p>
                    <p class="selections bold white--text">
                        {{ filters.toLocaleString(selectedMarkets.length) }}
                        {{ t('actions.selected') }}
                    </p>
                </div>
            </template>
            <template #tableHeaderActionsPrepend>
                <!-- // TODO replace with new searchComponent when ready -->
                <div class="search-wrapper ml-4">
                    <AonInput
                        v-model="searchTerm"
                        search-input
                        placeholder="Search Markets"
                        :disabled="loading"
                        @keyup.enter="search()"
                    />
                </div>
            </template>
            <template #tableMessage>
                <div class="height-holder w-full d-flex align-items-center">
                    <AonSkeleton
                        class="w-full d-flex align-items-center"
                        :loading="loading && totalDataLength === 0"
                        :data="totalDataLength"
                        light
                    >
                        <div
                            class="info d-flex align-items-center flex-wrap nowrap"
                            style="width: fit-content"
                        >
                            {{
                                t('actions.showingCountOf', {
                                    count: filters.toLocaleString(data.length),
                                    total: filters.toLocaleString(totalDataLength),
                                    units: 'items',
                                })
                            }}
                        </div>
                    </AonSkeleton>
                </div>
            </template>
            <template #aon_sector_name="{ data }">
                <div class="caption">
                    {{ sectorHierarchyDisplayName(data) }}
                </div>
                <div class="wrap d-flex align-items-center">
                    <p
                        class="d-flex overflow-ellipsis"
                        v-tooltip="{ content: sectorPathText(data.sector_path) }"
                        @click="showMarketSummary(data)"
                    >
                        <AonSearchResult
                            class="link"
                            :search-term="searchTerm"
                            :value="data.aon_sector_name"
                        />
                    </p>
                    <div
                        class="cursor-pointer"
                        v-tooltip="{ content: sectorPathText(data.sector_path) }"
                        @click="setMarket(data)"
                    >
                        <font-awesome-icon
                            class="fa-lg ml-2 knights-cloak--text"
                            :icon="['far', 'sitemap']"
                        />
                    </div>
                </div>
            </template>
        </TableAips>
        <MarketsDialog
            v-if="showMarketsDialog"
            :selected-market-id="parseInt(selectedMarket.aon_sector_pk)"
            :parent-market-id="parseInt(selectedMarket.ultimate_aon_sector_pk)"
            @cancel="showMarketsDialog = false"
        />
    </div>
</template>

<script setup>
import { computed, inject, onBeforeMount, ref, toRaw } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDatasetStore, useMarketsStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { getSectorSearchQuery } from '@/lib/openSearchQueryBuilder'
import * as osApi from '@/api/opensearch'
import { sectorPathText } from '@/lib/helpers'

import { AonSearchResult } from '@moatmetrics/armory/src/components'
import MarketsDialog from '@/components/Taxonomy/MarketsDialog'

const props = defineProps({
    tableTitle: {
        type: String,
        default: 'Markets',
    },
})

const eventBus = inject('eventBus')
const filters = inject('filters')
const logger = inject('logger')

const datasetStore = useDatasetStore()
const marketsStore = useMarketsStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

let defaultFilter = []

const columns = ref([
    {
        checkboxValue: 'checked',
        showColumn: true,
        hasCheckbox: true,
        headerCheckbox: false,
        hideFromColToggle: true,
        hideFromFilters: true,
        disableSort: true,
        width: '100px',
    },
    {
        value: 'aon_sector_name',
        locale: 'Market',
        showColumn: true,
        filterType: 'string',
        useSlot: true,
        disableTooltip: true,
        disableSort: true,
    },
    {
        value: 'description',
        locale: 'Description',
        showColumn: false,
        filterType: 'string',
        disableSort: true,
    },
    {
        value: 'ultimate_aon_sector_name',
        locale: 'Ultimate Parent Market',
        showColumn: true,
        filterType: 'string',
        disableSort: true,
    },
    {
        value: 'participating_company_count',
        locale: 'Participating Companies',
        showColumn: true,
        filterType: 'number',
        disableSort: true,
    },
    {
        value: 'calculated_total_revenue',
        locale: 'Annual Revenue',
        showColumn: true,
        filterType: 'number',
        disableSort: true,
    },
])
const data = ref([])
const loading = ref(true)
const searchMarketsTable = ref(null)
const searchTerm = ref(null)
const selectedMarket = ref(false)
const selectedMarkets = ref([])
const showMarketsDialog = ref(false)
const totalDataLength = ref(0)

const searchtFilterValues = computed(() => {
    if (!searchTerm.value || searchTerm.value.trim() === '') {
        return []
    }
    return [
        {
            filterColumn: 'aon_sector_name',
            filterLabel: 'Market',
            filterType: 'text contains',
            filterValue: searchTerm.value.trim(),
        },
    ]
})
const tableTitle = computed(() => {
    return props.tableTitle
})
const selectedMarketIds = computed(() => {
    return selectedMarkets.value.map((sm) => sm.id)
})
const tableBulkActions = computed(() => {
    const selectedCount = selectedMarketIds.value.length
    const isDisabled = !selectedCount || selectedCount > 25
    const tableActions = [
        {
            itemText: t('actions.addToWatchlist.label'),
            disabledRule: !isDisabled,
            disabledMsg: t('actions.addToWatchlist.disabled'),
            faIconStyle: 'fas',
            faIcon: 'list-dropdown',
        },
    ]

    return tableActions
})

onBeforeMount(() => {
    if (route.query.filter) {
        const json = atob(route.query.filter)
        const screener = JSON.parse(json)
        const nameFilter = screener.filters.find((sf) => sf.filterColumn === 'aon_sector_name')

        searchTerm.value = nameFilter ? nameFilter.filterValue : ''
    } else {
        defaultFilter = searchtFilterValues.value
    }
})

const addSelectedToWatchlist = () => {
    datasetStore.setIdsToAdd({
        id_type: 'Sector',
        id_values: selectedMarketIds.value,
    })
}
const clearSelectedData = () => {
    selectedMarkets.value = []
    setCheckboxSelections()
}
const getTableData = async (params = {}) => {
    try {
        loading.value = true

        const osParams = getSectorSearchQuery(params)
        const response = await osApi.searchSectors(osParams)

        totalDataLength.value = response.data.hits.total.value

        let scrubbedData = response.data.hits.hits.map((src) => {
            const item = src._source

            return {
                ...item,
                checked: selectedMarketIds.value.includes(item.aon_sector_pk),
                participating_company_count: filters.toLocaleString(
                    item.participating_company_count
                ),
                calculated_total_revenue: filters.abbreviate(item.calculated_total_revenue),
            }
        })

        if (osParams.from === 0) {
            data.value = [...scrubbedData]
        } else {
            data.value.push(...scrubbedData)
        }
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('error.genericError'),
        })
    }

    loading.value = false
}
const loadTableData = async ({ params }) => {
    await getTableData(params)
}
const search = () => {
    selectedMarkets.value = []
    if (!searchTerm.value || searchTerm.value.trim() === '') {
        searchMarketsTable.value.removeFilter(0)
    }

    searchMarketsTable.value.applyTableFilters(searchtFilterValues.value)
}
const selectAction = async (action) => {
    const [watchlist] = [t('actions.addToWatchlist.label')]
    switch (action.itemText) {
        case watchlist:
            addSelectedToWatchlist()
            break
    }
}
const selectRow = (row) => {
    // TODO avoid the non-performant `setCheckboxSelections()` calls and set data .checked here instead
    // TODO `.find` would be more performant than mapping the growing tableData each time something is checked
    const marketIndex = selectedMarkets.value.findIndex((sm) => sm.id === row.aon_sector_pk)

    if (!row.checked && marketIndex < 0) {
        selectedMarkets.value.push({ name: row.aon_sector_name, id: row.aon_sector_pk })
        // TODO set tableData row checked true => add
        row.checked = true
        setCheckboxSelections()
        return
    }

    if (row.checked && marketIndex >= 0) {
        selectedMarkets.value.splice(marketIndex, 1)
        // TODO set tableData row checked false => remove
        row.checked = false
        setCheckboxSelections()
        return
    }
}
const setCheckboxSelections = () => {
    const tableData = [...data.value]

    data.value = tableData.map((val) => {
        val.checked = selectedMarkets.value.map((e) => e.id).includes(val.aon_sector_pk)
            ? true
            : false
        return val
    })
}
const setMarket = async (data) => {
    selectedMarket.value = data
    showMarketsDialog.value = true
}
const showMarketSummary = async (data) => {
    let market = toRaw(data)
    marketsStore.setCurrentMarket(market)
}
const sectorHierarchyDisplayName = (data) => {
    let parsedPath = data.sector_path ? JSON.parse(data.sector_path) : ''
    let name = ''
    if (parsedPath?.length === 1) {
        return name
    } else {
        if (parsedPath?.length > 3) {
            return `${data.ultimate_aon_sector_name} > ... > ${data.parent_aon_sector_name} >`
        } else if (parsedPath?.length === 3) {
            return `${data.ultimate_aon_sector_name} > ${data.parent_aon_sector_name} >`
        } else if (parsedPath?.length === 2) {
            return `${data.ultimate_aon_sector_name} >`
        } else {
            return name
        }
    }
}
</script>

<style lang="scss" scoped>
.search-markets-table {
    width: 100%;
    overflow: hidden;

    .search-wrapper {
        width: 300px;
    }
}

:deep(.table-aips-latest) {
    .action-holder {
        .fa-filter {
            display: none !important;
        }
    }
    .table-filters-container {
        height: 0px !important;
    }
    .table-actions {
        width: 3em !important;
    }
    .aips-table-wrap {
        padding-bottom: 100px;
    }
    .no-value {
        display: block !important;
    }
    .table-filters {
        .full-row {
            &:first-of-type {
                display: none;
            }
        }
    }
    .aips-table {
        th {
            box-shadow: none !important;

            &:hover {
                background-color: inherit !important;
            }
        }
    }
    .table-aips-headers {
        pointer-events: none;
    }
    .table-filter-chips .chip.Market {
        display: none;
    }
}
</style>
