<template>
    <div class="market-ranks">
        <TableAips
            :key="`market_ranks_${currentMarketId}`"
            class="market-ranks-table table mb-5"
            sticky-header
            table-height="500px"
            table-name="Companies"
            table-style="fixed-scrolling"
            toggle-columns
            :columns="columns"
            :data="tableData"
            :loading-data="loading"
            :page-size="20"
            :selected-row="`row-${getHighlightedRow}`"
            :show-bulk-actions="selectedCompaniesCount > 0"
            :table-bulk-actions="tableBulkActions"
            :table-collapse="false"
            :use-page-data="false"
            :default-sort="defaultSort"
            :default-filter="defaultFilter"
            @bulk-action-selected="selectAction"
            @loadData="loadTableData"
        >
            <template #bulkActionBarPrepend>
                <div class="holder mr-10 d-flex">
                    <p class="white--text link mr-2" @click="clearSelectedCompanies">Clear</p>
                    <p class="white--text mr-2">•</p>
                    <p class="selections bold white--text">
                        {{ $filters.toLocaleString(selectedCompaniesCount) }}
                        {{ t('actions.selected') }}
                    </p>
                </div>
            </template>
            <template #tableMessage>
                <div class="height-holder">
                    <AonSkeleton
                        class="w-full d-flex align-items-center"
                        :loading="loading && allData === 0"
                        :data="allData"
                        light
                    >
                        <div
                            class="info d-flex align-items-center flex-wrap nowrap"
                            style="width: fit-content"
                        >
                            {{
                                t('actions.showingCountOf', {
                                    count: $filters.toLocaleString(tableData?.length),
                                    total: $filters.toLocaleString(allData),
                                    units: 'companies',
                                })
                            }}
                        </div>
                    </AonSkeleton>
                </div>
            </template>
            <template #selected="{ data }">
                <AonCheckbox
                    remove-margins
                    :is-checked="isCompanySelected(data.aon_entity_pk)"
                    @clicked="toggleSelected(data)"
                />
            </template>
            <template #aon_entity_name="{ data }">
                <span class="link" @click="showCompanySummary(data)">
                    {{ data.aon_entity_name }}
                </span>
            </template>
            <template #revenue="{ data }">
                <span v-if="data.periodEndDate" class="revenue">
                    {{ data.revenue ? $filters.abbreviate(data.revenue) : '--' }}
                    ({{ data.periodEndDate.slice(0, 4) }})
                </span>
                <span v-else class="revenue">
                    {{ data.revenue ? $filters.abbreviate(data.revenue) : '--' }}
                </span>
            </template>
        </TableAips>
    </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { useDatasetStore, useIpMetricsStore, useEntityStore } from '@/stores'
import { useRouter } from 'vue-router'
import { postSectorEntitiesExtract, querySectorEntities } from '@/api/sectors'
import { getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import * as SearchApi from '@/api/opensearch'
import { useI18n } from 'vue-i18n'

import { AonDrawer, AonSkeleton } from '@moatmetrics/armory/src/components'
import { useAuth } from '@/auth/authPlugin'

const props = defineProps({
    marketId: {
        type: Number,
        required: true,
    },
    entityId: {
        type: Number,
        default: null,
    },
})

const eventBus = inject('eventBus')
const logger = inject('logger')
const $filters = inject('filters')

const { user } = useAuth()
const datasetStore = useDatasetStore()
const ipMetricsStore = useIpMetricsStore()
const { t } = useI18n()
const router = useRouter()
const defaultSort = ref([
    {
        sortColumn: 'cor',
        sortDirection: -1,
        sortPriority: 1,
    },
])

const defaultFilter = ref([
    {
        filterColumn: 'is_participating',
        filterValue: 'True',
        filterType: 'Equals',
        filterLabel: 'Participating',
        permanentFilter: true,
    },
])

const columns = ref([
    {
        headerCheckbox: false,
        hideFromColToggle: true,
        hideFromFilters: true,
        showColumn: true,
        useSlot: true,
        value: 'selected',
        width: '70px',
    },
    {
        value: 'aon_entity_name',
        locale: t('market.marketRanks.columns.company'),
        showColumn: true,
        useSlot: true,
        filterType: 'string',
        width: '500px',
    },
    {
        value: 'cor',
        locale: t('market.marketRanks.columns.cor'),
        showColumn: true,
        filterType: 'number',
        useItemTextForDisplay: true,
    },
    {
        value: 'coverage',
        locale: t('market.marketRanks.columns.coverage'),
        showColumn: true,
        filterType: 'number',
    },
    {
        value: 'opportunity',
        locale: t('market.marketRanks.columns.opportunity'),
        showColumn: true,
        filterType: 'number',
    },
    {
        value: 'resilience',
        locale: t('market.marketRanks.columns.risk'),
        showColumn: true,
        filterType: 'number',
    },
    {
        value: 'revenue',
        locale: t('market.marketRanks.columns.revenue'),
        showColumn: true,
        filterType: 'number',
        useSlot: true,
    },
    {
        value: 'is_participating',
        locale: 'Participating',
        showColumn: false,
        filterType: 'dropdown',
        filterItems: [
            { itemText: 'True', itemValue: 'True' },
            { itemText: 'False', itemValue: 'False' },
        ],
    },
])
const loading = ref(true)
const loadingExport = ref(false)
const tableData = ref([])
const allData = ref(0)
const selectedRowsById = ref({})

const tableBulkActions = computed(() => {
    const selectedCount = selectedCompaniesCount.value
    const isDisabled = !anySelectedCompanies.value || selectedCount > 25

    return [
        {
            itemText: t('actions.addToWatchlist.label'),
            disabledRule: !isDisabled,
            disabledMsg: 'Add to Watchlists action requires at least one selection',
            faIconStyle: 'fas',
            faIcon: 'list-dropdown',
        },
        {
            itemText: t('actions.exportLimit.label'),
            disabledRule: !isDisabled,
            disabledMsg: 'Add to Research Tabs action requires at least one selection. Limit 25',
            faIconStyle: 'fas',
            faIcon: 'download',
        },
    ]
})
const getHighlightedRow = computed(() => {
    return tableData.value.findIndex((item) => item.aon_entity_pk === currentEntityId.value)
})
const currentMarketId = computed(() => {
    return props.marketId
})
const currentEntityId = computed(() => {
    return props.entityId
})
const getSelectedCompaniesIds = computed(() => {
    return [...Object.keys(selectedRowsById.value).map(Number)]
})
const selectedCompaniesCount = computed(() => {
    return getSelectedCompaniesIds.value.length
})
const anySelectedCompanies = computed(() => {
    return getSelectedCompaniesIds.value.length !== 0
})

onMounted(async () => {
    if (currentEntityId.value) {
        await getEntityMarketRank()
    }
})

const selectAction = (action) => {
    const [watchlist, exportAction] = [
        t('actions.addToWatchlist.label'),
        t('actions.exportLimit.label'),
    ]

    switch (action.itemText) {
        case watchlist:
            addSelectedToWatchlist()
            break
        case exportAction:
            exportClicked()
            break
    }
}
const addSelectedToWatchlist = () => {
    datasetStore.setIdsToAdd({
        id_type: 'Entity',
        id_values: getSelectedCompaniesIds.value,
    })
}
const exportClicked = async () => {
    try {
        const email = user.value.email
        const username = email.substring(0, email.indexOf('@'))

        loadingExport.value = true
        await postSectorEntitiesExtract(currentMarketId.value, username, email)
        eventBus.emit('snacktime', {
            type: 'notification',
            message: `
                ${t('entityDetails.export.snackbarL1')}
                ${user.value.email}
                ${t('entityDetails.export.snackbarL2')}
            `,
        })
    } catch (err) {
        logger.error(err)
    } finally {
        loadingExport.value = false
    }
}
const showCompanySummary = async (rowData) => {
    const entityStore = useEntityStore()

    try {
        const dslQuery = getOpenSearchQuery({
            FC: ['aon_entity_pk'],
            FT: ['eq'],
            FV: [rowData.aon_entity_pk],
        })
        const { data } = await SearchApi.searchEntities(dslQuery)

        let company = data.hits.hits.map((h) => h._source)[0]
        entityStore.setSummaryEntity(company)
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('market.errors.quickSummary'),
        })
    }
}
const clearSelectedCompanies = () => {
    selectedRowsById.value = {}
}
const isCompanySelected = (entityId) => {
    return !!selectedRowsById.value[entityId]
}
const toggleSelected = (rowData) => {
    const { aon_entity_pk: entityId, aon_entity_name: entityName } = rowData

    if (!entityId || !entityName) {
        return
    }
    if (isCompanySelected(entityId)) {
        delete selectedRowsById.value[entityId]
        return
    }
    selectedRowsById.value[entityId] = entityName
}
const loadTableData = async ({ params, paramsChanged }) => {
    if (paramsChanged) {
        allData.value = 0
        tableData.value = []
    }

    await getMarketRanks(params)
}
const getEntityMarketRank = async () => {
    try {
        await entityStore.fetchEntityMarket({
            entityId: currentEntityId.value,
            marketId: currentMarketId.value,
        })
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('market.errors.marketRanks'),
        })
    }
}
const getMarketRanks = async (params) => {
    if (!currentMarketId.value) {
        return
    }
    try {
        loading.value = true

        let { data } = await querySectorEntities(currentMarketId.value, params)
        allData.value = data.rowCount
        if (data.results) {
            let scrubbedData = data.results.map((item) => {
                return {
                    ...item,
                    cor: parseInt(item.cor).toFixed(2),
                    coverage: parseInt(item.coverage).toFixed(2),
                    opportunity: parseInt(item.opportunity).toFixed(2),
                    resilience: parseInt(item.resilience).toFixed(2),
                }
            })

            tableData.value = [...tableData.value, ...scrubbedData]
        }
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('error.genericError'),
        })
    } finally {
        loading.value = false
    }
}
</script>

<style lang="scss" scoped>
.market-ranks {
    width: 100%;

    :deep(.show-all-dd-items) {
        .aon-dropdown .items ul {
            max-height: none !important;
        }
    }
}

:deep(.table-aips-latest) {
    .table-filters {
        .full-row {
            &:first-of-type {
                display: none;
            }
        }
    }

    .no-value {
        display: block !important;
    }

    .aips-table {
        table-layout: auto !important;
    }

    .overflow-x-scroll,
    .aips-table,
    .aips-table-wrap {
        transform: none !important;
    }

    .aips-table-wrap {
        padding-bottom: 100px;
    }
}
</style>
