import { defineStore } from 'pinia'
import * as EntityApi from '@/api/entities'
import * as MarketsApi from '@/api/sectors'
import { logger } from '@moatmetrics/vue-logger'

// TODO REMOVE deprecated when the newSearch component completely replaces the Home page SearchBar component.
export const useSearchStore = defineStore('search', {
    state: () => ({
        searchTerm: '',
        selectedContext: 'entities',
        active: false,
        searchResults: [],
        searchPaging: {},
    }),
    getters: {
        // TODO use arrow functions with state here instead to be more conscise and readable
        isEntitiesContext() {
            return this.selectedContext === 'entities'
        },
        isMarketsContext() {
            return this.selectedContext === 'markets'
        },
    },
    actions: {
        async resetState() {
            this.$reset()
        },
        async search(searchTerm) {
            try {
                const api = this.selectedContext === 'entities' ? EntityApi : MarketsApi
                const response = await api.search(searchTerm, this.searchPaging)

                if (!response) return

                const searchResponse = response.data

                this.searchResults = searchResponse.results
                this.setSearchPaging(searchResponse)
                return searchResponse
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        clearSearchResponse() {
            this.searchResults = []
            this.searchPaging = {}
        },
        setSearchPaging(paging = {}) {
            const searchPaging = {
                ...paging,
            }

            delete searchPaging.results
            this.searchPaging = searchPaging
        },

        // Mutations
        setSearchTerm(searchTerm) {
            this.searchTerm = searchTerm
        },
        setSelectedContext(context) {
            this.selectedContext = context
        },
    },
})
