<template>
    <div class="no-data">
        <div class="error-icon">
            <font-awesome-icon icon="fas fa-exclamation" class="knights-cloak--text" />
        </div>
        <div class="break"></div>
        <div v-if="errorMessage" class="error-msg">
            {{ errorMessage }}
        </div>
        <div v-else class="error-msg">
            {{ $t('error.noData') }}
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    errorMessage: {
        type: String,
        default: null,
    },
})

const errorMessage = computed(() => {
    return props.errorMessage
})
</script>

<style lang="scss">
.no-data {
    display: flex;
    flex-wrap: wrap;

    .error-msg {
        font-size: 1em;
        margin: 1em auto auto;
        font-weight: 400;
        color: $grey02;
    }

    .error-icon {
        margin: auto;
        width: 3.5em;
        height: 3.5em;
        border-radius: 50%;
        background-color: $grey07;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-inline--fa {
            font-size: 1.4em;
        }
    }
    .break {
        flex-basis: 100%;
        height: 0;
    }
}
</style>
