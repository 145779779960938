<template>
    <div class="screener-table mb-6">
        <TableAips
            ref="marketsTable"
            class="table"
            :table-name="tableTitle"
            sticky-header
            table-height="75vh"
            filter-transition-name="none"
            sticky-columns="first"
            toggle-columns
            table-density="compact"
            :use-page-data="false"
            :table-collapse="false"
            :page-size="20"
            :columns="columns"
            :data="data"
            :loading-data="loading"
            :default-sort="defaultSort"
            :table-bulk-actions="tableBulkActions"
            :show-bulk-actions="marketsSelected.length > 0"
            :filtered-data-length="totalDataLength"
            :default-filter="defaultFilter"
            enable-header-height-adjustment
            header-height-adjustment-offset="82px"
            @loadData="loadTableData"
            @bulk-action-selected="selectAction"
            @table-row-checked="selectRow"
        >
            <template #bulkActionBarPrepend>
                <div class="holder mr-10 d-flex">
                    <p class="white--text link mr-2" @click="clearSelectedData">Clear</p>
                    <p class="white--text mr-2">•</p>
                    <p class="selections bold white--text">
                        {{ $filters.toLocaleString(marketsSelected.length) }}
                        {{ $t('actions.selected') }}
                    </p>
                </div>
            </template>
            <template #tableMessage>
                <div class="height-holder w-full d-flex align-items-center">
                    <AonSkeleton
                        class="w-full d-flex align-items-center"
                        :loading="loading && totalDataLength === 0"
                        :data="totalDataLength"
                        light
                    >
                        <div
                            class="info d-flex align-items-center flex-wrap nowrap"
                            style="width: fit-content"
                        >
                            {{
                                $t('actions.showingCountOf', {
                                    count: $filters.toLocaleString(data.length),
                                    total: $filters.toLocaleString(totalDataLength),
                                    units: 'items',
                                })
                            }}
                        </div>
                    </AonSkeleton>
                </div>
            </template>
            <template v-if="showtooltips" #apex_scoreHeader>
                <VDropdown
                    class="ml-2"
                    placement="top"
                    :distance="5"
                    :triggers="['hover']"
                    :popper-triggers="['hover']"
                >
                    <font-awesome-icon
                        icon="fas fa-circle-info"
                        class="castle-moat--text d-inline metric-icon"
                    />
                    <template #popper>
                        <PopperMarketMetrics
                            :title="$t('definitionTooltips.market.apex.title')"
                            :definition="$t('definitionTooltips.market.apex.definition')"
                            :more-info="$t('definitionTooltips.market.apex.more')"
                            :z-score-info="$t('definitionTooltips.zScore.definition')"
                        />
                    </template>
                </VDropdown>
            </template>
            <template v-if="showtooltips" #market_ti_scoreHeader>
                <VDropdown
                    class="ml-2"
                    placement="top"
                    :distance="5"
                    :triggers="['hover']"
                    :popper-triggers="['hover']"
                >
                    <font-awesome-icon
                        icon="fas fa-circle-info"
                        class="castle-moat--text d-inline metric-icon"
                    />
                    <template #popper>
                        <PopperMarketMetrics
                            :title="$t('definitionTooltips.market.ti.title')"
                            :definition="$t('definitionTooltips.market.ti.definition')"
                            :more-info="$t('definitionTooltips.market.ti.more')"
                            :z-score-info="$t('definitionTooltips.zScore.definition')"
                        />
                    </template>
                </VDropdown>
            </template>
            <template #aon_sector_name="{ data }">
                <div class="caption">
                    {{ sectorHierarchyDisplayName(data) }}
                </div>
                <div class="wrap d-flex align-items-center">
                    <p
                        class="link overflow-ellipsis"
                        v-tooltip="{ content: sectorPathText(data.sector_path) }"
                        @click="showMarketSummary(data)"
                    >
                        {{ data.aon_sector_name }}
                    </p>
                    <div
                        class="cursor-pointer"
                        v-tooltip="{ content: $t('marketSummary.viewTaxonomy') }"
                        @click="setMarket(data)"
                    >
                        <font-awesome-icon icon="far fa-sitemap" class="ml-2 knights-cloak--text" />
                    </div>
                </div>
            </template>
        </TableAips>
        <AonDrawer
            class="a-drawer-right"
            width="500px"
            right
            :show-close="false"
            :drawer-open="showQuickSummary"
            @input="showQuickSummary = $event"
        >
        </AonDrawer>
        <MarketsDialog
            v-if="showMarketsDialog"
            :selected-market-id="parseInt(selectedMarket.aon_sector_pk)"
            :parent-market-id="parseInt(selectedMarket.ultimate_aon_sector_pk)"
            @cancel="showMarketsDialog = false"
        />
    </div>
</template>

<script setup>
import { computed, inject, onBeforeMount, ref, toRaw, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useDatasetStore, useMarketsStore, useScreenerStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import * as osApi from '@/api/opensearch'
import * as datasetApi from '@/api/datasets'
import { globalFilterToScreenerFilterString } from '@/lib/helpers'
import { useScreenerTableComposable } from '@/composables/screenerTable'
import { sectorPathText } from '@/lib/helpers'

import { AonDrawer } from '@moatmetrics/armory/src/components'
import PopperMarketMetrics from '@/components/PopperMarketMetrics'
import MarketsDialog from '@/components/Taxonomy/MarketsDialog'
import { useScreenerPresignedUrlDownloader } from '@/composables/presignedUrlDownloader'

import { useFlag } from '@unleash/proxy-client-vue'
import { useAuth } from '@/auth/authPlugin'

const showtooltips = useFlag('ipAlpha.legalTooltips')

const props = defineProps({
    tableTitle: {
        type: String,
        default: 'Markets',
    },
    screenerContextId: {
        type: String,
        default: 'screener',
    },
})

const logger = inject('logger')
const $filters = inject('filters')
const eventBus = inject('eventBus')

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { user } = useAuth()
const datasetStore = useDatasetStore()
const marketsStore = useMarketsStore()
const screenerStore = useScreenerStore(props.screenerContextId)
const initDefaultSort = [
    {
        sortColumn: 'apex_score',
        sortDirection: -1,
        sortPriority: 1,
    },
]

const { defaultFilter, defaultSort } = useScreenerTableComposable(
    'MARKET',
    initDefaultSort,
    props.screenerContextId.includes('Watchlist')
)

const marketsTable = ref(null)
const loading = ref(true)
const totalDataLength = ref(0)
const data = ref([])
const showQuickSummary = ref(false)
const datasetPk = ref(router.currentRoute.value.params.datasetPk)
let sectorPks = ref(null)
const showMarketsDialog = ref(false)
const selectedMarket = ref(false)
const marketsSelected = computed(() => screenerStore.marketsSelected)
const columns = ref([
    {
        checkboxValue: 'checked',
        showColumn: true,
        hasCheckbox: true,
        headerCheckbox: false,
        hideFromColToggle: true,
        hideFromFilters: true,
        width: '30px',
    },
    {
        value: 'aon_sector_name',
        locale: 'Market',
        showColumn: true,
        filterType: 'string',
        useSlot: true,
        disableTooltip: true,
        width: '400px',
        stickyColumn: true,
    },
    {
        value: 'calculated_total_revenue',
        locale: 'Annual Rev',
        showColumn: true,
        filterType: 'number',
        width: '170px',
    },
    {
        value: 'participating_company_count',
        locale: 'Participating Companies',
        showColumn: true,
        filterType: 'number',
        width: '170px',
    },
])

const tableBulkActions = computed(() => {
    const selectedCount = screenerStore.marketsSelected.length
    const isDisabled = !screenerStore.anyMarketsSelected || selectedCount > 25

    let tableActions = [
        {
            itemText: t('actions.addToWatchlist.label'),
            disabledRule: !isDisabled,
            disabledMsg: t('actions.addToWatchlist.disabled'),
            faIconStyle: 'fas',
            faIcon: 'list-dropdown',
        },
        {
            itemText: t('actions.exportLimit.label'),
            disabledRule: !isDisabled,
            disabledMsg: t('actions.exportLimit.disabled'),
            faIconStyle: 'fas',
            faIcon: 'download',
        },
    ]
    if (datasetPk.value) {
        tableActions = tableActions.toSpliced(2, 0, {
            itemText: t('actions.removeFromWatchlist.label'),
            disabledRule: !isDisabled,
            disabledMsg: t('actions.removeFromWatchlist.disabled'),
            faIconStyle: 'fas',
            faIcon: 'xmark',
        })
    }

    return tableActions
})

onBeforeMount(async () => {})

watch(
    () => screenerStore.marketsSelected,
    () => {
        setCheckboxSelections()
    }
)

const loadTableData = async ({ params }) => {
    const base = globalFilterToScreenerFilterString(params, initDefaultSort)
    if (!route.query.filter || route.query.filter !== base) {
        totalDataLength.value = 0
        data.value = []
        await router.replace({
            query: { filter: base },
        })
    }

    try {
        if (datasetPk.value) {
            params = marketsTable.value.getParams()
            if (!sectorPks.value) {
                let dataset = await datasetApi.getDataset(datasetPk.value)
                let datasetValues = await datasetApi.getDatasetValues(
                    datasetPk.value,
                    'Sector',
                    0,
                    dataset.data.sector_count
                )
                sectorPks.value = datasetValues.data.results
            }

            if (sectorPks.value.length > 0) {
                if (!params.FC) {
                    params.FC = []
                }
                if (!params.FT) {
                    params.FT = []
                }
                if (!params.FV) {
                    params.FV = []
                }
                params.FC.push('aon_sector_pk')
                params.FT.push('termsParam')
                params.FV.push(
                    sectorPks.value.flatMap((val) => {
                        return val.id_value
                    })
                )
            } else if (sectorPks.value.length == 0) {
                loading.value = false
                return
            }
        }

        const osParams = getOpenSearchQuery(params)

        await getTableData(osParams)
    } catch (err) {
        logger.error(err)
    }
}
const getTableData = async (params) => {
    try {
        loading.value = true

        const response = await osApi.searchSectors(params)

        totalDataLength.value = response.data.hits.total.value

        let scrubbedData = response.data.hits.hits.map((src) => {
            const item = src._source
            return {
                ...item,
                name: item.aon_sector_name,
                power_score: $filters.zScore(item.power_score),
                velocity_score: $filters.zScore(item.velocity_score),
                apex_score: $filters.zScore(item.apex_score),
                market_ti_score: $filters.zScore(item.market_ti_score),
                calculated_total_revenue: $filters.abbreviate(item.calculated_total_revenue),
                participating_company_count: $filters.toLocaleString(
                    item.participating_company_count
                ),
                checked: screenerStore.isMarketSelected(item.aon_sector_pk),
            }
        })

        if (params.from === 0) {
            data.value = [...scrubbedData]
        } else {
            data.value.push(...scrubbedData)
        }
    } catch (err) {
        logger.error(err)
    }

    loading.value = false
}
const showMarketSummary = async (data) => {
    let market = toRaw(data)
    marketsStore.setCurrentMarket(market)
}
const clearSelectedData = () => {
    screenerStore.setSelectedMarkets([])
}
const selectRow = (row) => {
    row.checked
        ? screenerStore.removeSelectedMarket(row.aon_sector_pk)
        : screenerStore.addSelectedMarket({ name: row.name, pk: row.aon_sector_pk })
}

const setCheckboxSelections = () => {
    data.value.map((val) => {
        if (screenerStore.marketsSelected.map((e) => e.pk).includes(val.aon_sector_pk)) {
            val.checked = true
        } else {
            val.checked = false
        }
        return val
    })
}
const setMarket = async (data) => {
    selectedMarket.value = data
    showMarketsDialog.value = true
}
const selectAction = async (action) => {
    const [watchlist, removeFromWatchlist, exportAction] = [
        t('actions.addToWatchlist.label'),
        t('actions.removeFromWatchlist.label'),
        t('actions.exportLimit.label'),
    ]
    switch (action.itemText) {
        case watchlist:
            addSelectedToWatchlist()
            break
        case removeFromWatchlist:
            await removeSelectedFromWatchlist()
            break
        case exportAction:
            exportSelected()
            break
    }
}

// TODO dry these common table action methods up and move them to a helper/lib
const addSelectedToWatchlist = () => {
    datasetStore.setIdsToAdd({
        id_type: 'Sector',
        id_values: screenerStore.getMarketsSelectedIds,
    })
}

const removeSelectedFromWatchlist = async () => {
    try {
        await datasetApi.removeFromDataset(
            datasetPk.value,
            'Sector',
            screenerStore.getMarketsSelectedIds.map((id) => {
                return { id_value: id.toString() }
            })
        )
        clearSelectedData()
        eventBus.emit('watchlistChanged', 'Sector')
        eventBus.emit('snacktime', {
            type: 'success',
            message: t('watchlists.remove.removeSuccess'),
        })
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('watchlists.remove.addError'),
        })
    }
}
const exportSelected = () => {
    const selectedIds = screenerStore.getMarketsSelectedIds
    const tableParams = marketsTable.value.getParams()
    tableParams.FC.push('aon_sector_pk')
    tableParams.FT.push('termsParam')
    tableParams.FV.push(selectedIds)
    const searchQuery = getOpenSearchQuery(tableParams)

    useScreenerPresignedUrlDownloader('sectors', searchQuery)
}

const sectorHierarchyDisplayName = (data) => {
    let name = ''
    if (data.sector_path?.length === 1) {
        return name
    } else {
        if (data.sector_path?.length > 3) {
            return `${data.ultimate_aon_sector_name} > ... > ${data.parent_aon_sector_name} >`
        } else if (data.sector_path?.length === 3) {
            return `${data.ultimate_aon_sector_name} > ${data.parent_aon_sector_name} >`
        } else if (data.sector_path?.length === 2) {
            return `${data.ultimate_aon_sector_name} >`
        } else {
            return name
        }
    }
}
</script>

<style scoped lang="scss">
.screener-table {
    width: 100%;
    overflow: hidden;
}

:deep(.table-aips-latest) {
    height: 100%;

    .aips-table-wrap {
        padding-bottom: 100px;
    }
    .no-value {
        display: block !important;
    }

    .table-filters {
        .full-row {
            &:first-of-type {
                display: none;
            }
        }
    }

    .aips-table {
        th {
            box-shadow: none !important;
        }
    }

    .table-aips-headers {
        th {
            &:first-of-type {
                pointer-events: none;
                cursor: default !important;
            }
        }
    }
}
</style>
