<template>
    <div class="company-watchlist">
        <div class="table-holder">
            <AonCoverLoading
                :loading="tableLoading"
                title="Loading Company Watchlist..."
            ></AonCoverLoading>
            <MoatTable
                style="width: 100%; height: 73vh"
                :class="themeClass"
                :defaultColDef="defaultColDef"
                :column-defs="colDefs"
                :row-data="tableData"
                :tooltip-interaction="true"
                :tooltip-mouse-track="true"
                :tooltip-show-delay="500"
                tooltip-show-mode="whenTruncated"
                :max-blocks-in-cache="10"
                :statusBar="statusBar"
                :sideBar="sideBar"
                :row-selection="rowSelection"
                @grid-ready="gridInit"
                @row-selected="selectRow"
            />
            <div class="add-company-holder">
                <AddCompany class="mt-2 ml-2" :watchlist-pk="watchlistPk" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, inject, onBeforeMount, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { getCompaniesByDataset, removeFromDataset } from '@/api/datasets.js'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import AddCompany from './helpers/AddCompany.vue'

const eventBus = inject('eventBus')
const { t } = useI18n()
const router = useRouter()
const filters = inject('filters')
const logger = inject('logger')

const themeClass = ref('ag-theme-quartz')
const gridApi = ref(null)
const watchlistPk = ref(router.currentRoute.value.params.datasetPk)
const selectedRows = ref([])
const tableData = ref([])
const tableLoading = ref(true)
const statusBar = ref(null)
const sideBar = ref(null)
const rowSelection = ref({
    mode: 'multiRow',
    checkboxes: true,
    enableClickSelection: true,
})
const defaultColDef = ref({
    flex: 1,
})
const colDefs = ref([
    {
        field: 'entityName',
        headerName: 'Company Name',
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        filter: 'agTextColumnFilter',
        minWidth: 500,
        sort: 'asc',
        sortingOrder: ['asc', 'desc', null],
    },
    {
        field: 'cor',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('definitionTooltips.cor.definition'),
            headerName: 'Moat Quality',
            enableFilterButton: true,
        },
        headerName: 'Moat Quality',
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'coverage',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.coverage'),
            headerName: 'Scope',
            enableFilterButton: true,
        },
        filter: 'agNumberColumnFilter',
        headerName: 'Scope',
    },
    {
        field: 'opportunity',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
            enableFilterButton: true,
        },
        filter: 'agNumberColumnFilter',
        headerName: 'Momentum',
    },
    {
        field: 'revenue',
        headerName: 'Annual Revenue',
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.revenue)
            }
        },
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'hqCountryName',
        headerName: 'HQ Country',
        width: 150,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.hqCountryName
            }
        },
        filter: 'agTextColumnFilter',
    },

    {
        field: 'employeeCount',
        headerName: 'Employee Count',
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.employeeCount ? p.data.employeeCount : '--'
            }
        },
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'patentCountUs',
        headerName: 'Active Assets',
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.patentCountUs ? parseInt(p.data.patentCountUs).toLocaleString() : '--'
            }
        },
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'ownershipStatus',
        headerName: 'Ownership Status',
        valueFormatter: (p) => {
            if (p.data) {
                return t(`screeners.company.ownershipStatus.${p.data.ownershipStatus}`)
            }
        },
        filter: true,
    },
])

onBeforeMount(() => {
    eventBus.on('update-watchlist', () => {
        onGetCompaniesByDataset()
    })
})

onMounted(() => {
    onGetCompaniesByDataset()
})

onBeforeUnmount(() => {
    eventBus.off('update-watchlist')
    eventBus.off('tool-panel-clear')
})

const gridInit = (params) => {
    gridApi.value = params.api
    gridApi.value.closeToolPanel()

    sideBar.value = {
        toolPanels: [
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
            {
                id: 'actions',
                labelDefault: `Actions`,
                labelKey: 'actions',
                iconKey: 'aggregation',
                toolPanel: 'MTCustomToolPanelActions',
                toolPanelParams: {
                    showRemoveFromWatchlistAction: true,
                    removeFromWatchlistAction: (params) => {
                        removeSelectedRows()
                        gridApi.value.deselectAll()
                    },
                    clearSelections: () => {
                        gridApi.value.deselectAll()
                    },
                },
            },
        ],
    }
}

const selectRow = (params) => {
    eventBus.emit('row-selected')
    selectedRows.value = gridApi.value.getSelectedRows()
    if (selectedRows.value.length >= 1) {
        gridApi.value.openToolPanel('actions')
    } else {
        gridApi.value.closeToolPanel()
    }

    gridApi.value.refreshToolPanel()
}

const onGetCompaniesByDataset = async () => {
    tableLoading.value = true
    try {
        const params = {
            SC: [],
            SD: [],
            FC: [],
            FT: [],
            FV: [],
            page_size: 1000,
            page: 1,
        }
        const { data } = await getCompaniesByDataset(watchlistPk.value, params)
        if (!data.results.length) {
            noData.value = true
            return
        }
        tableData.value = data.results
    } catch (error) {
        logger.error('Error fetching litigation cases', error)
    } finally {
        tableLoading.value = false
        eventBus.emit('update-popper-position')
    }
}

const removeSelectedRows = async () => {
    tableLoading.value = true
    const selectedIds = gridApi.value.getSelectedRows().map((row) => {
        return { id_value: row.entityPk.toString() }
    })
    try {
        await removeFromDataset(watchlistPk.value, 'Entity', selectedIds)
        eventBus.emit('watchlistChanged', 'Entity')
        eventBus.emit('snacktime', {
            type: 'success',
            message: t('watchlists.remove.removeSuccess'),
        })
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('watchlists.remove.addError'),
        })
    } finally {
        onGetCompaniesByDataset()
    }
}
</script>

<style lang="scss" scoped>
.company-watchlist {
    width: 100%;
    height: 84vh;
    position: relative;

    .table-holder {
        position: relative;
    }

    :deep(.ag-status-bar) {
        overflow: visible !important;
    }

    .add-company-holder {
        position: relative;
        display: inline-block;
    }
}
</style>
