import axios from 'axios'

const baseUrl = 'api/datasets'

export function getDataset(datasetPk) {
    return axios.get(`${baseUrl}/${datasetPk}`)
}

export function getCompaniesByDataset(datasetPk, params) {
    return axios.get(`${baseUrl}/${datasetPk}/entities`, { params })
}

export function getDatasets(params, userId) {
    const endpoint = `${baseUrl}/user/${userId}`
    return axios.get(endpoint, { params, paramsSerializer: { indexes: null } })
}

export function getDatasetValues(datasetPk, type, page, pageSize) {
    return axios.get(
        `${baseUrl}/${datasetPk}/values?page=${page}&page_size=${pageSize}&dataset_set_type=${type}`
    )
}

export function createDataset(dataset) {
    return axios.post(baseUrl, dataset)
}

export function updateDataset(datasetPk, userId, dataset_name, dataset_description) {
    const requestBody = {
        user_id: userId,
        dataset_name: dataset_name,
        dataset_description: dataset_description,
    }
    return axios.patch(`${baseUrl}/${datasetPk}`, requestBody)
}

export function addToDataset(datasetPk, userId, type, values) {
    const requestBody = {
        user_id: userId,
        dataset_set_type: type,
        dataset_values: values,
    }
    return axios.post(`${baseUrl}/${datasetPk}`, requestBody)
}

export function removeFromDataset(datasetPk, type, values) {
    const requestBody = {
        dataset_set_type: type,
        dataset_values: values,
    }
    return axios.post(`${baseUrl}/${datasetPk}/exclusion`, requestBody)
}

export function deleteDataset(datasetPk) {
    return axios.delete(`${baseUrl}/${datasetPk}`)
}
