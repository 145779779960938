<template>
    <div class="timeline-xy-chart-am">
        <div id="amChart"></div>
    </div>
</template>

<script setup>
import { onMounted, ref, inject, onBeforeUnmount } from 'vue'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useMoat2ProductStore } from '@/stores'

import {
    dataPlum,
    dataTeal,
    dataBlue,
    dataAqua,
    dataCobalt,
    dataMarine,
    dataMagenta,
    dataRaspberry,
    dataOrange,
    dataYellow,
    dataLime,
    dataGreen,
} from '@/styles/js_variables.module.scss'
import { config } from '@/config'

let root
let yearLabel
let selectedDataItem

// eslint-disable-next-line no-unused-lets
const $filters = inject('filters')
const moat2ProductStore = useMoat2ProductStore()

const props = defineProps({
    chartData: {
        type: Array,
        default: () => [],
    },
    entityId: {
        type: [Number, String],
        default: undefined,
    },
})

const yearData = ref({ 2002: [] })
const companies = ref([])
const firstYear = ref(2002)
const lastYear = ref(2024)
const currentYear = ref(firstYear.value)
const xAxis = ref(null)
const yAxis = ref(null)
const topCompanyColors = ref([
    dataTeal,
    dataPlum,
    dataBlue,
    dataRaspberry,
    dataOrange,
    dataYellow,
    dataLime,
    dataGreen,
    dataMagenta,
    dataMarine,
    dataCobalt,
    dataAqua,
])

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChart')
    configureChart()
})

onBeforeUnmount(() => {
    root.dispose()
})

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            // wheelY: 'zoomXY',
        })
    )

    // Parse Data + Make grid
    generateDataByYear()
    generateAxes(chart)

    // Main series
    let series = chart.series.push(
        am5xy.LineSeries.new(root, {
            calculateAggregates: true,
            xAxis: xAxis.value,
            yAxis: yAxis.value,
            valueYField: 'y',
            valueXField: 'x',
            valueField: 'value',
        })
    )

    // Secondary series on click of node
    let lineSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
            valueXField: 'x',
            valueYField: 'y',
            xAxis: xAxis.value,
            yAxis: yAxis.value,
            stroke: am5.color('#EE4B2B'),
            strokeWidth: 4,
        })
    )

    // Nodes + Labels + timeline slider + play button
    generateNodes(series, lineSeries)
    generateLabels(chart)
    generateInteractiveElements(chart, series) // Play Button + tooltip

    // Plot
    series.data.setAll(yearData.value[currentYear.value])
    series.appear(1000)
    chart.appear(1000, 100)
}

const generateDataByYear = () => {
    companies.value = props.chartData
        .map((item) => {
            return {
                name: item.aonEntityName,
                data: [],
            }
        })
        .filter((obj, index, self) => index === self.findIndex((t) => t.name === obj.name))

    for (let year = firstYear.value; year <= lastYear.value; year++) {
        let data = []
        data = props.chartData
            .map((obj) => {
                if (obj.year === year) {
                    return {
                        x: obj.scaledFilingDt,
                        y: obj.scaledPowerScore,
                        value: obj.scaledFinances,
                        name: obj.aonEntityName,
                        nameAbr: obj.displayName,
                        year: $filters.toUTCString(obj.averageFilingDt),
                        count: obj.patentCount,
                        power: obj.scaledPowerScore,
                        powerNatural: parseInt(obj.powerScore.toFixed(0)).toLocaleString(),
                        color: topCompanyColors.value[
                            moat2ProductStore.topCompetitorCollection.findIndex(
                                (id) => id === obj.aonEntityPk
                            )
                        ]
                            ? topCompanyColors.value[
                                  moat2ProductStore.topCompetitorCollection.findIndex(
                                      (id) => id === obj.aonEntityPk
                                  )
                              ]
                            : am5.color(0x000000),
                    }
                }
            })
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((item) => item) // remove any undefined

        yearData.value[year] = data
    }

    Object.entries(yearData.value).forEach(([key, val]) => {
        val.forEach((dataPoint) => {
            companies.value.find((comp) => comp.name === dataPoint.name).data.push(dataPoint)
        })
    })
}

const generateAxes = (chart) => {
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 50 })
    xRenderer.labels.template.set('visible', false)
    xAxis.value = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            max: 100,
            extraMin: 0.05,
            extraMax: 0.05,
            renderer: xRenderer,
        })
    )
    xAxis.value.children.push(
        am5.Label.new(root, { text: 'Filing Recency', x: am5.p50, centerX: am5.p50 })
    )

    let yRenderer = am5xy.AxisRendererY.new(root, {})
    yRenderer.labels.template.set('visible', false)

    yAxis.value = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            max: 100,
            extraMin: 0.05,
            extraMax: 0.05,
            renderer: yRenderer,
        })
    )
    yAxis.value.children.moveValue(
        am5.Label.new(root, {
            text: 'Innovation Score',
            rotation: -90,
            y: am5.p50,
            centerX: am5.p50,
        }),
        am5.Label.new(root, {
            text: 'Innovation Score',
            rotation: -90,
            y: am5.p50,
            centerX: am5.p50,
        }),
        0
    )

    generateGridColorFills(chart)
}

const generateGridColorFills = (chart) => {
    // top-right
    let series0 = chart.series.push(
        am5xy.LineSeries.new(root, {
            xAxis: xAxis.value,
            yAxis: yAxis.value,
            valueXField: 'ax',
            valueYField: 'ay',
            fill: am5.color('#FFFFFF'),
        })
    )
    series0.fills.template.setAll({ fillOpacity: 0.5, visible: true })
    series0.strokes.template.set('forceHidden', true)
    series0.data.setAll([
        { ax: 50, ay: 50 }, // bottom left
        { ax: 150, ay: 50 }, // bottom right
        { ax: 150, ay: 150 }, // top right
        { ax: 50, ay: 150 }, // top left
    ])

    //bottom-right
    let series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
            xAxis: xAxis.value,
            yAxis: yAxis.value,
            valueXField: 'ax',
            valueYField: 'ay',
            fill: am5.color('#CDDBDE'),
        })
    )
    series1.fills.template.setAll({ fillOpacity: 0.5, visible: true })
    series1.strokes.template.set('forceHidden', true)
    series1.data.setAll([
        { ax: 50, ay: -50 }, // bottom left
        { ax: 150, ay: -50 }, // bottom right
        { ax: 150, ay: 50 }, // top right
        { ax: 50, ay: 50 }, // top left
    ])

    // bottom-left
    let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
            xAxis: xAxis.value,
            yAxis: yAxis.value,
            valueXField: 'ax',
            valueYField: 'ay',
            fill: am5.color('#FFFFFF'),
        })
    )
    series2.fills.template.setAll({ fillOpacity: 0.5, visible: true })
    series2.strokes.template.set('forceHidden', true)
    series2.data.setAll([
        { ax: -50, ay: -50 }, // bottom left
        { ax: 50, ay: -50 }, // bottom right
        { ax: 50, ay: 50 }, // top right
        { ax: -50, ay: 50 }, // top left
    ])

    // top-left
    let series3 = chart.series.push(
        am5xy.LineSeries.new(root, {
            xAxis: xAxis.value,
            yAxis: yAxis.value,
            valueXField: 'ax',
            valueYField: 'ay',
            fill: am5.color('#CDDBDE'),
        })
    )
    series3.fills.template.setAll({ fillOpacity: 0.5, visible: true })
    series3.strokes.template.set('forceHidden', true)
    series3.data.setAll([
        { ax: -50, ay: 50 }, // bottom left
        { ax: 50, ay: 50 }, // bottom right
        { ax: 50, ay: 150 }, // top right
        { ax: -50, ay: 150 }, // top left
    ])
}

const generateNodes = (series, lineSeries) => {
    // let circleTemplate = am5.Template.new(root)
    // console.log(series)
    let circleTemplate = am5.Template.new(root)
    circleTemplate.adapters.add('fill', function (fill, target) {
        let dataItem = target.dataItem
        if (dataItem) {
            return dataItem.dataContext.color
        }
        return fill
    })

    series.bullets.push(function () {
        let container = am5.Container.new(root, {})

        // add a circle to bullet container
        let circle
        circle = container.children.push(
            am5.Circle.new(
                root,
                {
                    fill: series.get('fill'),
                    // fill: series.dataItems.find(item => item.dataContext.name === moat2ProductStore.entity.name) ? am5.color(am5.color(0x000000) ? am5.color(am5.color(0x000000)),
                    fillOpacity: 0.3,
                    tooltipText: `Company Name: [bold]{name}[/]\nAverage Filing Date: [bold]{year}[/]\nCumulative Patent Count: [bold]{count}[/]\nInnovation Score: [bold]${'{powerNatural}'}[/]`,
                    tooltipY: 0,
                },
                circleTemplate
            )
        )

        // add label to bullet container
        container.children.push(
            am5.Label.new(root, {
                text: '{nameAbr}',
                fill: am5.color(0xffffff),
                populateText: true,
                centerX: am5.p50,
                centerY: am5.p50,
                textAlign: 'center',
                fontSize: '10px',
            })
        )

        // final node
        return am5.Bullet.new(root, {
            sprite: container,
        })
    })

    series.strokes.template.set('strokeOpacity', 0)
    series.set('heatRules', [
        {
            target: circleTemplate,
            min: 10,
            max: 40,
            dataField: 'value',
            key: 'radius',
            maxValue: 100,
        },
    ])

    // Events for nodes
    circleTemplate.events.on('click', (e) => {
        if (selectedDataItem == e.target.dataItem) {
            am5.array.each(series.dataItems, function (dataItem) {
                if (dataItem.bullets) {
                    var bullet = dataItem.bullets[0]
                    var sprite = bullet.get('sprite')
                    sprite.children.values[0].set('fillOpacity', 0.3)
                    sprite.children.values[1].set('fillOpacity', 1)
                }
            })
            lineSeries.data.clear()
            selectedDataItem = null
        } else {
            selectedDataItem = e.target.dataItem
            lineSeries.data.setAll(
                companies.value.find((item) => item.name === selectedDataItem.dataContext.name).data
            )
            lineSeries.show()

            am5.array.each(series.dataItems, function (dataItem) {
                if (dataItem.bullets) {
                    var bullet = dataItem.bullets[0]
                    var sprite = bullet.get('sprite')
                    if (dataItem !== selectedDataItem) {
                        sprite.children.values[0].set('fillOpacity', 0.05)
                        sprite.children.values[1].set('fillOpacity', 0.4)
                    } else {
                        sprite.children.values[0].set('fillOpacity', 0.3)
                        sprite.children.values[1].set('fillOpacity', 1)
                    }
                }
            })
        }
        setTimeout(() => {
            series.chart.events.enableType('click')
        }, 100)
    })

    // circleTemplate.events.on('blur', (e) => {
    //     console.log('jkbsdf')
    // })

    // secondary series nodes
    lineSeries.strokes.template.set('strokeOpacity', 1)
    lineSeries.bullets.push(function () {
        var bulletCircle = am5.Circle.new(root, {
            radius: 2,
            fill: lineSeries.stroke,
        })
        return am5.Bullet.new(root, {
            sprite: bulletCircle,
        })
    })
}

const generateLabels = (chart) => {
    // Current Year Label
    yearLabel = chart.plotContainer.children.push(
        am5.Label.new(root, {
            text: currentYear.value.toString(),
            fontSize: '3em',
            fill: am5.color(0x000000),
            opacity: 0.3,
        })
    )
    chart.plotContainer.children.push(
        am5.Label.new(root, {
            text: 'Innovators',
            fontSize: '1em',
            fill: am5.color('#555'),
            opacity: 0.7,
            x: am5.percent(100),
            y: am5.percent(43),
            centerX: am5.p100,
        })
    )
    chart.plotContainer.children.push(
        am5.Label.new(root, {
            text: 'Emerging Challengers',
            fontSize: '1em',
            fill: am5.color('#555'),
            opacity: 0.7,
            x: am5.percent(100),
            y: am5.percent(50),
            centerX: am5.p100,
        })
    )
    chart.plotContainer.children.push(
        am5.Label.new(root, {
            text: 'Established Pioneers',
            fontSize: '1em',
            fill: am5.color('#555'),
            opacity: 0.5,
            x: am5.percent(0),
            y: am5.percent(43),
        })
    )
    chart.plotContainer.children.push(
        am5.Label.new(root, {
            text: 'Legacy Holders',
            fontSize: '1em',
            fill: am5.color('#555'),
            opacity: 0.5,
            x: am5.percent(0),
            y: am5.percent(50),
        })
    )
}

const generateInteractiveElements = (chart, series) => {
    // Conatiner for any interactive elements
    let container = chart.plotContainer.children.push(
        am5.Container.new(root, {
            y: am5.percent(99),
            centerX: am5.p50,
            centerY: am5.p100,
            x: am5.p50,
            width: am5.percent(90),
            layout: root.horizontalLayout,
            paddingBottom: 0,
        })
    )

    // Play button + slider
    let playButton = container.children.push(
        am5.Button.new(root, {
            themeTags: ['play'],
            centerY: am5.p100,
            marginRight: 20,
            icon: am5.Graphics.new(root, {
                themeTags: ['icon'],
            }),
            dy: 40,
        })
    )

    let slider = container.children.push(
        am5.Slider.new(root, {
            orientation: 'horizontal',
            start: 1,
            centerY: am5.p100,
            dy: 30,
        })
    )

    playButton.events.on('click', function () {
        if (playButton.get('active')) {
            slider.set('start', slider.get('start'))
        } else {
            if (slider.get('start') === 1) {
                slider.set('start', 0)
            }
            slider.animate({
                key: 'start',
                to: 1,
                duration: 18000,
            })
        }
    })

    slider.on('start', function (start) {
        if (start === 1) {
            playButton.set('active', false)
        }
    })

    slider.events.on('rangechanged', function () {
        updateSeriesData(
            firstYear.value +
                Math.round(slider.get('start', 0) * (lastYear.value - firstYear.value)),
            series
        )
    })

    chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
            xAxis: xAxis.value,
            yAxis: yAxis.value,
        })
    )
}

// Helpers
const updateSeriesData = (year, series) => {
    if (currentYear.value != year) {
        currentYear.value = year
        let data = yearData.value[year]
        let i = 0
        am5.array.each(data, function (item) {
            series.data.setIndex(i, item)
            i++
        })

        yearLabel.set('text', year.toString())
    }
}
</script>

<style lang="scss" scoped>
.timeline-xy-chart-am {
    width: 100%;
    height: 100%;
    position: relative;

    #amChart {
        width: 100%;
        height: 100%;

        div {
            height: 100%;
        }
    }
}
</style>
