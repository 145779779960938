<template>
    <AonModal
        class="edit-modal"
        title="Rename Screener"
        col-width="8"
        @close="cancelDialog()"
        @click:outside="cancelDialog()"
    >
        <ScreenerForm
            :screener="screener"
            :loading="loading"
            submit-text="update"
            @submit="update"
        />
    </AonModal>
</template>

<script>
import { useScreenerStore } from '@/stores'

import { updateScreener as PutScreener } from '@/api/screeners'

import ScreenerForm from '@/components/screeners/ScreenerForm'

export default {
    name: 'ScreenerEditCard',
    components: { ScreenerForm },
    props: {
        screener: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        cancelDialog() {
            this.$emit('cancel')
        },
        async update(screener) {
            try {
                const screenerStore = useScreenerStore()

                this.loading = true
                await PutScreener(screener)
                if (screener.type === 'COMPANY') {
                    await screenerStore.updateCompanyScreener(screener)
                } else {
                    await screenerStore.updateMarketScreener(screener)
                }
                this.$emit('submit')
            } catch (err) {
                this.$logger.error(err)
                this.eventBus.emit('snacktime', {
                    type: 'error',
                    message: this.$t('screeners.screener.errors.update'),
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-modal {
    :deep(.aon-col) {
        width: 100% !important;
        height: auto !important;
    }
}
</style>
