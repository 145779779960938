<template>
    <AonModal title="Technology Area Breakdown" col-width="10" @close="closeModal()">
        <div class="modal-content">
            <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" />
            <MoatTable
                v-if="!loading"
                style="width: 100%; height: 500px"
                :class="themeClass"
                :column-defs="colDefs"
                :row-data="tableData"
                :auto-group-column-def="autoGroupColumnDef"
                :side-bar="sideBar"
                :tooltip-show-delay="500"
                @grid-ready="gridInit"
            />
        </div>
    </AonModal>
</template>

<script setup>
import { ref, onMounted, inject, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'

import { getEntityTechBreakdown } from '@/api/productAlignment.js'
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { formatTpeRange } from '@/lib/financial.js'

const eventBus = inject('eventBus')
const logger = inject('logger')
const { t } = useI18n()
const emit = defineEmits(['close-entity-tech-breakdown-modal'])
const props = defineProps({
    entityPkList: {
        type: Array,
        required: true,
    },
    techAreaIds: {
        type: Array,
        required: true,
    },
    alignmentRunPk: {
        type: [Number, String],
        default: undefined,
    },
})

const loading = ref(true)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')

const sideBar = ref(null)
const tableData = ref([])
const autoGroupColumnDef = ref({
    headerName: 'Technology Areas By Company',
    minWidth: 400,
    cellRenderer: 'agGroupCellRenderer',
})
const colDefs = ref([
    {
        headerName: 'Product',
        headerTooltip: 'Product',
        valueGetter: (p) => {
            if (p.data) {
                return p.data.ultimateConceptDisplayName
            }
        },
        filter: 'agTextColumnFilter',
        sort: 'asc',
        sortIndex: 0,
    },
    {
        field: 'displayName',
        headerName: 'Technology Area',
        minWidth: 400,
        filter: 'agTextColumnFilter',
        sort: 'asc',
        sortIndex: 1,
    },
    {
        field: 'entityName',
        headerName: 'Company',
        minWidth: 200,
        filter: 'agTextColumnFilter',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
            headerName: 'Innovation Score',
        },
        field: 'powerScore',
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
            }
        },
        minWidth: 220,
        filter: 'agNumberColumnFilter',
        sort: 'desc',
        sortIndex: 2,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
        },
        field: 'opportunity',
        valueFormatter: (p) => {
            if (p.data) {
                return Math.round(p.data.opportunity)
            }
        },
        headerTooltip: 'Momentum',
        minWidth: 150,
        flex: 1,
        filter: 'agNumberColumnFilter',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'II P/E',
        },
        field: 'tpe',
        headerTooltip: 'Innovation Implied P/E',
        minWidth: 120,
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
            }
        },
        filter: 'agNumberColumnFilter',
    },
])
onMounted(() => {
    onGetEntityTechBreakdown()
})

onBeforeUnmount(() => {
    eventBus.off('close-tech-area-modal')
})

const onGetEntityTechBreakdown = async () => {
    loading.value = true

    let alignmentRunPk = props.alignmentRunPk
    let techAreaIds = props.techAreaIds
    let entityPks = props.entityPkList.map((entity) => entity.aonEntityPk)

    try {
        let { data } = await getEntityTechBreakdown(alignmentRunPk, techAreaIds, entityPks)
        tableData.value = data
    } catch (error) {
        logger.error(error)
    } finally {
        loading.value = false
    }
}

const gridInit = (params) => {
    gridApi.value = params.api

    sideBar.value = {
        toolPanels: [
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    }
}

const closeModal = () => {
    emit('close-entity-tech-breakdown-modal', true)
}
</script>

<style lang="scss" scoped>
.a-modal {
    top: 50% !important;
    left: 41% !important;
    transform: translate(-41%, -50%) !important;
}
</style>
