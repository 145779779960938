<template>
    <AonContainer add-page-padding class="knights-cloak">
        <AonRow>
            <AonCol class="a-col-12 pt-6 pb-16">
                <h4 class="grey08--text">
                    {{ $t('user.accountProfile') }}
                </h4>
            </AonCol>
        </AonRow>
    </AonContainer>
    <AonContainer add-page-padding>
        <AonRow>
            <AonCol class="aon-col-12">
                <AonCard :title="$t('user.accountDetails')" class="mt-n12">
                    <AonContainer>
                        <AonRow>
                            <AonCol class="aon-col-4 pl-0">
                                <label>{{ $t('user.firstName') }}</label>
                                <h5
                                    class="overflow-ellipsis"
                                    v-tooltip="{
                                        content: user?.given_name,
                                    }"
                                >
                                    {{ user?.given_name }}
                                </h5>
                            </AonCol>
                            <AonCol class="aon-col-4 pr-0">
                                <label>{{ $t('user.lastName') }}</label>
                                <h5
                                    class="overflow-ellipsis"
                                    v-tooltip="{
                                        content: user?.family_name,
                                    }"
                                >
                                    {{ user?.family_name }}
                                </h5>
                            </AonCol>
                            <AonCol class="aon-col-4 pr-0">
                                <label>{{ $t('user.email') }}</label>
                                <h5
                                    class="overflow-ellipsis"
                                    v-tooltip="{
                                        content: user?.email,
                                    }"
                                >
                                    {{ user?.email }}
                                </h5>
                            </AonCol>
                        </AonRow>
                    </AonContainer>
                </AonCard>
            </AonCol>
        </AonRow>
    </AonContainer>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useAuth } from '@/auth/authPlugin'
const { t } = useI18n()
const { user } = useAuth()
</script>

<style scoped lang="scss">
.account-page {
    .button-holder {
        display: flex;
    }

    .disabled {
        opacity: 0.6;
    }

    .input-lock {
        pointer-events: none;
    }

    .icons {
        .icon {
            cursor: pointer;
        }
    }

    .form-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
