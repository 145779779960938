<template>
    <div class="playbook-generation-container">
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" />
        <div class="parent-holder d-flex" v-else>
            <PlaybookStepTracker
                overwrite-class="no-substeps"
                allow-step-navigation
                heading="Create a new Playbook"
                allow-collapse
            />
            <KeepAlive>
                <div
                    v-if="
                        playbookGenerationStore.trackStepsFinal.length === 0 ||
                        playbookGenerationStore.trackStepsFinal[0].isCurrentStep
                    "
                    class="w-full pa-5"
                >
                    <h5>Select Playbook Type</h5>
                    <AonCard class="mt-5">
                        <div class="content-holder">
                            <PlaybookTypeSelection />
                        </div>
                    </AonCard>
                </div>
            </KeepAlive>

            <div v-if="showMatchUpPlaybook" class="pa-5 flex-grow-1" style="width: 0">
                <MatchUpPlaybook />
            </div>
        </div>
        <AonContainer>
            <AonRow style="height: 75px">
                <AonCol class="aon-col-12 pa-0">
                    <PlaybookNav />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { computed, ref, onBeforeMount, onMounted } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'

import PlaybookStepTracker from '@/components/playbooks/shared/PlaybookStepTracker.vue'
import PlaybookNav from '@/components/playbooks/shared/PlaybookNav.vue'
import PlaybookTypeSelection from '@/components/playbooks/shared/PlaybookTypeSelection.vue'

import MatchUpPlaybook from './playbookTypes/MatchUpPlaybook.vue'

const playbookGenerationStore = usePlaybookGenerationStore()
const router = useRouter()

const loading = ref(true)

onBeforeMount(async () => {
    loading.value = false
})

onMounted(() => {})

const showMatchUpPlaybook = computed(() => {
    return (
        playbookGenerationStore.playbookTypeSelected === 'matchUp' &&
        playbookGenerationStore.trackStepsFinal.length > 0 &&
        !playbookGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})
</script>

<style lang="scss" scoped>
.playbook-generation-container {
    height: 100%;
    width: 100%;

    .parent-holder {
        height: calc(100% - 78px);
    }

    .content-holder {
        height: 100%;
    }

    .ignore-max-width {
        max-width: none !important;
    }

    .prevent-content-overflow {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
