import { generateEntitiesReport, generateSectorsReport } from '@/api/opensearch'
import { useAuth } from '@/auth/authPlugin'

export const useScreenerPresignedUrlDownloader = async (screenerType, query) => {
    try {
        const { user } = useAuth()

        const payload = {
            ...query,
            from: 0,
            size: 25,
            username: user.value.email,
        }
        let data

        if (screenerType === 'entities') {
            const response = await generateEntitiesReport(payload)

            data = response.data
        } else if (screenerType === 'sectors') {
            const response = await generateSectorsReport(payload)

            data = response.data
        }
        downloadFile(data)
    } catch (err) {
        logger.error(err)
    }
}

export const downloadFile = (url) => {
    let link = document.createElement('a')

    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const downloadFiles = (urls) => {
    let link = document.createElement('a')
    link.style.display = 'none'
    link.target = '_blank'
    document.body.appendChild(link)

    for (let i = 0; i < urls.length; i++) {
        link.href = urls[i].url
        link.setAttribute('download', urls[i].name)
        link.click()

        // setTimeout(() => {}, 1000)
    }
    document.body.removeChild(link)
}
