<template>
    <AonModal
        :title="t('watchlists.edit.header')"
        col_width="4"
        @close="cancelDialog()"
        @click:outside="close()"
    >
        <AonContainer>
            <AonInput
                v-model="watchlistName"
                maxlength="255"
                class="mb-5"
                :label="t('watchlists.edit.name')"
            />
            <AonTextarea
                v-model="watchlistDescription"
                maxlength="767"
                class="mb-5"
                :label="t('watchlists.edit.description')"
            />
            <div class="edit-watchlist-buttons">
                <AonButton
                    class="button btn-create mr-5"
                    :label="t('watchlists.edit.save')"
                    :loading="modifyingWatchlist"
                    :disabled="
                        !watchlistName || watchlistName === null || watchlistName?.trim() === ''
                    "
                    @clicked="editDataset"
                />
                <AonButton
                    :label="t('watchlists.edit.cancel')"
                    type="ghost"
                    @clicked="cancelDialog()"
                />
            </div>
        </AonContainer>
    </AonModal>
</template>

<script setup>
import { ref, onBeforeMount, inject } from 'vue'
import { AonContainer, AonTextarea } from '@moatmetrics/armory/src/components'
import { useDatasetStore } from '@/stores'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const eventBus = inject('eventBus')
const datasetStore = useDatasetStore()
const modifyingWatchlist = ref(false)
const watchlistName = ref(null)
const watchlistDescription = ref(null)

onBeforeMount(async () => {
    watchlistName.value = datasetStore.datasetToEdit.dataset_name
    watchlistDescription.value = datasetStore.datasetToEdit.dataset_description
})

const editDataset = async () => {
    modifyingWatchlist.value = true

    try {
        await datasetStore.editDataset(
            datasetStore.datasetToEdit.dataset_pk,
            watchlistName.value,
            watchlistDescription.value
        )
        eventBus.emit('snacktime', {
            type: 'success',
            message: t('watchlists.edit.editSuccess', { watchlistName: watchlistName.value }),
        })
        eventBus.emit('watchlistChanged')
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('watchlists.edit.editError', {
                watchlistName: datasetStore.datasetToEdit.dataset_name,
            }),
        })
    }

    modifyingWatchlist.value = false
    cancelDialog()
}

const cancelDialog = () => {
    datasetStore.setDatasetToEdit(null)
}
</script>

<style lang="scss" scoped>
.edit-watchlist-buttons {
    display: flex;
}
</style>
