<template>
    <div class="inventor-behavior-card mt-5">
        <p class="y-axis-label bold">Inventor</p>
        <p class="y-axis-label2 bold">Patent Count</p>
        <p class="x-axis-label bold">Patent Filings Over Time</p>
        <div id="amChartInventor" ref="amChartInventor"></div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { useEntityStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const entityStore = useEntityStore()
const amChartInventor = ref(null)
const props = defineProps({
    chartData: {
        type: Array,
        required: true,
        default: () => [],
    },
})

let root
let yAxis
let yAxis2
let xAxis

let atCompanyData = ref(null)
let awayFromCompanyData = ref(null)

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new(amChartInventor.value)
    mapData()
    configureChart()
})

onBeforeUnmount(() => {
    root.dispose()
})

const mapData = () => {
    atCompanyData.value = props.chartData.flatMap((entry) =>
        entry.inventionDates
            .filter((item) => item.atCompany)
            .map((item) => ({
                date: new Date(item.date).getTime(),
                name: entry.inventorName,
            }))
    )

    awayFromCompanyData.value = props.chartData.flatMap((entry) =>
        entry.inventionDates
            .filter((item) => !item.atCompany)
            .map((item) => ({
                date: new Date(item.date).getTime(),
                name: entry.inventorName,
            }))
    )
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    // root.dateFormatter.setAll({
    //     dateFormat: 'MM-dd-yyyy',
    //     dateFields: ['date'],
    // })

    let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            interactive: false,
            wheelY: 'none',
            layout: root.verticalLayout,
            paddingLeft: 50,
            paddingRight: 50,
        })
    )
    generateAxes(chart)
}
const generateAxes = (chart) => {
    var yRenderer = am5xy.AxisRendererY.new(root, {
        inversed: true,
    })

    yRenderer.grid.template.setAll({
        location: 0.5,
    })

    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            renderer: yRenderer,
            categoryField: 'name',
        })
    )

    var yRenderer2 = am5xy.AxisRendererY.new(root, {
        opposite: true,
        inversed: true,
    })
    yAxis2 = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'name',
            valueField: 'value',
            renderer: yRenderer2,
        })
    )

    yRenderer2.grid.template.setAll({
        forceHidden: true,
    })

    yRenderer2.labels.template.setAll({
        populateText: true,
    })
    yRenderer2.labels.template.adapters.add('text', function (text, target) {
        return '{count}'
    })

    var xRenderer = am5xy.AxisRendererX.new(root, {
        visible: false,
        minorGridEnabled: true,
        minGridDistance: 70,
    })

    xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: 'day', count: 30 },
            gridIntervals: [{ timeUnit: 'year', count: 1 }],
            renderer: xRenderer,
            cellEndLocation: 1,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    // xAxis.children.push(am5.Label.new(root, { text: 'Years', x: am5.p50, centerX: am5.p50 }))

    generateSeries(chart)
}

const generateSeries = (chart) => {
    let whileAtCompany = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: 'date',
            categoryYField: 'name',
        })
    )

    whileAtCompany.columns.template.setAll({
        forceHidden: true,
    })

    whileAtCompany.bullets.push(function () {
        var graphics = am5.Rectangle.new(root, {
            width: 6,
            height: 20,
            centerX: am5.p50,
            centerY: am5.p50,
            stroke: whileAtCompany.get('stroke'),
            fill: am5.color('#143862'), //knight-cloak
        })

        return am5.Bullet.new(root, {
            sprite: graphics,
            locationX: 1,
        })
    })

    let awayFromCompany = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: 'date',
            categoryYField: 'name',
        })
    )

    awayFromCompany.columns.template.setAll({
        forceHidden: true,
    })

    awayFromCompany.bullets.push(function () {
        var graphics = am5.Rectangle.new(root, {
            width: 6,
            height: 20,
            centerX: am5.p50,
            centerY: am5.p50,
            stroke: awayFromCompany.get('stroke'),
            fill: am5.color('#E06259'), // error
        })

        return am5.Bullet.new(root, {
            sprite: graphics,
            locationX: 1,
        })
    })

    let legend = chart.children.push(
        am5.Legend.new(root, {
            nameField: 'name',
            fillField: 'color',
            strokeField: 'color',
            centerX: am5.percent(40),
            x: am5.percent(50),
            paddingTop: 50,
        })
    )

    legend.data.setAll([
        {
            name: `While at ${entityStore.entity.name}`,
            color: am5.color('#143862'), // Knights-cloak
        },
        {
            name: `Away From ${entityStore.entity.name}`,
            color: am5.color('#E06259'), // error
        },
    ])

    const uniqueNames = new Map()
    const mergedData = [...atCompanyData.value, ...awayFromCompanyData.value]

    mergedData.forEach((item) => {
        const name = item.name
        uniqueNames.set(name, (uniqueNames.get(name) || 0) + 1)
    })

    yAxis.data.setAll(Array.from(uniqueNames, ([name, count]) => ({ name, count })))
    yAxis2.data.setAll(Array.from(uniqueNames, ([name, count]) => ({ name, count })))

    awayFromCompany.data.setAll(awayFromCompanyData.value)
    whileAtCompany.data.setAll(atCompanyData.value)
}
</script>

<style lang="scss" scoped>
.inventor-behavior-card {
    width: 100%;
    position: relative;

    #amChartInventor {
        width: 100%;
        height: 600px;

        div {
            height: 100%;
        }
    }

    .y-axis-label {
        position: absolute;
        left: 10px;
        top: 50%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }

    .y-axis-label2 {
        position: absolute;
        right: 10px;
        top: 50%;
        transform-origin: center right;
        transform: translateY(-50%) rotate(90deg);
    }

    .x-axis-label {
        position: absolute;
        left: 50%;
        bottom: 60px;
        transform: translateX(-50%);
    }
}
</style>
