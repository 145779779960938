<template>
    <AonCard>
        <div class="card-content d-flex flex-wrap">
            <div class="top d-flex flex-wrap mt-n2">
                <a class="bold" @click="getScreenerRoute(screener)">
                    {{
                        screener.name === 'Aon Apex Competencies'
                            ? 'Aon Apex Markets'
                            : screener.name
                    }}
                </a>
                <ScreenerDropdownActions :screener="screener" @screenerAction="performAction" />
            </div>
            <div class="bottom">
                <p class="extra-small">
                    {{ screener.description.replace('Competencies', 'Markets') }}
                </p>
            </div>
        </div>
        <ScreenerCreateCard
            v-if="screenerAction === 'saveAs'"
            :screener="screener"
            :dialog-open="screenerAction === 'saveAs'"
            @cancel="setScreenerAction()"
            @submit="setScreenerAction()"
        />
        <ScreenerEditCard
            v-if="screenerAction === 'rename'"
            :screener="screener"
            :dialog-open="screenerAction === 'rename'"
            @submit="setScreenerAction()"
            @cancel="setScreenerAction()"
        />
        <ScreenerDeleteCard
            v-if="screenerAction === 'delete'"
            :screener="screener"
            :dialog-open="screenerAction === 'delete'"
            @submit="setScreenerAction()"
            @cancel="setScreenerAction()"
        />
    </AonCard>
</template>

<script>
import ScreenerDropdownActions from '@/components/screeners/ScreenerDropdownActions'
import ScreenerCreateCard from '@/components/screeners/ScreenerCreateCard'
import ScreenerEditCard from '@/components/screeners/ScreenerEditCard'
import ScreenerDeleteCard from '@/components/screeners/ScreenerDeleteCard'
import { COMPANY_SCREENER } from '@/lib/constants/route-names'

export default {
    name: 'ScreenerListItem',
    components: {
        ScreenerCreateCard,
        ScreenerEditCard,
        ScreenerDeleteCard,
        ScreenerDropdownActions,
    },
    props: {
        screener: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            screenerAction: '',
        }
    },
    methods: {
        async getScreenerRoute(screener) {
            await this.$router
                .push({
                    name: screener.type === 'COMPANY' ? COMPANY_SCREENER : 'Markets',
                    params: { screenerId: screener.id },
                })
                .catch((err) => {
                    this.$logger.error(err)
                })
            this.$emit('link')
        },
        performAction(action) {
            switch (action) {
                case 'saveAs':
                    this.screenerAction = action
                    break
                case 'rename':
                    this.screenerAction = action
                    break
                case 'delete':
                    this.screenerAction = action
                    break
            }
        },
        hideAllMenus() {
            this.editShownList.forEach((item) => {
                item.shown = false
            })
        },
        setScreenerAction() {
            this.$emit('action')
            this.screenerAction = ''
        },
        // processShown(id) {
        //   if (this.editShownList.length) {
        //     let target = this.editShownList.find(item => item.id === id);
        //     return this.editShownList.length > 0 ? target.shown : false
        //   }
        // },
    },
}
</script>

<style scoped>
.card-content {
    width: 100%;
    .top,
    .bottom {
        width: 100%;
        align-items: center;
        .head {
            width: 100%;
        }
    }
}
</style>
