import { useRouter } from 'vue-router'

export function entityRouteLink(entityId, tabName = 'profile') {
    const router = useRouter()
    return router.resolve({
        name: 'Research Company',
        params: { targetPk: entityId },
        query: { tabName: tabName },
    }).href
}
