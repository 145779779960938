<template>
    <div class="fundamental-data">
        <FundamentalData :options="tickerOptions.chartOptions" />
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { FundamentalData } from 'vue-tradingview-widgets'
import { useEntityStore } from '@/stores'
import { formatTicker } from '@/lib/financial'

const entityStore = useEntityStore()

const tickerOptions = reactive({
    chartOptions: {
        allow_symbol_change: true,
        symbol: formatTicker(entityStore.entity.stock_exchange, entityStore.entity.stock_ticker),
        autosize: true,
        width: '100%',
    },
})
</script>

<style lang="scss" scoped>
.fundamental-data {
    width: 100%;
}
</style>
