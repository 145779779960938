import * as MarketsApi from '@/api/sectors'
import * as SearchApi from '@/api/opensearch'
import { logger } from '@moatmetrics/vue-logger'
import { addChildrenToTree, getParentPathIds } from '@/lib/helpers.js'
import { getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import { defineStore } from 'pinia'

export const useMarketsStore = defineStore('markets', {
    state: () => ({
        cachedMarketRanks: {},
        cachedMarkets: {},
        currentMarket: null,
        overwriteMarket: null,
        marketNpeEntities: null,
        marketRanks: null,
        marketTabs: [],
        marketYearlyRevenue: [],
        selectedTreeMarketId: null,
        taxonomyTree: null,
        taxonomyTreeLoading: false,
    }),
    getters: {
        getCurrentMarket: (state) => {
            return state.currentMarket
        },
        getOverwriteMarket: (state) => {
            return state.overwriteMarket
        },
    },
    actions: {
        async resetState() {
            this.$reset()
        },
        addChildrenNode(children) {
            if (children && children.length === 0) {
                return
            }
            const pathIds = getParentPathIds(children[0].sectorPath)

            try {
                this.taxonomyTree = addChildrenToTree(this.taxonomyTree, pathIds, children)
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        setCurrentMarket(market, isOverwrite) {
            this.overwriteMarket = null
            this.currentMarket = null

            if (isOverwrite) {
                this.overwriteMarket = market
            } else {
                this.currentMarket = market
            }
        },
        setCachedMarkets(markets) {
            this.cachedMarkets = markets
        },
        setMarketRanks(marketRanks) {
            this.marketRanks = marketRanks
        },
        setCachedMarketRanks(cachedMarketRanks) {
            this.cachedMarketRanks = cachedMarketRanks
        },
        setSelectedTreeMarketId(market) {
            this.selectedTreeMarketId = market
        },
        setTaxonomyTree(taxonomyTree) {
            this.taxonomyTree = taxonomyTree
        },
        setTaxonomyTreeLoading(taxonomyTreeLoading) {
            this.taxonomyTreeLoading = taxonomyTreeLoading
        },
        // TODO audit get actions that are actually state getters and move appropiately
        async getMarket(marketId, isOverwrite) {
            try {
                const dslQuery = getOpenSearchQuery({
                    FC: ['aon_sector_pk'],
                    FT: ['eq'],
                    FV: [marketId],
                })
                const { data } = await SearchApi.searchSectors(dslQuery)
                const market = data.hits.hits.map((h) => h._source)[0]

                this.setCurrentMarket(market, isOverwrite)

                const cachedMarkets = { ...this.cachedMarkets }

                if (!cachedMarkets[marketId]) {
                    cachedMarkets[marketId] = market
                    this.setCachedMarkets(cachedMarkets)
                }

                return market
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getMarketNpeEntities({ marketId }) {
            try {
                const { data } = await MarketsApi.getSectorNpeEntities(marketId)

                this.marketNpeEntities = data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getMarketYearlyRevenue(marketId) {
            try {
                const { data } = await MarketsApi.getSectorYearlyRevenue(marketId)

                this.marketYearlyRevenue = data

                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getMarketRanks({ marketId, params }) {
            try {
                const companySort = params.SC?.length ? params.SC[0] : ''
                const { data: marketRanks } = await MarketsApi.querySectorEntities(marketId, params)

                this.setMarketRanks(marketRanks.results)

                const cachedMarketRanks = { ...this.cachedMarketRanks }
                const key = `${marketId}_${companySort}`

                if (!cachedMarketRanks[key]) {
                    cachedMarketRanks[key] = marketRanks.results
                    this.setCachedMarketRanks(cachedMarketRanks)
                }

                return marketRanks
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getTaxonomyTree(payload) {
            try {
                this.setTaxonomyTree(null)
                this.setTaxonomyTreeLoading(true)
                this.setSelectedTreeMarketId(payload.selectedMarketId)

                const { data: sector } = await MarketsApi.getSector(payload.parentMarketId)

                if (!sector) {
                    throw new Error(`Market not found: ${payload.parentMarketId}`)
                }

                const { data: children } = await MarketsApi.getSectorChildren(
                    payload.parentMarketId
                )

                sector['markets'] = children

                this.setTaxonomyTree(sector)
                await this.loadSelectedMarketsBranch(payload.selectedMarketId)

                return sector
            } catch (err) {
                logger.error(err)
                throw err
            } finally {
                this.setTaxonomyTreeLoading(false)
            }
        },
        async loadChildren(sectorId) {
            try {
                const { data: children } = await MarketsApi.getSectorChildren(sectorId)

                this.addChildrenNode(children)

                return children
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async loadSelectedMarketsBranch(selectedSectorId) {
            try {
                const { data: selectedSector } = await MarketsApi.getSector(selectedSectorId)

                if (!selectedSector) {
                    throw new Error(`Market not found: ${selectedSectorId}`)
                }

                let sectorPathIds = getParentPathIds(selectedSector.sectorPath)

                for await (let sectorId of sectorPathIds) {
                    await this.loadChildren(sectorId)
                }
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
    },
})
