<template>
    <div class="market-header">
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" />
        <AonContainer v-else>
            <AonRow>
                <AonCol
                    class="aon-col-7 py-0 pl-0"
                    justify-content="space-between"
                    align-items="center"
                    style="flex-wrap: wrap"
                >
                    <div class="sector-details d-flex flex-column align-items-start">
                        <Breadcrumb :market="breadcrumbMarket" color="grey04--text" />
                        <h4 class="grey08--text">
                            {{ market.aon_sector_name }}
                        </h4>
                        <Description
                            class="mt-2"
                            :title="market.aon_sector_name"
                            :description="market.description"
                            dark
                        />
                    </div>
                    <div class="sector-reach mt-4">
                        <div class="metric mr-2">
                            <font-awesome-icon
                                icon="fas fa-sack-dollar"
                                class="grey06--text"
                                size="sm"
                            />
                            <p class="grey08--text d-inline ml-1 mr-2">${{ marketRevenue }}</p>
                        </div>
                        <div v-if="market.participating_company_count" class="metric mr-2">
                            <font-awesome-icon
                                icon="fas fa-building"
                                class="grey06--text"
                                size="sm"
                            />
                            <p class="grey08--text d-inline ml-2 mr-1">
                                {{ market.participating_company_count.toLocaleString() }}
                            </p>
                            <p class="d-inline grey08--text">
                                {{
                                    market.participating_company_count > 1
                                        ? $t('marketSummary.metrics.companies')
                                        : $t('marketSummary.metrics.company')
                                }}
                            </p>
                        </div>
                    </div>
                </AonCol>
                <AonCol
                    class="aon-col-5 py-0 pr-0"
                    justify-content="space-between"
                    align-items="center"
                    style="flex-wrap: wrap"
                >
                    <div class="button-holder w-full d-flex justify-content-end">
                        <AonButton
                            class="mr-4"
                            :label="$t('marketSummary.viewTaxonomy')"
                            :text-with-icon="true"
                            :icon-options="{ iconStyle: 'fas', iconName: 'fa-sitemap' }"
                            @clicked="showMarketsDialog = true"
                        />
                        <AddToWatchlistButton
                            :id-values="[market.aon_sector_pk]"
                            id-type="Sector"
                            button-width="auto"
                        />
                    </div>
                </AonCol>
            </AonRow>
            <MarketsDialog
                v-if="showMarketsDialog"
                :selected-market-id="market.aon_sector_pk"
                :parent-market-id="market.ultimate_aon_sector_pk"
                @cancel="showMarketsDialog = false"
            />
        </AonContainer>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import NumberAbbreviate from 'number-abbreviate'
import MarketsDialog from '@/components/Taxonomy/MarketsDialog'
import Description from '@/components/Description'
import AddToWatchlistButton from '../Watchlists/WatchlistActions/AddToWatchlistButton'

const numberAbbreviate = new NumberAbbreviate(['K', 'M', 'B', 'T'])

export default {
    name: 'MarketHeader',
    components: {
        Breadcrumb,
        Description,
        MarketsDialog,
        AddToWatchlistButton,
    },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        market: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            showMarketsDialog: false,
        }
    },
    computed: {
        breadcrumbMarket() {
            return {
                sectorName: this.market.aon_sector_name,
                sectorPath:
                    typeof this.market.sector_path === 'array'
                        ? this.market.sector_path.map((p) => ({
                              sectorName:
                                  'aon_sector_name' in p ? p.aon_sector_name : p.sector_name,
                          }))
                        : [this.market.sector_path],
            }
        },
        marketRevenue() {
            if (!this.market) {
                return null
            }

            return numberAbbreviate.abbreviate(this.market.calculated_total_revenue)
        },
        marketMetrics() {
            let finalArr = []

            if (this.market.power_score) {
                finalArr.push({
                    title: this.$t(`definitionTooltips.power.title`),
                    definition: this.$t(`definitionTooltips.power.definition`),
                    moreInfo: this.$t(`definitionTooltips.power.more`),
                    score: this.market.power_score,
                })
            }
            if (this.market.velocity_score) {
                finalArr.push({
                    title: this.$t(`definitionTooltips.velocity.title`),
                    definition: this.$t(`definitionTooltips.velocity.definition`),
                    moreInfo: this.$t(`definitionTooltips.velocity.more`),
                    score: this.market.velocity_score,
                })
            }
            if (this.market.market_ti_score) {
                finalArr.push({
                    title: this.$t(`definitionTooltips.market.ti.title`),
                    definition: this.$t(`definitionTooltips.market.ti.definition`),
                    moreInfo: this.$t(`definitionTooltips.market.ti.more`),
                    score: this.market.market_ti_score,
                })
            }
            return finalArr
        },
    },
}
</script>

<style lang="scss" scoped>
.market-header {
    width: 100%;

    .button-holder {
        display: flex;
        width: 100%;
    }

    .sector-details,
    .sector-reach {
        display: flex;
        align-items: center;
    }

    .sector-reach {
        width: 100%;
    }

    .graph-holder {
        background: transparent !important;
    }

    .market-metrics {
        width: 100%;
        display: flex;

        p,
        .metric-icon {
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            :hover {
                color: white;
            }
        }
    }
}
</style>
