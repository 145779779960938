export function setVisibleContextMenu(type, bool) {
    localStorage.setItem(`${type}`, bool)
}

export function getVisibleContextMenu(type) {
    const item = localStorage[`${type}`]

    return item
}

export function removeVisibleContextMenu(type) {
    localStorage.removeItem(`${type}`)
}
